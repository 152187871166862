import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { useDispatch, useSelector } from "react-redux"
import { SET_REFERRAL_DIALOG, SET_SHOW_LOGIN } from "@/redux/features/auth/authSlice"
import { Link } from "react-router-dom"
import toast from "react-hot-toast"
import { LogOut, User, CircleDollarSign, Users, Music, HeartIcon, Info, EllipsisVertical, MessageCircleQuestion, ShieldCheck, ShieldAlert, MessageSquareWarning, Award, Gift } from "lucide-react"
import { Megaphone } from "../navbar/SocialIcons"
import { LazyLoadImage } from "react-lazy-load-image-component"

export function ProfileIcon() {
    const { user } = useSelector(state => state.auth)
    const dispatch = useDispatch()
    function logOut() {
        localStorage.removeItem('token')
        window.location.replace("/discover")
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                {!user?.is_guest ? <div className="cursor-pointer text-black dark:text-black font-bold aspect-square bg-green-400 dark:bg-pink-500 w-10 h-10 flex items-center justify-center rounded-full overflow-hidden capitalize " >{user?.profile_url ? <LazyLoadImage className="w-full object-cover" src={user?.profile_url} alt="profile" effect="blur" /> : user?.username?.slice(0, 2)}</div> : <div className="text-slate-200 dark:text-black" ><EllipsisVertical size={24} /></div>}
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-slate-900 dark:bg-gradient-to-br dark:from-pink-100 dark:to-pink-300 w-auto border-slate-700 dark:border-pink-700 text-slate-200 dark:text-black mr-4">
                <DropdownMenuGroup>
                    {!user?.is_guest ? <Link to={"/profile"} className=" w-full" >
                        <DropdownMenuItem className=" w-full flex items-center cursor-pointer focus:bg-green-400 dark:focus:bg-pink-500">
                            <User className="mr-2 h-4 w-4" />
                            <span>Profile</span>
                        </DropdownMenuItem>
                    </Link> : <button onClick={() => {
                        toast.error("Please login to see profile", { id: "guest" })
                        dispatch(SET_SHOW_LOGIN(true))
                    }} className="w-full" >
                        <DropdownMenuItem className=" w-full flex items-center cursor-pointer focus:bg-green-400 dark:focus:bg-pink-500">
                            <User className="mr-2 h-4 w-4" />
                            <span>Profile</span>
                        </DropdownMenuItem>
                    </button>}
                    {!user?.is_guest && <Link to={"/liked"} className="w-full" >
                        <DropdownMenuItem className=" flex items-center gap-2 cursor-pointer focus:bg-green-400 dark:focus:bg-pink-500">
                            <div className="relative">
                                <Music className="text-white mt-1" size={16} strokeWidth={2} />
                                <div className="absolute bottom-[1px] -right-0.5 w-2 h-2 bg-transparent text-green-400 rounded-full flex items-center justify-center">
                                    <HeartIcon strokeWidth={7} className="text-white fill-white mt-1" size={20} />
                                </div>
                            </div>
                            <span>Liked Songs</span>
                        </DropdownMenuItem>
                    </Link>}
                    <Link to={"/premium"} className="w-full" >
                        <DropdownMenuItem className=" flex items-center w-full cursor-pointer focus:bg-amber-300 dark:focus:bg-amber-500">
                            <Award className="mr-2 h-4 w-4" />
                            <span>Buy Premium</span>
                        </DropdownMenuItem>
                    </Link>
                    {!user?.is_guest ? <DropdownMenuItem className="md:hidden block cursor-pointer text-green-400 dark:text-green-700 focus:bg-green-400 dark:focus:bg-green-500 focus:text-black dark:focus:text-black">
                        <button onClick={() => dispatch(SET_REFERRAL_DIALOG(true))} className="flex items-center w-full" >
                            <CircleDollarSign className="mr-2 h-4 w-4" />
                            <span>Refer and Earn</span>
                        </button>
                    </DropdownMenuItem> : <button onClick={() => {
                        toast.error("Please login to refer", { id: "guest" })
                        dispatch(SET_SHOW_LOGIN(true))
                    }} className="flex items-center w-full px-2 py-1.5" >
                        <CircleDollarSign className="mr-2 h-4 w-4" />
                        <span>Refer and Earn</span>
                    </button>}
                    <Link to={"/affiliate"} >
                        <DropdownMenuItem className=" flex items-center w-full md:hidden cursor-pointer text-cyan-300 dark:text-cyan-900 focus:bg-cyan-300 dark:focus:bg-cyan-300 focus:text-black dark:focus:text-black">
                            <Users className="mr-2 h-4 w-4" />
                            <span>Become an Affiliate</span>
                        </DropdownMenuItem>
                    </Link>
                    <Link to={"/user/gifts"} className="w-full" >
                        <DropdownMenuItem className=" flex items-center w-full cursor-pointer focus:bg-green-400 dark:focus:bg-pink-500">
                            <Gift size={16} className="mr-2 h-4 w-4" />
                            <span>My Gifts</span>
                        </DropdownMenuItem>
                    </Link>
                    <Link to={"/advertisement-dashboard"} className="w-full" >
                        <DropdownMenuItem className=" flex items-center md:hidden cursor-pointer focus:bg-green-400 dark:focus:bg-pink-500">
                            <Megaphone className="mr-2 h-4 w-4 fill-violet-400 text-violet-400" />
                            <span>Advertisement</span>
                        </DropdownMenuItem>
                    </Link>
                    <Link to={"/about"} className="w-full" >
                        <DropdownMenuItem className="cursor-pointer focus:bg-green-400 dark:focus:bg-pink-500">
                            <Info className="mr-2 h-4 w-4" />
                            <span>About Us</span>
                        </DropdownMenuItem>
                    </Link>
                    <Link to={"/privacy-policy"} className="w-full" >
                        <DropdownMenuItem className=" flex items-center cursor-pointer focus:bg-green-400 dark:focus:bg-pink-500">
                            <ShieldCheck className="mr-2 h-4 w-4" />
                            <span>Privacy Policy</span>
                        </DropdownMenuItem>
                    </Link>
                    <Link to={"/terms-and-conditions"} className="w-full" >
                        <DropdownMenuItem className=" flex items-center cursor-pointer focus:bg-green-400 dark:focus:bg-pink-500">
                            <ShieldAlert className="mr-2 h-4 w-4" />
                            <span>Terms and Condition</span>
                        </DropdownMenuItem>
                    </Link>
                    <Link to={"/contact"} className="w-full" >
                        <DropdownMenuItem className=" flex items-center cursor-pointer focus:bg-green-400 dark:focus:bg-pink-500">
                            <MessageCircleQuestion className="mr-2 h-4 w-4" />
                            <span>Contact Us</span>
                        </DropdownMenuItem>
                    </Link>
                    <Link to={"/feedback"} className="w-full" >
                        <DropdownMenuItem className=" flex items-center cursor-pointer focus:bg-green-400 dark:focus:bg-pink-500">
                            <MessageSquareWarning className="mr-2 h-4 w-4" />
                            <span>Feedback</span>
                        </DropdownMenuItem>
                    </Link>
                    {!user?.is_guest && <DropdownMenuItem onClick={logOut} className="cursor-pointer text-white dark:text-rose-800 focus:text-black dark:focus:text-rose-900 focus:bg-gray-300 dark:focus:bg-rose-400">
                        <LogOut className="mr-2 h-4 w-4" />
                        <span>Logout</span>
                    </DropdownMenuItem>}
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
