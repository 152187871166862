const GenreCardSkeleton = () => {
  return (
    <div className="relative h-full overflow-hidden rounded-xl border border-slate-700/50 dark:border-pink-300/50 bg-slate-800/50 dark:bg-pink-200/30">
      <div className="absolute inset-0 bg-gradient-to-t from-slate-900 via-slate-900/80 to-transparent dark:from-pink-500/80 dark:via-pink-400/60 dark:to-transparent z-10" />
      <div className="absolute inset-0 w-full h-full bg-slate-700/50 dark:bg-pink-300/30" />
      <div className="relative z-20 h-full flex flex-col justify-end px-4 py-2">
        <div className="h-3 w-1/2 bg-slate-700/50 dark:bg-pink-300/50 rounded-lg mb-2" />
      </div>
    </div>
  );
};

export default GenreCardSkeleton;