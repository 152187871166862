import TopBar from '@/components/navbar/TopBar';
import BottomBar from '@/components/sidebar/BottomBar';

const UserGiftsSkeletonLoader = () => {
    // Create an array of 3 items to show skeleton cards
    const skeletonCards = Array(10).fill(null);
  
    return (
      <div className="min-h-screen bg-gradient-to-bl from-slate-900 via-slate-950 to-black p-6">
        <TopBar heading="Gift Codes" />
        
        <div className="max-w-7xl mx-auto mt-16 mb-16 md:mb-0">
          <div className="flex items-center justify-between mb-8">
            <div className="h-9 w-48 bg-slate-800/50 rounded-lg animate-pulse" />
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {skeletonCards.map((_, index) => (
              <div
                key={index}
                className="bg-gradient-to-b from-slate-900 via-slate-950 to-black border border-slate-800/20 rounded-lg shadow-lg shadow-amber-900/10 overflow-hidden"
              >
                <div className="p-6">
                  {/* Header section with icon and status */}
                  <div className="flex items-center justify-between mb-4 gap-2">
                    <div className="flex items-center space-x-2 min-w-0">
                      <div className="p-2 rounded-full bg-slate-800/50 w-10 h-10 animate-pulse" />
                      <div className="h-7 w-24 bg-slate-800/50 rounded-lg animate-pulse" />
                    </div>
                    <div className="h-7 w-20 bg-slate-800/50 rounded-full animate-pulse" />
                  </div>
                  
                  {/* Duration and Amount section */}
                  <div className="mt-6 space-y-4">
                    <div className="flex justify-between items-center">
                      <div className="h-5 w-16 bg-slate-800/50 rounded animate-pulse" />
                      <div className="h-7 w-24 bg-slate-800/50 rounded-full animate-pulse" />
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="h-5 w-14 bg-slate-800/50 rounded animate-pulse" />
                      <div className="h-7 w-20 bg-slate-800/50 rounded-full animate-pulse" />
                    </div>
                  </div>
                  
                  {/* Code section */}
                  <div className="mt-6 pt-4 border-t border-slate-800">
                    <div className="flex justify-between items-center">
                      <div className="h-5 w-10 bg-slate-800/50 rounded animate-pulse" />
                      <div className="h-9 w-32 bg-slate-800/50 rounded-lg animate-pulse" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <BottomBar />
      </div>
    );
  };

export default UserGiftsSkeletonLoader;