import { useEffect, useState } from 'react';
import successAni from "../../assets/success.json"
import Lottie from 'lottie-react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { logEvent } from 'firebase/analytics';
import { analytics } from '@/firebase';
import { trackMetaEvent } from '@/utils/metaPixel';
import { trackLinkedInEvent } from '@/utils/linkedinTag';
import { trackRedditEvent } from '@/utils/redditPixel';
import { trackTwitterEvent } from '@/utils/twitterPixel';
const PaymentSuccess = () => {
    const [seconds, setSeconds] = useState(8);

    const { token } = useParams();
    console.log(token)

    useEffect(() => {

        const intervalId = setInterval(() => {
            setSeconds(seconds => seconds - 1);
        }, 1000);

        if (seconds <= 0) {
            window.location.replace("/creations")
            clearInterval(intervalId)
        }
        return () => clearInterval(intervalId);
    }, [seconds]);

    useEffect(() => {
        async function verifyPayment() {

            const utmParams = JSON.parse(localStorage.getItem('utmParams')) || {};
            const utmSource = utmParams.utm_source || 'unknown';
            const utmMedium = utmParams.utm_medium || 'unknown';
            const utmCampaign = utmParams.utm_campaign || 'unknown';
            const utmContent = utmParams.utm_content || 'unknown';

            try {
                const { data } = await axios.get(`/confirmsubs`, { headers: { "Authorization": `Bearer ${token}` } })
                if (data?.verify) {
                    logEvent(analytics, 'checkout_success', {
                        method: 'subscription',
                        source: utmSource,
                        medium: utmMedium,
                        campaign: utmCampaign,
                        content: utmContent,
                    })
                    if (utmSource.includes('meta')) {
                        trackMetaEvent('Purchase', {
                            method: 'subscription',
                        })
                    } else if (utmSource.includes('linkedin')) {
                        trackLinkedInEvent('checkout_success', {
                            method: 'subscription',
                            source: utmSource,
                            medium: utmMedium,
                            campaign: utmCampaign,
                            content: utmContent,
                            event: 'checkout_success',
                            conversion_id: 19113220
                        })
                    } else if (utmSource.includes('reddit')) {
                        trackRedditEvent('Purchase', {
                            method: 'subscription',
                        })
                    } else if (utmSource.includes('twitter')) {
                        trackTwitterEvent('tw-p774a-p8bip', {
                            currency: 'USD',
                            value: data?.amount,
                        });
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        verifyPayment()
    }, [token])

    const handleClick = () => {
        window.location.replace("/creations")
    }

    return (
        <div className=" font-poppins flex justify-center items-center h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 text-slate-300 px-4">
            <div className="flex flex-col items-center">
                <div className='max-w-xs w-full' >
                    <Lottie animationData={successAni} loop={false} />
                </div>
                <h1 className="text-3xl xs:text-4xl text-center text-slate-50 font-bold mt-4 relative -top-16">Payment Successful!</h1>
                <p className='mt-2 relative -top-16' >You will be redirected in {seconds} seconds.</p>
                <button onClick={handleClick} className="text-green-400 font-medium mt-2 relative -top-16" >Go Home</button>
            </div>
        </div>
    );
};

export default PaymentSuccess;
