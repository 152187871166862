import { useDispatch, useSelector } from 'react-redux';
import { SET_ACHIEVEMENTS, SET_CLAIMABLE_ACHIEVEMENT } from '../../redux/features/theme/extradialogs';
import { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import AchievementSkeleton from './AchievementSkeleton';
import SUCCESS_ANIMATION from '../../assets/success.json';
import SUCCESS_SOUND from '../../assets/success.mp3';
import Lottie from 'lottie-react';
import { Trophy, Medal, Crown, Target, Award, Sparkles, CheckCircle, Lock, RefreshCw } from 'lucide-react';

const AchievementTab = ({ tab }) => {
    const dispatch = useDispatch();
    const { achievements } = useSelector((state) => state.extradialogs);
    const [isLoading, setIsLoading] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);
    const [currentAchievement, setCurrentAchievement] = useState(null);

    const fetchAchievements = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get('/achievements/progress', {
                headers: { 'accept': 'application/json', 'Authorization': `Bearer ${token}` }
            });
            dispatch(SET_ACHIEVEMENTS(response.data));
            const claimable = response.data.filter(achievement => achievement.can_claim);
            if (claimable.length > 0) {
                dispatch(SET_CLAIMABLE_ACHIEVEMENT(claimable));
            }
        } catch (error) {
            console.error('Error fetching achievements:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (tab === "achievements") {
            fetchAchievements();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    const nextMilestone = (achievement) => {
        const nextMilestone = achievement.milestones.find(milestone => !milestone.reached);
        return nextMilestone;
    };

    const claimableMilestone = (achievement) => {
        const claimableMilestone = achievement.milestones.find(m => m.reached && !m.claimed);
        return claimableMilestone;
    };

    const handleClaim = async (achievement) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`/achievements/claim/${achievement.achievement_type}`, null, {
                headers: { 'accept': 'application/json', 'Authorization': `Bearer ${token}` }
            });

            if (response.data.success) {
                // Show animation
                setShowAnimation(true);
                setCurrentAchievement(achievement);
                setTimeout(() => {
                    setShowAnimation(false);
                    setCurrentAchievement(null);
                }, 2500);
                // Play success sound
                const audio = new Audio(SUCCESS_SOUND);
                audio.play();

                // Update claim status for this specific milestone
                const updatedAchievements = achievements.map(ach => {
                    if (ach.achievement_type === achievement.achievement_type) {
                        // Create a copy of the achievement as a new object for updating the claim
                        const updatedAchievement = { ...ach };
                        // Update only the milestone that was claimed
                        updatedAchievement.milestones = ach.milestones.map(m => {
                            if (m.milestone === claimableMilestone(achievement).milestone) {
                                return { ...m, claimed: true };
                            }
                            return m;
                        });
                        // Check if there are still unclaimed milestones that have been reached
                        const hasClaimableMilestones = updatedAchievement.milestones.some(m =>
                            m.reached && !m.claimed
                        );
                        // Update can_claim flag
                        updatedAchievement.can_claim = hasClaimableMilestones;
                        return updatedAchievement;
                    }
                    return ach;
                });
                dispatch(SET_ACHIEVEMENTS(updatedAchievements));
                dispatch(SET_CLAIMABLE_ACHIEVEMENT(updatedAchievements));
                toast.success(`${claimableMilestone(achievement).description} claimed successfully`);
            }
        } catch (error) {
            console.error('Error claiming milestone:', error);
        }
    };

    const getClaimableCount = () => {
        const claimableCount = achievements.filter(achievement => achievement.can_claim).length;
        return claimableCount;
    };

    return (
        <>
            <div className={` flex items-center justify-center transition-opacity duration-300 `}>
                {showAnimation && (
                    <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black/50">
                        <Lottie animationData={SUCCESS_ANIMATION} loop={false} style={{ width: 500, height: 500 }} />
                        <p className="text-white text-2xl font-bold">
                            {claimableMilestone(currentAchievement)?.description || 'Achievement Claimed!'}
                        </p>
                    </div>
                )}
                <div className={`relative w-full overflow-y-auto transform transition-transform duration-300`}>
                    {/* Header */}
                    <div className="flex items-center justify-between md:mb-6 mb-4">
                        <h2 className="text-3xl font-bold text-gradient bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-teal-400">
                            Your Achievements
                        </h2>
                        <div className="flex items-center gap-4">
                            <button onClick={fetchAchievements} className="text-gray-300 hover:text-white transition-colors" disabled={isLoading}>
                                <RefreshCw className={`w-6 h-6 ${isLoading ? 'animate-spin text-green-400' : ''}`} />
                            </button>
                        </div>
                    </div>

                    {/* Stats Overview */}
                    <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center place-items-center md:gap-6 gap-2 w-full md:mb-6 mb-3">
                        <div className="bg-gradient-to-br from-blue-900/30 to-cyan-900/30 rounded-xl md:p-6 p-2 border border-blue-500/20 w-full">
                            <div className="flex items-center gap-4">
                                <div className="bg-blue-500/20 p-3 rounded-lg">
                                    <Award className="w-6 h-6 text-blue-400" />
                                </div>
                                <div>
                                    <p className="text-gray-400">Total Achievements</p>
                                    <h3 className="text-2xl font-bold">{achievements?.length || 0}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gradient-to-br from-amber-900/30 to-yellow-900/30 rounded-xl md:p-6 p-2 border border-yellow-500/20 w-full">
                            <div className="flex items-center gap-4">
                                <div className="bg-yellow-500/20 p-3 rounded-lg">
                                    <Sparkles className="w-6 h-6 text-yellow-400" />
                                </div>
                                <div>
                                    <p className="text-gray-400">Ready to Claim</p>
                                    <h3 className="text-2xl font-bold">{getClaimableCount()}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Loading State */}
                    {isLoading && (
                        <AchievementSkeleton />
                    )}
                    {/* Achievements Grid */}
                    {!isLoading && achievements && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 pb-20">
                            {achievements.map((achievement, index) => (
                                <div key={index} className={`relative w-full overflow-hidden rounded-lg md:p-5 p-2 pt-3 transition-all duration-300 hover:shadow-lg ${achievement.can_claim ? 'bg-gradient-to-b from-green-950/20 to-slate-950/40 border-1 border border-green-800/40 '
                                    : 'bg-slate-900/60'}`}>
                                    <div className="flex flex-col gap-4 justify-between w-full items-start mb-3">
                                        <div className="flex items-center gap-2 w-full">
                                            <Trophy className="w-4 h-4" />
                                            <h3 className="md:text-xl text-md font-bold text-white capitalize truncate w-full">
                                                {achievement?.description}
                                            </h3>
                                            {(achievement?.can_claim) ? <span className="text-xs text-amber-400">
                                                <Crown className="w-6 h-6" />
                                            </span> : <span className="text-xs text-purple-400">
                                                <Medal className="w-6 h-6" />
                                            </span>}
                                        </div>
                                        <span className="text-xs text-gray-400">
                                            {achievement?.can_claim && (
                                                <span className="text-sm font-normal text-amber-400 border border-amber-400 rounded-md px-2 py-1">
                                                    ★ Ready to claim!
                                                </span>
                                            )}
                                        </span>
                                    </div>

                                    {/* Progress */}
                                    <div className="mb-4">
                                        <div className="flex justify-between items-center mb-1">
                                            <span className="text-gray-300 text-sm md:text-base">Current Progress</span>
                                            <span className="text-gray-300 font-medium">
                                                <span className="text-green-400">{achievement?.current_count}</span> /{nextMilestone(achievement)?.milestone}
                                            </span>
                                        </div>
                                        <div className="md:h-2 h-1 w-full bg-gray-700 rounded-full overflow-hidden">
                                            <div className={`h-full rounded-full ${achievement?.can_claim ? 'bg-gradient-to-r from-green-400 to-teal-400'
                                                : 'bg-gradient-to-r from-green-500 to-teal-500'}`} style={{ width: `${achievement?.progress_percentage}%` }}>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Milestones */}
                                    <div className="h-[220px] overflow-hidden">
                                        <div className='flex items-center justify-between mb-4'>
                                            <h4 className="text-gray-200 font-semibold flex items-center gap-2">
                                                <Target className="w-4 h-4 text-teal-400" />
                                                <span className="bg-gradient-to-r from-gray-200 to-gray-300 bg-clip-text text-transparent">Milestones</span>
                                            </h4>
                                            {achievement?.can_claim && (
                                                <button 
                                                    onClick={() => { handleClaim(achievement) }} 
                                                    className="py-1 px-6 bg-gradient-to-r from-green-500 to-teal-600 text-black font-medium rounded-md 
                                                               hover:from-green-600 hover:to-teal-700 transition-all duration-300 shadow-md shadow-green-900/20
                                                               focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 flex items-center gap-1">
                                                    <Sparkles className="w-3.5 h-3.5" />
                                                    <span>Claim</span>
                                                </button>
                                            )}
                                        </div>
                                        <div className="h-[160px] overflow-y-auto pr-2 custom-scrollbar space-y-3">
                                            {achievement?.milestones?.map((milestone, idx) => (
                                                <div 
                                                    key={idx} 
                                                    className={`p-3 rounded-lg transition-all duration-300 
                                                               ${achievement?.current_count >= milestone.milestone 
                                                                   ? 'bg-gradient-to-r from-slate-900/80 to-green-950/30 border border-green-800/30' 
                                                                   : 'bg-slate-800/40 border border-slate-700/20'}`}
                                                >
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex items-center gap-2 max-w-[65%]">
                                                            <div className={`${milestone?.reached ? 'text-green-400' : 'text-slate-500'}`}>
                                                                {(milestone?.reached && !milestone?.claimed) && 
                                                                    <CheckCircle className="w-4 h-4 text-green-400 animate-pulse" />}
                                                                {(milestone?.reached && milestone?.claimed) && 
                                                                    <Award className="w-4 h-4 text-amber-400" />}
                                                                {!milestone?.reached && 
                                                                    <Lock className="w-4 h-4" />}
                                                            </div>
                                                            <span className={`text-sm truncate ${milestone?.reached ? 'text-gray-200' : 'text-gray-400'}`}>
                                                                {milestone?.description}
                                                            </span>
                                                        </div>
                                                        <div className="flex items-center gap-2">
                                                            <div className="flex flex-row items-center font-medium justify-end gap-1 capitalize">
                                                                <span className="text-amber-400 text-xs">
                                                                    {milestone?.reward_value}
                                                                </span>
                                                                <span className="text-sm text-amber-400/70">
                                                                    {milestone?.reward_type}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3 flex items-center gap-2">
                                                        <div className="flex-1 h-1.5 bg-gray-800 rounded-full overflow-hidden">
                                                            <div 
                                                                className={`h-full rounded-full transition-all duration-500 
                                                                         ${achievement?.current_count >= milestone?.milestone 
                                                                             ? 'bg-gradient-to-r from-green-500 to-teal-500' 
                                                                             : 'bg-gradient-to-r from-slate-600 to-slate-700'}`} 
                                                                style={{ width: `${Math.min(100, (achievement?.current_count / milestone?.milestone) * 100)}%` }} 
                                                            />
                                                        </div>
                                                        <span className="text-xs text-gray-400 min-w-[40px] text-right">
                                                            {achievement?.current_count >= milestone?.milestone 
                                                                ? `${milestone?.milestone}/${milestone?.milestone}` 
                                                                : `${achievement?.current_count}/${milestone?.milestone}`}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Empty State */}
                    {!isLoading && (!achievements || achievements?.length === 0) && (
                        <div className="flex flex-col items-center justify-center py-20 text-center">
                            <div className="w-20 h-20 mb-4 rounded-full bg-gray-800 flex items-center justify-center">
                                <span className="text-3xl">🏆</span>
                            </div>
                            <h3 className="text-xl font-bold text-white mb-2">No Achievements Yet</h3>
                            <p className="text-gray-400 max-w-md">
                                Continue using the app to unlock achievements and earn rewards!
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default AchievementTab;