import Lottie from "lottie-react"
import failedAni from "../../assets/failure.json"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const GiftFailure = () => {
    const [seconds, setSeconds] = useState(8);
    const navigate = useNavigate();

    useEffect(() => {

        const intervalId = setInterval(() => {
            setSeconds(seconds => seconds - 1);
        }, 1000);

        if (seconds <= 0) {
            navigate('/premium');  // Redirect to home or other desired path
            clearInterval(intervalId)
        }
        return () => clearInterval(intervalId);
    }, [seconds, navigate]);

    return (
        <div className=" font-poppins flex justify-center items-center h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 text-slate-300 px-4">
            <div className="flex flex-col items-center">
                <div className='max-w-[220px] w-full' >
                    <Lottie animationData={failedAni} loop={true} />
                </div>
                <h1 className="text-3xl xs:text-4xl text-slate-50 font-bold mt-8 ">Payment Failed!</h1>
                <p className='mt-2 ' >You will be redirected in {seconds} seconds.</p>
                <Link className="text-green-400 font-medium mt-2" to="/premium" >Retry</Link>
            </div>
        </div>
    )
}

export default GiftFailure