import axios from "axios";
import { Copy, LoaderIcon } from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const ViewGiftCode = ({ activeTab }) => {
    const [giftResponse, setGiftResponse] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchGiftCodes = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`/user/inventory`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            setGiftResponse(response.data.gift_subscriptions || []);
        } catch (error) {
            console.error('Error fetching gift codes:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (activeTab === 'viewGiftCodes') {
            fetchGiftCodes();
        }
    }, [activeTab]);

    const handleCopy = async (url) => {
        try {
            if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(url);
            } else {
                // Fallback for mobile devices and older browsers
                const textArea = document.createElement('textarea');
                textArea.value = url;
                textArea.style.position = 'fixed';
                textArea.style.left = '-999999px';
                textArea.style.top = '-999999px';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                document.execCommand('copy');
                textArea.remove();
            }
            toast.success("Gift Code Copied to Clipboard!", { id: "gift-code-copy", duration: 2000 });
        } catch (err) {
            toast.error("Failed to Copy Gift Code!", { id: "gift-code-copy", duration: 2000 });
        }
    };

    return (
        <div className="w-full mt-4 space-y-3">
            <h3 className="sm:text-sm text-xs font-semibold text-white mb-4 px-1">Share these gift codes with your loved ones and surprise them with a subscription!</h3>
            {loading ? (
                <div className="flex justify-center py-6">
                    <LoaderIcon className="w-8 h-8 text-green-400 animate-spin" />
                </div>
            ) : giftResponse.length === 0 ? (
                <div className="text-center py-6 text-slate-400">
                    No gift codes found in your inventory
                </div>
            ) : (
                giftResponse.filter(status => status.status === 'pending').map((code) => (
                    <div key={code.gift_code} className="group relative bg-slate-950/90 rounded-md sm:px-3 px-1.5 sm:py-2.5 py-1.5">
                        <div className="flex items-center justify-between gap-2">
                            <div className="flex-1 overflow-hidden">
                                <p className="w-full sm:text-sm text-xs text-green-400 font-medium cursor-pointer hover:bg-slate-900/70 transition-colors truncate">
                                    {`https://soundofmeme.com/redeem/success/${code.gift_code}`}
                                </p>
                            </div>
                            <button onClick={() => handleCopy(`https://soundofmeme.com/redeem/success/${code.gift_code}`)} className="flex-shrink-0 text-green-400 hover:text-green-400 p-1">
                                <Copy size={16} />
                            </button>
                        </div>
                    </div>
                ))
            )}
        </div>
    )
}

export default ViewGiftCode
