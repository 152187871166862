import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    term: "",
    artists: [],
    genres: [],
    latestSongs: [], 
    searchSongs: [],
    searchPlaylists: [],
    searchUsers: [],
    totalSearchSongs: null,
    totalSearchPlaylists: null,
    totalSearchUsers: null,
}

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        SET_SEARCHSONGS(state, action) {
            state.searchSongs = action.payload
        },
        ADD_SEARCHSONGS(state, action) {
            const newSongs = action.payload.filter(
                song => !state.searchSongs.some(s => s.song_id === song.song_id)
            );
            state.searchSongs = [...state.searchSongs, ...newSongs];
        },
        SET_SEARCHPLAYLISTS(state, action) {
            state.searchPlaylists = action.payload
        },
        ADD_SEARCHPLAYLISTS(state, action) {
            const newPlaylists = action.payload.filter(
                playlist => !state.searchPlaylists.some(p => p.playlist_id === playlist.playlist_id)
            );
            state.searchPlaylists = [...state.searchPlaylists, ...newPlaylists];
        },
        SET_SEARCHUSERS(state, action) {
            state.searchUsers = action.payload
        },
        ADD_SEARCHUSERS(state, action) {
            const newUsers = action.payload.filter(
                user => !state.searchUsers.some(u => u.email === user.email)
            );
            state.searchUsers = [...state.searchUsers, ...newUsers];
        },
        SET_TOTALSEARCHSONGS(state, action) {
            state.totalSearchSongs = action.payload
        },
        SET_TOTALSEARCHPLAYLISTS(state, action) {
            state.totalSearchPlaylists = action.payload
        },
        SET_TOTALSEARCHUSERS(state, action) {
            state.totalSearchUsers = action.payload
        },

        SET_TERM(state, action) {
            state.term = action.payload
        },
        SET_ARTISTS(state, action) {
            state.artists = action.payload
        },
        ADD_ARTISTS(state, action) {
            state.artists = [...state.artists, ...action.payload]
        },
        SET_GENRES(state, action) {
            state.genres = action.payload
        },
        SET_LATESTSONGS(state, action) {
            state.latestSongs = action.payload
        },
        ADD_GENRES(state, action) {
            state.genres = [...state.genres, ...action.payload]
        },
        ADD_LATESTSONGS(state, action) {
            state.latestSongs = [...state.latestSongs, ...action.payload]
        }
    }
});

export const {
    SET_SEARCHSONGS,
    ADD_SEARCHSONGS,
    SET_TERM,
    SET_ARTISTS,
    SET_GENRES,
    SET_LATESTSONGS,
    ADD_ARTISTS,
    ADD_GENRES,
    ADD_LATESTSONGS,
    SET_SEARCHPLAYLISTS,
    ADD_SEARCHPLAYLISTS,
    SET_SEARCHUSERS,
    ADD_SEARCHUSERS,
    SET_TOTALSEARCHSONGS,
    SET_TOTALSEARCHPLAYLISTS,
    SET_TOTALSEARCHUSERS
} = searchSlice.actions

export default searchSlice.reducer