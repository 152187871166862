import TopBar from '@/components/navbar/TopBar';
import BottomBar from '@/components/sidebar/BottomBar';
import PlaylistsComponent from './PlaylistComponent';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from 'firebase/analytics';
import { analytics } from '@/firebase';
import { useEffect } from 'react';
import { SET_OTPCARD, SET_SHOW_LOGIN } from '@/redux/features/auth/authSlice';
import Lottie from 'lottie-react';
import musicAni from "../../assets/music.json"
import { trackMetaEvent } from '@/utils/metaPixel';
import { trackLinkedInEvent } from '@/utils/linkedinTag';
import { trackRedditEvent } from '@/utils/redditPixel';
import { LogIn } from 'lucide-react';

const Playlist = () => {
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (!user && !token) {
            dispatch(SET_SHOW_LOGIN(true));
        }
        if (user && token) {
            dispatch(SET_SHOW_LOGIN(false))
        }
        window.scrollTo(0, 0);
        logEvent(analytics, 'page_view', { page_title: 'Playlist Page' })
        trackMetaEvent('PageView', { page_title: 'Playlist Page' })
        trackLinkedInEvent('page_view', {
            event: 'page_view',
            page_title: 'Playlist Page',
        })
        trackRedditEvent('PageVisit', {
            page_title: 'Playlist Page',
        })
    }, [user, dispatch]);

    useEffect(() => {
        const otpToken = sessionStorage.getItem('otpToken')
        if (otpToken) {
            dispatch(SET_SHOW_LOGIN(true));
            dispatch(SET_OTPCARD(true));
        }
    }, [dispatch])

    return (
        <div className='relative min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 dark:bg-gradient-to-br dark:from-pink-100 dark:to-pink-200 overflow-hidden flex flex-col w-full'>
            <TopBar heading={"Play Lists"} />
            {user?.is_guest ? (
                <div className='px-4 flex items-center justify-center flex-col h-screen' >
                    <div className='max-w-[250px] w-full ' >
                        <Lottie animationData={musicAni} />
                    </div>
                    <h2 className='text-center text-xl font-medium text-slate-100 mt-4' >Login to view your playlists...</h2>
                    <button onClick={() => dispatch(SET_SHOW_LOGIN(true))} className='mt-2 text-green-400 font-medium flex items-center justify-center gap-1 underline' >Login <LogIn className='mt-1' /></button>
                </div>
            ) : (
                <div className='pt-24 px-4'>
                    <PlaylistsComponent />
                    <BottomBar />
                </div>
            )}
        </div>
    );
};

export default Playlist;