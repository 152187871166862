import TopBar from '@/components/navbar/TopBar';
import BottomBar from '@/components/sidebar/BottomBar';
import axios from 'axios';
import { Check, CheckCheck, Copy, Gift, Award } from 'lucide-react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import UserGiftsSkeletonLoader from './UserGiftsSkeletonLoader';

const UserGifts = () => {
  const [giftCodes, setGiftCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Helper function to get plan name based on plan_type
  const getPlanName = (planType) => {
    const plans = {
      1: 'Premium',
      2: 'Gold',
      3: 'Platinum'
    };
    return plans[planType] || 'Unknown';
  };

  useEffect(() => {
    const fetchGiftCodes = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/usergiftcodes`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setGiftCodes(response.data.gift_codes);
      } catch (error) {
        console.error('Error fetching gift codes:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchGiftCodes();
  }, []);

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    toast.success('Code copied to clipboard');
  };

  // Helper function to get gradient based on plan type
  const getCardGradient = (planType) => {
    const gradients = {
      1: 'from-slate-900 via-slate-950 to-black border-green-500/20',
      2: 'from-slate-900 via-slate-950 to-black border-green-500/30',
      3: 'from-slate-900 via-slate-950 to-black border-green-500/40'
    };
    return gradients[planType] || 'from-slate-900 via-slate-950 to-black border-amber-500/10';
  };

  if (isLoading) {
    return <UserGiftsSkeletonLoader />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-bl from-slate-900 via-slate-950 to-black p-6">
      <TopBar heading="Gift Codes" />
      
      <div className="max-w-7xl mx-auto mt-16 mb-16 md:mb-0">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-white">
            Your Gift Codes
          </h1>
        </div>
        
        {giftCodes.length === 0 ? (
          <div className="bg-slate-900/50 rounded-2xl p-10 text-center border border-slate-800">
            <Gift className="text-amber-500/50 text-5xl mx-auto mb-4" />
            <p className="text-slate-400">No gift codes found</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {giftCodes.map((gift) => (
              <div
                key={gift.coupon_code}
                className={`bg-gradient-to-b ${getCardGradient(gift.plan_type)} rounded-lg shadow-lg shadow-amber-900/10 border overflow-hidden transition-all duration-300`}
              >
                <div className="p-6">
                  <div className="flex items-center justify-between mb-4 gap-2">
                    <div className="flex items-center space-x-2 min-w-0">
                      <div className={`p-2 rounded-full flex-shrink-0 ${gift.is_active ? 'bg-emerald-400/10' : 'bg-gray-500/10'}`}>
                        {gift.is_active ? (
                          <Gift className="text-emerald-400" />
                        ) : (
                          <Award className="text-gray-400" />
                        )}
                      </div>
                      <span className={`font-bold text-xl truncate ${gift.is_active ? 'bg-gradient-to-r from-emerald-400 to-green-400 bg-clip-text text-transparent' : 'text-gray-400'}`}>
                        {getPlanName(gift.plan_type)}
                      </span>
                    </div>
                    {gift.is_active ? (
                      <span className="flex items-center text-emerald-400 bg-emerald-400/10 px-3 py-1 rounded-full text-sm font-medium whitespace-nowrap flex-shrink-0">
                        <Check className="mr-1" /> Active
                      </span>
                    ) : (
                      <span className="flex items-center text-gray-400 bg-gray-500/10 px-3 py-1 rounded-full text-sm font-medium whitespace-nowrap flex-shrink-0">
                        <CheckCheck className="mr-1" /> Inactive
                      </span>
                    )}
                  </div>
                  
                  <div className="mt-6 space-y-4">
                    <div className="flex justify-between items-center text-slate-300">
                      <span className="text-slate-400">Duration:</span>
                      <span className="font-medium bg-slate-800/50 px-3 py-1 rounded-full">
                        {gift.duration_months} {gift.duration_months === 1 ? 'month' : 'months'}
                      </span>
                    </div>
                    <div className="flex justify-between items-center text-slate-300">
                      <span className="text-slate-400">Amount:</span>
                      <span className="font-medium bg-slate-800/50 px-3 py-1 rounded-full">
                        {gift.amount}
                      </span>
                    </div>
                  </div>
                  
                  <div className="mt-6 pt-4 border-t border-slate-800">
                    <div className="flex justify-between items-center group">
                      <p className="text-sm text-slate-400">Code:</p>
                      <div 
                        className={`flex min-w-0 items-center space-x-2 bg-slate-800/30 rounded-lg px-3 py-2 ${
                          gift.is_active ? 'cursor-pointer hover:bg-slate-800/50' : 'cursor-not-allowed opacity-75'
                        } transition-colors`}
                        onClick={() => {
                          if (gift.is_active) {
                            copyToClipboard(`https://soundofmeme.com/redeem/success/${gift.coupon_code}`);
                          }
                        }}
                      >
                        <span className={`text-sm font-mono ${gift.is_active ? 'text-green-400' : 'text-gray-400'} truncate`}>
                          {gift.coupon_code}
                        </span>
                        {!gift.is_active ? (
                          <Check className="text-gray-400" />
                        ) : (
                          <Copy className="text-green-400 min-w-10" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <BottomBar />
    </div>
  );
};

export default UserGifts;