// 'use strict';

import { createContext, useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from './ui/dialog';
import { DialogOverlay } from '@radix-ui/react-dialog';
import { useLocation } from 'react-router-dom';

const ThemeContext = createContext({ theme: 'light', toggleTheme: () => null, isValentinePeriod: false });
// eslint-disable-next-line react-refresh/only-export-components
export const useTheme = () => useContext(ThemeContext);
export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('light');
  const [showValentineDialog, setShowValentineDialog] = useState(false);
  const [isValentinePeriod, setIsValentinePeriod] = useState(false);
  const location = useLocation();

  // Check if current date is within Valentine's period (Feb 7-15)
  const checkValentinePeriod = () => {
    const today = new Date();
    const year = today.getFullYear();
    const valentineStart = new Date(year, 0, 7);  // Month is 0-indexed (1 = February)
    const valentineEnd = new Date(year, 0, 15);   // February 15th, 00:00
    // Remove time part for precise date comparison
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    // Check if today falls within the Valentine period
    const isValentinePeriod = (todayDate >= valentineStart) && (todayDate < valentineEnd);
    return isValentinePeriod;
  };
  

  // Toggle theme function
  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      setTheme('light');
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  };

  // Check for Valentine's period, saved theme preference, or system preference
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    const isValentine = checkValentinePeriod();
    setIsValentinePeriod(isValentine);
    if (isValentine) {
      setTheme('dark');
      document.documentElement.classList.add('dark');
      if (!localStorage.getItem('valentineDialogSeen')) {
        setShowValentineDialog(true);
        localStorage.setItem('valentineDialogSeen', 'true');
      }
    } else if (savedTheme) {
      setTheme(savedTheme);
      document.documentElement.classList.toggle('dark', savedTheme === 'dark');
    }
    if (location.pathname === "/valentines-day" || isValentine) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [location.pathname]);
  

  // Check for theme changes periodically
  useEffect(() => {
    const interval = setInterval(() => {
      const isValentine = checkValentinePeriod();
      if (isValentinePeriod !== isValentine) {
        setIsValentinePeriod(isValentine);
        if (isValentine) {
          // Valentine's period just started
          setTheme('dark');
          document.documentElement.classList.add('dark');
          localStorage.setItem('theme', 'dark');
          setShowValentineDialog(true);
        }
        // No need to clear localStorage or reload the page
      }
    }, 60000); // Check every minute
    return () => clearInterval(interval);
  }, [isValentinePeriod]);



  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, isValentinePeriod }}>
      {children}
      <Dialog open={showValentineDialog} onOpenChange={setShowValentineDialog}>
      <DialogOverlay className='z-[999]'>
        <DialogContent className="bg-pink-100 dark:bg-pink-200 border-pink-300 z-[9999]">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold text-pink-700">
              💝 Valentine&apos;s Theme Activated!
            </DialogTitle>
            <DialogDescription className="text-slate-700">
              Welcome to our special Valentine&apos;s edition! We&apos;ve updated our theme to celebrate the season of love. 
              Enjoy the romantic vibes at SounofMeme. You can switch back to the default theme anytime from the profile page.
              <br/><br/>
              Share your love for music with someone special! 🎵💕

            </DialogDescription>
          </DialogHeader>
        </DialogContent>
        </DialogOverlay>
      </Dialog>
    </ThemeContext.Provider>
  );
} 