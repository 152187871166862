const AddSongDialogSkeleton = () => {
    return (
        <div className="space-y-4">
            {[...Array(6)].map((_, index) => (
                <div 
                    key={index} 
                    className="flex items-center justify-between py-2 px-2 sm:p-4 bg-slate-800/50 dark:bg-gradient-to-br dark:from-pink-200/30 dark:via-pink-300/30 dark:to-pink-400/30 rounded-lg animate-pulse"
                >
                    <div className="flex items-center space-x-4">
                        <div className="sm:w-12 sm:h-12 h-8 w-8 bg-gray-700 dark:bg-pink-400/30 rounded"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-700 dark:bg-pink-400/30 rounded sm:w-48 w-40"></div>
                            <div className="h-3 bg-gray-700 dark:bg-pink-400/20 rounded sm:w-32 w-28"></div>
                        </div>
                    </div>
                    <div className="sm:h-9 sm:w-24 h-8 w-20 bg-gray-700 dark:bg-pink-400/30 rounded"></div>
                </div>
            ))}
        </div>
    );
};

export default AddSongDialogSkeleton;