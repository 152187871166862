import { useDispatch, useSelector } from 'react-redux';
import { SET_OTPCARD, SET_SHOW_LOGIN } from '@/redux/features/auth/authSlice';

const useAuthCheck = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth)

  return () => {
    // Example condition to check authentication
    const token = localStorage.getItem('token')
    if (!token && !user) {
      dispatch(SET_SHOW_LOGIN(true));
    } else {
      dispatch(SET_SHOW_LOGIN(false));
    }

    const otpToken = sessionStorage.getItem('otpToken');
    if (otpToken) {
        dispatch(SET_OTPCARD(true))
        // dispatch(SET_SHOW_LOGIN(false))
    }
  };
};

export default useAuthCheck;