import React, { useCallback, useEffect, useState } from "react";
import TopBar from "../../components/navbar/TopBar";
import BottomBar from "@/components/sidebar/BottomBar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "lottie-react";
import guitarAni from "../../assets/guitar.json";
import { ADD_SONGS, SET_USERSONGS } from "@/redux/features/music/musicSlice";
import { debounce } from "@/utils/debounce";
import CreatingSongsLoading from "@/components/songCard/CreatingSongsLoading";
import SongCardListView from "@/components/songCard/SongCardListView";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase";
import { Link } from "react-router-dom";
import { SET_OTPCARD, SET_SHOW_LOGIN } from "@/redux/features/auth/authSlice";
import { trackMetaEvent } from "@/utils/metaPixel";
import { trackLinkedInEvent } from "@/utils/linkedinTag";
import { trackRedditEvent } from "@/utils/redditPixel";
import SongCard from "@/components/songCard/LazyLoadSongCard";
import { LogIn, List, LayoutGrid } from "lucide-react";
import SongCardPageLoader from "@/components/songCardPageLoader/SongCardPageLoader";

const MyCreations = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const { song, userSongs } = useSelector((state) => state.music);
  const { creatingSongs, creatingValentineSongs } = useSelector((state) => state.create);
  const { user } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [noResultMsg, setNoResultMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchSongs, setSearchSongs] = useState([]);
  const tabViewType = localStorage.getItem("tabViewCreate");
  const [tabView, setTabView] = useState(tabViewType);

  useEffect(() => {
    const fetchSongs = async () => {
      const token = localStorage.getItem("token") || localStorage.getItem("guestToken");
      try {
        if (page === 1) {
          dispatch(SET_USERSONGS([]));
        }
        const response = await axios.get(`/usersongs?page=${page}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // setSongs(prevSongs => [...prevSongs, ...response.data.songs]);
        dispatch(ADD_SONGS(response.data.songs));
        if (
          response.data.songs.length === 0 ||
          response.data.songs.length < 20
        ) {
          setHasMore(false); // No more songs to load
        }
      } catch (error) {
        console.log("Error fetching songs:", error);
      }
    };
    if (user) {
      fetchSongs();
    }
  }, [page, user, dispatch]);

  // Define the debounced function directly inside useEffect
  useEffect(() => {
    const token = window.localStorage.getItem("token");
    const debouncedSearch = debounce(async () => {
      if (!term) return;
      try {
        setIsLoading(true);
        const response = await axios.get(`/searchusersongs?item=${term}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSearchSongs(response.data.songs);
        setIsLoading(false);
        if (response?.data?.songs?.length === 0) {
          setNoResultMsg(true);
        } else {
          setNoResultMsg(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Search failed:", error);
      }
    }, 1000);
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [term]);

  // change view
  const handleViewChange = useCallback(() => {
    const view = localStorage.getItem("tabViewCreate");
    if (view === "list") {
      setTabView("grid");
      localStorage.setItem("tabViewCreate", "grid");
    } else {
      setTabView("list");
      localStorage.setItem("tabViewCreate", "list");
    }
  }, []);
  // Change Tab view
  useEffect(() => {
    if (localStorage.getItem("tabViewCreate") === null) {
      setTabView("list");
      localStorage.setItem("tabViewCreate", "list");
    } else {
      setTabView(tabView);
    }
  }, [tabView]);

  useEffect(() => {
    // const token = localStorage.getItem('token')
    if (user) {
      dispatch(SET_SHOW_LOGIN(false));
    }
  }, [dispatch, user]);

  useEffect(() => {
    const otpToken = sessionStorage.getItem('otpToken')
    if (otpToken) {
      dispatch(SET_SHOW_LOGIN(true));
      dispatch(SET_OTPCARD(true));
    }
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0);
    logEvent(analytics, "page_view", { page_title: "My Creations Page" });
    trackMetaEvent('PageView', { page_title: 'My Creations Page' });
    trackLinkedInEvent('page_view', {
      event: 'page_view',
      page_title: 'My Creations Page',
    })
    trackRedditEvent('PageVisit', {
      page_title: 'My Creations Page',
    })
  }, []);

  return (
    <div className="relative min-h-screen bg-slate-950 dark:bg-gradient-to-br dark:from-pink-100 dark:to-pink-200 overflow-hidden flex flex-col flex-grow w-full">
      <TopBar heading={"My Creations"} />
      {user && (
        <div className="md:pt-20 pt-20 flex flex-col flex-grow overflow-hidden">
          <div className=" pb-2 flex justify-between items-center gap-4 px-4">
            <input disabled={userSongs?.length === 0} type="text" placeholder="Search my songs" value={term} onChange={(e) => setTerm(e.target.value)} className="flex-grow bg-transparent border h-10 border-slate-700 p-2 rounded-md lg:max-w-lg w-full focus:outline-none" />
            <button onClick={handleViewChange} className=" h-10 aspect-square flex items-center p-1 justify-center border rounded border-slate-700 hover:bg-slate-900 hover:text-green-400 dark:border-pink-400/30 dark:hover:bg-pink-400/30 dark:hover:text-pink-400 dark:text-slate-800" type="button" >
              {tabView === "grid" ? (
                <List size={26} />
              ) : (
                <LayoutGrid size={26} />
              )}
            </button>
          </div>
          {term === "" && (creatingSongs?.length > 0 || creatingValentineSongs?.length > 0) && (
            <div className={`w-full grid grid-cols-1 gap-x-4 gap-y-3 pt-4 ${tabView === "grid" && " pb-4 "}`}>
              {creatingSongs.map((creation) => (
                <React.Fragment key={creation.creationId}>
                  {creation?.songs.map((song) => (
                    <CreatingSongsLoading key={song?.song_id} ani={creation?.ani} song={song} />
                  ))}
                </React.Fragment>
              ))}
              {creatingValentineSongs.map((creation) => (
                <React.Fragment key={creation.creationId}>
                  {creation?.songs.map((song) => (
                    <CreatingSongsLoading key={song?.song_id} ani={creation?.ani} song={song} />
                  ))}
                </React.Fragment>
              ))}
            </div>
          )}
          {!isLoading && term === "" ? (
            <InfiniteScroll
              dataLength={userSongs?.length}
              next={() => setPage((prevPage) => prevPage + 1)}
              hasMore={hasMore}
              loader={<SongCardPageLoader tabView={tabView} author={true} />}
              endMessage={null}
              className={" flex-grow py-4 pt-3 w-full grid  " + (song ? " pb-36 md:pb-28 " : " pb-24 ") + (tabView === "list" ? " grid-cols-1 gap-x-4 gap-y-3 " : " grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-8 px-4 ")} >
              {userSongs?.map((song) =>
                tabView === "list" ? (
                  <SongCardListView author={true} extend={true} song={song} key={song?.song_id} playingFrom={"userSongs"} />
                ) : (
                  <SongCard author={true} song={song} key={song?.song_id} playingFrom={"userSongs"} />
                )
              )}
            </InfiniteScroll>
          ) : !isLoading && !noResultMsg && searchSongs?.length > 0 ? (
            <div className={"py-4 w-full grid " + (song ? " pb-36 md:pb-28 " : " pb-24 ") + (tabView === "list" ? " grid-cols-1 gap-x-4 gap-y-3 " : " grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-8 px-4 ")} >
              {searchSongs?.map((song) =>
                tabView === "list" ? (
                  <SongCardListView extend={true} song={song} key={song?.song_id} playingFrom={"userSongs"} author={true} />
                ) : (
                  <SongCard song={song} key={song?.song_id} playingFrom={"userSongs"} author={true} />
                )
              )}
            </div>
          ) : !isLoading && searchSongs.length === 0 && noResultMsg ? (
            <p className="px-4">No songs found. Try different search terms.</p>
          ) : (
            <div className={" py-4 flex-grow w-full grid " + (song ? " pb-36 md:pb-28 " : " pb-24 ") + (tabView === "list" ? " grid-cols-1 gap-x-4 gap-y-3 " : " grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-8 px-4 ")} >
              <SongCardPageLoader tabView={tabView} author={true} />
            </div>
          )}
          {user && userSongs?.length === 0 && creatingSongs?.length === 0 && (
            <div className=" -mt-16 flex items-center justify-center">
              <div>
                <div className="max-w-sm w-full mb-24 relative">
                  <Lottie animationData={guitarAni} />
                  <p className=" absolute bottom-16 text-center w-full text-slate-300 font-semibold">
                    No songs found. Start creating your first one!
                  </p>
                  <div className=" absolute bottom-8 w-full flex items-center justify-center">
                    {" "}
                    <Link className="text-green-400 font-medium hover:underline" to={"/create"}>
                      Create
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!user && (
        <div className=" flex items-center justify-center h-screen">
          <div>
            <div className="max-w-sm w-full mb-24 relative">
              <Lottie animationData={guitarAni} />
              <p className=" absolute bottom-16 text-center w-full text-green-400 font-semibold">
                Login to start creating your music!
              </p>
              <div className=" absolute bottom-2 w-full flex items-center justify-center">
                <button onClick={() => dispatch(SET_SHOW_LOGIN(true))} className="mt-2 text-green-400 font-medium flex items-center justify-center gap-1 underline" >Login <LogIn className="mt-1" /></button>
              </div>
            </div>
          </div>
        </div>
      )}
      <BottomBar />
    </div>
  );
};

export default MyCreations;

