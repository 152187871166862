import { useSelector, useDispatch } from "react-redux";
import { Button } from "../ui/button";
import { ProfileIcon } from "../profileIcon/ProfileIcon";
import { CreateSongIconLoading } from "../createSong/CreateSongLoading";
import { useNavigate } from "react-router-dom";
import frogImg from "../../assets/footer-frog.png"
import { SET_OTPCARD, SET_SHOW_LOGIN } from "@/redux/features/auth/authSlice";
import { useEffect } from "react";
import { SET_IS_MANUAL_OPEN, SET_OPEN_GAMIFY_DIALOG } from "@/redux/features/theme/extradialogs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import fireImg from "../../assets/fire.png"
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LogInIcon, Music, PlusIcon } from "lucide-react";
import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterXIcon, YouTubeIcon, TelegramIcon } from "./SocialIcons";

const TopBar = ({ heading }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { creatingSongs } = useSelector(state => state.create)
    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/create")
    }

    useEffect(() => {
        if (user) {
            dispatch(SET_SHOW_LOGIN(false))
        }
    }, [dispatch, user])

    useEffect(() => {
        const otpToken = sessionStorage.getItem('otpToken')
        if (otpToken) {
            dispatch(SET_SHOW_LOGIN(true))
            dispatch(SET_OTPCARD(true))
        }
    }, [dispatch])

    const handleGamify = () => {
        dispatch(SET_OPEN_GAMIFY_DIALOG(true))
        dispatch(SET_IS_MANUAL_OPEN(true))
    }

    return (
        <div className=' h-16 fixed top-0 left-0 right-0 z-[100] bg-slate-900 dark:bg-pink-50 md:ml-52 lg:ml-64 flex justify-between items-center gap-4  p-4 border-b border-slate-800 dark:border-pink-300 ' >
            <div className="flex items-center gap-2 truncate">
                <img src={frogImg} alt="frog" className="w-8 h-8 md:hidden" />
                <h1 className='font-potta text-lg xs:text-2xl text-white dark:text-pink-500 truncate mt-2.5' >{heading} <span className="text-slate-900 dark:text-black text-[0.1px] " >| AI Song Generator | AI Music | AI Music Maker</span></h1>
            </div>
            <div className="flex items-center gap-4 md:gap-4" >
                <div className="flex items-center gap-2 dark:text-slate-950">
                    {!user?.is_guest && <button onClick={handleGamify} className=" text-amber-500" > <LazyLoadImage effect="blur" src={fireImg} alt="fire" className="w-14 h-14" /> </button>}
                    <a href="https://www.instagram.com/soundofmemesome/?hl=en" target="_blank" className=" hidden md:block p-2 w-10 hover:text-green-400 dark:hover:text-pink-500" ><InstagramIcon size={24} /> </a>
                    <a href="https://twitter.com/SoundOfMemeSol" target="_blank" className=" hidden md:block p-2 w-10 hover:text-green-400 dark:hover:text-pink-500" ><TwitterXIcon size={22} /> </a>
                    <a href="https://www.youtube.com/@SoundOfMemeSome" target="_blank" className=" hidden md:block p-2 w-10 hover:text-green-400 dark:hover:text-pink-500" ><YouTubeIcon size={24} /> </a>
                    <a href="https://www.linkedin.com/company/sound-of-meme-some" target="_blank" className=" hidden lg:block p-2 w-10 hover:text-green-400 dark:hover:text-pink-500" ><LinkedInIcon size={24} /> </a>
                    <a href="https://www.facebook.com/profile.php?id=61571411417691" target="_blank" className=" hidden lg:block p-2 w-10 hover:text-green-400 dark:hover:text-pink-500" ><FacebookIcon size={24} /> </a>
                    <a href="https://t.me/SoundOfMeme" target="_blank" className=" hidden lg:block p-2 w-10 hover:text-green-400 dark:hover:text-pink-500" ><TelegramIcon size={24} /> </a>
                </div>
                {creatingSongs.length > 0 && <CreateSongIconLoading />}
                {/* <CreateSongDialog /> */}
                <Button onClick={handleClick} className='items-center justify-center gap-1.5 rounded bg-green-400 text-black hover:bg-green-300 dark:bg-pink-500 dark:text-black dark:hover:bg-pink-400 font-semibold px-4 py-2 hidden md:flex' >
                    <div className="relative">
                        <Music className="text-black mt-1" size={16} strokeWidth={3} />
                        <div className="absolute top-[0px] -right-1.5 w-2.5 h-2.5 bg-green-400 text-black rounded-full flex items-center justify-center">
                            <PlusIcon strokeWidth={3} className="text-black mt-1" size={20} />
                        </div>
                    </div>
                    Create
                </Button>
                {user?.is_guest &&
                    <Button className="bg-green-400 text-black hover:bg-green-300 dark:bg-pink-500 dark:text-black dark:hover:bg-pink-400 font-semibold px-4 py-2  flex items-center gap-2" onClick={() => dispatch(SET_SHOW_LOGIN(true))} >Login<LogInIcon className="mt-1" size={18} strokeWidth={2.5} /></Button>
                }
                {user && <ProfileIcon />}
            </div>
        </div>
    )
}

export default TopBar