import TopBar from '@/components/navbar/TopBar'
import BottomBar from '@/components/sidebar/BottomBar'
import { analytics } from '@/firebase'
import { SET_SHOW_LOGIN } from '@/redux/features/auth/authSlice'
import axios from 'axios'
import { logEvent } from 'firebase/analytics'
import { useEffect, useState, useCallback } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import boxPattern from "@/assets/box-pattern.svg"
import free from "@/assets/free.svg"
import premium from "@/assets/premium-badge.svg"
import gold from "@/assets/gold-badge.svg"
import platinum from "@/assets/platinum.svg"
import { SET_OPEN_GIFT_DIALOG } from '@/redux/features/theme/extradialogs'
import { trackMetaEvent } from '@/utils/metaPixel'
import { trackLinkedInEvent } from '@/utils/linkedinTag'
import { trackRedditCustomEvent, trackRedditEvent } from '@/utils/redditPixel'
import { trackTwitterEvent } from '@/utils/twitterPixel'
import { CircleCheck, Flame, Rocket, Star } from 'lucide-react'

const Subscription = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [pendingPlan, setPendingPlan] = useState(null);
    const { user } = useSelector(state => state.auth)
    const [searchParams] = useSearchParams()
    const affCode = searchParams.get('aff')
    // const discount = false;
    const premiumPrice = 14;
    const goldPrice = 19;
    const platinumPrice = 45;
    const [isYearly, setIsYearly] = useState(false);

    // Calculate yearly prices (monthly * 12) with 20% discount
    const calculateYearlyPrice = (monthlyPrice) => {
        const yearlyPrice = monthlyPrice * 12;
        const discount = yearlyPrice * 0.2; // 20% discount
        return (yearlyPrice - discount).toFixed(2);
    };
    // Get price based on subscription type
    const getPrice = useCallback((basePrice) => {
        return isYearly ? calculateYearlyPrice(basePrice) : basePrice;
    }, [isYearly]);

    useEffect(() => {
        window.scrollTo(0, 0)
        logEvent(analytics, 'page_view', { page_title: 'Subscription Page' })
        trackMetaEvent('PageView', { page_title: 'Subscription Page' })
        trackLinkedInEvent('page_view', {
            event: 'page_view',
            page_title: 'Subscription Page',
        })
        trackRedditEvent('PageVisit', {
            page_title: 'Subscription Page',
        })
    }, [])

    // aff code
    useEffect(() => {
        if (affCode) {
            if (user) {
                const token = window.localStorage.getItem('token')
                try {
                    const { data } = axios.post(`/affiliate/update-link-access?affiliate_code=${affCode}`, {}, { headers: { Authorization: `Bearer ${token}` } })
                    console.log(data);
                } catch (error) {
                    console.log(error);
                }
            } else {
                localStorage.setItem('affCode', affCode)
            }
        }
    }, [affCode, user])

    const buyPremium = useCallback(async (plan, yearly = false) => {
        if (!user || user?.is_guest) {
            setPendingPlan({ plan, yearly: isYearly })
            dispatch(SET_SHOW_LOGIN(true))
            return toast.error("Please login to continue", { id: "premium" })
        }
        const token = window.localStorage.getItem('token')
        const utmParams = JSON.parse(localStorage.getItem('utmParams')) || {};
        const utmSource = utmParams.utm_source || 'unknown';
        const utmMedium = utmParams.utm_medium || 'unknown';
        const utmCampaign = utmParams.utm_campaign || 'unknown';
        const utmContent = utmParams.utm_content || 'unknown';

        try {
            setLoading(true)
            toast.loading("Opening Payment Page", { id: "premium" })
            const response = await axios.post("/checkout", { catagory: plan, yearly: yearly }, { headers: { Authorization: `Bearer ${token}` } })
            logEvent(analytics, 'begin_checkout', {
                currency: 'USD',
                plan: `plan_${plan}`,
                plan_name: plan === 1 ? 'Premium' : plan === 2 ? 'Gold' : 'Platinum',
                source: utmSource,
                medium: utmMedium,
                campaign: utmCampaign,
                content: utmContent,
            });
            if (utmSource.includes('meta')) {
                trackMetaEvent('InitiateCheckout', {
                    currency: 'USD',
                    plan: `plan_${plan}`,
                    plan_name: plan === 1 ? 'Premium' : plan === 2 ? 'Gold' : 'Platinum',
                })
            } else if (utmSource.includes('linkedin')) {
                trackLinkedInEvent('begin_checkout', {
                    currency: 'USD',
                    plan: `plan_${plan}`,
                    plan_name: plan === 1 ? 'Premium' : plan === 2 ? 'Gold' : 'Platinum',
                    source: utmSource,
                    medium: utmMedium,
                    campaign: utmCampaign,
                    content: utmContent,
                    event: 'begin_checkout',
                });
            } else if (utmSource.includes('reddit')) {
                trackRedditCustomEvent('begin_checkout', {
                    currency: 'USD',
                    plan: `plan_${plan}`,
                    plan_name: plan === 1 ? 'Premium' : plan === 2 ? 'Gold' : 'Platinum',
                });
            } else if (utmSource.includes('twitter')) {
                trackTwitterEvent('tw-p774a-p8bkd', {
                    currency: 'USD',
                    value: getPrice(plan === 1 ? premiumPrice : plan === 2 ? goldPrice : platinumPrice),
                });
            }
            window.location.href = response.data.url
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.detail || error.message || "Oops! Error Occured", { id: "premium" });
            console.log(error);
        }
    }, [dispatch, user, isYearly, getPrice])

    useEffect(() => {
        if (user && !user.is_guest && pendingPlan) {
            buyPremium(pendingPlan.plan, pendingPlan.yearly);
            setPendingPlan(null);
        }
    }, [user, pendingPlan, buyPremium])

    const handleGiftSubscription = () => {
        dispatch(SET_OPEN_GIFT_DIALOG(true))
    }

    return (
        <div className='relative min-h-screen bg-slate-950 dark:bg-gradient-to-t from-pink-300 via-pink-200 to-pink-300 overflow-hidden flex flex-col flex-grow w-full'>
            <div className='absolute top-0 left-0 w-full h-[650px]'>
                <img src={boxPattern} alt="box pattern" className='w-full h-full object-cover' />
            </div>
            <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-transparent via-transparent to-green-400/30 dark:to-pink-400/30 mix-blend-screen z-20 pointer-events-none'></div>
            <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-tl from-transparent via-transparent to-green-400/20 dark:to-pink-400/20 mix-blend-screen z-20 pointer-events-none'></div>
            <TopBar heading={"Buy Premium"} />
            <div className='flex flex-col items-center pt-24 px-4 pb-32 text-center'>
                <p className='rounded-full bg-green-400/10 dark:bg-pink-300/50 text-slate-300 dark:text-black px-2 py-1.5 text-xs font-medium inline-block text-center border-2 border-green-400/50 dark:border-pink-400/50'>
                    Your Music, Your Way!
                </p>
                <h1 className='text-4xl font-medium text-slate-100 dark:text-pink-950 text-center mt-4'>
                    Create Your Own Music
                </h1>
                <p className='text-center mt-4 w-full sm:w-2/3 lg:w-1/2 mx-auto text-slate-400 dark:text-slate-950'>
                    Select the perfect plan to unleash your creativity. Start crafting your AI-generated music today with exclusive features and flexibile options.
                </p>
                <button onClick={handleGiftSubscription}
                    className="mt-4 z-50 px-4 py-2 bg-gradient-to-r from-green-400 to-emerald-500 hover:from-green-500 hover:to-emerald-600 text-black font-medium rounded-lg transform hover:scale-105 transition-all duration-200 shadow-lg hover:shadow-green-500/25 border border-green-400/20 flex items-center gap-2 relative overflow-hidden before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-white/20 before:to-transparent before:translate-x-[-200%] hover:before:translate-x-[200%] before:transition-transform before:duration-1000">
                    <span className="animate-pulse">🎁</span>
                    Gift Subscription
                    <div className="absolute -top-0 -right-0 w-3 h-3 bg-red-500 rounded-full animate-ping"></div>
                    <div className="absolute -top-0 -right-0 w-3 h-3 bg-red-500 rounded-full"></div>
                </button>

                <div className='flex flex-col items-center justify-center gap-2 mt-8 z-50'>
                    <div className="flex items-center justify-center gap-4 rounded-full border border-green-400/20 bg-gradient-to-r from-slate-950/50 to-slate-900 shadow-xl">
                        {[1, 2].map(num => (
                            <div key={num} className="relative group ">
                                {num === 2 && isYearly && (
                                    <div className="absolute sm:-top-5 -top-6 sm:rotate-[25deg] rotate-[35deg] sm:left-40 left-32 transform -translate-x-1/2 whitespace-nowrap animate-pulse">
                                        <div className="text-green-400 flex items-center justify-center text-xs font-bold sm:px-4 px-2 py-1 bg-transparent rounded-full shadow-md">
                                            <Star className='mr-2 fill-yellow-500 text-yellow-500' size={20} />
                                            SAVE 20% OFF
                                        </div>
                                    </div>
                                )}
                                <button onClick={() => setIsYearly(num === 2)} className={`relative w-32 sm:w-40 py-2 text-sm sm:text-base rounded-full font-semibold transition-all duration-300 ease-in-out ${isYearly === (num === 2) ? 'bg-gradient-to-r from-teal-500 to-green-500 text-black shadow-lg' : 'bg-transparent text-gray-300 hover:text-white'}`}>
                                    {num === 1 ? 'Monthly' : 'Yearly'}
                                </button>
                            </div>
                        ))}
                    </div>

                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center gap-4 mt-4 w-full lg:max-w-screen-lg xl:max-w-screen-xl mx-auto'>
                        <div className='relative w-full rounded-3xl flex flex-col max-w-xs py-4 px-8 bg-gradient-to-b from-slate-950 via-slate-900/50 to-slate-900/50 backdrop-blur-lg border border-slate-800/10'>
                            {user?.plan === 0 && (
                                <div className='absolute top-0 left-0 border rounded border-green-400/80 text-green-400 rounded-tl-3xl rounded-br-3xl px-4 py-1.5 text-xs font-medium bg-green-400/10'>
                                    Active Plan
                                </div>
                            )}
                            <div className='flex items-center justify-center pt-7'>
                                <img src={free} alt="free" className='object-cover' />
                            </div>
                            <h3 className='text-white dark:text-slate-800 mt-8'>Free Plan</h3>
                            <p className='text-xs w-full text-center mx-auto text-slate-400 dark:text-slate-600 mt-2'>
                                Enjoy basic features with the flexibility to upgrade your creative horizons.
                            </p>
                            <div className='pt-2 pb-3'>
                                <div className='flex flex-col items-center justify-center gap-1'>
                                    {isYearly && (
                                        <div className='flex items-center justify-center gap-2 text-sm text-red-400'>
                                            <span className="text-green-500">Enjoy Free Credits</span>
                                        </div>
                                    )}
                                    <div className='flex items-center justify-center gap-1 text-2xl font-medium text-green-400'>
                                        <span>$</span>
                                        <span>{getPrice(0)}</span>
                                        <span>/{isYearly ? 'year' : 'month'}</span>
                                    </div>
                                </div>
                            </div>
                            <div disabled={loading} className='text-lg flex items-center justify-center border border-slate-800 hover:text-black bg-gradient-to-b from-green-400 to-teal-400 w-full text-black h-10 rounded-lg cursor-pointer transition-all duration-300'>
                                Free Plan
                            </div>
                            <div className='h-px w-full bg-slate-800 dark:bg-pink-400/20 mt-8 mb-6'></div>
                            <div className='flex-grow flex flex-col gap-2'>
                                <Benifit text={"200 Daily Credits"} />
                                <Benifit text={"No Customer Support"} />
                                <Benifit text={"No Affiliate Program"} />
                                <Benifit text={"Share Songs with Friends"} />
                                <Benifit text={"Create and Custom Songs"} />
                                <Benifit text={"1 Simultaneous Song Generation"} />
                            </div>
                        </div>
                        {/* Premium Plan */}
                        <div className='relative w-full rounded-3xl flex flex-col max-w-xs py-4 px-8 bg-gradient-to-bl from-green-950/50 via-slate-900/50 to-slate-900/50 dark:from-pink-100 dark:via-pink-200 dark:to-pink-400 backdrop-blur-lg border border-slate-800/10 dark:border-pink-400/20'>
                            {user?.plan === 1 && (
                                <div className='absolute top-0 left-0 border rounded border-green-400/80 dark:border-pink-500 text-green-400 dark:text-pink-700 rounded-tl-3xl rounded-br-3xl px-4 py-1.5 text-xs font-medium bg-green-400/10 dark:bg-pink-400/10'>
                                    Active Plan
                                </div>
                            )}
                            <div className='flex items-center justify-center'>
                                <img src={premium} alt="premium" className='object-cover' />
                            </div>
                            <h3 className='text-white dark:text-slate-800 mt-6'>Premium Plan</h3>
                            <p className='text-xs w-full text-center mx-auto text-slate-400 dark:text-slate-600 mt-2'>
                                Perfect for hobbyists and music enthusiasts taking their first step into AI music creation.
                            </p>
                            <div className='pt-2 pb-3'>
                                <div className='flex flex-col items-center justify-center gap-1'>
                                    {isYearly && (
                                        <div className='flex items-center justify-center gap-2 text-sm text-red-400'>
                                            <span className="line-through text-red-400">
                                                ${(premiumPrice * 12).toFixed(2)}
                                            </span>
                                            <span className="text-green-400 text-sm">
                                                Save ${(premiumPrice * 12 * 0.2).toFixed(2)}
                                            </span>
                                        </div>
                                    )}
                                    <div className='flex items-center justify-center gap-1 text-2xl font-medium text-green-400'>
                                        <span>$</span>
                                        <span>{getPrice(premiumPrice)}</span>
                                        <span>/{isYearly ? 'year' : 'month'}</span>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => buyPremium(1, isYearly)} disabled={loading} className='text-sm border border-slate-800 dark:border-pink-400 hover:text-black bg-gradient-to-b from-slate-800/90 dark:from-pink-400 dark:to-pink-800 hover:from-green-400 dark:hover:from-pink-400 to-transparent hover:to-green-400 dark:hover:to-pink-400 w-full text-white dark:text-black h-10 rounded-lg cursor-pointer transition-all duration-300'>
                                Subscribe
                            </button>
                            <div className='h-px w-full bg-slate-800 dark:bg-pink-400/20 mt-8 mb-6'></div>
                            <div className='flex-grow flex flex-col gap-2'>
                                <Benifit text={`5,000 ${isYearly ? 'Monthly' : ''} Credits`} />
                                <Benifit text={"Affiliate Program"} />
                                <Benifit text={"Extend & Clone Songs"} />
                                <Benifit text={"Launch Your Song Token"} />
                                <Benifit text={"Generate Songs from url"} />
                                <Benifit text={"Standard Customer Support"} />
                                <Benifit text={"Generate Songs from Images"} />
                                <Benifit text={"2 Simultaneous Song Generations"} />
                            </div>
                        </div>
                        <div className='relative rounded-3xl flex flex-col max-w-xs w-full py-4 px-8 bg-gradient-to-b from-green-950/80 via-slate-900/50 to-slate-900/50 dark:from-pink-100 dark:via-pink-300 dark:to-pink-400 backdrop-blur-lg border border-slate-800/10 dark:border-pink-400'>
                            {user?.plan === 2 && (
                                <div className='absolute top-0 left-0 border rounded border-green-400/80 dark:border-pink-500 text-green-400 dark:text-pink-700 rounded-tl-3xl rounded-br-3xl px-4 py-1.5 text-xs font-medium bg-green-400/10 dark:bg-pink-400/10'>
                                    Active Plan
                                </div>
                            )}
                            <div className='flex items-center justify-center'>
                                <img src={gold} alt="gold" className='object-cover dark:opacity-90' />
                            </div>
                            <h3 className='text-white dark:text-slate-800 mt-6'>Gold Plan</h3>
                            <p className='text-xs w-full text-center mx-auto text-slate-400 dark:text-slate-600 mt-2'>
                                Designed for creators who want more freedom in their music projects.
                            </p>
                            <div className='pt-2 pb-3'>
                                <div className='flex flex-col items-center justify-center gap-1'>
                                    {isYearly && (
                                        <div className="flex items-center justify-center gap-2 text-sm text-red-400">
                                            <span className="line-through text-red-400">
                                                ${(goldPrice * 12).toFixed(2)}
                                            </span>
                                            <span className="savings text-green-400 text-sm">
                                                Save ${(goldPrice * 12 * 0.2).toFixed(2)}
                                            </span>
                                        </div>
                                    )}
                                    <div className='flex items-center justify-center gap-1 text-2xl font-medium text-green-400'>
                                        <span>$</span>
                                        <span>{getPrice(goldPrice)}</span>
                                        <span>/{isYearly ? 'year' : 'month'}</span>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => buyPremium(2, isYearly)} disabled={loading} className='text-sm border border-slate-800 dark:border-pink-400 hover:text-black bg-gradient-to-b from-slate-800/90 dark:from-pink-400 dark:to-pink-800 hover:from-amber-300 dark:hover:from-pink-400 to-transparent hover:to-amber-400 dark:hover:to-pink-400 w-full text-white dark:text-black h-10 rounded-lg cursor-pointer transition-all duration-300'>
                                Subscribe
                            </button>
                            <div className='h-px w-full bg-slate-800 dark:bg-pink-400/20 mt-8 mb-6'></div>
                            <div className='flex-grow flex flex-col gap-2'>
                                <Benifit2 text={`10,000 ${isYearly ? 'Monthly' : ''} Credits`} />
                                <Benifit2 text={"Affiliate Program"} />
                                <Benifit2 text={"Extend & Clone Songs"} />
                                <Benifit2 text={"Launch Your Song Token"} />
                                <Benifit2 text={"Generate Songs from url"} />
                                <Benifit2 text={"Standard Customer Support"} />
                                <Benifit2 text={"Generate Songs from Images"} />
                                <Benifit2 text={"3 Simultaneous Song Generations"} />
                            </div>
                            <div className='absolute top-0 right-0 border rounded border-green-400/80 dark:border-pink-500 text-green-400 dark:text-pink-700 rounded-tr-3xl rounded-bl-3xl px-4 py-1.5 text-xs font-medium bg-green-400/10 dark:bg-pink-400/10'>
                                Most Popular
                            </div>
                        </div>
                        <div className='relative rounded-3xl flex flex-col max-w-xs w-full px-8 pt-2.5 pb-4 bg-gradient-to-br from-green-950/50 via-slate-900/50 to-slate-900/50 dark:from-pink-100 dark:via-pink-200 dark:to-pink-400 backdrop-blur-lg border border-slate-800/10 dark:border-pink-400'>
                            {user?.plan === 3 && (
                                <div className='absolute top-0 left-0 border rounded border-green-400/80 dark:border-pink-500 text-green-400 dark:text-pink-700 rounded-tl-3xl rounded-br-3xl px-4 py-1.5 text-xs font-medium bg-green-400/10 dark:bg-pink-400/10'>
                                    Active Plan
                                </div>
                            )}
                            <div className='flex items-center justify-center'>
                                <img src={platinum} alt="platinum" className='object-cover dark:opacity-90' />
                            </div>
                            <h3 className='text-white dark:text-slate-800 mt-5'>Platinum Plan</h3>
                            <p className='text-xs w-full text-center mx-auto text-slate-400 dark:text-slate-600 mt-2'>
                                The ultimate plan for professionals and studios producing high-volume AI music.
                            </p>
                            <div className='pt-2 pb-3'>
                                <div className='flex flex-col items-center justify-center gap-1'>
                                    {isYearly && (
                                        <div className="flex items-center justify-center gap-2 text-sm text-red-400">
                                            <span className="line-through text-red-400">
                                                ${(platinumPrice * 12).toFixed(2)}
                                            </span>
                                            <span className="savings text-green-400 text-sm">
                                                Save ${(platinumPrice * 12 * 0.2).toFixed(2)}
                                            </span>
                                        </div>
                                    )}

                                    <div className='flex items-center justify-center gap-1 text-2xl font-medium text-green-400'>
                                        <span>$</span>
                                        <span>{getPrice(platinumPrice)}</span>
                                        <span>/{isYearly ? 'year' : 'month'}</span>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => buyPremium(3, isYearly)} disabled={loading} className='text-sm border border-slate-800 dark:border-pink-400 hover:text-black bg-gradient-to-b from-slate-800/90 dark:from-pink-400 dark:to-pink-800 hover:from-slate-100 dark:hover:from-pink-400 to-transparent hover:to-slate-100 dark:hover:to-pink-400 w-full text-white dark:text-black h-10 rounded-lg cursor-pointer transition-all duration-300'>
                                Subscribe
                            </button>
                            <div className='h-px w-full bg-slate-800 dark:bg-pink-400/20 mt-8 mb-6'></div>
                            <div className='flex-grow flex flex-col gap-2'>
                                <Benifit3 text={`50,000 ${isYearly ? 'Monthly' : ''} Credits`} />
                                <Benifit3 text={"Affiliate Program"} />
                                <Benifit3 text={"Extend & Clone Songs"} />
                                <Benifit3 text={"Launch Your Song Token"} />
                                <Benifit3 text={"Generate Songs from url"} />
                                <Benifit3 text={"Publish Songs on Spotify"} />
                                <Benifit3 text={"Priority Customer Support"} />
                                <Benifit3 text={"Generate Songs from Images"} />
                                <Benifit3 text={"5 Simultaneous Song Generations"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BottomBar />
        </div>
    )
}

export default Subscription

function Benifit({ text }) {
    return (
        <div className='flex items-center gap-2'>
            <CircleCheck size={18} strokeWidth={1.5} className="text-slate-300 dark:text-pink-700" />
            <p className='text-sm text-slate-400 dark:text-slate-800 text-left'>{text}</p>
        </div>
    )
}
function Benifit2({ text }) {
    return (
        <div className='flex items-center gap-2'>
            <Flame size={18} strokeWidth={1.5} className="text-slate-300 dark:text-pink-700" />
            <p className='text-sm text-slate-400 dark:text-slate-800 text-left'>{text}</p>
        </div>
    )
}
function Benifit3({ text }) {
    return (
        <div className='flex items-center gap-2'>
            <Rocket size={18} strokeWidth={1.5} className="text-slate-300 dark:text-pink-700" />
            <p className='text-sm text-slate-400 dark:text-slate-800 text-left'>{text}</p>
        </div>
    )
}