import axios from "axios"

// login user
const loginUser = async (formData) => {
    const guest = localStorage.getItem("guestToken")
    let response
    if (guest) {
        response = await axios.post("/login", formData, {
            headers: { Authorization: `Bearer ${guest}` }
        })
    } else {
        response = await axios.post("/login", formData)
    }
    return response.data
}

// signup user
const signUpUser = async (formData) => {
    const guestToken = localStorage.getItem('guestToken')
    let response
    if (guestToken) {
        response = await axios.post("/signup3", formData, { headers: { Authorization: `Bearer ${guestToken}` } })
    } else {
        response = await axios.post("/signup3", formData)
    }
    return response.data

}
// change password
const changePassword = async (formData) => {
    const token = localStorage.getItem('token')
    const response = await axios.post("/change-password", formData, {
        headers: { Authorization: `Bearer ${token}` }
    })
    return response.data
}
//forgot password
const forgotPassword = async (formData) => {
    const response = await axios.post("/resetpassword", formData)
    return response.data
}
//reset password link
const resetPassword = async (formData) => {
    const response = await axios.post("/reset", {
        new_password: formData.new_password,
        token: formData.token
    });
    return response.data;
}

// get user details
const getUserDetails = async ({ token }) => {
    const response = await axios.get("/user", { headers: { Authorization: `Bearer ${token}` } })
    return response.data
}

const verifyOtp = async (formData) => {
    const otpToken = sessionStorage.getItem('otpToken')
    const response = await axios.post("/verify3", formData, { headers: { Authorization: `Bearer ${otpToken}` } })
    return response.data

}

const authService = {
    loginUser,
    signUpUser,
    changePassword,
    forgotPassword,
    resetPassword,
    getUserDetails,
    verifyOtp
}

export default authService