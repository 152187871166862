import { SongCardSkeleton } from "@/components/createSong/CreateSongLoading";
import TopBar from "@/components/navbar/TopBar";
import BottomBar from "@/components/sidebar/BottomBar";
import useAuthCheck from "@/customHook/useAuthCheck";
import { ADD_SONGS, SET_USERSONGS } from "@/redux/features/music/musicSlice";
import axios from "axios";
import { debounce } from "@/utils/debounce";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CancelSubscription from "./CancelSubscription";
import UpdateProfileDialogBox from "./UpdateProfileDialogBox";
import lottieJson from "../../assets/hero.gif"
import { SET_REFERRAL_DIALOG } from "@/redux/features/auth/authSlice";
import premium from "@/assets/premium-badge.svg"
import gold from "@/assets/gold-badge.svg"
import platinum from "@/assets/platinum.svg"
import warning from "@/assets/free.svg"
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase";
import { trackMetaEvent } from "@/utils/metaPixel";
import AchievementTab from "./AchievementTab";
import SongCard from "@/components/songCard/LazyLoadSongCard";
import { Music, Trophy } from "lucide-react";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { song, userSongs } = useSelector((state) => state.music);
  const [isUpdateProfileOpen, setIsUpdateProfileOpen] = useState(false);
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(1);
  const [noResultMsg, setNoResultMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchSongs, setSearchSongs] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [activeTab, setActiveTab] = useState("songs");

  const checkAuth = useAuthCheck();
  const handleClick = () => {
    checkAuth();
  }

  useEffect(() => {
    const fetchSongs = async () => {
      const token = localStorage.getItem("token") || localStorage.getItem("guestToken");
      try {
        if (page === 1) {
          dispatch(SET_USERSONGS([]));
        }
        const response = await axios.get(`/usersongs?page=${page}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // setSongs(prevSongs => [...prevSongs, ...response.data.songs]);
        dispatch(ADD_SONGS(response.data.songs));
        if (
          response.data.songs.length === 0 ||
          response.data.songs.length < 20
        ) {
          setHasMore(false); // No more songs to load
        }
      } catch (error) {
        console.log("Error fetching songs:", error);
      }
    };
    if (user) {
      fetchSongs();
    }
  }, [page, user, dispatch]);

  // Define the debounced function directly inside useEffect
  useEffect(() => {
    const token = window.localStorage.getItem("token") || window.localStorage.getItem("guestToken");
    const debouncedSearch = debounce(async () => {
      if (!term) return;
      try {
        setIsLoading(true);
        const response = await axios.get(`/searchusersongs?item=${term}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSearchSongs(response.data.songs);
        setIsLoading(false);
        if (response?.data?.songs?.length === 0) {
          setNoResultMsg(true);
        } else {
          setNoResultMsg(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Search failed:", error);
      }
    }, 1000);
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [term]);

  useEffect(() => {
    window.scrollTo(0, 0);
    logEvent(analytics, 'page_view', { page_title: 'Profile Page' });
    trackMetaEvent('PageView', { page_title: 'Profile Page' });
  }, []);

  const handleTabChange = (tab) => {
    if (tab === "songs") {
      setActiveTab("songs");
    } else if (tab === "achievements") {
      setActiveTab("achievements");
    }
  }

  return (
    <div className="relative min-h-screen bg-slate-950 text-slate-300 dark:bg-gradient-to-b from-pink-100 to-pink-300 dark:text-slate-900 overflow-hidden flex flex-col flex-grow w-full">
      {user && <UpdateProfileDialogBox isOpen={isUpdateProfileOpen} close={() => setIsUpdateProfileOpen(false)} />}
      <TopBar heading={"Profile"} />
      {!user && (
        <div className="flex flex-col justify-center items-center h-screen">
          <img src={lottieJson} alt="lottie" className="w-44 h-44 object-scale-down mb-4" />
          <h1 className="sm:text-2xl text-xl font-normal font-potta text-center text-white dark:text-slate-900 mb-3">Please Login to View Your Profile</h1>
          <button onClick={handleClick} className="text-green-400 dark:text-pink-900 font-medium text-md sm:text-lg hover:underline">Login</button>
        </div>
      )}
      <div className={" pt-20 px-4 " + (song ? " pb-36 md:pb-28 " : " pb-24 md:pb-8 ")}>
        <div className="flex flex-col xl:flex-row gap-4  border border-green-400/60 rounded-xl bg-gradient-to-br from-green-400/20 to-green-400/10 p-4 md:p-6">
          <div className=" w-full xl:w-3/5 flex flex-col gap-4 justify-center xl:border-r-2 border-r-slate-600">
            <div className="flex items-center gap-4" >
              <div className="flex-shrink-0 text-5xl text-black dark:text-slate-900 font-bold aspect-square capitalize bg-green-400 dark:bg-pink-300 w-24 sm:w-40 flex items-center justify-center rounded-full overflow-hidden">
                {user?.profile_url ? <img className="w-full object-cover" src={user?.profile_url} alt="profile" /> : user?.username?.slice(0, 2)}
              </div>
              {!user?.is_guest ? <div className="flex flex-col sm:gap-2" >
                <p className="text-2xl sm:text-3xl font-semibold text-slate-100 dark:text-slate-900">{user?.name}</p>
                <p className="text-sm text-slate-300 font-medium dark:text-pink-600">{user?.email}</p>
                <div className="hidden text-slate-400 text-xs sm:flex gap-2 items-center flex-wrap mt-1">
                  <button onClick={() => setIsUpdateProfileOpen(true)} type="button" className="bg-green-400 hover:bg-green-300 text-black px-3 py-1 rounded font-medium" >Update Profile</button>
                  <button onClick={() => navigate("/changepassword")} type="button" className="border border-slate-400 text-slate-200 hover:bg-slate-800 hover:text-green-400 px-3 py-1 rounded font-medium" >Change Password</button>
                  <button onClick={() => dispatch(SET_REFERRAL_DIALOG(true))} type="button" className="border border-slate-400 text-slate-200 hover:bg-slate-800 hover:text-green-400 px-3 py-1 rounded font-medium" >Refer and Earn</button>
                  <Link to={"/affiliate"} type="button" className="border border-slate-400 text-slate-200 hover:bg-slate-800 hover:text-green-400 px-3 py-1 rounded font-medium" >Become an Affiliate</Link>
                </div>
              </div> : <div className=" truncate text-xl " >{user?.username}</div>}
            </div>
            {!user?.is_guest && <div className="text-slate-400 text-xs grid grid-cols-2 gap-3 mt-1 sm:hidden font-poppins">
              <button onClick={() => setIsUpdateProfileOpen(true)} type="button" className="bg-green-400 hover:bg-green-300 text-black px-3 py-1.5 rounded font-semibold truncate" >Update Profile</button>
              <button onClick={() => navigate("/changepassword")} type="button" className="border border-slate-400 text-slate-200 hover:bg-slate-800 hover:text-green-400 px-3 py-1.5 rounded font-semibold truncate" >Change Password</button>
              <button onClick={() => dispatch(SET_REFERRAL_DIALOG(true))} type="button" className="border border-slate-400 text-slate-200 hover:bg-slate-800 hover:text-green-400 px-3 py-1.5 rounded font-semibold truncate" >Refer and Earn</button>
              <Link to={"/affiliate"} type="button" className="border border-slate-400 text-slate-200 hover:bg-slate-800 hover:text-green-400 px-3 py-1.5 rounded font-semibold truncate text-center" >Become an Affiliate</Link>
            </div>}
          </div>
          <div className={` w-full xl:w-2/5 flex items-center gap-4 ${user?.plan === 1 ? " border-green-600/75 dark:border-pink-900/75 " : user?.plan === 2 ? " border-amber-600/75 dark:border-pink-900/75 " : user?.plan === 3 ? " border-slate-100/75 dark:border-pink-900/75 " : " border-slate-800 dark:border-pink-900/75 "}`}>
            <div className="rounded-full overflow-hidden flex-shrink-0 sm:w-40 w-24">
              <img src={user?.plan === 1 ? premium : user?.plan === 2 ? gold : user?.plan === 3 ? platinum : warning} alt="rocket" className=" w-full scale-125 xl:scale-110 object-cover" />
            </div>
            <div className="flex flex-col sm:gap-1" >
              <p className={`text-xl font-bold ${user?.plan === 1 ? " text-green-400 dark:text-pink-900" : user?.plan === 2 ? " text-amber-300 dark:text-rose-900" : " text-slate-100 dark:text-pink-700 "}`}>
                {user?.plan === 1 ? "Premium" : user?.plan === 2 ? "Gold" : user?.plan === 3 ? "Platinum" : "No Active Plan"}
              </p>
              <p className="text-sm text-slate-300 dark:text-black font-medium">Credits Remaining : {user?.credits}</p>
              <div className="flex flex-wrap gap-2 mt-2">
                {user?.plan < 3 && <Link to={"/premium"} className="text-xs font-medium bg-teal-400 hover:bg-teal-300 text-black px-3 py-1 rounded truncate">Upgrade Plan</Link>}
                {(user?.plan > 0 && !user?.is_guest) && <CancelSubscription />}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full mt-4">
          <div className="relative border border-slate-700 dark:border-pink-900 md:p-1 p-1 rounded-xl overflow-hidden shadow-lg shadow-slate-900 dark:shadow-pink-900 w-full">
            {/* Animated background highlight */}
            <div className={`absolute top-1.5 bottom-1.5 ${activeTab === 'songs' ? 'left-1.5 right-[calc(50%+1.5px)]' : 'left-[calc(50%+1.5px)] right-1.5'} bg-gradient-to-r from-green-500/90 to-green-400/80 dark:from-pink-500/90 dark:to-pink-400/80 rounded-sm transition-all duration-300 ease-in-out shadow-md`} />
            {/* Tab buttons */}
            <div className="grid grid-cols-2 relative z-10 mt-2">
              <button onClick={() => handleTabChange('songs')} className={`flex items-center justify-center gap-2 md:py-1 md:px-4 py-2 px-1 rounded-sm font-medium transition-all duration-300 ${activeTab === 'songs' ? 'text-black dark:text-white lg:text-lg text-sm' : 'lg:text-lg text-sm text-slate-300 dark:text-slate-600 hover:text-slate-100 dark:hover:text-slate-800'}`}>
                <Music size={18} className={`${activeTab === 'songs' ? "animate-bounce" : ""}`} />
                <span>Songs</span>
              </button>
              <button onClick={() => handleTabChange('achievements')} className={`flex items-center justify-center gap-2 md:py-2.5 md:px-4 py-2 px-1 rounded-sm font-medium transition-all duration-300 ${activeTab === 'achievements' ? 'text-black dark:text-white lg:text-lg text-sm' : 'lg:text-lg text-sm text-slate-300 dark:text-slate-600 hover:text-slate-100 dark:hover:text-slate-800'}`}>
                <Trophy size={18} className={activeTab === 'achievements' ? "animate-bounce-subtle" : ""} />
                <span>Achievements</span>
              </button>
            </div>
          </div>
        </div>
        {activeTab === "songs" && user && (<div className="mt-8 flex flex-col flex-grow overflow-hidden">
          <div className="pb-2 flex justify-between flex-col-reverse lg:flex-row gap-4">
            <input disabled={userSongs?.length === 0} type="text" placeholder="Search my songs" value={term} onChange={(e) => setTerm(e.target.value)} className="disabled:opacity-50 flex-grow bg-transparent border border-slate-700 dark:border-pink-900 p-2 rounded-md lg:max-w-lg w-full focus:outline-none" />
          </div>
          {!isLoading && term === "" ? (
            <InfiniteScroll dataLength={userSongs?.length} next={() => setPage((prevPage) => prevPage + 1)} hasMore={hasMore} loader={<Loader />} endMessage={null} className="mt-8 flex-grow grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-8">
              {userSongs?.map((song) => <SongCard song={song} key={song?.song_id} playingFrom={"discoverSongs"} />)}
            </InfiniteScroll>
          ) : !isLoading && !noResultMsg && searchSongs?.length > 0 ? (
            <div className="mt-8 flex-grow grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-8">
              {searchSongs?.map((song) => <SongCard song={song} key={song?.song_id} playingFrom={"discoverSongs"} />)}
            </div>
          ) : !isLoading && searchSongs.length === 0 && noResultMsg ? (
            <p className="px-4">No songs found. Try different search terms.</p>
          ) : (
            <div className="mt-8 flex-grow grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-8">
              <Loader />
            </div>
          )}
        </div>
        )}
        {activeTab === "achievements" &&
          <div className="mt-8">
            <AchievementTab tab={activeTab} />
          </div>
        }
      </div>


      <BottomBar />
    </div>
  );
};

export default Profile;

function Loader() {
  return (
    <>
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
    </>
  );
}
