import axios from 'axios';
import { useState } from 'react'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Download } from 'lucide-react';
const DownloadButton = ({ position, song }) => {
    const { user } = useSelector(state => state.auth)
    const [downloadLoading, setdownloadLoading] = useState(false)
    // download song
    const downloadSong = async (song) => {
        if (!user) {
            return toast.error('Login to download the song')
        }
        const token = window.localStorage.getItem('token')
        try {
            setdownloadLoading(true)
            toast.loading("Starting Download", { id: "download" })
            const response = await axios.get(`/download?song_id=${song?.song_id}`, { headers: { Authorization: `Bearer ${token}` } });
            const signedUrl = response.data.url;
            download(signedUrl);
            setdownloadLoading(false)
            toast.success("Download Started", { id: "download" })
        } catch (error) {
            setdownloadLoading(false)
            console.error('Error fetching signed URL:', error);
            toast.error('Error Occured. Please try again.', { id: "download" });
        }
    };

    function download(url) {
        const a = document.createElement('a');
        a.href = url;
        a.download = `${song?.song_name} - Sound of Meme`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <>{
            position === 'bigScreen' ?
                <button disabled={downloadLoading} type='button' className=' disabled:opacity-50 relative z-10 focus:outline-none text-white hover:text-green-400 dark:text-pink-500 dark:hover:text-pink-900'
                    onClick={(e) => {
                        e.stopPropagation()
                        downloadSong(song)
                    }} >
                    <Download size={22} />
                </button>
                : <button disabled={downloadLoading} type='button' className=' disabled:opacity-50 relative z-10 focus:outline-none text-white dark:text-pink-950 hover:text-green-400' onClick={() => downloadSong(song)} ><Download size={24} /> </button>

        }</>
    )
}

export default DownloadButton