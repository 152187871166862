import CryptoJS from "crypto-js";

// Generate a stable fingerprint using only reliable properties
export const getDeviceFingerprint = async () => {
    try {
        // Collect only stable properties that don't change with DevTools
        const fingerprintData = {
            // Hardware-level properties
            hardwareConcurrency: navigator.hardwareConcurrency || 0,
            deviceMemory: navigator.deviceMemory || 0,
            // System properties that are harder to spoof
            architecture: navigator.platform?.includes('64') ? '64-bit' : '32-bit',
            vendor: navigator.vendor || "unknown",
            // Audio context fingerprint
            audioFingerprint: await getAudioFingerprint(),
            // WebGL properties
            webglFingerprint: getWebGLFingerprint(),
            // Font fingerprint
            fontFingerprint: await getFontFingerprint(),
            // Stable browser properties
            cookieEnabled: navigator.cookieEnabled,
            doNotTrack: navigator.doNotTrack || "unknown",
            // Locale data (generally stable)
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "unknown",
            language: navigator.language || "unknown",
        };

        // Convert object to a stable JSON string with sorted keys
        const fingerprintString = JSON.stringify(fingerprintData, Object.keys(fingerprintData).sort());
        // Generate hash
        const hashedFingerprint = CryptoJS.SHA256(fingerprintString).toString(CryptoJS.enc.Hex);
        return hashedFingerprint;
    } catch (error) {
        console.error("Error generating fingerprint:", error);
        return null;
    }
};

// Audio fingerprinting - unique to device audio hardware
async function getAudioFingerprint() {
    try {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const oscillator = audioContext.createOscillator();
        const analyser = audioContext.createAnalyser();
        const gainNode = audioContext.createGain();

        // Use fixed settings for consistency
        analyser.fftSize = 2048;
        oscillator.frequency.value = 1000; // Fixed frequency
        gainNode.gain.value = 0; // Mute the sound

        oscillator.connect(analyser);
        analyser.connect(gainNode);
        gainNode.connect(audioContext.destination);

        oscillator.start();

        // Use frequency data instead of time domain data
        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        // Wait for a fixed time to ensure stable readings
        await new Promise(resolve => setTimeout(resolve, 100));

        analyser.getByteFrequencyData(dataArray);
        oscillator.stop();
        audioContext.close();

        // Take only the first 10 frequency bins for stability
        const stableData = dataArray.slice(0, 10);
        return stableData.join(',');
    } catch {
        return "audio-not-supported";
    }
}

// WebGL fingerprinting - unique to GPU hardware
function getWebGLFingerprint() {
    try {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        if (!gl) return "webgl-not-supported";

        const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        return debugInfo ? {
            vendor: gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
            renderer: gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL),
        } : "webgl-info-not-available";
    } catch {
        return "webgl-error";
    }
}

// Font fingerprinting - checks system fonts
async function getFontFingerprint() {
    const baseFonts = ['monospace', 'sans-serif', 'serif'];
    const testString = 'mmmmmmmmmmlli';

    try {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) return "canvas-not-supported";

        // Get the width of the text in different base fonts
        const getFontWidth = (fontFamily) => {
            context.font = '72px ' + fontFamily;
            return context.measureText(testString).width;
        };
        // Get base measurements
        const baseMeasurements = baseFonts.map(font => getFontWidth(font));

        return baseMeasurements.join(',');
    } catch {
        return "font-detection-failed";
    }
}
