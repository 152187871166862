import { SET_SHOW_LOGIN } from "@/redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
import { DialogOverlay, DialogContent, Dialog, DialogTitle, DialogClose, DialogDescription } from "../ui/dialog";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import LoginForm from "@/pages/login/LoginForm";
import OtpForm from "@/pages/login/OtpForm";
import SignUpForm from "@/pages/login/SignUpForm";
import { X } from "lucide-react";


const LoginDialog = () => {
  const dispatch = useDispatch();
  const { showLogin, otpCard } = useSelector(state => state.auth);

  const onOpenChange = (value) => {
    dispatch(SET_SHOW_LOGIN(value));
    if (value === false) {
      setTimeout(() => {
        document.body.style.removeProperty('pointer-events');
      }, 500);
    }
  };

  return (
    <Dialog open={showLogin} onOpenChange={onOpenChange}>
      <DialogOverlay className="z-[99]">
        <DialogContent className="z-[999] w-[95%] sm:w-full bg-gradient-to-r from-slate-950 via-slate-900 to-slate-950 dark:from-pink-200 dark:via-pink-300 dark:to-pink-200 border border-green-800 dark:border-pink-700 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-lg h-full max-h-[58vh] xs:h-auto xs:max-h-[80vh] overflow-y-auto overflow-x-hidden scrollbar-hide sm:p-4 p-0 rounded-lg text-slate-200 dark:text-slate-800">
          <DialogClose asChild className="z-[999] absolute sm:top-2 sm:right-2 top-2 right-2">
            <button onClick={() => onOpenChange(false)}>
              <X size={16} className="text-green-400 dark:text-pink-700" />
            </button>
          </DialogClose>
          <DialogTitle className="">
            <div className="flex flex-col justify-center items-center mt-4">
              <h1 className="text-sm sm:text-md text-center font-normal font-potta text-green-400 dark:text-pink-700">Please Login or SignUp to Explore</h1>
            </div>
          </DialogTitle>
          <DialogDescription className="hidden"></DialogDescription>
          <Tabs defaultValue="login" className="w-full sm:p-4 p-2 pb-16 sm:pb-0 max-w-lg flex flex-col items-center justify-center">
            <TabsList className="grid w-full grid-cols-2 text-slate-400 dark:text-slate-800 font-semibold bg-slate-900 dark:bg-pink-200 border border-slate-700/75 dark:border-pink-700/75">
              <TabsTrigger
                className="data-[state=active]:bg-green-400 dark:data-[state=active]:bg-pink-700 dark:data-[state=active]:text-white"
                value="login"
              >
                Login
              </TabsTrigger>
              <TabsTrigger
                className="data-[state=active]:bg-green-400 dark:data-[state=active]:bg-pink-700 dark:data-[state=active]:text-white font-semibold"
                value="signup"
              >
                SignUp
              </TabsTrigger>
            </TabsList>
            <div className="w-full max-w-lg">
              {!otpCard ? <LoginForm /> : <OtpForm tabName={"login"} />}
              {!otpCard ? <SignUpForm /> : <OtpForm tabName={"signup"} />}
            </div>
          </Tabs>
        </DialogContent>
      </DialogOverlay>
    </Dialog>
  );
};

export default LoginDialog;
