import TopBar from "@/components/navbar/TopBar"
import BottomBar from "@/components/sidebar/BottomBar"
import SongCardListView from "@/components/songCard/SongCardListView"
import SortFilter from "@/components/sortFilter/SortFilter"
import { analytics } from "@/firebase"
import { ADD_DISCSONGS, SET_DISCSONGS, SET_PLAYINGFROM } from "@/redux/features/music/musicSlice"
import { SET_SEARCHSONGS, SET_TOTALSEARCHSONGS, SET_TOTALSEARCHPLAYLISTS, SET_TOTALSEARCHUSERS, SET_SEARCHPLAYLISTS, SET_SEARCHUSERS } from "@/redux/features/music/searchSlice"
import axios from "axios"
import { logEvent } from "firebase/analytics"
import { debounce } from "@/utils/debounce"
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useDispatch, useSelector } from "react-redux"
import 'swiper/css';
import 'swiper/css/navigation';
import CreateSongHomeComp from "./CreateSongHomeComp"
import PlaylistCard from "./PlaylistCard"
import UserCard from "./UserCard"
import { toast } from "react-hot-toast"
import PlaylistsLoader from "../playlist/PlaylistLoader"
import ArtistCardSkeleton from "../artists/ArtistCardSkeleton"
import { Link } from "react-router-dom"
import { trackLinkedInEvent } from "@/utils/linkedinTag"
import { trackMetaEvent } from "@/utils/metaPixel"
import { trackRedditEvent } from "@/utils/redditPixel"
import SongCard from "@/components/songCard/LazyLoadSongCard"
import { ArrowRight, LayoutGrid, List } from "lucide-react"
import SongCardPageLoader from "@/components/songCardPageLoader/SongCardPageLoader"
import FeaturedArtistSkeleton from "./FeaturedArtistSkeleton"
import GenreCategorySkeleton from "./GenreCategorySkeleton"
import TrendingSongSkeleton from "./TrendingSongSkeleton"

const allSongsSortOptions = [
    {
        value: "mostrecent",
        label: "Most Recent",
    },
    {
        value: "mostliked",
        label: "Most Liked",
    },
    {
        value: "mostviewed",
        label: "Most Played",
    }
]

const TrendingSongsLazyLoad = lazy(() => import("./TrendingSongs"))
const GenreCategoryLazyLoad = lazy(() => import("./GenreCategory"))
const FeaturedArtistLazyLoad = lazy(() => import("./FeaturedArtist"))
const DiscoverBannerLazyLoad = lazy(() => import("./DiscoverBanner"))

const Discover = () => {

    const dispatch = useDispatch()
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const { song, discoverSongs } = useSelector(state => state.music)
    const [noResultMsg, setNoResultMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { searchSongs, searchPlaylists, searchUsers, totalSearchSongs, totalSearchPlaylists, totalSearchUsers } = useSelector(state => state.search)
    const [term, setTerm] = useState('')
    const [sort, setSort] = useState('mostrecent')
    const tabViewType = localStorage.getItem('tabViewDiscover')
    const [tabView, setTabView] = useState(tabViewType)
    const [displayCounts, setDisplayCounts] = useState({ songs: 10, playlists: 10, users: 10 });
    const [loadingMore, setLoadingMore] = useState(false);
    const [type, setType] = useState(null);

    useEffect(() => {
        const fetchSongs = async () => {
            const token = localStorage.getItem('token');
            try {
                if (page === 1) {
                    dispatch(SET_DISCSONGS([]))
                }
                const response = await axios.get(`/allsongs?page=${page}&sort=${sort}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                // setSongs(prevSongs => [...prevSongs, ...response.data.songs]);
                dispatch(ADD_DISCSONGS(response.data.songs))
                if (response.data.songs.length === 0 || response.data.songs.length < 10) {
                    setHasMore(false); // No more songs to load
                }
            } catch (error) {
                console.log('Error fetching songs:', error);
            }
        };
        fetchSongs();
    }, [page, dispatch, sort]);

    // Define the debounced function directly inside useEffect
    useEffect(() => {
        const debouncedSearch = debounce(async () => {
            if (!term) return;
            try {
                setIsLoading(true);
                const token = window.localStorage.getItem("token") || window.localStorage.getItem("guestToken");
                const response = await axios.get(`/unified-search`, {
                    params: { query: term, type: 'all', page: 1, limit: 10 },
                    headers: { Authorization: `Bearer ${token}` }
                });
                const { songs, playlists, users, total_songs, total_playlists, total_users } = response.data;
                // Update Redux state with all data
                dispatch(SET_SEARCHSONGS(songs));
                dispatch(SET_TOTALSEARCHSONGS(total_songs));
                dispatch(SET_SEARCHPLAYLISTS(playlists));
                dispatch(SET_TOTALSEARCHPLAYLISTS(total_playlists));
                dispatch(SET_SEARCHUSERS(users));
                dispatch(SET_TOTALSEARCHUSERS(total_users));
                // Update loading and no results states
                setIsLoading(false);
                setDisplayCounts({ songs: 10, playlists: 10, users: 10 })
                setNoResultMsg(songs.length === 0 && playlists.length === 0 && users.length === 0);
            } catch (error) {
                setIsLoading(false);
                console.error('Search failed:', error);
                setNoResultMsg(true);
            }
        }, 800);
        debouncedSearch();
        return () => {
            debouncedSearch.cancel();
        };
    }, [term, dispatch]);

    const handleSortChange = (sort, page) => {
        setSort(sort)
        setPage(page)
    }


    const handleChange = async (e) => {
        setTerm(e.target.value)
    }

    useEffect(() => {
        if (term === "") {
            setNoResultMsg(false)
            dispatch(SET_SEARCHSONGS([]))
            dispatch(SET_SEARCHPLAYLISTS([]))
            dispatch(SET_SEARCHUSERS([]))
            setIsLoading(false)
            setDisplayCounts({ songs: 5, playlists: 5, users: 5 })
        }
    }, [dispatch, term])

    // change view
    const handleViewChange = useCallback(() => {
        const view = localStorage.getItem('tabViewDiscover')
        if (view === 'list') {
            setTabView('grid')
            localStorage.setItem('tabViewDiscover', 'grid')
        } else {
            setTabView('list')
            localStorage.setItem('tabViewDiscover', 'list')
        }
    }, [])
    // Change Tab view
    useEffect(() => {
        if (localStorage.getItem('tabViewDiscover') === null) {
            setTabView('grid')
            localStorage.setItem('tabViewDiscover', 'grid')
        } else {
            setTabView(tabView)
        }

    }, [tabView])

    useEffect(() => {
        window.scrollTo(0, 0)
        logEvent(analytics, 'page_view', { page_title: 'Home Page' })
        trackLinkedInEvent('page_view', {
            event: 'page_view',
            page_title: 'Home Page',
        })
        trackMetaEvent('PageView', {
            page_title: 'Home Page',
        })
        trackRedditEvent('PageVisit', {
            page_title: 'Home Page',
        })
    }, [])

    useEffect(() => {
        if (location.pathname === '/') {
            dispatch(SET_PLAYINGFROM('discoverSongs'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, dispatch]);

    const handleViewMoreS = async (type) => {
        try {
            setLoadingMore(true); // Only set loading for new items
            setType(type)
            const currentPage = page + 1;
            const response = await axios.get(`/unified-search`, { params: { query: term, type: type, page: currentPage } });
            const { songs, playlists, users } = response.data;
            // Append new items to existing ones
            dispatch(SET_SEARCHSONGS([...searchSongs, ...songs]));
            dispatch(SET_SEARCHPLAYLISTS([...searchPlaylists, ...playlists]));
            dispatch(SET_SEARCHUSERS([...searchUsers, ...users]));
            setDisplayCounts(prev => ({ ...prev, [type]: prev[type] + 10 }));
            setLoadingMore(false);
        } catch (error) {
            console.error('Failed to load more items:', error);
            setLoadingMore(false);
            toast.error('Failed to load more items. Please try again.');
        }
    };


    return (
        <div className={`bg-slate-950 dark:bg-gradient-to-br dark:from-pink-100 dark:to-pink-300 min-h-screen`}>
            <TopBar heading={"Sound Of Meme"} />
            <div className='w-full overflow-hidden mt-16 relative flex flex-col-reverse xl:flex-row xl:border-b-2 border-slate-800 dark:border-pink-700 pb-2' >
                <CreateSongHomeComp />
                <Suspense fallback={<div className='w-full h-full bg-slate-900 animate-pulse' ></div>}>
                    <DiscoverBannerLazyLoad />
                </Suspense>
            </div>
            <div className='pt-4 flex flex-col justify-between gap-4 sm:px-4 px-2' >
                <input type='text' placeholder='Search all songs' value={term} onChange={handleChange} className='flex-grow bg-transparent border text-white dark:text-black border-slate-700 dark:border-pink-700 p-2 rounded-md lg:max-w-lg w-full focus:outline-none' />
            </div>
            <div className={term ? "hidden" : ""}>
                <Suspense fallback={<TrendingSongSkeleton lazyLoad={true} />}>
                    <TrendingSongsLazyLoad />
                </Suspense>
                <Suspense fallback={<GenreCategorySkeleton lazyLoad={true} />}>
                    <GenreCategoryLazyLoad />
                </Suspense>
                <Suspense fallback={<FeaturedArtistSkeleton lazyLoad={true} />}>
                    <FeaturedArtistLazyLoad />
                </Suspense>
            </div>
            <div className='pt-10 pb-2 flex flex-row justify-between  gap-4 sm:px-4 px-2' >
                <h1 className='text-xl md:text-2xl font-poppins font-bold text-slate-200 dark:text-black'>
                    {term ? "Search Results" : "Recent Songs"}
                </h1>
                <div className="flex flex-row items-center gap-4 justify-end" >
                    {!term && <SortFilter setSort={handleSortChange} sortValue={sort} options={allSongsSortOptions} />}
                    <button onClick={handleViewChange} className=' h-8 sm:h-10 aspect-square flex items-center p-1 justify-center border rounded border-slate-700 dark:border-pink-500 text-slate-300 dark:text-black hover:bg-slate-900 dark:hover:bg-pink-500 hover:text-green-400 dark:hover:text-slate-800' type='button' >
                        {tabView === "grid" ? <List size={26} strokeWidth={3} /> : <LayoutGrid size={20} strokeWidth={3} />}
                    </button>
                </div>
            </div>
            {(!isLoading && term === "") ?
                <InfiniteScroll
                    dataLength={discoverSongs.length}
                    next={() => setPage(prevPage => prevPage + 1)}
                    hasMore={hasMore}
                    loader={<SongCardPageLoader tabView={tabView} author={false} />}
                    endMessage={null}
                    className={' flex-grow py-4 pt-3 w-full grid  ' + (song ? " pb-36 md:pb-28 " : " pb-24 ") + (tabView === "list" ? " grid-cols-1 gap-x-4 gap-y-3 " : " grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-8 sm:px-4 px-2 ")}
                >{discoverSongs?.map((song) => (
                    tabView === "list" ?
                        <SongCardListView author={false} extend={true} song={song} key={song.song_id} playingFrom={"discoverSongs"} />
                        :
                        <SongCard song={song} key={song.song_id} playingFrom={"discoverSongs"} />
                ))}
                </InfiniteScroll>
                : (!isLoading && !noResultMsg) ?
                    <div className={"mx-auto sm:pt-0 pt-2 pb-32" + (song ? " pb-36 md:pb-48 " : "")} >
                        <div className={' flex-grow py-4 w-full grid  ' + (tabView === "list" ? " grid-cols-1 gap-x-4 gap-y-3 " : " grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-8 sm:px-4 px-2 ") + (searchSongs.length === 0 ? "hidden" : "")}                        >
                            {/* Search Songs Section */}
                            {searchSongs?.length > 0 && (<>
                                {searchSongs?.slice(0, displayCounts.songs).map((song) => (
                                    <React.Fragment key={song.song_id}>
                                        {tabView === "list" ? (
                                            <SongCardListView author={false} extend={true} song={song} playingFrom={"searchSongs"} />
                                        ) : (
                                            <SongCard song={song} playingFrom={"searchSongs"} />
                                        )}
                                    </React.Fragment>
                                ))}
                                {!loadingMore && displayCounts.songs < 20 && totalSearchSongs > 20 && (
                                    <div className="flex flex-row items-center justify-center h-full p-2 gap-2">
                                        <button onClick={() => handleViewMoreS('songs')} className="flex flex-row items-center justify-center border border-slate-700 rounded-md p-2 gap-2 h-max">
                                            <span className="text-sm hidden sm:block">View More Songs</span>
                                            <ArrowRight size={18} />
                                        </button>
                                    </div>
                                )}
                                {displayCounts.songs >= 20 && (
                                    <div className="flex flex-row items-center justify-center h-full p-2 gap-2">
                                        <Link to={`/songs/search?term=${encodeURIComponent(term)}`} className="flex flex-row items-center justify-center border border-slate-700 rounded-md p-2 gap-2 h-max">
                                            <span className="text-sm">View All Songs</span>
                                        </Link>
                                    </div>
                                )}
                                {loadingMore && type === 'songs' && searchSongs && (
                                    <SongCardPageLoader tabView={tabView} author={false} />
                                )}
                            </>)}
                        </div>

                        {/* Search Users Section */}
                        {!isLoading && !loadingMore && searchUsers.length > 0 && <div className="flex justify-start sm:px-4 px-2">
                            <h1 className="text-xl md:text-2xl font-poppins font-bold text-slate-200 dark:text-black">
                                Users
                            </h1>
                        </div>}
                        <div className={'w-full grid grid-cols-3 xs:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 2xl:grid-cols-8 3xl:grid-cols-8 gap-x-4 gap-y-4 sm:gap-x-8 sm:gap-y-8 sm:px-4 px-2 ' + (searchUsers.length === 0 ? "hidden" : "")}>
                            {searchUsers?.length > 0 && (<>
                                {searchUsers?.slice(0, displayCounts.users).map((user) => (
                                    <UserCard user={user} key={user.email} />
                                ))}
                                {!loadingMore && displayCounts.users < 20 && totalSearchUsers > 20 && (
                                    <div className="flex flex-row items-center justify-center w-40 h-full p-1 gap-0">
                                        <button onClick={() => handleViewMoreS('users')} className="flex flex-row items-center justify-center border border-slate-700 rounded-md p-2 gap-2 h-max">
                                            <span className="text-xs hidden sm:block">View More Users</span>
                                            <ArrowRight size={18} />
                                        </button>
                                    </div>
                                )}
                                {displayCounts.users >= 20 && (
                                    <div className="flex flex-row items-center justify-center h-full w-max sm:w-full p-1">
                                        <Link to={`/users/search?term=${encodeURIComponent(term)}`} className="flex flex-row items-center justify-center border border-slate-700 rounded-md p-2 gap-2 h-max">
                                            <span className="sm:text-sm text-xs">View All Users</span>
                                        </Link>
                                    </div>
                                )}
                                {loadingMore && type === 'users' && searchUsers && (<>
                                    {[...Array(10)].map((_, i) => (
                                        <div key={i} className="animate-pulse">
                                            <ArtistCardSkeleton />
                                        </div>
                                    ))}
                                </>)}
                            </>)}
                        </div>

                        {/* Search Playlists Section */}
                        {!isLoading && !loadingMore && searchPlaylists.length > 0 && <div className=" flex justify-start sm:px-4 px-2 sm:mb-4 mb-2 pt-8">
                            <h1 className="text-xl md:text-2xl font-poppins font-bold text-slate-200 dark:text-black">
                                Playlists
                            </h1>
                        </div>}
                        <div className={'py-4 w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 3xl:grid-cols-6 sm:gap-x-8 sm:gap-y-8 gap-x-2 gap-y-0 sm:px-4 px-2 ' + (searchPlaylists.length === 0 ? "hidden" : "")}>
                            {searchPlaylists?.length > 0 && (<>
                                {searchPlaylists?.slice(0, displayCounts.playlists).map((playlist) => (
                                    <PlaylistCard playlist={playlist} key={playlist.playlist_id} />
                                ))}
                                {!loadingMore && displayCounts.playlists < 20 && totalSearchPlaylists > 20 && (
                                    <div className="flex flex-row items-center justify-center h-full p-2 gap-0">
                                        <button onClick={() => handleViewMoreS('playlists')} className="flex flex-row items-center justify-center border border-slate-700 rounded-md p-2 gap-2 h-max">
                                            <span className="text-sm hidden sm:block">View More Playlists</span>
                                            <ArrowRight size={18} />
                                        </button>
                                    </div>
                                )}
                                {displayCounts.playlists >= 20 && (
                                    <div className="flex flex-row items-center justify-center h-full w-max sm:w-full sm:p-2 p-1">
                                        <Link to={`/playlists/search?term=${encodeURIComponent(term)}`} className="flex flex-row items-center justify-center border border-slate-700 rounded-md p-2 gap-2 h-max">
                                            <span className="sm:text-sm text-xs">View All Playlists</span>
                                        </Link>
                                    </div>
                                )}
                                {loadingMore && type === 'playlists' && searchPlaylists && (<>
                                    {[...Array(10)].map((_, i) => (
                                        <div key={i} className="animate-pulse">
                                            <PlaylistsLoader />
                                        </div>
                                    ))}
                                </>)}
                            </>)}
                        </div>
                    </div>
                    : (!isLoading && searchSongs.length === 0 && noResultMsg) ?
                        <p className='px-4' >No songs found. Try different search terms.</p> :
                        <div className={' flex-grow w-full grid ' + (tabView === "list" ? " grid-cols-1 gap-x-4 gap-y-3 py-4 " : " p-4 grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-8 sm:px-4 px-2 ") + (song ? " pb-36 md:pb-28 " : " pb-24 ")} >
                            <SongCardPageLoader tabView={tabView} author={false} />
                        </div>
            }
            <BottomBar />
        </div>
    )
}

export default Discover