const AdvertCardSkeleton = () => {
  return (
    <div className="bg-gradient-to-br from-gray-800/40 to-gray-900/40 dark:from-pink-300/30 dark:via-pink-400/20 dark:to-pink-500/30 backdrop-blur-xl rounded-2xl overflow-hidden border border-gray-700/50 dark:border-pink-300/30 shadow-lg dark:shadow-pink-400/20">
      {/* Image skeleton */}
      <div className="relative">
        <div className="w-full h-48 bg-gray-700/50 dark:bg-pink-400/30 animate-pulse" />
        <div className="absolute bottom-4 left-4 right-4 flex justify-between items-end">
          <div>
            {/* Title skeleton */}
            <div className="h-6 w-48 bg-gray-600/50 dark:bg-pink-400/40 rounded animate-pulse mb-1" />
            {/* Prize pool skeleton */}
            <div className="h-4 w-24 bg-gray-600/50 dark:bg-pink-400/30 rounded animate-pulse" />
          </div>
          {/* Days left skeleton */}
          <div className="h-7 w-24 bg-gray-600/50 dark:bg-pink-400/40 rounded-full animate-pulse" />
        </div>
      </div>

      <div className="p-4">
        {/* Date and submissions skeleton */}
        <div className="flex items-center justify-between mb-4">
          <div className="h-4 w-32 bg-gray-600/50 dark:bg-pink-400/30 rounded animate-pulse" />
          <div className="h-4 w-32 bg-gray-600/50 dark:bg-pink-400/30 rounded animate-pulse" />
        </div>

        {/* Button skeleton */}
        <div className="w-full h-10 bg-gray-600/50 dark:bg-gradient-to-r dark:from-pink-400/40 dark:to-pink-500/40 rounded-xl animate-pulse" />
      </div>
    </div>
  );
};

export default AdvertCardSkeleton;