import { useCallback, useEffect, useState } from "react";
import { Music, PlayCircle } from "lucide-react";
import TopBar from "@/components/navbar/TopBar";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BottomBar from "@/components/sidebar/BottomBar";
import toast from "react-hot-toast";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { SongCardSkeleton } from "@/components/createSong/CreateSongLoading";
import PlaylistLoader from '../playlist/PlaylistLoader';
import lockImage from '../../assets/lock.webp';
// import { SET_PLAYLISTS_UPDATED } from "@/redux/features/music/playlistSlice";
import frogImg from "../../assets/greenbird.png";
import SongCard from "@/components/songCard/LazyLoadSongCard";
import { SquareArrowUpRight } from "lucide-react";


// import { SET_PLAYLISTS, SET_PLAYLISTS_UPDATED } from "@/redux/features/music/playlistSlice";
// import { SET_PLAYLISTS } from "@/redux/features/music/playlistSlice";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { playlists, playlistsUpdated } = useSelector((state) => state.playlists);
  const [activeTab, setActiveTab] = useState("songs");
  const { song: activeSong } = useSelector((state) => state.music);
  const stackedCards = [1, 2, 3];
  const { slug } = useParams();
  const [userData, setUserData] = useState({});
  const [userDataSongs, setUserDataSongs] = useState({ songs: [] });
  const [userDataPlaylists, setUserDataPlaylists] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [songsPage, setSongsPage] = useState(1);
  const [songsHasMore, setSongsHasMore] = useState(true);
  // const { user } = useSelector((state) => state.auth);
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);

  const handleShare = async () => {

    try {
      if (navigator.share) {
        await navigator.share({
          title: userData.username,
          text: `Check out ${userData.username}'s profile`,
          url: window.location.href,
        });
      } else {
        // Fallback for browsers that don't support navigator.share
        await navigator.clipboard.writeText(window.location.href);
        toast.success("Profile link copied to clipboard!");
      }
    } catch (error) {
      console.error("Error sharing:", error);
      toast.error("Failed to share profile");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`/profile/${slug}/info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error("Failed to fetch profile data:", error);
      }
    };
    if (slug) {
      fetchData();
    }
  }, [slug]);

  useEffect(() => {
    const fetchSongs = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`/profile/${slug}/songs?page=${songsPage}&limit=20`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Update state while preventing duplicates
        setUserDataSongs((prevState) => {
          const existingSongs = prevState.songs || [];
          const newSongs = response.data.songs;

          // Filter out duplicates based on song_id or slug
          const uniqueNewSongs = newSongs.filter(
            newSong => !existingSongs.some(
              existingSong => existingSong.song_id === newSong.song_id
            )
          );

          return {
            songs: [...existingSongs, ...uniqueNewSongs]
          };
        });

        // console.log('userDataSongs.songs', userDataSongs.songs);
        setSongsHasMore(response.data.songs.length === 20);
      } catch (error) {
        console.error("Error fetching songs:", error);
        setSongsHasMore(false);
      }
    };

    if (slug) {
      fetchSongs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, songsPage]);

  const fetchPlaylists = useCallback(async (currentPage = 1) => {
    try {
      const token = localStorage.getItem('token');
      const res = await axios.get(`/profile/${slug}/playlists?page=${currentPage}&limit=20`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const fetchedPlaylists = res.data.playlists;

      setUserDataPlaylists((prevState) => {
        if (currentPage === 1) {
          // For first page, replace existing playlists
          return fetchedPlaylists;
        } else {
          // For pagination, merge with existing playlists
          const existingIds = new Set(prevState.map(p => p.playlist_id));
          const uniqueNewPlaylists = fetchedPlaylists.filter(
            playlist => !existingIds.has(playlist.playlist_id)
          );
          return [...prevState, ...uniqueNewPlaylists];
        }
      });

      setHasMore(fetchedPlaylists.length === 20);
      setPage(currentPage);
    } catch (error) {
      console.error("Error fetching playlists:", error);
      toast.error('Failed to load playlists');
      setHasMore(false);
    }
  }, [slug]);

  // Tab switching logic without refetching if already available in Redux
  useEffect(() => {
    if (slug ) {
      // Fetch playlists if playlistsUpdated is true or playlists array is empty
      if (playlistsUpdated || playlists.length === 0) {
        setPage(1);
        fetchPlaylists(1);
        // dispatch(SET_PLAYLISTS_UPDATED(false)); // Reset the flag
      }
    }
  }, [fetchPlaylists, slug, playlistsUpdated, playlists.length, dispatch]);


  // Modify the initial fetch effect to reset the page when tab changes


  const handleMore = useCallback(() => {
    if (!hasMore) return;
    fetchPlaylists(page + 1);
  }, [hasMore, fetchPlaylists, page]);

  const handlePlaylistClick = (e, playlist) => {
    if (!playlist.publish && playlist.playlist_slug === "private") {
      e.preventDefault(); // Prevent navigation
      toast.error("This is a private playlist. You cannot access it.");
      return;
    }
  };

  const subscriptionTypes = {
    0: {
      label: "Not Subscribed",
      color: "text-slate-400"
    },
    1: {
      label: "Premium",
      color: "text-blue-400"
    },
    2: {
      label: "Gold",
      color: "text-yellow-400"
    },
    3: {
      label: "Platinum",
      color: "text-yellow-600"
    }
  };

  // useEffect(() => {
  //   if (slug === user?.username) {
  //     navigate('/profile');
  //   }
  // }, [slug, user, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="min-h-screen bg-slate-950 dark:bg-gradient-to-b from-pink-100 to-pink-300 text-slate-300">
      <TopBar heading={userData.name} />
      {/* Header */}
      <div className="relative pt-20 pb-8">
        <div className="absolute inset-0 bg-cover bg-center backdrop-blur-xl blur-xl md:blur-2xl opacity-30 mt-20" style={{ backgroundImage: `url(${userData.profile_url})` }} />
        <div className="mx-auto sm:px-6 px-2">
          <div className="flex flex-col md:flex-row items-center md:items-center gap-6 md:gap-0">
            <div className="relative">
              {userData.profile_url ? <img src={userData.profile_url} alt={userData.name} className="md:w-[12rem] md:h-40 w-[10rem] h-[10rem] rounded-full border-2 border-green-500/30 dark:border-pink-500/30 shadow-lg" /> : <div className="md:w-40 md:h-40 w-[10rem] h-[10rem] rounded-full border-2 border-green-500/30 dark:border-pink-500/30 shadow-lg bg-green-400 dark:bg-pink-400 flex items-center justify-center" >
                <span className="text-black capitalize text-7xl font-potta" >{userData.name?.charAt(0)}</span>
              </div>}
            </div>
            <div className="flex flex-col items-start md:gap-1 z-50 w-full px-[2rem]">
              <div className="flex flex-row items-start w-full justify-between md:justify-start md:gap-4">
                <div className="w-full flex flex-col items-start">
                  <h1 className="sm:text-4xl text-2xl font-normal font-potta text-green-400 dark:text-pink-900 line-clamp-1">{userData.name}</h1>
                  <h1 className="text-md font-normal font-potta text-slate-400 dark:text-pink-600 mt-3 w-full truncate max-w-[20rem]">{userData.username}</h1>
                </div>
                <div className="relative group">
                  <button 
                    onClick={handleShare}
                    className="hover:bg-green-400/10 dark:hover:bg-pink-400/30 p-2 rounded-full transition-all duration-200"
                  >
                    <SquareArrowUpRight 
                      size={24} 
                      className="text-green-400 dark:text-pink-900 cursor-pointer" 
                    />
                  </button>
                  <span className={`absolute -top-10 sm:-top-6 transform hidden group-hover:block bg-slate-800/90 dark:bg-pink-600/90 backdrop-blur-md text-green-400 dark:text-pink-900 text-xs font-medium px-3 py-1.5 rounded-lg shadow-lg border border-green-400/20 dark:border-pink-400/20 transition-all duration-200 ease-in-out whitespace-nowrap left-1/2 -translate-x-1/2 before:content-[''] before:absolute before:top-full before:left-1/2 before:-translate-x-1/2 before:border-4 before:border-transparent sm:before:border-t-slate-800/90 dark:before:border-t-pink-600/90 before:border-t-green-800/90`}>
                    Share Profile
                  </span>
                </div>
              </div>
              <p className="text-slate-400 text-lg mt-1">{userData.handle}</p>
              <div className="flex flex-row md:gap-4 gap-[2.5rem] mt-2 md:mt-0 w-full">
                <span className="font-semibold text-sm bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-teal-500 dark:from-pink-900 dark:to-pink-400">{userData.total_songs} Songs</span>
                <span className="font-semibold text-sm bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-teal-500 dark:from-pink-900 dark:to-pink-400">{userData.total_playlists} Playlists</span>
                <span className="font-semibold text-sm bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-teal-500 dark:from-pink-900 dark:to-pink-400">{userData.total_likes} Likes</span>
              </div>
              <p className="text-slate-400 dark:text-pink-600 text-sm mt-2">
                <span className="font-semibold">Credits:</span>{" "}
                <span className={`font-bold ${subscriptionTypes[userData.subscription_type]?.color ?? "text-slate-400"}`}>{subscriptionTypes[userData.subscription_type]?.label ?? "Not Subscribed"}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="mx-auto px-4 sm:pr-10">
        <div className="flex justify-center gap-8 border-b border-slate-800 dark:border-pink-800">
          <button onClick={() => setActiveTab("songs")} className={`flex w-full justify-center items-center gap-2 py-4 px-2 text-sm font-medium ${activeTab === "songs" ? "border-b-2 border-green-500 text-green-500 dark:border-pink-500 dark:text-pink-900" : "text-slate-400 hover:text-white dark:text-pink-600 dark:hover:text-pink-900"}`}>
            <Music size={18} />
            <span>Songs</span>
          </button>
          <button onClick={() => setActiveTab("playlists")} className={`flex w-full justify-center items-center gap-2 py-4 px-2 text-sm font-medium ${activeTab === "playlists" ? "border-b-2 border-green-500 text-green-500 dark:border-pink-500 dark:text-pink-900" : "text-slate-400 hover:text-white dark:text-pink-600 dark:hover:text-pink-900"}`}>
            <PlayCircle size={18} />
            <span>Playlists</span>
          </button>
        </div>

        {/* Content */}
        <div className={`py-8 ${activeSong ? "mb-28 sm:mb-20" : ""}`}>
          {activeTab === "songs" ? (
            <div>
              <InfiniteScroll
                dataLength={userDataSongs.songs?.length}
                next={() => setSongsPage(prevPage => prevPage + 1)}
                hasMore={songsHasMore}
                loader={<Loader />}
                endMessage={null}
                scrollThreshold={0.8}
                style={{ overflow: 'visible' }}
                className={`flex-grow mb-20 sm:py-4 w-full grid mr-2 sm:mr-3 md:mr-4 lg:mr-5 xl:mr-6 ml-[4px] grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-y-8 gap-x-4`}
              >
                {userDataSongs.songs &&
                  userDataSongs.songs.map((song, index) => (
                    <SongCard
                      song={song}
                      key={index}
                      playingFrom={"profileSongs"}
                    />
                  ))}
              </InfiniteScroll>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={userDataPlaylists.length}
              next={handleMore}
              hasMore={hasMore}
              loader={<PlaylistLoaderSkeleton />}
              endMessage={null}
              scrollThreshold={0.8}
              style={{ overflow: 'visible' }}
              className={`flex-grow sm:py-4 w-full grid sm:gap-6 md:gap-6 lg:gap-8 xl:gap-8 sm:mt-6 md:mt-6 mr-2 sm:mr-3 md:mr-4 lg:mr-5 xl:mr-6 ml-[4px] ${activeSong ? "pb-36 md:pb-28" : "pb-24"} grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 sm:gap-y-8 sm:pr-5`}
            >
              {userDataPlaylists &&
                userDataPlaylists.map((playlist, index) => (
                  <div key={index}>
                    <Link to={`/playlist?id=${playlist?.playlist_slug}`} onClick={(e) => handlePlaylistClick(e, playlist)} className="relative group">
                      <div key={index} className="relative w-full aspect-square flex items-center justify-start sm:justify-center hover:rotate-1 transition-transform duration-300 ease-in-out">
                        {stackedCards.map((_, index) => (
                          <div key={`${playlist?.playlist_id}-${index}`} className={`absolute w-5/6 h-3/4 sm:w-full sm:h-full md:w-full md:h-full rounded-lg ${index === 0 ? "z-30 hover:-translate-x-1 hover:-rotate-2 transition-transform duration-300" : ""} ${index === 1 ? "z-20 -translate-y-0 rotate-2 translate-x-2" : ""} ${index === 2 ? "z-10 -translate-y-0 rotate-3 translate-x-4" : ""}`}>
                            <div className={`relative w-full h-full rounded-lg p-[2px] bg-gradient-to-r from-green-500 to-green-300 dark:from-pink-500 dark:to-pink-300 shadow-[0_0_10px_rgba(34,197,94,0.5)] dark:shadow-[0_0_10px_rgba(255,0,255,0.5)] ${index !== 0 ? "opacity-50" : ""}`}>
                              <div className="relative w-full h-full bg-slate-900 rounded-lg overflow-hidden">
                                <div className="absolute top-2 left-2 bg-black/50 text-white text-xs px-2 py-1 rounded-full z-20">
                                  {playlist?.song_count === 1 ? "1 Song" : `${playlist?.song_count || 0} Songs`}
                                </div>
                                <img src={playlist?.playlist_slug === "private" ? lockImage : (playlist?.images?.[0] || frogImg)} alt={playlist?.name} className={`w-full h-full ${playlist?.playlist_slug === "private" ? 'object-scale-down bg-slate-800' : 'object-cover'}`} />
                                <div className="absolute bottom-0 w-full p-4 bg-gradient-to-t from-black/80 to-transparent">
                                  <h3 className="text-white text-lg font-semibold truncate">
                                    {playlist?.name || "Hello"}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Link>
                  </div>
                ))}

            </InfiniteScroll>
          )}
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default UserProfile;

function Loader() {
  return (
    <>
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
      <SongCardSkeleton />
    </>
  )
}

function PlaylistLoaderSkeleton() {
  return (
    <>
      <PlaylistLoader />
      <PlaylistLoader />
      <PlaylistLoader />
      <PlaylistLoader />
      <PlaylistLoader />
      <PlaylistLoader />
      <PlaylistLoader />
      <PlaylistLoader />
      <PlaylistLoader />
      <PlaylistLoader />
      <PlaylistLoader />
    </>
  )
}