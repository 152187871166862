import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    lastValentineOpen: null,
    valentineSongs: [],
    valentineSongsFromUser: [],
    isOpenGiftDialog: false,   // for gifting subscription dialog
    isOpenFeedbackDialog: false, // for feedback dialog
    lastFeedbackOpen: null,
    isOpenWelcomeDialog: false,
    lastWelcomeOpen: null,
    isOpenGamifyDialog: false,
    lastGamifyOpen: null,
    achievements: [],
    claimableAchievement: null,
    claimStatus: null,
    claimKey: null,
    claimReward: null,
    showClaimDialog: false,
    lastClaimDialogOpen: null,
    updatedAchievements: [],
    isManualOpen: false,
}

const valentineSlice = createSlice({
    name: 'valentine',
    initialState,
    reducers: {
        SET_VALENTINE_OPEN: (state, action) => {
            state.isOpen = action.payload;
            state.lastValentineOpen = new Date().toDateString();
        },
        RESET_VALENTINE: (state) => {
            state.isOpen = false;
            state.lastValentineOpen = null;
        },
        SET_VALENTINE_SONGS: (state, action) => {
            state.valentineSongs = action.payload;

        },
        SET_VALENTINE_SONGS_FROM_USER: (state, action) => {
            state.valentineSongsFromUser = action.payload;
        },
        ADD_VALENTINE_SONGS_FROM_USER: (state, action) => {
            const newSongs = action.payload;
            const existingIds = new Set(state.valentineSongsFromUser.map(song => song.song_id));
            const filteredSongs = newSongs.filter(song => !existingIds.has(song.song_id));
            state.valentineSongsFromUser = [...state.valentineSongsFromUser, ...filteredSongs];
        },
        APPEND_VALENTINE_SONGS_FROM_USER: (state, action) => {
            state.valentineSongsFromUser = [...action.payload, ...state.valentineSongsFromUser];
        },
        SET_OPEN_GIFT_DIALOG: (state, action) => {
            state.isOpenGiftDialog = action.payload;
        },
        FILTER_VALENTINE_SONGS_FROM_USER: (state, action) => {
            state.valentineSongsFromUser = state.valentineSongsFromUser.filter(
                song => song.song_id !== action.payload
            );
        },
        SET_FEEDBACK: (state, action) => {
            state.isOpenFeedbackDialog = action.payload;
            state.lastFeedbackOpen = new Date().toDateString();
        },
        SET_OPEN_WELCOME_DIALOG: (state, action) => {
            state.isOpenWelcomeDialog = action.payload;
            state.lastWelcomeOpen = new Date().toDateString();
        },
        // Redux for Gamify Dialog
        SET_OPEN_GAMIFY_DIALOG: (state, action) => {
            state.isOpenGamifyDialog = action.payload;
            state.lastGamifyOpen = new Date().toDateString();
        },
        SET_ACHIEVEMENTS: (state, action) => {
            state.achievements = action.payload;
        },
        SET_CLAIMABLE_ACHIEVEMENT: (state, action) => {
            state.claimableAchievement = action.payload;
        },
        SET_SHOW_CLAIM_DIALOG: (state, action) => {
            state.showClaimDialog = action.payload;
            state.lastClaimDialogOpen = new Date().toDateString();
        },
        SET_IS_MANUAL_OPEN: (state, action) => {
            state.isManualOpen = action.payload;
        },
    }
});

export const { SET_SHOW_CLAIM_DIALOG,
    SET_OPEN_GAMIFY_DIALOG,
    SET_OPEN_WELCOME_DIALOG,
    SET_VALENTINE_OPEN, RESET_VALENTINE,
    SET_VALENTINE_SONGS,
    SET_VALENTINE_SONGS_FROM_USER,
    APPEND_VALENTINE_SONGS_FROM_USER,
    SET_OPEN_GIFT_DIALOG,
    FILTER_VALENTINE_SONGS_FROM_USER,
    ADD_VALENTINE_SONGS_FROM_USER,
    SET_FEEDBACK,
    SET_ACHIEVEMENTS,
    SET_CLAIMABLE_ACHIEVEMENT,
    SET_IS_MANUAL_OPEN,
} = valentineSlice.actions;

export default valentineSlice.reducer;