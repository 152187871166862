import { useEffect } from "react";
import clarity from "@microsoft/clarity";

const useClarity = () => {
    useEffect(() => {
        clarity.init(import.meta.env.VITE_CLARITY_ID);
    }, []);
};

export default useClarity;
