import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode"
import { getUserDetails, SET_LOGIN } from "../../redux/features/auth/authSlice";
import toast from "react-hot-toast";
import axios from "axios";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { trackMetaEvent } from "@/utils/metaPixel";
import { trackRedditEvent } from "@/utils/redditPixel";

const GoogleLoginButton = ({ defaultPath }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    // handle login function
    const handleCallback = useCallback(async (response) => {
        const refCode = sessionStorage.getItem('refCode')
        try {
            toast.loading("Logging In", { id: "login" })
            const userObj = jwtDecode(response.credential)
            const userData = {
                name: userObj.name,
                email: userObj.email,
                picture: userObj.picture,
                referral_code: refCode || null

            }
            const guestToken = localStorage.getItem('guestToken')
            const { data } = await axios.post("/googlelogin3", userData, { headers: { Authorization: `Bearer ${guestToken}` } })
            window.localStorage.setItem('token', data?.access_token)
            sessionStorage.removeItem('refCode')
            dispatch(SET_LOGIN(true))
            localStorage.removeItem("guestToken")
            const token = localStorage.getItem('token')
            await dispatch(getUserDetails(token)).unwrap()
            const utmParams = JSON.parse(localStorage.getItem('utmParams')) || {};
            const utmSource = utmParams.utm_source || 'unknown';
            if (data?.exists) {
                logEvent(analytics, 'google_login', {
                    method: 'google',
                    email: userData.email
                });
            } else {
                logEvent(analytics, 'google_signup', {
                    method: 'google',
                    email: userData.email
                });
                if (utmSource.includes('meta')) {
                    trackMetaEvent('CompleteRegistration', {
                        method: 'google',
                        email: userData.email
                    })
                } else if (utmSource.includes('reddit')) {
                    trackRedditEvent('SignUp', {
                        method: 'google',
                        email: userData.email
                    })
                }
            }

            // navigate("/");
            //This line added by me arun 
            const navigateTo = location.state?.from || defaultPath;
            navigate(navigateTo)
            toast.success("Logged In", { id: "login" })
        } catch (error) {
            toast.error("Error occurred while login", { id: "login" })
            console.log(error);
        }
    }, [dispatch, navigate, defaultPath, location.state])

    const initializeGoogle = useCallback(() => {
        // eslint-disable-next-line no-undef
        google.accounts.id.initialize({
            client_id: import.meta.env.VITE_GOOGLE_ID,
            callback: handleCallback
        })
        // eslint-disable-next-line no-undef
        google.accounts.id.renderButton(
            document.getElementById('signIn'),
            { shape: 'rounded', size: 'fill', width: "320", text: "Continue with Google" }
        );
    }, [handleCallback])

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.onload = () => initializeGoogle();
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [initializeGoogle]);

    return (
        <>
            <div className="" id="signIn" ></div>

        </>
    )
}

export default GoogleLoginButton