import { useCallback, useEffect, useState } from 'react';
import failedAni from "../../assets/failure.json"
import Lottie from 'lottie-react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TopBar from '@/components/navbar/TopBar';
import BottomBar from '@/components/sidebar/BottomBar';
import toast from 'react-hot-toast';
import premium from "@/assets/premium-badge.svg"
import gold from "@/assets/gold-badge.svg"
import platinum from "@/assets/platinum.svg"
import GiftCodeSkeleton from './GiftCodeSkeleton';
import { Card, CardContent } from '@/components/ui/card';
import { Sparkles, Copy, Download } from 'lucide-react';

const GiftSuccess = () => {
    const { code } = useParams();
    const [verified, setVerified] = useState(false);
    const [verifiedData, setVerifiedData] = useState(null);
    const url = `https://soundofmeme.com/redeem/success/${code}`;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function verifyGiftCode() {
            const toastId = "gift-code-verification";
            try {
                toast.loading("Verifying Gift Code...", { id: toastId });
                const token = localStorage.getItem("token") || localStorage.getItem("guestToken");
                setLoading(true);
                const response = await axios.get(`/verifygiftcode?gift_code=${code}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                toast.dismiss(toastId);
                toast.success("Gift Code Verified Successfully!", { id: toastId, duration: 3000 });
                setVerified(true);
                setVerifiedData(response.data);
                setLoading(false);
            } catch (error) {
                setVerified(false);
                toast.error("Gift Code Verification Failed!", { id: toastId, duration: 3000 });
                setLoading(false);
            }
        }

        if (code) {
            verifyGiftCode();
        }
        return () => {
            toast.dismiss("gift-code-verification");
        };
    }, [code]);


    const handleCopy = async (url) => {
        try {
            // Try the modern clipboard API first
            if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(url);
            } else {
                // Fallback for mobile devices and older browsers
                const textArea = document.createElement('textarea');
                textArea.value = url;
                textArea.style.position = 'fixed';
                textArea.style.left = '-999999px';
                textArea.style.top = '-999999px';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                document.execCommand('copy');
                textArea.remove();
            }
            toast.success("Gift Code Copied to Clipboard!", { id: "gift-code-copy", duration: 2000 });
        } catch (err) {
            toast.error("Failed to Copy Gift Code!", { id: "gift-code-copy", duration: 2000 });
        }
    };

    const handleDownload = useCallback(() => {
        toast.loading("Downloading Gift Code...", { id: "gift-code-download" });
        const messageContent = `
=================================================
           🎁 SoundOfMeme Gift Code 🎁
=================================================

Thank you for choosing SoundOfMeme!

Your Gift Code: ${code}

Instructions:
1. Visit ${url}
2. Click on "Redeem Gift"
3. Start enjoying your ${verifiedData?.gift_details?.plan_type === 1 ? 'Premium' : verifiedData?.gift_details?.plan_type === 2 ? 'Gold' : verifiedData?.gift_details?.plan_type === 3 ? 'Platinum' : ''} subscription!

=================================================
Questions? Contact support@soundofmeme.com
=================================================
`;
        const element = document.createElement('a');
        const file = new Blob([messageContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'soundofmeme-gift-code.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        toast.dismiss("gift-code-download");
    }, [code, url, verifiedData]);


    useEffect(() => {
        if (verified) {
            handleDownload();
        }
    }, [verified, handleDownload]);




    return (
        <div className="min-h-screen bg-gradient-to-bl from-slate-900 via-slate-950 to-black">
            <TopBar heading="Gift Code!" />
            <div className="container mx-auto px-1 pt-28 pb-8">
                {loading && <GiftCodeSkeleton />}
                {verified && !loading && (
                    <Card className="max-w-md mx-auto bg-gradient-to-b from-slate-900 via-slate-950 to-black rounded-2xl px-4 sm:px-4 py-8 shadow-2xl shadow-amber-500/30 border border-amber-500/10">
                        <CardContent className="relative ">
                            {/* <div className="absolute inset-0 bg-gradient-to-r from-amber-500/10 via-transparent to-amber-500/10 rounded-2xl blur-3xl" /> */}
                            <div className="relative z-10 flex flex-col items-center space-y-4 sm:space-y-8">
                                <div className="sm:space-y-3 space-y-1 text-center">
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-md sm:text-xl font-bold text-green-400">
                                            Gift Code Generated Successfully!
                                        </h1>
                                    </div>
                                    <p className="text-slate-400 text-sm">
                                        Your gift code is ready to be redeemed
                                    </p>
                                </div>

                                <div className="relative group">
                                    <div className="rounded-full overflow-hidden flex-shrink-0 sm:w-40 w-36 ">
                                        <img src={verifiedData?.gift_details?.plan_type === 1 ? premium : verifiedData?.gift_details?.plan_type === 2 ? gold : verifiedData?.gift_details?.plan_type === 3 ? platinum : ''} alt="rocket" className=" w-full scale-125 xl:scale-110 object-cover" />
                                    </div>
                                </div>

                                <div className="text-center flex flex-col sm:gap-2 gap-1">
                                    <div className="flex items-center justify-center sm:gap-2 gap-1">
                                        <Sparkles className="w-5 h-5 text-emerald-400" />
                                        <h3 className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-emerald-400 to-green-400 bg-clip-text text-transparent">
                                            {verifiedData?.gift_details?.plan_type === 1 ? 'Premium' : verifiedData?.gift_details?.plan_type === 2 ? 'Gold' : 'Platinum'} Plan
                                        </h3>
                                    </div>
                                    <p className="text-base text-slate-300 font-medium">
                                        {verifiedData?.gift_details?.duration_months} {verifiedData?.gift_details?.duration_months === 1 ? 'Month' : 'Months'}
                                    </p>
                                </div>

                                <div className="flex flex-col items-center justify-center  w-full max-w-[500px] gap-3 mt-6 sm:mt-8">
                                    <div className="w-full">
                                        <p onClick={() => handleCopy(url)}
                                            className="w-full text-sm text-green-400 font-medium bg-slate-950/90 rounded-lg px-3 sm:py-2.5 py-1.5 cursor-pointer hover:bg-slate-900/70 transition-colors truncate">
                                            {url}
                                        </p>
                                    </div>

                                    <div className="flex flex-col items-center justify-center gap-2 w-full">
                                        <button onClick={() => handleCopy(url)} type="button" className="sm:px-4 sm:py-2 px-2 py-2 flex items-center justify-center gap-2 w-full bg-gradient-to-r from-emerald-500 to-green-500 hover:from-emerald-600 hover:to-green-600 font-medium shadow-lg shadow-emerald-500/25 hover:shadow-emerald-500/40 text-black rounded-md transition-colors duration-200">
                                            <Copy className="w-5 h-5" />
                                            Copy Link
                                        </button>
                                        <button onClick={() => handleDownload()} type="button" className="sm:px-4 sm:py-2 px-2 py-2 flex items-center justify-center gap-2 w-full bg-transparent hover:bg-slate-700 border border-green-400 text-green-400 rounded-md transition-colors duration-200">
                                            <Download className="w-5 h-5" />
                                            Download
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                )}
                {(!verified && !loading) && (
                    <div className="flex flex-col items-center justify-center h-screen w-full max-w-md mx-auto">
                        <div className="w-full flex flex-col items-center justify-center gap-4 mb-8">
                            <Lottie animationData={failedAni} loop={true} className='w-96 h-96' />
                            <h1 className="text-4xl text-center font-bold text-green-400"> Please Login to Redeem Gift Code </h1>
                        </div>
                    </div>
                )}
                {(!verified && !loading) && (
                    <div className="flex flex-col items-center justify-center h-screen w-full max-w-md mx-auto">
                        <div className="w-full max-w-[240px] mb-8">
                            <Lottie animationData={failedAni} loop={true} />
                        </div>
                        <h1 className="text-4xl text-center font-bold bg-gradient-to-r from-red-400 via-rose-500 to-red-500 bg-clip-text text-transparent">
                            Gift Code Invalid
                        </h1>
                        <p className="mt-4 text-slate-400 text-center">
                            The gift code you entered has expired or is invalid. Please check and try again.
                        </p>
                    </div>
                )}
            </div>
            <BottomBar />
        </div>
    );

};

export default GiftSuccess;