import { SET_PLAYING, SET_PLAYLOADING, SET_SONG } from '@/redux/features/music/musicSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Play, Pause, SkipBack, SkipForward } from 'lucide-react';

const SongControlButton = ({ position, playerRef }) => {

    const dispatch = useDispatch()
    const { playing, playLoading, song, discoverSongs, userSongs, likedSongs, relatedSongs, genres, playingFrom } = useSelector(state => state.music)
    const { valentineSongs, valentineSongsFromUser } = useSelector(state => state.extradialogs)
    const { latestSongs, searchSongs } = useSelector(state => state.search)
    const { songs: leaderBoardSongs, cryptoSongs: cryptoLeaderboardSongs } = useSelector(state => state.leaderboard)
    const { songs } = useSelector(state => state.playlists)

    const handleToggle = (e) => {
        e.stopPropagation()
        dispatch(SET_PLAYING(!playing));
    }

    const changeSong = (songs, direction) => {
        // if (!songs || songs.length === 0) return;

        if (!Array.isArray(songs) || songs.length === 0) {
            // Handle the case when songs is not an array or is empty
            console.warn("No related songs available");
            return;
        }

        const index = songs.findIndex(dsong => dsong?.song_id === song?.song_id);
        let nextIndex = index + direction;

        // Only allow next navigation at the end, but prevent prev at start
        if (nextIndex < 0) {
            return; // Don't do anything if trying to go previous from first song
        } else if (nextIndex >= songs.length) {
            nextIndex = 0;
        }

        // Update the song state and playing state
        dispatch(SET_SONG(songs[nextIndex]));
        dispatch(SET_PLAYING(true));
        dispatch(SET_PLAYLOADING(true));
    };

    // Add this helper function to check if it's the first song
    const isFirstSong = (song, allSongs) => {
        const { songs, discoverSongs, userSongs, likedSongs, searchSongs, leaderBoardSongs, relatedSongs, genres, valentineSongs, valentineSongsFromUser, cryptoLeaderboardSongs } = allSongs;
        const sourceMap = {
            "playlists": songs,
            "discoverSongs": discoverSongs,
            "userSongs": userSongs,
            "likedSongs": likedSongs,
            "leaderBoard": leaderBoardSongs,
            "cryptoLeaderboard": cryptoLeaderboardSongs,
            "relatedSongs": relatedSongs,
            "genres": genres,
            "trendingSongs": latestSongs,
            "valentineSongs": valentineSongs,
            "valentineSongsFromUser": valentineSongsFromUser,
            "searchSongs": searchSongs,
        };

        const currentSource = playingFrom;
        // const currentSongs = sourceMap[currentSource] || [];
        const currentSongs = Array.isArray(sourceMap[currentSource]) ? sourceMap[currentSource] : [];

        // return currentSongs.findIndex(s => s.song_id === song?.song_id) === 0;
        return currentSongs.length > 0 && currentSongs.findIndex(s => s.song_id === song?.song_id) === 0;
    };

    const handleNext = (e) => {

        if (e) e.stopPropagation();
        if (playingFrom === "playlists") {
            changeSong(songs, 1); // Use the current playlist songs
        } else if (playingFrom === "discoverSongs") {
            changeSong(discoverSongs, 1);
        } else if (playingFrom === "userSongs") {
            changeSong(userSongs, 1);
        } else if (playingFrom === "likedSongs") {
            changeSong(likedSongs, 1);
        } else if (playingFrom === "leaderBoard") {
            changeSong(leaderBoardSongs, 1);
        } else if (playingFrom === "cryptoLeaderboard") {
            changeSong(cryptoLeaderboardSongs, 1);
        } else if (playingFrom === "relatedSongs") {
            changeSong(relatedSongs, 1);
        } else if (playingFrom === "genres") {
            changeSong(genres, 1);
        } else if (playingFrom === "trendingSongs") {
            changeSong(latestSongs, 1);
        } else if (playingFrom === "valentineSongs") {
            changeSong(valentineSongs, 1);
        } else if (playingFrom === "valentineSongsFromUser") {
            changeSong(valentineSongsFromUser, 1);
        } else if (playingFrom === "searchSongs") {
            changeSong(searchSongs, 1);
        }
    };

    const handlePrev = (e) => {

        if (e) e.stopPropagation();
        if (playingFrom === "playlists") {
            changeSong(songs, -1); // Use the current playlist songs
        } else if (playingFrom === "discoverSongs") {
            changeSong(discoverSongs, -1);
        } else if (playingFrom === "userSongs") {
            changeSong(userSongs, -1);
        } else if (playingFrom === "likedSongs") {
            changeSong(likedSongs, -1);
        } else if (playingFrom === "leaderBoard") {
            changeSong(leaderBoardSongs, -1);
        } else if (playingFrom === "relatedSongs") {
            changeSong(relatedSongs, -1);
        } else if (playingFrom === "genres") {
            changeSong(genres, -1);
        } else if (playingFrom === "trendingSongs") {
            changeSong(latestSongs, -1);
        } else if (playingFrom === "valentineSongs") {
            changeSong(valentineSongs, -1);
        } else if (playingFrom === "valentineSongsFromUser") {
            changeSong(valentineSongsFromUser, -1);
        } else if (playingFrom === "searchSongs") {
            changeSong(searchSongs, -1);
        } else if (playingFrom === "cryptoLeaderboard") {
            changeSong(cryptoLeaderboardSongs, -1);
        }
    };

    // Add MediaSession API support
    useEffect(() => {
        if ('mediaSession' in navigator) {
            navigator.mediaSession.setActionHandler('play', () => {
                dispatch(SET_PLAYING(true));
            });

            navigator.mediaSession.setActionHandler('pause', () => {
                dispatch(SET_PLAYING(false));
            });

            navigator.mediaSession.setActionHandler('previoustrack', () => {
                handlePrev(null);
            });

            navigator.mediaSession.setActionHandler('nexttrack', () => {
                handleNext(null);
            });

            // Update metadata when song changes
            if (song) {
                navigator.mediaSession.metadata = new MediaMetadata({
                    title: song.song_name,
                    artist: song.username,
                    artwork: song.image_url ? [
                        { src: song.image_url, sizes: '512x512', type: 'image/jpeg' }
                    ] : undefined
                });
            }
        }

        // Cleanup
        return () => {
            if ('mediaSession' in navigator) {
                navigator.mediaSession.setActionHandler('play', null);
                navigator.mediaSession.setActionHandler('pause', null);
                navigator.mediaSession.setActionHandler('previoustrack', null);
                navigator.mediaSession.setActionHandler('nexttrack', null);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [song, dispatch, playerRef]);

    // Update media session playback state
    useEffect(() => {
        if ('mediaSession' in navigator) {
            navigator.mediaSession.playbackState = playing ? 'playing' : 'paused';
        }
    }, [playing]);


    return (
        <>{
            position === 'bigScreen' ?
                <>
                    <button type='button' disabled={isFirstSong(song, { songs, discoverSongs, userSongs, likedSongs, searchSongs, leaderBoardSongs })} className='relative z-10 text-green-400 disabled:opacity-50 disabled:cursor-not-allowed group' onClick={handlePrev} >
                        <SkipBack size={24} className='fill-green-400 dark:fill-pink-700' />
                        {isFirstSong(song, { songs, discoverSongs, userSongs, likedSongs, searchSongs, leaderBoardSongs, relatedSongs }) && (
                            <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-slate-700 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none">
                                No previous song
                            </span>
                        )}
                    </button>
                    <button type='button' className='relative z-10 text-green-400' onClick={handleToggle}>{!playing ? <Play className='pl-0.5 fill-green-400 dark:fill-pink-700' size={28} /> : <Pause className='fill-green-400 dark:fill-pink-700' size={28} />}</button>
                    <button type='button' className='relative z-10 text-green-400' onClick={handleNext}><SkipForward size={24} className='fill-green-400 dark:fill-pink-700' /></button>
                </>
                :
                <>
                    <button type='button' className='relative z-10 text-green-400' onClick={handlePrev}><SkipBack className='fill-green-400 dark:fill-pink-700' size={32} /></button>
                    <button type='button' disabled={playLoading} className='disabled:opacity-50 relative z-10 focus:outline-none rounded-full p-2.5 bg-green-400 dark:bg-pink-700' onClick={handleToggle}>{!playing ? <Play className='pl-0.5 fill-black dark:fill-pink-700' size={32} /> : <Pause className='fill-black dark:fill-pink-700' size={32} />}</button>
                    <button type='button' className='relative z-10 text-green-400' onClick={handleNext}><SkipForward className='fill-green-400 dark:fill-pink-700' size={32} /></button>
                </>
        }</>
    )
}

export default SongControlButton