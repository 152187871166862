import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import toast from 'react-hot-toast';
import { SET_DELETE_DIALOG_OPEN } from '@/redux/features/music/createSlice';
import { SET_SONG, SET_PLAYING } from '@/redux/features/music/musicSlice';
import { REMOVE_DELETED_SONG } from '@/redux/features/music/musicSlice';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogOverlay, DialogTitle } from '../ui/dialog';
import { FILTER_VALENTINE_SONGS_FROM_USER } from '@/redux/features/theme/extradialogs';

const DeleteSongDialog = () => {
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { isDialogOpen, songToDelete } = useSelector(state => state.create);
  const { song: selectedSong } = useSelector(state => state.music);

  const handleDelete = async () => {
    if (!songToDelete) return;

    try {
      setDeleteLoading(true);
      const token = localStorage.getItem('token') || localStorage.getItem('guestToken');
      const { data } = await axios.post("/deletesong",
        { song_id: songToDelete.song_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      toast.success(data?.message);

      if (songToDelete?.song_id === selectedSong?.song_id) {
        dispatch(SET_SONG(null));
        dispatch(SET_PLAYING(false));
      }

      dispatch(REMOVE_DELETED_SONG(songToDelete.song_id));
      dispatch(FILTER_VALENTINE_SONGS_FROM_USER(songToDelete.song_id))
      dispatch(SET_DELETE_DIALOG_OPEN(false));
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.detail || error.message || "Oops! Something went wrong. Please try again.");
    } finally {
      setDeleteLoading(false);
      setTimeout(() => {
        document.body.style.removeProperty('pointer-events');
      }, 500);
    }
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={(open) => {
      dispatch(SET_DELETE_DIALOG_OPEN(open))
      if (open === false) {
        setTimeout(() => {
          document.body.style.removeProperty('pointer-events');
        }, 500);
      }
    }}>
      <DialogOverlay className="z-[999]">
        <DialogContent className="w-[90%] sm:w-full block max-w-sm z-[9999] p-4 bg-slate-900 border-slate-700 dark:bg-gradient-to-br dark:from-pink-200 dark:to-pink-400 dark:border-gray-300 rounded-lg">
          <DialogTitle className="text-2xl text-left text-red-500 dark:text-red-900 font-potta">Delete Song</DialogTitle>
          <DialogDescription className="text-left text-slate-300 dark:text-slate-900 text-lg mt-1">
            Are you sure you want to delete the song?
          </DialogDescription>
          {songToDelete && (
            <div className="flex gap-2 items-center bg-slate-800 dark:bg-slate-200 rounded-lg mt-3">
              <img className="w-10 rounded-lg" src={songToDelete?.image_url} alt="cover" />
              <p className="text-slate-300 dark:text-slate-900 text-lg font-medium truncate">{songToDelete?.song_name}</p>
            </div>
          )}

          <div className="grid grid-cols-2 gap-4 mt-4">
            <button
              disabled={deleteLoading}
              onClick={handleDelete}
              type="button"
              className="disabled:animate-pulse bg-slate-800 dark:bg-pink-700 hover:bg-slate-700 hover:text-red-500 font-medium text-red-500 dark:text-black px-4 py-2 rounded-lg"
            >
              {deleteLoading ? "Deleting..." : "Delete"}
            </button>

            <DialogClose asChild>
              <button
                type="button"
                className="bg-slate-800 dark:bg-slate-200 hover:bg-slate-700 hover:text-slate-100 font-medium text-slate-300 dark:text-slate-900 px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
            </DialogClose>
          </div>
        </DialogContent>
      </DialogOverlay>
    </Dialog>
  );
};

export default DeleteSongDialog;