import { Link, useLocation, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { SET_OTPCARD, SET_SHOW_LOGIN } from "@/redux/features/auth/authSlice";
import { useEffect } from "react";
import { FileMusic, ListMusic, Home } from "lucide-react";
import { Leaderboard, MusicAdd } from "../navbar/SocialIcons";

const BottomBar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { user } = useSelector(state => state.auth)
    const handleClick = () => {
        if (user) {
            navigate("/create")
            dispatch(SET_SHOW_LOGIN(false))
        } else {
            toast.error("Login to create a song", { id: 'login' })
            dispatch(SET_SHOW_LOGIN(true))
        }
    }

    useEffect(() => {
        const otpToken = sessionStorage.getItem("otpToken")
        if (otpToken) {
            dispatch(SET_SHOW_LOGIN(true))
            dispatch(SET_OTPCARD(true))
        }
    }, [user,dispatch])

    return (
        <div className="fixed bottom-0 left-0 w-full bg-slate-900 dark:bg-pink-100 z-[999] md:hidden rounded-t-xl" >
            <div className="grid grid-cols-3 align-middle h-16 px-4" >
                <div className="flex items-center justify-between" >
                    <Link to={"/"} ><Home className={location.pathname === "/" ? " text-green-400 dark:text-black " : " text-slate-100 dark:text-pink-500 "} size={26} /></Link>
                    <Link to={"/leaderboard/crypto"} ><Leaderboard className={"mr-3 xs:mr-5 sm:mr-8 " + (location.pathname === "/leaderboard/crypto" || location.pathname === "/leaderboard" ? " text-green-400 dark:text-black " : " text-slate-100 dark:text-pink-500 ")} size={28} /></Link>
                </div>
                <div className="flex items-center justify-center" >
                    <button onClick={handleClick} className='focus:outline-none ' >
                        <MusicAdd className="text-black bg-green-400 dark:bg-pink-500 p-1 rounded-full animate-glow-2 dark:animate-glow-pink" size={48} />
                    </button>
                </div>
                <div className={"flex items-center justify-between "} >
                    <Link to={"/playlist"} ><ListMusic className={"ml-3 xs:ml-5 sm:ml-8 " + (location.pathname === "/playlist" ? " text-green-400 dark:text-black " : " text-slate-100 dark:text-pink-500 ")} size={28} /></Link>
                    <Link to={"/creations"} ><FileMusic className={location.pathname === "/creations" ? " text-green-400 dark:text-black " : " text-slate-100 dark:text-pink-500 "} size={28} /></Link>
                </div>
            </div>
        </div>
    )
}

export default BottomBar