import GenreCardSkeleton from '../genres/GenreSkeleton'

const GenreCategorySkeleton = ({ lazyLoad = false }) => {
    return (
        <div className={lazyLoad ? "px-4 pt-4 pb-8" : ""}>
            {lazyLoad && <h2 className="text-lg md:text-2xl font-poppins font-bold text-slate-200 dark:text-black mb-4">
                Genres
            </h2>}
            <div className='flex items-center gap-4 overflow-x-auto w-full whitespace-nowrap scrollbar-hide'>
                {Array.from({ length: 20 }).map((_, i) => (
                    <div key={i} className="!w-32 sm:!w-40 xl:!w-52 h-16 sm:h-24 xl:h-32 flex-shrink-0">
                        <GenreCardSkeleton />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default GenreCategorySkeleton