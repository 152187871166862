import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose, DialogOverlay } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import imgGreenBird from '../../assets/greenbird.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { ADD_PLAYLIST, addPlaylist, APPEND_PLAYLIST_TO_PLAYLIST, SET_PLAYLIST } from '@/redux/features/music/playlistSlice';
import { useDispatch, useSelector } from 'react-redux';
import PlaylistLoader from '../playlist/PlaylistLoader';
import { SET_SHOW_LOGIN } from '@/redux/features/auth/authSlice';
import { ListPlus, Pencil } from 'lucide-react';

const PlaylistsComponent = () => {
    const dispatch = useDispatch();
    const { playlists } = useSelector((state) => state.playlists);
    const [newPlaylistName, setNewPlaylistName] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [publish, setPublish] = useState(false);
    const { isLoading } = useSelector((state) => state.playlists);
    const { song } = useSelector(state => state.music);
    const [showRegenerateDialog, setShowRegenerateDialog] = useState(false);
    const [customPrompt, setCustomPrompt] = useState('');
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const { user } = useSelector(state => state.auth);


    // Local state for pagination
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const stackedCards = [0, 1, 2];

    // Fetch playlists
    // const fetchPlaylists = useCallback(async (currentPage) => {
    //     try {
    //         const token = localStorage.getItem('token');
    //         const res = await axios.get(`/playlists2?page=${currentPage}`, {
    //             headers: { Authorization: `Bearer ${token}` },
    //         });

    //         const fetchedPlaylists = res.data.playlists;

    //         // setLocalPlaylists((prevPlaylists) => [
    //         //     ...prevPlaylists,
    //         //     ...fetchedPlaylists.filter(
    //         //         (playlist) => !prevPlaylists.some((p) => p.playlist_id === playlist.playlist_id)
    //         //     ),
    //         // ]);
    //         dispatch(APPEND_PLAYLIST_TO_PLAYLIST(fetchedPlaylists));

    //         setHasMore(fetchedPlaylists.length === 20); // Assuming 20 is the page size
    //         setPage(currentPage); // Update current page after successful fetch
    //     } catch (error) {
    //         toast('Login to view playlists.', {
    //             icon: '⚠️',
    //             id: 'login-to-view-playlists',
    //         });
    //         setHasMore(false);
    //     }
    // }, [dispatch]);

    useEffect(() => {
        const fetchPlaylists = async () => {
            try {
                if (page === 1) {
                    dispatch(SET_PLAYLIST([]));
                }
                const token = localStorage.getItem('token');
                const res = await axios.get(`/playlists2?page=${page}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                dispatch(APPEND_PLAYLIST_TO_PLAYLIST(res.data.playlists));
                if (res.data.playlists.length === 20) {
                    setHasMore(true);
                } else {
                    setHasMore(false);
                }
            } catch (error) {
                console.error("Error setting playlists:", error);
            }
        };
        fetchPlaylists();
    }, [dispatch, page]);

    // Add new playlist
    const handleAddPlaylist = useCallback(async () => {
        if (newPlaylistName.trim() === '') {
            toast.error("Playlist name cannot be empty");
            return;
        }
        try {
            setIsAdding(true);
            const newPlaylist = await dispatch(addPlaylist({
                playlistName: newPlaylistName,
                publish
            })).unwrap();

            if (newPlaylist) {
                dispatch(ADD_PLAYLIST(newPlaylist));
            }
            // Reset states
            setNewPlaylistName('');
            setShowDialog(false);
            setPublish(false);
        } catch (error) {
            // toast.error(`Failed to create playlist: ${error.response?.data?.message || error.message}`);
        } finally {
            setIsAdding(false);
        }
    }, [newPlaylistName, publish, dispatch]);

    const handleRegenerate = async (e, playlist) => {
        e.preventDefault();
        setSelectedPlaylist(playlist);
        setShowRegenerateDialog(true);
    };

    const handleRegenerateConfirm = async (customPrompt, playlistId) => {
        if (customPrompt.trim() === '') {
            toast.error("Custom prompt cannot be empty");
            return;
        }
        try {
            const token = localStorage.getItem("token");
            toast.loading("Generating playlist cover...", { id: "regenerate-loading" });
            const response = await axios.post(
                "/playlist/regenerate-cover",
                {
                    playlist_slug: selectedPlaylist?.playlist_slug,
                    custom_prompt: customPrompt,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            const newCoverImageUrl = response.data.cover_url;
            // Update the specific playlist in the array
            const updatedPlaylists = playlists.map(playlist =>
                playlist.playlist_id === playlistId
                    ? { ...playlist, cover_image: newCoverImageUrl }
                    : playlist
            );

            // Dispatch SET_PLAYLIST with the updated array
            dispatch(SET_PLAYLIST(updatedPlaylists));
            toast.dismiss("regenerate-loading");
            toast.success("Playlist cover regeneration successful");
            setShowRegenerateDialog(false);
        } catch (error) {
            console.error("Error regenerating playlist cover:", error);
            toast.dismiss("regenerate-loading");
            toast.error("Failed to regenerate playlist cover");
        }
    };

    // Loading state for initial load
    if (isLoading && page === 1) {
        if (playlists.length === 0) {
            return (
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-x-4 sm:gap-y-8 sm:pr-5">
                    <Loader />
                </div>

            );
        }
    }


    return (
        <div className='overflow-hidden'>
            <div className={`flex flex-row justify-between `}>
                <div className="flex flex-col gap-1">
                    <h1 className="lg:text-2xl text-lg font-semibold bg-gradient-to-r from-yellow-400 dark:from-pink-400 via-green-400 dark:via-rose-400 to-yellow-500 dark:to-red-500 text-transparent bg-clip-text">Curated Collections ✨</h1>
                    <p className="lg:text-sm text-xs bg-gradient-to-r from-slate-300 dark:from-pink-500 via-yellow-600 dark:via-pink-600 to-red-700 dark:to-yellow-700 text-transparent bg-clip-text italic">Every mood, every moment, your way</p>
                </div>

                <Dialog open={showDialog} onOpenChange={setShowDialog}>

                    {!user?.is_guest ? <DialogTrigger asChild>
                        <button className="text-green-400 hover:text-green-300 dark:text-black dark:hover:text-pink-600 transition-colors">
                            <ListPlus size={40} />
                        </button>
                    </DialogTrigger> : <button onClick={() => {
                        toast.error("Login to create playlist", { id: "guest" })
                        dispatch(SET_SHOW_LOGIN(true))
                    }} className="text-green-400 hover:text-green-300 transition-colors">
                        <ListPlus size={40} />
                    </button>}

                    <DialogOverlay className="z-[99999]">
                        <DialogContent className="z-[999999] bg-gradient-to-r from-slate-950 via-slate-900 to-slate-950 dark:bg-gradient-to-r dark:from-pink-300 dark:via-pink-400 dark:to-pink-500 text-slate-300 dark:text-pink-950 border-green-400 dark:border-pink-900 outline-1">
                            <DialogHeader>
                                <DialogTitle>Add New Playlist</DialogTitle>
                                <DialogDescription className="text-slate-400 dark:text-pink-900 dark:font-semibold text-xs sm:text-md">
                                    Fill in the details for your new playlist.
                                </DialogDescription>
                            </DialogHeader>
                            <div className="space-y-4">
                                <input
                                    type="text"
                                    placeholder="Playlist Name"
                                    value={newPlaylistName}
                                    onChange={(e) => setNewPlaylistName(e.target.value)}
                                    className="w-full px-2 py-3 rounded bg-slate-800 dark:bg-pink-300 text-sm text-slate-200 dark:text-slate-900 placeholder-slate-400 dark:placeholder-slate-900 focus:outline-none focus:ring-1 focus:ring-green-400 dark:focus:ring-pink-400 focus:border-transparent"
                                />
                                <label className="switch flex items-center justify-between border-b pb-1 border-slate-700 dark:border-pink-700 cursor-pointer ">
                                    <p className="font-semibold text-sm">Publish</p>
                                    <input checked={publish} onChange={(e) => setPublish(e.target.checked)} type="checkbox" />
                                    <span className="slider dark:bg-pink-200 "></span>
                                </label>
                            </div>
                            <DialogFooter>
                                <div className='flex flex-row justify-end gap-2'>
                                    <Button
                                        onClick={handleAddPlaylist}
                                        disabled={isAdding}
                                        className="bg-green-400 px-8 hover:bg-green-300 text-slate-800  dark:bg-pink-700 dark:hover:bg-pink-700/70 dark:text-white dark:hover:text-white dark:border-pink-800"
                                    >
                                        {isAdding ? 'Creating...' : 'Save'}
                                    </Button>
                                    <DialogClose asChild>
                                        <Button className="bg-slate-700 px-6 hover:bg-slate-800 text-slate-300 dark:bg-pink-300 dark:hover:bg-pink-300/70 dark:text-slate-900 dark:hover:text-slate-900 dark:border-pink-800">
                                            Cancel
                                        </Button>
                                    </DialogClose>
                                </div>
                            </DialogFooter>
                        </DialogContent>
                    </DialogOverlay>
                </Dialog>
            </div>

            <InfiniteScroll
                dataLength={playlists.length}
                next={() => setPage(prev => prev + 1)}
                hasMore={hasMore}
                loader={<Loader />}
                style={{ overflow: 'visible' }}
                className={`flex-grow sm:py-4 w-full grid sm:gap-6 md:gap-6 lg:gap-8 xl:gap-8 sm:mt-6 md:mt-6 mr-2 sm:mr-3 md:mr-4 lg:mr-5 xl:mr-6 ml-[4px] ${song ? "pb-36 md:pb-28" : "pb-24"} grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-x-4 sm:gap-y-8 sm:pr-5`}
            >
                {playlists?.length > 0 ? (
                    playlists.map((playlist, index) => (
                        <Link
                            to={`/playlist?id=${playlist?.playlist_slug}`}
                            key={index}
                            className="relative group"
                        >
                            <div className="relative w-full aspect-square flex items-center justify-start sm:justify-center hover:rotate-1 transition-transform duration-300 ease-in-out">
                                {stackedCards.map((_, index) => (
                                    <div
                                        key={`${playlist?.playlist_id}-${index}`}
                                        className={`absolute w-5/6 h-3/4 sm:w-full sm:h-full md:w-full md:h-full rounded-lg 
                                ${index === 0 ? 'z-30 hover:-translate-x-1 hover:-rotate-2 transition-transform duration-300' : ''}
                                ${index === 1 ? 'z-20 -translate-y-0 rotate-2 translate-x-2' : ''} 
                                ${index === 2 ? 'z-10 -translate-y-0 rotate-3 translate-x-4' : ''}`}
                                    >
                                        <div className={`relative w-full h-full rounded-lg p-[2px] bg-gradient-to-r from-green-500 to-green-300 shadow-[0_0_10px_rgba(34,197,94,0.5)] dark:bg-gradient-to-r dark:from-pink-500 dark:to-pink-300 dark:shadow-[0_0_10px_rgba(229,30,120,0.5)]
                                ${index !== 0 ? 'opacity-50' : ''}`}>
                                            <div className="relative w-full h-full bg-gray-900 rounded-lg overflow-hidden">
                                                <div className="absolute top-2 left-2 flex justify-between items-center z-20 w-full">
                                                    <div className="bg-black/50 text-white text-xs px-2 py-1 rounded-full flex items-center">
                                                        <span className="mr-1">
                                                            {playlist?.song_count === 1 ? "1 Song" : `${playlist?.song_count || 0} Songs`}
                                                        </span>
                                                    </div>
                                                    <div className="relative group mr-3">
                                                        <button
                                                            onClick={(e) => handleRegenerate(e, playlist)}
                                                            className="flex items-center gap-1.5 bg-green-800/80 backdrop-blur-sm hover:bg-green-800/90 
                                                              text-white text-xs py-1.5 px-3 rounded-full 
                                                              transition-all duration-300 ease-out
                                                              border border-white/10 hover:border-white/20
                                                              shadow-sm hover:shadow-md"
                                                        >
                                                            <Pencil className="w-3 h-3" />
                                                        </button>
                                                    </div>
                                                </div>

                                                <img
                                                    src={playlist.cover_image || imgGreenBird}
                                                    alt={playlist.name}
                                                    className="w-full h-full object-cover"
                                                />
                                                <div className="absolute flex flex-col justify-between bottom-0 w-full p-1 sm:p-2 bg-gradient-to-t from-black/80 to-transparent">
                                                    <h3 className="text-white text-md sm:text-lg font-semibold truncate">
                                                        {playlist?.name || 'Hello'}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Link>
                    ))
                ) : (
                    <p className="col-span-full flex justify-center items-end text-slate-400"></p>
                )}
            </InfiniteScroll>
            <Dialog open={showRegenerateDialog} onOpenChange={setShowRegenerateDialog}>
                <DialogOverlay className="z-[99]">
                    <DialogContent className="z-[999] w-[90%] max-w-sm p-4 bg-gradient-to-br from-slate-900 via-slate-800 to-slate-900 dark:bg-gradient-to-br dark:from-pink-300 dark:via-pink-400 dark:to-pink-500 border dark:border-pink-800 rounded-lg shadow-2xl shadow-green-900/20">
                        <DialogHeader>
                            <DialogTitle className="text-xl text-green-400 dark:text-pink-900 font-normal font-potta">
                                Regenerate Playlist Cover
                            </DialogTitle>
                            <DialogDescription className="text-slate-300 dark:text-pink-800 text-sm">
                                Enter a custom prompt to generate a new cover image for your playlist
                            </DialogDescription>
                        </DialogHeader>
                        <div className="mt-4">
                            <input
                                type="text"
                                placeholder="Enter custom prompt (optional)"
                                className="w-full p-2 rounded bg-slate-800 dark:bg-pink-300 text-slate-200 dark:text-slate-900 placeholder-slate-400 dark:placeholder-slate-900 focus:outline-none focus:ring-1 focus:ring-green-300 dark:focus:ring-pink-300"
                                onChange={(e) => setCustomPrompt(e.target.value)}
                            />
                        </div>
                        <DialogFooter className="flex flex-row gap-2 justify-end mt-4">
                            <Button
                                variant="outline"
                                onClick={() => setShowRegenerateDialog(false)}
                                className="bg-slate-800 hover:bg-slate-700 text-slate-300 dark:bg-pink-300 dark:hover:bg-pink-300/70 dark:text-slate-900 dark:hover:text-slate-900 dark:border-pink-800"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => handleRegenerateConfirm(customPrompt, selectedPlaylist?.playlist_id)}
                                className="bg-green-400 hover:bg-green-300 text-slate-700 dark:bg-pink-700 dark:hover:bg-pink-700/70 dark:text-white dark:hover:text-white dark:border-pink-800"
                            >
                                Regenerate
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </DialogOverlay>
            </Dialog>
        </div>
    );
};
export default PlaylistsComponent;



function Loader() {
    return (
        <>
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
        </>
    )
}