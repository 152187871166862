import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter } from "@/components/ui/card"
import { TabsContent } from "@/components/ui/tabs"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { signUpUser } from "@/redux/features/auth/authSlice"
import GoogleLoginButton from "@/components/googleLoginButton/GoogleLoginButton"
import { useLocation } from "react-router-dom"
import { useState } from 'react';
import { Eye, EyeOff } from "lucide-react";

const SignUpForm = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { handleSubmit, register, reset, formState: { errors }, watch } = useForm()
    const password = watch('password');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    // Signup
    const handleSignup = async (data) => {
        const { name, email, password, username } = data;
        const refCode = sessionStorage.getItem('refCode')
        const formData = {
            name,
            username,
            email,
            password,
            referral_code: refCode || null
        }
        await dispatch(signUpUser(formData)).unwrap()
        reset()
    }


    return (
        <TabsContent value="signup" className="mt-4" >
            <Card className="p-0 bg-transparent border-0" >
                <form onSubmit={handleSubmit(handleSignup)}>
                    <CardContent className="space-y-4 p-0">
                        <div className="flex flex-col gap-3 relative">
                            <input
                                type='text'
                                placeholder="Name"
                                {...register('name', {
                                    required: 'Name is required',
                                    validate: (value) => {
                                        const trimmedValue = value.trim();
                                        return trimmedValue.length > 3 || 'Name must be at least 4 characters long';
                                    },
                                })}
                                className="bg-slate-900 dark:bg-pink-300 text-white dark:text-slate-800 p-2 rounded placeholder:text-sm placeholder:text-slate-500 dark:placeholder:text-slate-700 border border-slate-700/75 dark:border-pink-700/75 focus:outline-none focus:ring-1 focus:ring-green-400 dark:focus:ring-pink-700"
                            />
                            {errors.name && <p className="absolute -bottom-4 right-1 text-xs text-green-400 dark:text-pink-700 font-medium">{errors.name.message}</p>}
                        </div>
                        <div className="flex flex-col gap-3 relative">
                            <input
                                type='text'
                                placeholder="Username"
                                {...register('username', {
                                    required: 'Username is required',
                                    validate: (value) => {
                                        const trimmedValue = value.trim();
                                        return trimmedValue.length > 4 || 'Username must be at least 5 characters long';
                                    },
                                })}
                                className="bg-slate-900 dark:bg-pink-300 text-white dark:text-slate-800 p-2 rounded placeholder:text-sm placeholder:text-slate-500 dark:placeholder:text-slate-700 border border-slate-700/75 dark:border-pink-700/75 focus:outline-none focus:ring-1 focus:ring-green-400 dark:focus:ring-pink-700"
                            />
                            {errors.username && <p className="absolute -bottom-4 right-1 text-xs text-green-400 dark:text-pink-700 font-medium">{errors.username.message}</p>}
                        </div>
                        <div className="flex flex-col gap-3 relative">
                            <input
                                type='email'
                                placeholder="Email"
                                {...register('email', {
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^\S+@\S+\.\S+$/,
                                        message: 'Invalid email format'
                                    }
                                })}
                                className="bg-slate-900 dark:bg-pink-300 text-white dark:text-slate-800 p-2 rounded placeholder:text-sm placeholder:text-slate-500 dark:placeholder:text-slate-700 border border-slate-700/75 dark:border-pink-700/75 focus:outline-none focus:ring-1 focus:ring-green-400 dark:focus:ring-pink-700"
                            />
                            {errors.email && <p className="absolute -bottom-4 right-1 text-xs text-green-400 dark:text-pink-700 font-medium">{errors.email.message}</p>}
                        </div>
                        <div className="flex flex-col gap-3 relative">
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    autoComplete="off"
                                    {...register('password', {
                                        required: 'Password is required',
                                        minLength: {
                                            value: 8,
                                            message: 'Password must have at least 8 characters',
                                        },
                                    })}
                                    className="bg-slate-900 dark:bg-pink-300 text-white dark:text-slate-800 p-2 rounded placeholder:text-sm placeholder:text-slate-500 dark:placeholder:text-slate-700 border border-slate-700/75 dark:border-pink-700/75 pr-10 w-full focus:outline-none focus:ring-1 focus:ring-green-400 dark:focus:ring-pink-700"
                                />
                                <button onClick={togglePasswordVisibility} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-slate-500 dark:text-slate-700 focus:outline-none" type="button" >
                                    {showPassword ? <EyeOff /> : <Eye />}
                                </button>
                            </div>
                            {errors.password && (
                                <p className="absolute -bottom-4 right-1 text-xs text-green-400 dark:text-pink-700 font-medium">
                                    {errors.password.message}
                                </p>
                            )}
                        </div>
                        <div className="flex flex-col gap-3 relative">
                            <div className="relative">
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Confirm Password"
                                    autoComplete="off"
                                    {...register('confirmPassword', {
                                        required: 'Confirm password is required',
                                        validate: value =>
                                            value === password || 'The passwords do not match'
                                    })}
                                    className="bg-slate-900 dark:bg-pink-300 text-white dark:text-slate-800 p-2 rounded placeholder:text-sm placeholder:text-slate-500 dark:placeholder:text-slate-700 border border-slate-700/75 dark:border-pink-700/75 focus:outline-none focus:ring-1 focus:ring-green-400 dark:focus:ring-pink-700 pr-10 w-full"
                                />
                                <button onClick={toggleConfirmPasswordVisibility} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-slate-500 dark:text-slate-700 focus:outline-none" type="button" >
                                    {showConfirmPassword ? <EyeOff /> : <Eye />}
                                </button>
                            </div>
                            {errors.confirmPassword && <p className="absolute -bottom-4 right-1 text-xs text-green-400 dark:text-pink-700 font-medium">{errors.confirmPassword.message}</p>}
                        </div>
                    </CardContent>
                    <CardFooter className="p-0 mt-5" >
                        <Button type="submit" className="w-full bg-green-400 dark:bg-gradient-to-br dark:from-pink-600 dark:to-pink-900 hover:bg-green-500 dark:hover:opacity-90 text-black dark:text-white" >SignUp</Button>
                    </CardFooter>
                </form>
                <div className="h-px my-2 bg-slate-600 dark:bg-pink-700/50"></div>
                <div className="flex items-center justify-center" >
                    <GoogleLoginButton defaultPath={location.pathname === '/login' ? '/' : location.state?.from} />
                </div>
            </Card>
        </TabsContent>
    )
}

export default SignUpForm