import premium from "../../assets/premium-badge.svg"
import gold from "../../assets/gold-badge.svg"
import platinum from "../../assets/platinum.svg"
import { useSelector } from "react-redux";
import { SET_SHOW_LOGIN } from "@/redux/features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase";
import { CircleCheck, Flame, Rocket, Star } from "lucide-react";

const SubscribeCard = () => {
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [pendingPlan, setPendingPlan] = useState(null)
    const discount = false;
    const discountText = "20% off"
    const [isYearly, setIsYearly] = useState(true);

    const buyPremium = useCallback(async (plan, isYearly) => {
        if (!user || user?.is_guest) {
            setPendingPlan({plan, isYearly:isYearly})
            dispatch(SET_SHOW_LOGIN(true))
            return toast.error("Please login to continue", { id: "premium" })
        }
        const token = window.localStorage.getItem('token')
        try {
            setLoading(true)
            toast.loading("Opening Payment Page", { id: "premium" })
            const response = await axios.post("/checkout", { catagory: plan, yearly: isYearly }, { headers: { Authorization: `Bearer ${token}` } })
            logEvent(analytics, 'begin_checkout', {
                currency: 'USD',
                plan: `plan_${plan}`,
                plan_name: plan === 1 ? 'Premium' : plan === 2 ? 'Gold' : 'Platinum',
            });
            window.location.href = response.data.url
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.detail || error.message || "Oops! Error Occured", { id: "premium" });
            console.log(error);
        }
    }, [dispatch, user])

    useEffect(() => {
        if (user && !user.is_guest && pendingPlan) {
            buyPremium(pendingPlan.plan, pendingPlan.isYearly);
            setPendingPlan(null);
        }
    }, [user, pendingPlan, buyPremium])

    const plans = [
        {
            id: 1,
            name: 'Premium Plan',
            price: 14,
            image: premium,
            description: 'Perfect for hobbyists and music enthusiasts taking their first step into AI music creation.',
            active: user?.plan === 1,
            show: !user || user?.is_guest || (user.plan < 1),
            benifits: [
                <Benifit key={1} text={`5,000 ${isYearly ? 'Monthly' : ''} Credits`} />,
                <Benifit key={2} text="Affiliate Program" />,
                <Benifit key={3} text="Extend & Clone Songs" />,
                <Benifit key={6} text="Generate Songs from url" />,
                <Benifit key={4} text="Standard Customer Support" />,
                <Benifit key={5} text="Generate Songs from Images" />,
                <Benifit key={7} text="2 Simultaneous Song Generations" />
            ]
        },
        {
            id: 2,
            name: 'Gold Plan',
            price: 19,
            image: gold,
            description: 'Designed for creators who want more freedom and flexibility in their music projects.',
            active: user?.plan === 2,
            show: !user || user?.is_guest || (user.plan < 2),
            benifits: [
                <Benifit2 key={1} text={`10,000 ${isYearly ? 'Monthly' : ''} Credits`} />,
                <Benifit2 key={2} text="Affiliate Program" />,
                <Benifit2 key={3} text="Extend & Clone Songs" />,
                <Benifit2 key={6} text="Generate Songs from url" />,
                <Benifit2 key={4} text="Standard Customer Support" />,
                <Benifit2 key={5} text="Generate Songs from Images" />,
                <Benifit2 key={7} text="3 Simultaneous Song Generations" />
            ]
        },
        {
            id: 3,
            name: 'Platinum Plan',
            price: 45,
            image: platinum,
            description: 'The ultimate plan for professionals and studios producing high-volume AI music.',
            active: user?.plan === 3,
            show: !user || user?.is_guest || (user.plan < 3),
            benifits: [
                <Benifit3 key={1} text={`50,000 ${isYearly ? 'Monthly' : ''} Credits`} />,
                <Benifit3 key={2} text="Affiliate Program" />,
                <Benifit3 key={3} text="Extend & Clone Songs" />,
                <Benifit3 key={6} text="Generate Songs from url" />,
                <Benifit3 key={8} text="Publish Songs on Spotify" />,
                <Benifit3 key={4} text="Priority Customer Support" />,
                <Benifit3 key={5} text="Generate Songs from Images" />,
                <Benifit3 key={7} text="5 Simultaneous Song Generations" />
            ]
        }
    ];

    // Calculate yearly prices (monthly * 12) with 20% discount
    const getPrice = (monthlyPrice) => {
        if (isYearly) {
            const yearlyPrice = monthlyPrice * 12;
            const discountedPrice = yearlyPrice * 0.2; // 20% discount
            return (yearlyPrice - discountedPrice).toFixed(2); // Convert back to monthly price
        }
        return monthlyPrice;
    };

    const getBillingText = () => {
        return isYearly ? '/year' : '/month';
    };

    return (
        <div>
            <div className="flex flex-col items-center justify-center w-max bg-transparent p-0 mx-auto mt-4">
                <div className="flex items-center justify-center gap-3 mt-4 bg-slate-900 backdrop-blur-lg rounded-full border border-slate-800/20">
                    <div className="relative">
                        <button onClick={() => setIsYearly(false)} className={`relative sm:px-8 px-6 py-2 text-sm sm:text-base rounded-full font-semibold ${!isYearly ? 'bg-gradient-to-r from-green-400 to-teal-400 text-slate-950 shadow-lg border-1 border-green-400/20' : 'bg-transparent hover:bg-green-800/40 text-green-400 hover:text-green-300 hover:border-green-600/50'}`}>Monthly</button>
                    </div>
                    <div className="relative">
                        {isYearly && (
                            <div className="absolute -top-8 left-1/2 -translate-x-1/2 text-green-400 flex items-center justify-center text-xs font-bold sm:px-4 px-2 py-1 bg-transparent rounded-full shadow-md whitespace-nowrap">
                                <Star className='mr-2 text-yellow-500 fill-yellow-500' size={20} />
                                SAVE 20% OFF
                            </div>
                        )}
                        <button onClick={() => setIsYearly(true)} className={`relative sm:px-8 px-6 py-2 text-sm sm:text-base rounded-full font-semibold ${isYearly ? 'bg-gradient-to-r from-green-400 to-teal-400 text-slate-950 shadow-lg border-1 border-green-400/20' : 'bg-transparent hover:bg-green-800/40 text-green-400 hover:text-green-300 hover:border-green-600/50'}`}>Yearly</button>
                    </div>
                </div>
                <div className={`grid grid-cols-1 ${user?.plan === 1 ? 'lg:grid-cols-2' : user?.plan === 2 ? 'lg:grid-cols-1' : 'lg:grid-cols-2 xl:grid-cols-3'} justify-items-center items-center gap-4 mt-4 w-full mx-auto`}>
                    {plans.map(plan => plan.show && (
                        <div 
                            key={plan.id} 
                            className={`relative w-full rounded-3xl flex flex-col items-center justify-center max-w-xs py-4 px-8 backdrop-blur-lg border ${
                                plan.id === 1 ? 
                                'bg-gradient-to-bl from-green-950/50 via-slate-900/50 to-slate-900/50 border-green-700/20' :
                                plan.id === 2 ?
                                'bg-gradient-to-b from-green-950/80 via-slate-900/50 to-slate-900/50 border-amber-900/20' :
                                'bg-gradient-to-br from-green-950/50 via-slate-900/50 to-slate-900/50 border-slate-400/20'
                            }`}
                        >
                            {plan.active && (
                                <div className='absolute top-0 left-0 border rounded border-green-400/80 dark:border-pink-500 text-green-400 dark:text-pink-700 rounded-tl-3xl rounded-br-3xl px-4 py-1.5 text-xs font-medium bg-green-400/10 dark:bg-pink-400/10'>
                                    Active Plan
                                </div>
                            )}
                            <div className='flex items-center justify-center'>
                                <img src={plan.image} alt={plan.name.toLowerCase()} className='object-cover dark:opacity-90' />
                            </div>
                            <h3 className='text-white dark:text-slate-800 mt-6'>{plan.name}</h3>
                            <p className='text-xs w-full text-center mx-auto text-slate-400 dark:text-slate-600 mt-2'>
                                {plan.description}
                            </p>
                            <p className='text-lg font-medium text-red-400 mt-3'>
                                {discount ? <><s>${plan.price}</s> /{discountText}</> : ``}
                            </p>
                            <div className="flex items-center justify-center text-sm gap-2">
                            <p className={`${isYearly ? 'text-red-400 line-through' : ''}`}>{isYearly ? `$${plan.price * 12}/year` : ``}  </p>
                            <p className={`${isYearly ? 'text-green-400' : ''}`}>{isYearly ? `Save $${(plan.price * 12 * 0.2).toFixed(2)}` : ``}  </p>
                            </div>
                            <p className='text-2xl font-medium text-green-400 mb-3'>
                                ${getPrice(plan.price)}{getBillingText()}
                            </p>
                            <button
                                onClick={() => buyPremium(plan.id, isYearly)}
                                disabled={loading}
                                className={`text-sm border border-slate-800 hover:text-black bg-gradient-to-b from-slate-800/90 to-transparent  w-full text-white dark:text-black h-10 rounded-lg cursor-pointer transition-all duration-300 ${plan.id === 1 && 'hover:from-green-400 hover:to-green-400 hover:text-black'} ${plan.id === 2 && 'hover:from-amber-400 hover:to-amber-400 hover:text-black'} ${plan.id === 3 && 'hover:from-slate-100 hover:to-slate-100 hover:text-black'}`}
                            >
                                Upgrade Plan
                            </button>
                            <div className='h-px w-full bg-slate-800 dark:bg-pink-400/20 mt-8 mb-6'></div>
                            <div className='flex flex-col gap-2'>
                                {plan.benifits.map((benifit, index) => (
                                    <div key={index}>{benifit}</div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SubscribeCard

function Benifit({ text }) {
    return (
        <div className='flex items-center gap-2'>
            <CircleCheck size={20} className="text-slate-300 dark:text-pink-700" />
            <p className='text-sm text-slate-400 dark:text-slate-800 text-left'>{text}</p>
        </div>
    )
}
function Benifit2({ text }) {
    return (
        <div className='flex items-center gap-2'>
            <Flame size={20} className="text-slate-300 fill-slate-300 dark:text-pink-700 dark:fill-pink-700" />
            <p className='text-sm text-slate-400 dark:text-slate-800 text-left'>{text}</p>
        </div>
    )
}
function Benifit3({ text }) {
    return (
        <div className='flex items-center gap-2'>
            <Rocket size={18} className="text-slate-300 fill-slate-300 dark:text-pink-700 dark:fill-pink-700" />
            <p className='text-sm text-slate-400 dark:text-slate-800 text-left'>{text}</p>
        </div>
    )
}
