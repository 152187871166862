import { Link, useLocation } from "react-router-dom"
import logo from "../../assets/footer-frog.png"
import { useDispatch, useSelector } from "react-redux";
import { SET_REFERRAL_DIALOG } from "@/redux/features/auth/authSlice";
import { SET_FEEDBACK } from "@/redux/features/theme/extradialogs";
import { Home, Music2, ListMusic, Award, Users, MessageSquareWarning } from "lucide-react";
import { Megaphone, Leaderboard, MusicAdd } from "../navbar/SocialIcons";

const Sidebar = ({ children }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { user } = useSelector(state => state.auth)
    const { song } = useSelector(state => state.music)

    return (
        <div className={` w-full min-h-screen bg-gradient-to-tr from-slate-900 to-cyan-950 text-slate-300 font-lato overflow-x-hidden fade-in`} >
            <div className=" fixed top-0 left-0 h-full w-52 lg:w-64 bg-slate-900 dark:bg-pink-50 hidden md:block flex-shrink-0" >
                <Link to="/" className="flex items-center gap-2 py-4 pl-4 border-b border-slate-800 dark:border-pink-700 h-16" >
                    <img src={logo} className="max-w-[40px] w-full rounded-full" alt="img" />
                    <div className=" font-bold font-potta text-center lg:text-xl text-green-400 dark:text-pink-500 mt-1" >SOME</div>
                </Link>
                <div className={"mt-4 flex flex-col gap-6 h-full overflow-y-auto [&::-webkit-scrollbar]:!w-1 [&::-webkit-scrollbar-track]:!bg-slate-950 [&::-webkit-scrollbar-thumb]:!bg-green-400/50 [&::-webkit-scrollbar-thumb]:!rounded-full hover:[&::-webkit-scrollbar-thumb]:!bg-green-500 dark:[&::-webkit-scrollbar-thumb]:!bg-pink-500 " + (song ? " pb-44 " : " pb-28 ")} >
                    <Link to={"/create"} className={"flex items-center gap-2 border-l-2 p-1.5 pl-3 " + (location.pathname === "/create" ? "border-green-400 font-semibold text-green-400 dark:border-pink-300 dark:text-black dark:font-semibold dark:bg-gradient-to-r dark:from-pink-300 dark:to-pink-500 dark:hover:bg-pink-500 dark:hover:text-black dark:rounded-lg" : "border-gray-950 text-slate-100 dark:text-pink-500 dark:border-pink-200 dark:hover:bg-pink-200 dark:rounded-lg")}><MusicAdd size={22} /><span className="mt-0.5">Create</span></Link>
                    <Link to={"/"} className={"flex items-center gap-2 border-l-2 p-1.5 pl-3 " + (location.pathname === "/" ? "border-green-400 font-semibold text-green-400 dark:border-pink-200 dark:text-black dark:font-semibold dark:bg-gradient-to-r dark:from-pink-300 dark:to-pink-500 dark:hover:bg-pink-500 dark:hover:text-black dark:rounded-lg" : "border-gray-950 text-slate-100 dark:text-pink-500 dark:border-pink-200 dark:hover:bg-pink-200 dark:rounded-lg")}><Home size={22} /><span className="mt-0.5">Discover</span></Link>
                    <Link to={"/creations"} className={"flex items-center gap-2 border-l-2 p-1.5 pl-3 " + (location.pathname === "/creations" ? "border-green-400 text-green-400 font-semibold bg-slate-900 dark:border-pink-200 dark:text-black dark:font-semibold dark:bg-gradient-to-r dark:from-pink-300 dark:to-pink-500 dark:hover:bg-pink-500 dark:hover:text-black dark:rounded-xl" : "border-gray-950 dark:border-pink-200 text-slate-100 dark:text-pink-500 dark:hover:bg-pink-200 dark:rounded-lg")}><Music2 size={22} /><span>My Creations</span></Link>
                    <Link to={"/leaderboard/crypto"} className={"flex items-center gap-2 border-l-2 p-1.5 pl-3 " + (location.pathname.startsWith("/leaderboard") ? "border-green-400 text-green-400 font-semibold bg-slate-900 dark:border-pink-200 dark:text-black dark:font-semibold dark:bg-gradient-to-r dark:from-pink-300 dark:to-pink-500 dark:hover:bg-pink-500 dark:hover:text-black dark:rounded-xl" : "border-gray-950 dark:border-pink-200 text-slate-100 dark:text-pink-500 dark:hover:bg-pink-200 dark:rounded-lg")}><Leaderboard size={22} /><span>Leaderboard</span></Link>
                    {user && <Link to={"/playlist"} className={"flex items-center gap-2 border-l-2 p-1.5 pl-3 " + (location.pathname === "/playlist" ? "border-green-400 text-green-400 font-semibold bg-slate-900 dark:border-pink-200 dark:text-black dark:font-semibold dark:bg-gradient-to-r dark:from-pink-300 dark:to-pink-500 dark:hover:bg-pink-500 dark:hover:text-black dark:rounded-xl" : "border-gray-950 dark:border-pink-200 text-slate-100 dark:text-pink-500 dark:hover:bg-pink-200 dark:rounded-lg")} > <ListMusic size={24} /> <span className="" >Playlists</span></Link>}
                    {(user?.is_guest || user?.plan === 0) && <Link to={"/premium"} className={"flex items-center gap-2 border-l-2 p-1.5 pl-3 " + (location.pathname === "/premium" ? "border-green-400 text-green-400 font-semibold bg-slate-900 dark:border-pink-200 dark:text-black dark:font-semibold dark:bg-gradient-to-r dark:from-pink-300 dark:to-pink-500 dark:hover:bg-pink-500 dark:hover:text-black dark:rounded-xl" : "border-gray-950 dark:border-pink-200 text-slate-100 dark:text-pink-500 dark:hover:bg-pink-200 dark:rounded-lg")} > <Award size={24} /> <span className="" >Buy Premium</span></Link>}
                    <Link to={"/advertisement-dashboard"} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 " + (location.pathname.startsWith("/advertisement-dashboard") ? " border-blue-400 text-blue-300 font-semibold  bg-slate-900 dark:bg-blue-700/60 dark:border-blue-900 dark:text-blue-900/90 dark:hover:bg-blue-300 dark:rounded-lg" : " border-gray-950 text-blue-300 dark:text-blue-500  dark:border-blue-200 dark:hover:bg-blue-200 dark:rounded-lg")} > <Megaphone size={30} className="h-5 w-5" /> <span className="" >Advertisement</span></Link>
                    {user && <button onClick={() => dispatch(SET_REFERRAL_DIALOG(true))} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 border-transparent text-amber-300 dark:text-amber-500 font-medium bg-slate-900 dark:bg-transparent dark:border-pink-200  dark:hover:bg-amber-300/40 dark:rounded-lg"} > <Award size={22} /> <span className="" >Refer and Earn</span></button>}
                    {user && <Link to={"/affiliate"} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 border-transparent text-cyan-300 dark:text-cyan-800 font-medium bg-slate-900 dark:bg-transparent dark:border-pink-200  dark:hover:bg-amber-300/40 dark:rounded-lg"} > <Users size={22} /> <span className="" >Become an Affiliate</span></Link>}
                    <button onClick={() => dispatch(SET_FEEDBACK(true))} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 border-transparent text-orange-400 dark:text-orange-800 font-medium bg-slate-900 dark:bg-transparent dark:border-pink-200  dark:hover:bg-amber-300/40 dark:rounded-lg"} > <MessageSquareWarning size={22} /> <span className="" >Feedback</span></button>
                </div>
            </div>
            <div className="h-full md:ml-52 lg:ml-64 " >{children}</div>
        </div>
    )
}

export default Sidebar