import { useState, useEffect } from 'react'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ADD_SEARCHSONGS, SET_SEARCHSONGS } from '@/redux/features/music/searchSlice'
import { useDispatch, useSelector } from 'react-redux'
import TopBar from '@/components/navbar/TopBar'
import BottomBar from '@/components/sidebar/BottomBar'
import { SongCardSkeleton } from '@/components/createSong/CreateSongLoading'
import { useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import SongCard from '@/components/songCard/LazyLoadSongCard'

function SongSearch() {
    const dispatch = useDispatch()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const term = searchParams.get('term');
    const { searchSongs } = useSelector(state => state.search)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const { song } = useSelector(state => state.music)

    const fetchMoreSongs = async () => {
        if (!term) return;
        try {
            if (page === 1) {
                dispatch(SET_SEARCHSONGS([]));
            }
            const token = window.localStorage.getItem("token") || window.localStorage.getItem("guestToken");
            const response = await axios.get(`/unified-search`, { params: { query: term, type: 'songs', page: page, limit: 25 },
                headers: { Authorization: `Bearer ${token}` }
            });
            const { songs } = response.data;
            dispatch(ADD_SEARCHSONGS(songs));
            if (songs.length === 0 || songs.length < 10) {
                setHasMore(false); // No more songs to load
            }
        } catch (err) {
            toast.error(err.message);
        }
    };
    useEffect(() => {
        setPage(1);
    }, [term]);

    useEffect(() => {
        fetchMoreSongs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, term]);

    return (
        <div className={`min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 dark:bg-gradient-to-br dark:from-pink-100 dark:to-pink-300 sm:px-4 px-0 py-8 pt-[6rem] ${song ? "pb-36 md:pb-28" : "pb-24 sm:pb-16"}`}>
            <TopBar heading={`Search Songs: ${term}`} />
            <div className="mx-auto">
                <div className="text-center sm:mb-8 mb-4">
                    <h1 className="text-2xl md:text-3xl font-semibold font-comfortaa text-slate-200 dark:text-black">
                        Search Songs for &quot;{term}&quot;
                    </h1>
                </div>
                {/* Songs */}
                <InfiniteScroll
                    dataLength={searchSongs.length}
                    next={() => setPage(prev => prev + 1)}
                    hasMore={hasMore}
                    loader={<SearchSkeleton />}
                    className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 sm:gap-x-4 sm:gap-y-4 gap-x-2 gap-y-2 sm:px-0 px-2"
                >
                    {searchSongs.map((song) => (
                        <SongCard song={song} key={song.song_id} playingFrom={"searchSongs"} />
                    ))}
                </InfiniteScroll>
            </div>
            <BottomBar />
        </div>
    )
}

const SearchSkeleton = () => {
    return (
        <>
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
        </>
    )
}

export default SongSearch