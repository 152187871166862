import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import footerFrog from "../../assets/footer-frog.png"
import { useState, useEffect } from 'react';

const AiMusicMaker = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Configuration object for different routes
  const pageConfig = {
    '/ai-music-maker': {
      title: 'AI Music Maker | Sound of Meme',
      heading: 'Create Amazing AI Music Tracks',
      description: 'Generate custom AI music tracks instantly with Sound of Meme\'s AI Music Maker. Create, remix, and share your AI-generated music.',
    },
    '/music-generator': {
      title: 'Free AI Music Generator | Sound of meme',
      heading: 'Free AI Music Generator',
      description: 'Transform your ideas into music with our AI-powered music generator. No musical experience needed.',
    },
    '/ai-music-generator': {
      title: 'AI Music Generator | Sound of Meme',
      heading: 'Create Amazing AI Music Tracks',
      description: 'Generate custom AI music tracks instantly with Sound of Meme\'s AI Music generator. Create, remix, and share your AI-generated music.',
    },
    '/ai-music-generator-free': {
      title: 'AI Music Generator Free | Sound of Meme',
      heading: 'Create Amazing AI Music free',
      description: 'Generate custom AI music tracks instantly free with Sound of Meme\'s AI Music generator. Create, remix, and share your AI-generated music.',
    },
    '/ai-music-generator-online': {
      title: 'AI Music Generator Online  | Sound Of Meme',
      heading: 'Create Amazing AI Music Online',
      description: 'Generate custom AI music tracks instantly with Sound of Meme\'s AI Music generator online. Create, remix, and share your AI-generated music.',
    },
    '/ai-music-generator-free-online': {
      title: 'AI Music Generator Free Online | Sound Of Meme',
      heading: 'Create Amazing AI Music Free Online',
      description: 'Generate custom AI music tracks instantly free with Sound of Meme\'s AI Music generator free online. Create, remix, and share your AI-generated music.',
    },
    '/ai-song-generator': {
      title: 'AI Song Generator | Sound Of Meme',
      heading: 'Create Amazing AI Songs',
      description: 'Generate custom AI songs instantly with Sound of Meme\'s AI song generator. Create, remix, and share your AI-generated songs.',
    },
    '/ai-song-generator-free': {
      title: 'AI Song Generator Free | Sound Of Meme',
      heading: 'Create Amazing AI Songs Free',
      description: 'Generate custom AI songs instantly free with Sound of Meme\'s AI song generator free. Create, remix, and share your AI-generated songs.',
    },
    '/crypto-music': {
      title: 'Crypto Music | Sound of Meme',
      heading: 'Create Amazing Crypto Music',
      description: 'Generate custom AI music tracks instantly with Sound of Meme\'s AI Music generator. Create, remix, and share your AI-generated music.',
    },
    '/crypto-songs': {
      title: 'Create Crypto Songs | Sound of Meme',
      heading: 'Create Amazing Crypto Songs',
      description: 'Generate custom AI crypto songs instantly with Sound of Meme\'s AI Music generator. Create, remix, and share your AI-generated crypto songs.',
    },
    '/ai-song-maker': {
      title: 'AI Song Maker | Sound of Meme',
      heading: 'Make Amazing AI Songs',
      description: 'Generate custom AI songs instantly with Sound of Meme\'s AI song maker. Create, remix, and share your AI-generated songs.',
    },
    '/music-ai-generator': {
      title: 'Music AI Generator | Sound of Meme',
      heading: 'Make Amazing Music AI Generator',
      description: 'Generate custom AI music tracks instantly with Sound of Meme\'s AI Music generator. Create, remix, and share your AI-generated music.',
    },
    '/free-ai-song-generator': {
      title: 'Free AI Song Generator | Sound of Meme',
      heading: 'Make Amazing Songs with Free AI Song Generator',
      description: 'Generate custom AI music tracks instantly free with Sound of Meme\'s Free AI song generator. Create, remix, and share your AI-generated songs.',
    },
    '/free-ai-music-generator': {
      title: 'Free AI Music Generator | Sound of Meme',
      heading: 'Make Amazing Songs with Free AI Music Generator',
      description: 'Generate custom AI music tracks instantly free with Sound of Meme\'s Free AI music generator. Create, remix, and share your AI-generated music.',
    },
    '/ai-music-generator-free-no-sign-up': {
      title: 'Free AI Music Generator No Sign Up | Sound of Meme',
      heading: 'Make Amazing Songs with Free AI Music Generator No Sign Up',
      description: 'Generate custom AI music tracks instantly free with Sound of Meme\'s Free AI music generator no sign up. Create, remix, and share your AI-generated music.',
    },
    '/best-ai-music-generator': {
      title: 'Best AI Music Generator | Sound of Meme',
      heading: 'Make Amazing Songs with Best AI Music Generator',
      description: 'Generate custom AI music tracks instantly free with Sound of Meme\'s Best AI music generator. Create, remix, and share your AI-generated music.',
    },
    '/best-ai-song-generator': {
      title: 'Best AI Song Generator | Sound of Meme',
      heading: 'Make Amazing Songs with Best AI Song Generator',
      description: 'Generate custom AI music tracks instantly free with Sound of Meme\'s Best AI song generator. Create, remix, and share your AI-generated songs.',
    },
    '/best-ai-song-generator-free': {
      title: 'Best AI Song Generator Free | Sound of Meme',
      heading: 'Make Amazing Songs with Best AI Song Generator Free',
      description: 'Generate custom AI music tracks instantly free with Sound of Meme\'s Best AI song generator free. Create, remix, and share your AI-generated songs.',
    },
    '/best-ai-music-generator-free': {
      title: 'Best AI Music Generator Free | Sound of Meme',
      heading: 'Make Amazing Songs with Best AI Music Generator Free',
      description: 'Generate custom AI music tracks instantly free with Sound of Meme\'s Best AI music generator free. Create, remix, and share your AI-generated music.',
    },
    // Add more routes as needed
  };

  const currentPage = pageConfig[location.pathname] || pageConfig['/ai-music-maker'];

  const trendingSearches = [
    'Create AI Music',
    'AI Beat Maker',
    'AI Song Generator',
    'Music Production AI',
    'AI Melody Creator',
    'AI Music Composition',
    'Free Music Generator',
    'AI Music Tools',
    'Create Music Online',
    'AI Music Production',
    'AI Music Leaderboard',
    'AI Music Generator',
    'AI Music Generator Free',
    'AI Music Generator Online',
    'AI Music Generator Free Online',
    'AI Music Generator Free Online',
    'MusicNFTs',
    'Tokenize Music',
    'Trading Music NFTs',
    'Trading',
    'Crypto Music',
    'Crypto Songs',
    'Crypto Beats',
    'Crypto Tracks',
    'Crypto Melodies',
    'Crypto Rhythms',
    'Crypto Sounds',
    'AI Music Maker',
    'AI Song Maker',
    'Music AI Generator',
    'Free AI Song Generator',
    'Free AI Music Generator',
    'AI Music Generator Free No Sign Up',
    'Best AI Music Generator',
    'Best AI Song Generator',
    'Best AI Song Generator Free',
    'Best AI Music Generator Free',
  ];

  useEffect(() => {
    document.title = currentPage.title;
    // Set meta description
    const metaDesc = document.querySelector('meta[name="description"]');
    if (metaDesc) {
      metaDesc.setAttribute('content', currentPage.description);
    } else {
      const newMetaDesc = document.createElement('meta');
      newMetaDesc.setAttribute('name', 'description');
      newMetaDesc.setAttribute('content', currentPage.description);
      document.head.appendChild(newMetaDesc);
    }
  }, [currentPage]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0F172A] via-[#131D35] to-[#0F172A] text-white">
      {/* Navigation */}
      <nav className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-slate-950/60' : 'bg-transparent'} backdrop-blur-xl border-b ${isScrolled ? 'border-white/10' : 'border-transparent'}`}>
        <div className="max-w-7xl mx-auto sm:px-6 px-2 py-4">
          <div className="flex items-center justify-between">
            <Link to="/" className="flex items-center sm:gap-3 gap-2">
              <img className="sm:w-12 sm:h-12 w-10 h-10 object-contain" src={footerFrog} alt="Sound of Meme logo" />
              <span className="text-xl sm:text-2xl font-normal text-green-400 font-potta">
                SoundofMeme
              </span>
            </Link>
            <div className="flex items-center sm:gap-4 gap-2">
              <Link to="/" className="px-2 sm:px-5 sm:py-2 py-1 rounded-md bg-gradient-to-r from-green-400/70 to-teal-400/70 text-black sm:font-medium font-normal transition-all transform hover:scale-105 hover:shadow-lg hover:shadow-green-500/20">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="relative pt-32 sm:mb-10 mb-2 px-6 md:px-10 lg:px-20 overflow-hidden">
        <div className="absolute inset-0 z-0 opacity-30">
          <div className="absolute top-0 left-0 w-full h-full bg-[radial-gradient(circle_at_30%_30%,#4ade80_0%,transparent_20%)]"></div>
          <div className="absolute bottom-0 right-0 w-full h-full bg-[radial-gradient(circle_at_70%_70%,#3b82f6_0%,transparent_20%)]"></div>
        </div>
        <div className="max-w-7xl mx-auto relative z-10">
          <div className="flex flex-col items-center text-center sm:mb-16 mb-8">
            <span className="px-4 py-1.5 bg-white/10 rounded-full text-sm font-medium text-green-400 mb-6">
              {currentPage.heading}
            </span>
            <h1 className="text-xl md:text-3xl lg:text-5xl font-bold mb-8 bg-gradient-to-r from-green-400  to-teal-400/90 bg-clip-text text-transparent">
              {currentPage.title}
            </h1>
            <p className="md:text-xl sm:text-base text-sm text-gray-300 max-w-3xl mb-10">
              {currentPage.description}
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Link to='/create' className="px-8 py-2 rounded-xl bg-gradient-to-r from-green-400/70 to-teal-400/70 text-black font-medium transition-all transform hover:scale-105 hover:shadow-lg hover:shadow-green-500/20 flex items-center justify-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                </svg>
                Create Ai Music
              </Link>
              <Link to='/' className="px-8 py-2 rounded-xl bg-white/10 backdrop-blur-sm text-white font-medium transition-all hover:bg-white/20 flex items-center justify-center gap-2">
                See Examples
              </Link>
            </div>
          </div>
          {/* Waveform Animation */}
          <div className="w-full h-24 flex items-center justify-center gap-1">
            {[...Array(20)].map((_, i) => {
              const height = 50 + Math.sin(i * 0.5) * 30;
              const animDuration = 1 + Math.random() * 0.5;
              const animDelay = i * 0.09;

              return (
                <div
                  key={i}
                  className="sm:w-4 w-2 bg-gradient-to-t from-green-400 to-teal-400 rounded-full opacity-90 animate-pulse"
                  style={{
                    height: `${height}px`,
                    animationDuration: `${animDuration}s`,
                    animationDelay: `${animDelay}s`,
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Features Section */}
      <section className="sm:mt-20 mt-16 px-6 md:px-10 lg:px-20 relative">
        <div className="max-w-7xl mx-auto">
          <div className="text-center sm:mb-16 mb-8">
            <h2 className="sm:text-4xl text-xl font-bold mb-6 bg-gradient-to-r from-green-400 to-teal-400 bg-clip-text text-transparent">
              Transform Your AI Music Creation with Sound of meme
            </h2>
            <p className="sm:text-xl text-sm text-gray-300 max-w-3xl mx-auto">
              Our revolutionary AI music generation platform enables anyone to create professional-quality AI music in minutes.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: "🎵",
                title: "AI-Generated Songs",
                description: "Create original songs in seconds with our advanced music AI"
              },
              {
                icon: "🎚️",
                title: "Customize Your AI Music",
                description: "Get professional ai sound quality"
              },
              {
                icon: "🎸",
                title: "Multiple Genres for ai music",
                description: "Generate ai music across any style from pop to classical"
              },
              {
                icon: "💾",
                title: "Download and Share your ai music",
                description: "Download and share your ai music with your friends"
              }
            ].map((feature, index) => (
              <div key={index} className="sm:p-4 p-2 rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 hover:border-green-500/30 transition-all hover:shadow-lg hover:shadow-green-500/10 hover:translate-y-[-5px]">
                <div className='flex items-center gap-2'>
                  <div className="sm:text-4xl text-2xl mb-4">{feature.icon}</div>
                  <h3 className="text-xl font-semibold mb-3 text-green-400">{feature.title}</h3>
                </div>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Trending Searches Section */}
      <section className="py-20 px-6 md:px-10 lg:px-20">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h4 className="text-3xl font-bold mb-6 text-white">AI Music Creation</h4>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-10">
              Explore what others are creating with our AI music tools
            </p>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {trendingSearches.map((search, index) => (
              <div
                key={index}
                className="p-2 rounded-xl bg-white/5 border border-white/10 text-center cursor-pointer hover:bg-gradient-to-r hover:from-green-500/20 hover:to-teal-500/20 hover:border-white/20 transition-all"
              >
                {search}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-12 px-6 md:px-10 lg:px-20 bg-slate-900/50 backdrop-blur-lg">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center gap-8 pb-8">
            {/* Logo and Tagline */}
            <div className="flex flex-col items-center md:items-start">
              <div className="flex items-center gap-3 mb-4">
                <img className="w-12 h-12 object-contain" src={footerFrog} alt="Sound of Meme logo" />
                <span className="text-2xl font-potta bg-gradient-to-r from-green-400 to-teal-500 bg-clip-text text-transparent">
                  SoundofMeme
                </span>
              </div>
              <p className="text-gray-400 text-center md:text-left">
                Create amazing music with AI technology
              </p>
            </div>

          </div>

          <div className="pt-6 border-t border-white/10 text-center text-gray-500 flex flex-col md:flex-row justify-between items-center gap-4">
            <div>© {new Date().getFullYear()} Sound of Meme. All rights reserved.</div>
            <div className="flex gap-8">
              <a href="#" className="text-gray-400 hover:text-green-400 transition-colors">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
              </a>
              <a href="#" className="text-gray-400 hover:text-green-400 transition-colors">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AiMusicMaker;