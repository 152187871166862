

const PlaylistsLoader = () => {
    const stackedCards = [0, 1, 2];

    return (
        <div>
            <div className="animate-pulse">

                <div

                    className="relative  "
                >
                    <div className=" w-full aspect-square flex items-center justify-center ">
                        {stackedCards.map((cardIndex) => (
                            <div
                                key={cardIndex}
                                className={`
                                        absolute 
                                        sm:w-full sm:h-full
                                        md:w-full md:h-full
                                        w-3/4 h-3/4
                                        rounded-lg
                                        ${cardIndex === 0 ? 'z-30' : ''} 
                                        ${cardIndex === 1 ? 'z-20 -translate-y-0 rotate-2 translate-x-2' : ''} 
                                        ${cardIndex === 2 ? 'z-10 -translate-y-0 rotate-3 translate-x-4' : ''}
                                    `}
                            >
                                <div className={`
                                        relative 
                                        w-full 
                                        h-full 
                                        rounded-lg 
                                        p-[2px]
                                      bg-slate-700 dark:bg-pink-300
                                        ${cardIndex !== 0 ? 'opacity-50' : ''}
                                    `}>
                                    <div className="relative w-full h-full bg-slate-700 dark:bg-pink-300 rounded-lg overflow-hidden">
                                        <div className="absolute top-2 left-2 bg-black/300 dark:bg-pink-300  text-white text-xs px-2 py-1 rounded-full z-20">
                                            <div className="h-4 w-12 bg-slate-700 dark:bg-pink-300 rounded "></div>
                                        </div>

                                        <div className="w-full h-full flex flex-col">
                                            <div className="w-full h-full bg-slate-800 dark:bg-pink-400"></div>

                                            <div className="absolute bottom-0 w-full p-4 bg-gradient-to-t from-black/40 to-transparent dark:from-pink-400 dark:to-transparent">
                                                <div className="h-6 w-3/4 bg-slate-800 dark:bg-pink-300 rounded mb-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PlaylistsLoader;