import { useDispatch, useSelector } from 'react-redux';
import { SET_ACHIEVEMENTS, SET_CLAIMABLE_ACHIEVEMENT, SET_IS_MANUAL_OPEN, SET_OPEN_GAMIFY_DIALOG, SET_SHOW_CLAIM_DIALOG } from '../../redux/features/theme/extradialogs';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ClaimDialog from './ClaimDialog';
import toast, { LoaderIcon } from 'react-hot-toast';
import { Award, X, RefreshCw, TrophyIcon } from 'lucide-react';
import SONGSCREATED from '../../assets/song-created-achievement.webp';
import SONGSREVIEWED from '../../assets/total-view-achievement.webp';
import SONGSSTREAMED from '../../assets/Spotify-distributions-achievement.webp';
import TOKENLAUNCHED from '../../assets/Spotify-distributions-achievement.webp';
import LIKES from '../../assets/song-liked-achievement.webp';
import LOGIN from '../../assets/login-streak-achievement.webp';
import SINGLESSONGLIKES from '../../assets/singlesonglikes.webp';
import SINGLESSONGVIEWS from '../../assets/singlesongviews.webp';
import Lottie from 'lottie-react';
import celebrationAnimation from '../../assets/success.json';
import { Dialog, DialogDescription, DialogOverlay, DialogPortal, DialogTitle } from '../../components/ui/dialog';
import { cn } from '@/lib/utils';
import React from 'react';
import * as DialogPrimitive from "@radix-ui/react-dialog"
import SUCCESSSOUND from '../../assets/success.mp3';
import ViewGiftCode from './ViewGiftCode';

const GamifyDialog = () => {
    const dispatch = useDispatch();
    const { isOpenGamifyDialog, achievements, showClaimDialog, claimableAchievement, isManualOpen } = useSelector((state) => state.extradialogs);
    const [isClosing, setIsClosing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [activeTab, setActiveTab] = useState('running');

    useEffect(() => {
        if (isOpenGamifyDialog) {
            fetchAchievements();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenGamifyDialog]);

    useEffect(() => {
        if (isOpenGamifyDialog) {
            const timer = setTimeout(() => {
                handleClose();
            }, 300000); // Increased time to allow users to view achievements
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenGamifyDialog]);

    useEffect(() => {
        const today = new Date().toDateString();
        const lastGamifyOpen = localStorage.getItem('lastGamifyOpen');
        if (!lastGamifyOpen && user?.is_guest === false) {
            dispatch(SET_OPEN_GAMIFY_DIALOG(true));
            dispatch(SET_SHOW_CLAIM_DIALOG(false));
            localStorage.setItem('lastGamifyOpen', today);
            dispatch(SET_IS_MANUAL_OPEN(false));
            return;
        }
        const lastOpenDate = new Date(lastGamifyOpen);
        const daysSinceLastOpen = Math.floor((new Date() - lastOpenDate) / (1000 * 60 * 60 * 24));
        if (daysSinceLastOpen >= 1 && user?.is_guest === false) {
            dispatch(SET_OPEN_GAMIFY_DIALOG(true));
            dispatch(SET_SHOW_CLAIM_DIALOG(false));
            localStorage.setItem('lastGamifyOpen', today);
            dispatch(SET_IS_MANUAL_OPEN(false));
        }
    }, [dispatch, user?.is_guest]);

    // Show claim dialog only if there are claimable achievements and the dialog hasn't been shown today 
    // and the dialog is open manually from the top bar
    useEffect(() => {
        if (isOpenGamifyDialog && isManualOpen) {
            const lastClaimDialogOpen = localStorage.getItem('lastClaimDialogOpen');
            const today = new Date().toDateString();
            if (!lastClaimDialogOpen) {
                // Show claim dialog only if there are claimable achievements
                if (claimableAchievement && claimableAchievement.length > 0) {
                    dispatch(SET_SHOW_CLAIM_DIALOG(true));
                    localStorage.setItem('lastClaimDialogOpen', today);
                }
            }
        }
    }, [isOpenGamifyDialog, isManualOpen, claimableAchievement, dispatch]);

    const fetchAchievements = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get('https://testapi.soundofmeme.com/achievements/progress', {
                headers: { 'accept': 'application/json', 'Authorization': `Bearer ${token}` }
            });
            dispatch(SET_ACHIEVEMENTS(response.data));
            // Check if there are any claimable achievements and set them
            const claimable = response.data.filter(achievement => achievement.can_claim);
            if (claimable.length > 0) {
                dispatch(SET_CLAIMABLE_ACHIEVEMENT(claimable));
                // Set tab to 'claimable' if there are actually claimable achievements
                setActiveTab('claimable');
                // Only show claim dialog if this is a manual open and the dialog hasn't been shown today
                if (isManualOpen) {
                    const lastClaimDialogOpen = localStorage.getItem('lastClaimDialogOpen');
                    const today = new Date().toDateString();
                    if (!lastClaimDialogOpen || lastClaimDialogOpen !== today) {
                        dispatch(SET_SHOW_CLAIM_DIALOG(true));
                        localStorage.setItem('lastClaimDialogOpen', today);
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching achievements:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            dispatch(SET_OPEN_GAMIFY_DIALOG(false));
            setIsClosing(false);
            dispatch(SET_SHOW_CLAIM_DIALOG(false));
        }, 500);
    };

    const handleRefresh = () => {
        fetchAchievements();
    };

    const getAchievementIcon = (achievement) => {
        switch (achievement.achievement_type) {
            case 'songs_created':
                return SONGSCREATED;
            case 'song_views':
                return SONGSREVIEWED;
            case 'spotify_distributions':
                return SONGSSTREAMED;
            case 'tokens_launched':
                return TOKENLAUNCHED;
            case 'total_likes':
                return LIKES;
            case 'login_days':
                return LOGIN;
            case 'max_song_likes':
                return SINGLESSONGLIKES;
            case 'max_song_views':
                return SINGLESSONGVIEWS;
        }
    };

    const claimableMilestone = (achievement) => {
        const claimableMilestone = achievement.milestones.find(m => m.reached && !m.claimed);
        return claimableMilestone;
    };

    const handleClaim = async (achievement) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`/achievements/claim/${achievement.achievement_type}`, null, {
                headers: { 'accept': 'application/json', 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                // Show animation
                setShowAnimation(true);
                // Play sound
                const audio = new Audio(SUCCESSSOUND);
                audio.play();
                // Hide animation after 2.5 seconds
                setTimeout(() => {
                    setShowAnimation(false);
                }, 2500);
                // Update claim status for this specific milestone
                const updatedAchievements = achievements.map(ach => {
                    if (ach.achievement_type === achievement.achievement_type) {
                        // Create a copy of the achievement as a new object for updating the claim
                        const updatedAchievement = { ...ach };
                        // Update only the milestone that was claimed
                        updatedAchievement.milestones = ach.milestones.map(m => {
                            if (m.milestone === claimableMilestone(achievement).milestone) {
                                return { ...m, claimed: true };
                            }
                            return m;
                        });
                        // Check if there are still unclaimed milestones that have been reached
                        const hasClaimableMilestones = updatedAchievement.milestones.some(m =>
                            m.reached && !m.claimed
                        );
                        // Update can_claim flag
                        updatedAchievement.can_claim = hasClaimableMilestones;
                        return updatedAchievement;
                    }
                    return ach;
                });
                dispatch(SET_ACHIEVEMENTS(updatedAchievements));
                dispatch(SET_CLAIMABLE_ACHIEVEMENT(updatedAchievements));
                // Check if there are any remaining claimable achievements
                const remainingClaimable = updatedAchievements.filter(ach => ach.can_claim);
                if (remainingClaimable.length === 0 && activeTab === 'claimable') {
                    // No more claimable achievements, switch to running tab
                    setActiveTab('running');
                }
                toast.success(`${claimableMilestone(achievement).description} claimed successfully`);
            }
        } catch (error) {
            console.error('Error claiming milestone:', error);
        }
    };

    const handleTabChange = (tab) => {
        if (tab === 'claimable') {
            setActiveTab('claimable');
        } else if (tab === 'completed') {
            setActiveTab('completed');
        } else if (tab === 'running') {
            setActiveTab('running');
        } else if (tab === 'viewGiftCodes') {
            setActiveTab('viewGiftCodes');
        }
    };

    // Create a custom DialogContent component that overrides the default positioning
    const CustomDialogContent = React.forwardRef(({ className, children, ...props }, ref) => (
        <DialogPrimitive.Content
            ref={ref}
            className={cn(
                "fixed !left-auto !right-0 !top-28 sm:!top-0 !transform-none z-50 w-auto",
                className
            )}
            {...props}
        >
            {children}
        </DialogPrimitive.Content>
    ));
    CustomDialogContent.displayName = "CustomDialogContent";

    return (
        <>
            <Dialog open={isOpenGamifyDialog && !showClaimDialog} onOpenChange={handleClose}>
                <DialogPortal>
                    <DialogOverlay className="bg-black/30 backdrop-blur-md z-[999]" />
                    <CustomDialogContent className="!bg-transparent !border-0 !shadow-none !p-0 !m-0 z-[9999] overflow-visible">
                        <div className={`lg:w-[500px] w-screen max-w-[95%] sm:max-w-[100%] sm:w-[400px] min-h-[40vh] md:min-h-full max-h-[70vh] sm:pt-2 p-2 rounded-xl border-1 border shadow-2xl shadow-green-900 border-green-900 bg-gradient-to-br from-slate-950 via-slate-900 to-slate-950 overflow-y-auto scrollbar-hide mx-auto transition-all duration-500 ${isClosing ? 'opacity-0 scale-50 rotate-5' : 'opacity-100 scale-100 rotate-0'}`}>
                            {/* Lottie Animation */}
                            {showAnimation && (
                                <div className="absolute inset-0 bg-slate-900/80 flex items-center justify-center z-50 pointer-events-none">
                                    <div className="flex flex-col items-center">
                                        <Lottie animationData={celebrationAnimation} loop={false} autoplay style={{ width: 250, height: 250 }} />
                                    </div>
                                </div>
                            )}
                            {isLoading && (
                                <div className="absolute inset-0 bg-slate-900/70 flex items-center justify-center z-50">
                                    <LoaderIcon className="w-10 h-10 text-green-400 animate-spin" />
                                </div>
                            )}
                            {/* Dialog content */}
                            <DialogTitle className="sr-only">Your Progress</DialogTitle>
                            <DialogDescription className="sr-only">Achievement progress and rewards</DialogDescription>
                            <div className="flex flex-col items-center justify-between p-1">
                                <h2 className="text-2xl lg:text-3xl font-normal font-potta bg-gradient-to-b from-green-400 to-teal-400/50 text-transparent bg-clip-text">
                                    Your Progress
                                </h2>
                                <div className="flex items-center justify-between w-full bg-slate-900/50 p-1 rounded-md shadow-lg border border-slate-700/50 mt-6">
                                    <button onClick={() => handleTabChange('claimable')} className={`relative w-1/4 px-1.5 sm:px-3 py-2.5 rounded-md font-medium sm:text-sm text-xs transition-all duration-200 ${activeTab === 'claimable' ? 'text-black bg-gradient-to-r from-green-500/90 to-green-600/90 shadow-md' : 'text-slate-400 hover:text-slate-200'}`}>
                                        {activeTab === 'claimable' &&
                                            <span className="absolute inset-0 rounded-md bg-green-400/10 animate-pulse"></span>
                                        }
                                        Claimable
                                    </button>
                                    <button onClick={() => handleTabChange('completed')} className={`relative w-1/4 px-1.5 sm:px-3 py-2.5 rounded-md font-medium sm:text-sm text-xs transition-all duration-200 ${activeTab === 'completed' ? 'text-black bg-gradient-to-r from-green-500/90 to-green-600/90 shadow-md' : 'text-slate-400 hover:text-slate-200'}`}>
                                        {activeTab === 'completed' &&
                                            <span className="absolute inset-0 rounded-md bg-green-400/10 animate-pulse"></span>
                                        }
                                        Completed
                                    </button>
                                    <button onClick={() => handleTabChange('running')} className={`relative w-1/4 px-1.5 sm:px-3 py-2.5 rounded-md font-medium sm:text-sm text-xs transition-all duration-200 ${activeTab === 'running' ? 'text-black bg-gradient-to-r from-green-500/90 to-green-600/90 shadow-md' : 'text-slate-400 hover:text-slate-200'}`}>
                                        {activeTab === 'running' &&
                                            <span className="absolute inset-0 rounded-md bg-green-400/10 animate-pulse"></span>
                                        }
                                        Running
                                    </button>
                                    <button onClick={() => handleTabChange('viewGiftCodes')} className={`relative w-1/4 px-1.5 sm:px-3 py-2.5 rounded-md font-medium sm:text-sm text-xs transition-all duration-200 ${activeTab === 'viewGiftCodes' ? 'text-black bg-gradient-to-r from-green-500/90 to-green-600/90 shadow-md' : 'text-slate-400 hover:text-slate-200'}`}>
                                        {activeTab === 'viewGiftCodes' &&
                                            <span className="absolute inset-0 rounded-md bg-green-400/10 animate-pulse"></span>
                                        }
                                        Gift Codes
                                    </button>
                                </div>
                                {/* Achievement list */}
                                {activeTab === 'claimable' && (
                                    <div className="w-full grid grid-cols-1 gap-2 mt-4 capitalize">
                                        {achievements.filter(achievement => achievement.can_claim).map((achievement, index) => {
                                            const claimableMilestone = achievement.milestones.find(milestone => milestone.reached && !milestone.claimed);
                                            if (!claimableMilestone) return null;

                                            return (
                                                <div key={index} className="group relative bg-gradient-to-br from-slate-800/40 to-slate-900/40 rounded-lg p-2 border border-slate-700/30">
                                                    <div className="relative flex items-start gap-2">
                                                        {/* Achievement Icon with glow effect */}
                                                        <div className="relative flex-shrink-0">
                                                            <div className="relative bg-gradient-to-br from-slate-800 to-slate-900 p-0.5 rounded-lg border border-slate-700/50 shadow-inner">
                                                                <img src={getAchievementIcon(achievement)} alt={achievement.description} className="w-10 h-10 rounded-md" />
                                                            </div>
                                                        </div>

                                                        {/* Achievement Content */}
                                                        <div className="flex-1 min-w-0">
                                                            <div className="flex items-start justify-between gap-1">
                                                                <div className="">
                                                                    {/* Title */}
                                                                    <h3 className="flex items-center gap-2 text-base font-medium text-slate-200 leading-tight">
                                                                        {claimableMilestone.description}
                                                                        <Award className="w-4 h-4 text-amber-400 animate-pulse" />
                                                                    </h3>
                                                                    {/* Reward */}
                                                                    <div className="flex items-center">
                                                                        <div className="">
                                                                            <span className="text-xs font-medium text-emerald-400">
                                                                                {claimableMilestone.reward_value} {claimableMilestone.reward_type}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Claim Button */}
                                                                <button onClick={() => handleClaim(achievement)} className="flex-shrink-0 px-3 py-1.5 text-xs font-medium bg-gradient-to-r from-green-500/90 to-teal-500/60 hover:from-green-400 hover:to-teal-400 text-slate-900 rounded-sm shadow-md transform transition-all duration-200 hover:shadow-lg hover:shadow-green-900/20 focus:ring-2 focus:ring-green-500/50 focus:outline-none">
                                                                    Claim
                                                                </button>
                                                            </div>
                                                            {/* Progress Bar */}
                                                            <div className="mt-2 w-full h-1.5 bg-slate-700/30 rounded-full overflow-hidden">
                                                                <div className="h-full bg-gradient-to-r from-green-500 to-emerald-400 rounded-full transition-all duration-1000 ease-out" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {achievements.filter(achievement => achievement.can_claim).length === 0 && (
                                            <div className="flex flex-col items-center justify-center py-12 px-4">
                                                <div className="relative">
                                                    <Award className="w-16 h-16 text-green-200 animate-pulse" />
                                                    <div className="absolute -bottom-1 -right-1 w-4 h-4 bg-slate-800 rounded-full border-2 border-slate-600 flex items-center justify-center">
                                                        <span className="text-slate-400 text-xs">0</span>
                                                    </div>
                                                </div>
                                                <h3 className="mt-4 text-lg font-semibold text-green-400">No Achievements to Claim</h3>
                                                <p className="mt-2 text-sm text-slate-400 text-center max-w-xs">
                                                    Keep creating and engaging to unlock new achievements. Your next milestone awaits!
                                                </p>
                                                <button onClick={() => handleTabChange('running')} className="mt-6 px-4 py-2 bg-slate-800/50 hover:bg-slate-700/50 text-slate-300 rounded-lg border border-slate-600/50 transition-all duration-200 flex items-center gap-2" >
                                                    <TrophyIcon className="w-4 h-4 text-green-400 animate-pulse" />
                                                    View Progress
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {activeTab === 'completed' && (
                                    <div className="w-full grid grid-cols-1 gap-2 mt-4 capitalize">
                                        {achievements.filter(achievement => {
                                            // Show achievements that have at least one claimed milestone
                                            return achievement.milestones.some(m => m.claimed);
                                        }).map((achievement, index) => (
                                            <div key={index} className="group relative overflow-hidden bg-gradient-to-r from-slate-800/30 to-slate-900/30 rounded-md p-2 border border-slate-700/30">
                                                {/* Achievement Header */}
                                                <div className="flex items-start gap-1">
                                                    <div className="relative">
                                                        <div className="absolute inset-0 bg-gradient-to-r from-green-500/20 to-emerald-500/20 blur-xl rounded-full animate-pulse"></div>
                                                        <div className="relative bg-gradient-to-br from-slate-800 to-slate-900 p-1 rounded-md shadow-inner border border-slate-700/50">
                                                            <img src={getAchievementIcon(achievement)} alt={achievement.description} className="w-10 h-10 rounded-md" />
                                                        </div>
                                                    </div>
                                                    <div className="flex-1 flex flex-col gap-1">
                                                        <h3 className="sm:text-sm text-xs font-bold bg-gradient-to-r from-white to-slate-300 bg-clip-text text-transparent">
                                                            {achievement.description}
                                                        </h3>
                                                        <p className="text-xs text-slate-400 mt-0">Completed Milestones</p>
                                                    </div>
                                                </div>

                                                {/* Milestones Grid */}
                                                <div className="mt-1.5 grid grid-cols-1 gap-1">
                                                    {achievement.milestones.filter(milestone => milestone.claimed).map((milestone, idx) => (
                                                        <div key={idx} className="relative overflow-hidden bg-gradient-to-r from-slate-800/50 to-slate-900/50 border border-green-700/20 rounded-sm sm:p-2 p-1 transition-all duration-300">
                                                            <div className="flex items-center justify-between relative">
                                                                <div className="flex-1">
                                                                    <div className="flex items-center gap-1">
                                                                        <span className="text-white sm:text-sm text-xs font-medium">
                                                                            {milestone.description}
                                                                        </span>
                                                                    </div>
                                                                    <div className="flex items-center gap-1">
                                                                        <div className="flex items-center gap-1 py-1 rounded-full">
                                                                            <span className="text-xs font-medium text-green-400">
                                                                                {milestone.reward_value}
                                                                            </span>
                                                                            <span className="text-xs font-medium text-slate-400">
                                                                                {milestone.reward_type}
                                                                            </span>
                                                                        </div>
                                                                        <div className="h-2.5 w-px bg-slate-700"></div>
                                                                        <span className="text-xs text-slate-500">Claimed</span>
                                                                    </div>
                                                                </div>
                                                                <div className="relative">
                                                                    <div className="absolute inset-0 bg-green-500/10 rounded-full"></div>
                                                                    <div className="relative bg-gradient-to-br from-slate-800 to-slate-900 p-2 rounded-md">
                                                                        <Award className="w-5 h-5 text-amber-300/60" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                        {achievements.filter(achievement => achievement.milestones.some(m => m.claimed)).length === 0 && (
                                            <div className="flex flex-col items-center justify-center py-12 px-4">
                                                <div className="relative">
                                                    <div className="absolute inset-0 bg-green-500/20 blur-xl rounded-full animate-pulse"></div>
                                                    <Award className="w-16 h-16 text-slate-400 animate-float" />
                                                </div>
                                                <h3 className="mt-6 text-xl font-semibold bg-gradient-to-r from-slate-200 to-slate-400 bg-clip-text text-transparent">
                                                    No Achievements Yet
                                                </h3>
                                                <p className="mt-3 text-sm text-slate-400 text-center max-w-sm leading-relaxed">
                                                    Complete challenges and milestones to unlock achievements. Your journey to musical innovation and crypto rewards starts here!
                                                </p>
                                                <div className="mt-0 flex items-center">
                                                    <button onClick={() => handleTabChange('running')} className="mt-6 px-4 py-2 bg-slate-800/50 hover:bg-slate-700/50 text-slate-300 rounded-lg border border-slate-600/50 transition-all duration-200 flex items-center gap-2" >
                                                        <TrophyIcon className="w-4 h-4 text-green-400 animate-pulse" />
                                                        View Progress
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {activeTab === 'running' && (
                                    <div className="w-full grid grid-cols-1 gap-1.5 mt-4 capitalize">
                                        {achievements.filter(achievement => achievement.total_progress_percentage !== 100).map((achievement, index) => {
                                            const nextMilestone = achievement.milestones.find(milestone => !milestone.reached && !milestone.claimed);
                                            const unclaimedReachedMilestone = achievement.milestones.find(milestone => milestone.reached && !milestone.claimed);
                                            const milestone = nextMilestone || unclaimedReachedMilestone;
                                            if (!milestone) return null;
                                            return (
                                                <div key={index} className="group relative bg-gradient-to-br from-slate-800/40 to-slate-900/40 rounded-lg p-2 border border-slate-700/30">
                                                    <div className="relative flex items-center justify-center gap-2">
                                                        {/* Achievement Icon */}
                                                        <div className="relative flex-shrink-0">
                                                            <div className="relative bg-gradient-to-br from-slate-800 to-slate-900 p-0.5 rounded-lg border border-slate-700/50 shadow-inner">
                                                                <img src={getAchievementIcon(achievement)} alt={achievement.description} className="w-10 h-10 rounded-sm" />
                                                            </div>
                                                        </div>
                                                        {/* Achievement Details */}
                                                        <div className="flex-1 min-w-0 mb-0">
                                                            <div className="flex items-center justify-between mb-1">
                                                                <h3 className="sm:text-sm text-xs font-medium text-slate-200 truncate pr-2">
                                                                    {milestone.description}
                                                                </h3>
                                                                <div className="flex items-center gap-2">
                                                                    <span className="text-xs px-2 py-0.5 bg-slate-800/80 rounded-full">
                                                                        <span className="text-green-400">{achievement.current_count}</span>
                                                                        <span className="text-slate-400">/{milestone.milestone}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/* Progress Bar */}
                                                            <div className="flex flex-col items-start justify-between gap-1">
                                                                <div className="text-xs text-emerald-400/90">
                                                                    {milestone.reward_value} {milestone.reward_type}
                                                                </div>
                                                                <div className="w-full h-1.5 bg-slate-700/30 rounded-full overflow-hidden">
                                                                    <div className="h-full bg-gradient-to-r from-green-500 to-emerald-400 rounded-full transition-all duration-1000 ease-out" style={{ width: `${achievement.progress_percentage}%` }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {achievements.filter(a => a.total_progress_percentage !== 100).length === 0 && (
                                            <div className="text-center py-6">
                                                <p className="text-slate-400 text-sm">No achievements in progress</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {activeTab === 'viewGiftCodes' && (
                                    <ViewGiftCode activeTab={activeTab} />
                                )}
                            </div>
                            <button onClick={() => dispatch(SET_OPEN_GAMIFY_DIALOG(false))} className="absolute flex items-center justify-center md:w-20 md:h-14 w-16 h-10 top-1 -right-2 text-slate-400 hover:text-green-400 p-3">
                                <X size={24} />
                            </button>
                            <button onClick={handleRefresh} className={`absolute flex items-center justify-center md:w-20 md:h-14 w-16 h-10 top-1 -left-2 text-slate-400 hover:text-green-400 p-3 `}>
                                <RefreshCw size={24} className={`${isLoading ? 'animate-spin' : ''}`} />
                            </button>
                        </div>
                    </CustomDialogContent>
                </DialogPortal>
            </Dialog>
            {showClaimDialog && claimableAchievement && claimableAchievement.length > 0 && (
                <ClaimDialog achievements={claimableAchievement} setActiveTab={setActiveTab} />
            )}
        </>
    );
};

export default GamifyDialog;