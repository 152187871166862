import { SongCardSkeleton } from "../createSong/CreateSongLoading"
import { SongCardMyCreationSkeleton } from "../songCard/SongCardListView"

const SongCardPageLoader = ({ tabView, author }) => {
    return (
        tabView === "list" ? <>
            <SongCardMyCreationSkeleton author={author} />
            <SongCardMyCreationSkeleton author={author} />
            <SongCardMyCreationSkeleton author={author} />
            <SongCardMyCreationSkeleton author={author} />
            <SongCardMyCreationSkeleton author={false} />
            <SongCardMyCreationSkeleton author={false} />
            <SongCardMyCreationSkeleton author={false} />
            <SongCardMyCreationSkeleton author={false} />
            <SongCardMyCreationSkeleton author={false} />
            <SongCardMyCreationSkeleton author={false} />
        </> : <>
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
        </>
    )
}

export default SongCardPageLoader