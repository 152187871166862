import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { PATCH_USER } from "@/redux/features/auth/authSlice";
import toast from "react-hot-toast";
import { useEffect } from "react";
import axios from "axios";
import { LoaderCircle, Pencil } from "lucide-react";

const Error = ({ children }) => {
  return <p className="text-red-600 text-[0.8rem]">{children}</p>;
};

const Input = ({ label, placeholder, inputParams, isError, errorMessage }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <p>{label}</p>
      <input
        {...inputParams}
        type="text"
        placeholder={placeholder}
        className={
          "w-full px-2 border border-slate-600 py-3 rounded bg-slate-800 dark:bg-pink-100 text-sm text-slate-200 dark:text-pink-900 placeholder-slate-400 dark:placeholder-pink-900 focus:outline-none focus:ring-1 focus:ring-green-400 dark:focus:ring-pink-400 focus:border-transparent"
        }

      />
      {isError && <Error>{errorMessage || `${label} is required.`}</Error>}
    </div>
  );
};

const DisabledInput = ({ label, value }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <p>{label}</p>
      <input
        value={value}
        type="text"
        disabled
        className={
          "w-full px-2 py-3 text-slate-400 dark:text-pink-900 rounded bg-gray-900 dark:bg-pink-200 dark:font-medium text-sm  placeholder-slate-400 dark:placeholder-pink-900 focus:outline-none focus:ring-1 focus:ring-green-400 dark:focus:ring-pink-400 focus:border-transparent"
        }
      />
    </div>
  );
};

const stripNullAndUndefined = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] != undefined && obj[key] != null) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

const getFirstAndLastName = (name) => {
  const fullName = String(name).split(" ");
  const firstName = fullName[0];
  const lastName = fullName.length > 1 ? fullName[fullName.length - 1] : "";
  return { firstName, lastName };
};

const handleImage = (image) => {
  if (!image) {
    return null;
  }
  if (typeof image === "string") {
    return image;
  }
  return URL.createObjectURL(image);
};

// Main Component
export default function UpdateProfile({
  image,
  showCropContent,
  isVisible,
  setImageSrc,
  close,
}) {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImageSrc(URL.createObjectURL(file));
      showCropContent();
    }
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    let isImageUpdated = false;

    if (image && typeof image !== "string") {
      formData.append("image", image, "image.png");
      isImageUpdated = true;
    }

    try {
      const token = localStorage.getItem("token");
      const { firstName: currentFirstName, lastName: currentLastName } = getFirstAndLastName(user.name);
      const newFullName = `${data["firstName"]} ${data["lastName"]}`;

      if (`${currentFirstName} ${currentLastName}` !== newFullName) {
        try {
          await axios.post(`/user/changename?name=${encodeURIComponent(newFullName)}`, {}, {
            headers: { Authorization: `Bearer ${token}` }
          });
        } catch (error) {
          toast.error("Failed to update name");
          return;
        }
      }

      if (data.username !== user.username) {
        try {
          const response = await axios.post(`/user/checkusername?username=${data.username}`, {}, {
            headers: { Authorization: `Bearer ${token}` }
          });
          if (response.data.username !== data.username) {
            await axios.post(`/user/changeusername?username=${data.username}`, {}, {
              headers: { Authorization: `Bearer ${token}` }
            });
          }
        } catch (error) {
          toast.error("Username is already taken or an error occurred");
          return;
        }
      }

      if (isImageUpdated) {
        await axios.post(`/user/changeprofileurl`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          }
        });
      }

      dispatch(PATCH_USER(stripNullAndUndefined({
        name: newFullName,
        username: data.username,
        profile_url: isImageUpdated ? URL.createObjectURL(image) : user.profile_url
      })));

      toast.success("Profile details updated successfully");
      close();
    } catch (error) {
      toast.error("Updating profile failed");
    }
  };


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    const { firstName, lastName } = getFirstAndLastName(user.name);
    reset({
      firstName: firstName,
      lastName: lastName,
      username: user.username,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      className={`${isVisible ? "" : "hidden"} flex flex-col items-center w-full`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex items-center space-x-3"></div>
      <div className="h-52 w-52 rounded-full bg-black dark:bg-pink-100 relative">
        <img
          className="absolute object-cover w-52 h-52 z-1 rounded-full"
          src={handleImage(image)}
          alt="user profile image"
        />

        <div className="bg-white dark:bg-pink-500 p-2 w-6 h-6 rounded-full absolute bottom-5 right-5 flex items-center justify-center">
          <Pencil className="fill-black dark:fill-pink-900 scale-[0.7] absolute w-full h-full" />

          <input
            {...register("image")}
            onChange={handleImageChange}
            className="opacity-0 absolute w-full h-full"
            type="file"
            id=""
          />
        </div>
      </div>
      <p className="h-12"></p>
      <div className="space-y-4 w-full">
        <div className="flex gap-6">
          <Input
            label={"First Name"}
            placeholder={"Enter your first name"}
            inputParams={register("firstName", {
              required: true,
              pattern: {
                value: /^[A-Za-z]+$/,
                message: "First name should only contain letters"
              },
              minLength: {
                value: 2,
                message: "First name should be at least 2 characters"
              }
            })}
            isError={errors.firstName}
            errorMessage={errors.firstName?.message}
          />
          <Input
            label={"Last Name"}
            placeholder={"Enter your last name"}
            inputParams={register("lastName", {
              pattern: {
                value: /^[A-Za-z]+$/,
                message: "Last name should only contain letters"
              },
              minLength: {
                value: 2,
                message: "Last name should be at least 2 characters"
              }
            })}
            isError={errors.lastName}
            errorMessage={errors.lastName?.message}
          />
        </div>
        <Input
          label={"Username"}
          placeholder={"Enter your username"}
          inputParams={register("username", {
            required: true,
            pattern: {
              value: /^[a-zA-Z0-9_@]+$/,
              message: "Username can only contain letters, numbers and underscores"
            },
            minLength: {
              value: 3,
              message: "Username should be at least 3 characters"
            },
            maxLength: {
              value: 20,
              message: "Username should not exceed 20 characters"
            }
          })}
          isError={errors.username}
          errorMessage={errors.username?.message}
        />
        <DisabledInput label={"Email"} value={user.email}></DisabledInput>
        <div className="pt-3 flex flex-row justify-end gap-2">
          <Button
            type="submit"
            disabled={isSubmitting}
            className="bg-green-400 px-8 hover:bg-green-300 text-slate-800 dark:bg-pink-700 dark:hover:bg-pink-600 dark:text-black"
          >
            {isSubmitting ? (
              <LoaderCircle size={18} className="animate-spin" />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            type="button"
            className="bg-slate-700 px-6 hover:bg-slate-800 text-slate-300 dark:bg-pink-100 dark:hover:bg-pink-200 dark:text-black dark:border-1 dark:border dark:border-pink-400"
            onClick={() => {
              setImageSrc(user.profile_url);
              close();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
}
