import { ADD_LIKESONG, REMOVE_LIKESONG, SET_DISCSONGS, SET_LAUNCHTOKENSONG, SET_PLAYING, SET_PLAYINGFROM, SET_PLAYLOADING, SET_SONG, SET_USERSONGS } from "@/redux/features/music/musicSlice"
import axios from "axios"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { Oval, Rings } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuTrigger } from "../ui/dropdown-menu"
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu"
import { SET_CLONESONG, SET_CREATEDESC, SET_CREATEGENRE, SET_CUSTOMDESC, SET_CUSTOMLYRICS, SET_CUSTOMTITLE, SET_DELETE_DIALOG_OPEN, SET_EXTENDSONG, SET_SELECTEDTAB, SET_SONG_TO_DELETE } from "@/redux/features/music/createSlice"
import { SET_DIALOG_OPEN, SET_SONG_TO_ADD } from "@/redux/features/music/leaderboardSlice"
import { SET_DIALOG_OPEN_TWO, SET_PLAYLIST_SONGS, SET_SONG_TO_ADD_TWO } from "@/redux/features/music/playlistSlice"
import { SET_SONG_DRAWER_IS_PLAYLIST, SET_SONG_DRAWER_OPEN, SET_SONG_DRAWER_SONG, SET_SONG_FROM, UPDATE_SONG_PRIVACY } from "@/redux/features/music/songDrawerSlice"
import { DrawerTrigger } from "../ui/drawer"
import { Dialog } from "../ui/dialog"
import frogImg from "../../assets/greenbird.png"
import { Link } from "react-router-dom"
import { SET_SHOW_LOGIN } from "@/redux/features/auth/authSlice"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css";
import { SET_WALLETBTN } from "@/redux/features/music/musicSlice"
import PUMPFUN from "../../assets/pumpfun.webp"
import { Play, Clock, Coins, Download, FolderPlus, Globe, GlobeLock, Heart, MoreHorizontal, RefreshCcw, Share2, Trash2, UnfoldHorizontal } from "lucide-react"
import { Leaderboard, MusicalNotes, SpotifyIcon } from "../navbar/SocialIcons"

const SongCardListView = ({ song, playingFrom, author, imageUrl }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userSong, setUserSong] = useState(song);
    const [loading, setLoading] = useState(false);
    const { song: selectedSong, playing, playLoading, userSongs, discoverSongs } = useSelector(state => state.music);
    const { songs: playlistSongs } = useSelector(state => state.playlists);
    const { user } = useSelector(state => state.auth);
    const { songsList } = useSelector(state => state.songDrawer);
    const [liked, setLiked] = useState(userSong?.is_liked);
    const [likedLoading, setLikedLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const displayImage = imageUrl || userSong?.image_url;

    useEffect(() => {
        if (songsList[userSong?.song_id]?.publish !== undefined) {
            setUserSong(prev => ({
                ...prev,
                publish: songsList[userSong.song_id].publish
            }));
        }
    }, [songsList, userSong?.song_id]);

    const handlePlay = () => {
        if (!song?.song_url) {
            toast.error("Cannot play this song, please reload.", { id: "song" })
            return
        }
        dispatch(SET_PLAYING(true));
        dispatch(SET_PLAYINGFROM(playingFrom));
        dispatch(SET_SONG(song));
        if (selectedSong?.song_url !== userSong?.song_url) {
            dispatch(SET_PLAYLOADING(true));
        }
    }

    const handlePause = () => {
        dispatch(SET_PLAYING(false));
    }

    const handleExtend = (e) => {
        e.stopPropagation();
        dispatch(SET_SELECTEDTAB("extend"));
        dispatch(SET_EXTENDSONG({
            song_id: userSong?.song_id,
            song_name: userSong?.song_name
        }));
        navigate("/create");
    }
    const handleClone = (e) => {
        e.stopPropagation();
        dispatch(SET_SELECTEDTAB("clone"));
        dispatch(SET_CLONESONG({
            song_id: userSong?.song_id,
            song_name: userSong?.song_name
        }));
        navigate("/create");
    }
    // copy song link
    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            console.log('URL copied to clipboard!');
            toast.success('URL copied to clipboard!', { id: "copyToast" });
        }).catch(err => {
            console.error('Failed to copy:', err);
        });
    };

    const handleShare = async (song) => {
        const url = `https://soundofmeme.com/song/${song?.slug}`;
        const title = 'Check out this song!';
        if (navigator.share) {
            try {
                await navigator.share({
                    title,
                    // text,
                    url
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            copyToClipboard(url);
        }
    };

    // handle publish
    const handlePublish = async (e) => {
        if (user?.is_guest) {
            dispatch(SET_SHOW_LOGIN(true));
            return toast.error("Please login to publish the song", { id: "guest" });
        }
        e.stopPropagation();
        const token = window.localStorage.getItem('token');
        try {
            setLoading(true);
            toast.loading(userSong.publish ? "Unpublishing" : "Publishing", { id: "publish" });
            await axios.post(
                userSong.publish ? "/private" : "/public",
                { song_id: userSong.song_id },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const newPublishState = !userSong.publish;
            setUserSong(prevSong => ({
                ...prevSong,
                publish: newPublishState
            }));
            dispatch(UPDATE_SONG_PRIVACY({
                songId: userSong.song_id,
                isPublic: newPublishState
            }));
            toast.success(newPublishState ? "Published" : "Unpublished", { id: "publish" });
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.detail || "Error Occurred", { id: "publish" });
        } finally {
            setLoading(false);
        }
    }

    const handleRecreate = (song) => {
        if (song?.prompt === null || song.prompt === '' || song.prompt === undefined) {
            toast.error('Feature not available on this song', { id: "recreate" });
            return;
        }
        if (song?.prompt?.type === "create" || song?.prompt?.type === "custom") {
            if (song.prompt.type === "create") {
                dispatch(SET_SELECTEDTAB('create'));
                dispatch(SET_CREATEDESC(song.prompt.desc));
                dispatch(SET_CREATEGENRE(song.prompt.genre.split(' ').map(tag => tag.charAt(0).toUpperCase() + tag.slice(1)).join(', ')));
                navigate("/create");
            } else if (song.prompt.type === "custom") {
                dispatch(SET_SELECTEDTAB('custom'));
                dispatch(SET_CUSTOMDESC(song.prompt.genre));
                dispatch(SET_CUSTOMTITLE(song.prompt.title2));
                dispatch(SET_CUSTOMLYRICS(song.prompt.lyrics2));
                navigate("/create");
            }
        } else {
            toast.error('Feature not available on this song', { id: "recreate" });
            return;
        }
    }

    // download song
    function download(url) {
        const a = document.createElement('a');
        a.href = url;
        a.download = `Sound of Meme`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    const downloadSong = async (song) => {
        if (!user || user?.is_guest) {
            dispatch(SET_SHOW_LOGIN(true));
            return toast.error('Login to download the song', { id: "guest" });
        }
        const token = window.localStorage.getItem('token');
        try {
            setDownloadLoading(true);
            toast.loading("Starting Download", { id: "download" });
            const response = await axios.get(`/download?song_id=${song?.song_id}`, { headers: { Authorization: `Bearer ${token}` } });
            const signedUrl = response.data.url;
            download(signedUrl);
            toast.success("Download Started", { id: "download" });
        } catch (error) {
            console.error('Error fetching signed URL:', error);
            toast.error('Error Occured. Please try again.', { id: "download" });
        } finally {
            setDownloadLoading(false);
        }
    };

    const handleOpenDeleteDialog = (e) => {
        e.stopPropagation();
        dispatch(SET_SONG_TO_DELETE(song));
        dispatch(SET_DELETE_DIALOG_OPEN(true));
        dispatch(SET_SONG_DRAWER_OPEN(false));
    };

    const AddToPlaylist = (e) => {
        if (!user || user?.is_guest) {
            dispatch(SET_SHOW_LOGIN(true));
            return toast.error('Login to add to playlist');
        }
        e.stopPropagation();
        dispatch(SET_SONG_TO_ADD_TWO(song));
        dispatch(SET_DIALOG_OPEN_TWO(true));
        dispatch(SET_SONG_DRAWER_OPEN(false));
    }

    const handleAddToLeaderboard = (e) => {
        if (user?.is_guest) {
            dispatch(SET_SHOW_LOGIN(true));
            return toast.error('Login to add to leaderboard', { id: "guest" });
        }
        e.stopPropagation();
        dispatch(SET_SONG_DRAWER_OPEN(false));
        dispatch(SET_SONG_TO_ADD(song));
        dispatch(SET_DIALOG_OPEN(true));
    };


    const handleOpenSongDrawer = (e) => {
        e.stopPropagation();
        dispatch(SET_SONG_DRAWER_OPEN(true));
        dispatch(SET_SONG_DRAWER_SONG(userSong));
        dispatch(SET_SONG_FROM(playingFrom));
        dispatch(SET_SONG_DRAWER_IS_PLAYLIST(false));
    }

    // like song
    const handleLike = async (songId) => {
        const token = window.localStorage.getItem('token');
        if (!user || !token || user?.is_guest) {
            dispatch(SET_SHOW_LOGIN(true));
            return toast.error("Login to like the song", { id: "likeSong" });
        }
        try {
            setLikedLoading(true);
            const isLiking = !liked;
            const endpoint = isLiking ? "/like" : "/dislike";
            const loadingMessage = isLiking ? "Adding to liked songs" : "Removing from liked songs";
            toast.loading(loadingMessage, { id: "likeSong" });
            await axios.post(endpoint,
                { song_id: songId },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            // Create updated song data
            const updates = {
                is_liked: isLiking,
                likes: userSong?.likes + (isLiking ? 1 : -1)
            };
            // Update currently playing song if it matches
            if (selectedSong?.song_id === songId) {
                dispatch(SET_SONG({ ...selectedSong, ...updates }));
            }
            // Update in all possible sources
            switch (playingFrom) {
                case "userSongs":
                    dispatch(SET_USERSONGS(
                        userSongs.map(song =>
                            song.song_id === songId ? { ...song, ...updates } : song
                        )
                    ));
                    break;
                case "discoverSongs":
                    dispatch(SET_DISCSONGS(
                        discoverSongs.map(song =>
                            song.song_id === songId ? { ...song, ...updates } : song
                        )
                    ));
                    break;
                case "playlists":
                    dispatch(SET_PLAYLIST_SONGS(
                        playlistSongs.map(song =>
                            song.song_id === songId ? { ...song, ...updates } : song
                        )
                    ));
                    break;
            }
            // Always update likedSongs array
            if (isLiking) {
                dispatch(ADD_LIKESONG({ ...userSong, ...updates }));
            } else {
                dispatch(REMOVE_LIKESONG(songId));
            }
            // Update local state
            setLikedLoading(false);
            setLiked(isLiking);
            setUserSong(prev => ({ ...prev, ...updates }));
            toast.success(isLiking ? "Like Added" : "Like Removed", { id: "likeSong" });
        } catch (error) {
            console.log(error);
            setLikedLoading(false);
            toast.error(error.response.data.detail || "Error Occurred", { id: "likeSong" });
        }
    };

    const handlePublishSpotify = async (song) => {
        const token = localStorage.getItem('token');
        if (!user || !token || user?.is_guest) {
            dispatch(SET_SHOW_LOGIN(true));
            return toast.error("Login to publish to Spotify", { id: "publishSpotify" });
        }
        if (user?.plan === 0 || user?.plan === 2 || user?.plan === 1) {
            let planName = '';
            if (user?.plan === 0) {
                planName = 'Free';
            } else if (user?.plan === 1) {
                planName = 'Premium';
            } else if (user?.plan === 2) {
                planName = 'Gold';
            }
            return toast.error(`Please upgrade from ${planName} to Platinum to publish songs on Spotify`, { id: "publishSpotify" });
        }
        try {
            const response = await axios.get(
                'https://engine.soundofmeme.com/song/distribute/',
                {
                    params: { song_id: song?.song_id },
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            if (response.data.status === 'Uploaded') {
                toast.success("Song Uploaded Published to Spotify", { id: "publishSpotify" });
            } else {
                toast.success('Song Publishing to Spotify', { id: "publishSpotify" });
            }
            // setUserSong(prev => ({ ...prev, spotify_status: response.data.status, spotify_link: response.data.link }));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setUserSong(song);
    }, [song])

    const handleWalletConnect = () => {
        if (!user || user?.is_guest) {
            dispatch(SET_SHOW_LOGIN(true));
            return toast.error("Login to launch token", { id: "walletConnect" });
        }
        dispatch(SET_LAUNCHTOKENSONG(userSong));
        dispatch(SET_WALLETBTN(true));
    }

     // check if the song is older than 24 hours
     const backendTime = new Date(userSong.date_time + (userSong.date_time.endsWith('Z') ? '' : 'Z')); // Force UTC
     const currentUTCTime = new Date(); // JavaScript Date() is always in UTC
     const diffMs = currentUTCTime - backendTime;
     const diffHours = (24 * 60 * 60 * 1000) - diffMs;
     const isDisabled = diffMs < 24 * 60 * 60 * 1000;

    return (
        <div className={selectedSong?.song_id === userSong?.song_id ? " bg-green-400/10 dark:bg-pink-400/10 " : " hover:bg-slate-900 dark:hover:bg-pink-300/40 "} >
            <div className={"flex gap-2 py-1 w-full px-4"}>
                <div className={" flex-shrink-0 w-full max-w-[80px] aspect-square overflow-hidden rounded-lg relative select-none border border-slate-800 dark:border-pink-700 " + (selectedSong?.song_id === song?.song_id && playing ? " animate-border " : "")} >
                    {displayImage ? <LazyLoadImage className="w-full h-full object-cover select-none  " src={displayImage} alt='songCover' effect="blur" wrapperClassName="w-full h-full absolute bottom-0 left-0" /> : <img className="w-full h-full object-cover select-none aspect-square p-4" src={frogImg} alt='songCover' />}
                    <div className={"absolute inset-0 w-full  flex items-center justify-center   transition-opacity duration-200" + (selectedSong?.song_id === song?.song_id && playing ? " opacity-100  bg-black/60 backdrop-blur " : selectedSong?.song_id === userSong?.song_id ? " opacity-100 bg-black/50 backdrop-blur " : " opacity-0 hover:opacity-100 bg-black/50 ")} >
                        {(selectedSong?.song_id === userSong?.song_id && playLoading) ?
                            <div className="w-full flex items-center justify-center cursor-pointer" >
                                <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    strokeWidth={6}
                                    strokeWidthSecondary={6}
                                    color="#ffffff"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div> :
                            (selectedSong?.song_id === userSong?.song_id && playing) ?
                                <div onClick={handlePause} className="w-full flex items-center justify-center cursor-pointer" >
                                    <Rings
                                        visible={true}
                                        height="60"
                                        width="60"
                                        color="#ffffff"
                                        ariaLabel="rings-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    /></div>
                                : <div onClick={handlePlay} className="w-full h-full flex items-center justify-center cursor-pointer" ><Play size={30} /> </div>
                        }
                    </div>
                </div>
                <div className="flex justify-between flex-grow truncate gap-0 sm:gap-4 w-full" >
                    <div className="truncate sm:max-w-[250px] md:max-w-[220px] lg:max-w-xs xl:max-w-sm 2xl:max-w-full w-full flex-grow" >
                        <div className=" h-full cursor-pointer flex flex-col justify-between truncate flex-grow" >
                            <div className={" block text-slate-100 dark:text-slate-900 font-lato font-semibold truncate lg:text-lg xl:text-xl capitalize " + (userSong?.song_id === selectedSong?.song_id && " cursor-pointer text-green-400 ")} ><Link to={`/song/${userSong?.slug}`} state={{ playingFrom: "relatedSongs" }}>{userSong?.song_name || userSong?.slug}</Link></div>
                            <div className={" mt- select-none flex gap-2 text-xs font-semibold text-slate-300 dark:text-slate-700 font-comforta " + (userSong?.song_id === selectedSong?.song_id && " cursor-pointer ")} >
                                {userSong?.tags?.slice(0, 3)?.map((tag, index) => (
                                    <span key={index} className="capitalize cursor-default truncate" >{tag}</span>
                                ))}
                            </div>
                            <Link to={author ? `/profile` : `/profile/${userSong?.username}`} className="inline-flex items-center gap-1 truncate" >
                                {userSong?.profile_url ? <img src={userSong?.profile_url} alt="profile" className="w-5 h-5 rounded-full border border-green-400 dark:border-pink-400 flex-shrink-0" /> : <div className="w-5 h-5 rounded-full bg-green-400 dark:bg-pink-400 flex items-center justify-center border border-green-400 dark:border-pink-400 flex-shrink-0" >
                                    <span className="text-black capitalize text-xs font-potta" >{userSong?.username?.charAt(0)}</span>
                                </div>}
                                <p className="text-xs text-slate-300 dark:text-slate-700 hover:text-green-400 dark:hover:text-pink-800 font-comforta capitalize truncate " >{userSong?.username}</p>
                            </Link>
                        </div>
                    </div>
                    <div className=" flex-grow hidden sm:flex items-center justify-end gap-8 lg:gap-12 2xl:gap-16 3xl:gap-20 text-xs lg:text-sm" >
                        {/* RECREATE BUTTON */}
                        {(author || user?.username === userSong?.username) && <div className="relative group hidden lg:block">
                            {(userSong?.prompt?.type === "create" || userSong?.prompt?.type === "custom") && <button type="button" onClick={() => handleRecreate(userSong)} className="flex items-center text-xs gap-1 text-slate-300 hover:text-green-400 dark:hover:text-pink-800 dark:text-slate-900" ><RefreshCcw size={18} /></button>}
                            <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-500 text-xs text-slate-200 dark:text-slate-800 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                Recreate
                            </div>
                        </div>}
                        {(author || user?.username === userSong?.username) && <div className="relative group hidden sm:block">
                            <button type="button" onClick={() => handlePublishSpotify(userSong)} className={"flex items-center text-xs gap-1 " + (userSong?.spotify_link === null ? "text-slate-300 dark:text-pink-700 " : " text-green-400 dark:text-pink-400 ")} >
                                <SpotifyIcon size={18} />
                            </button>
                            <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-500 text-xs text-slate-200 dark:text-slate-800 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                {userSong?.spotify_link === null ? "Publish to Spotify" : "Published to Spotify"}
                            </div>
                        </div>}
                        <div className="flex items-center text-xs gap-1 text-slate-300 dark:text-slate-900" ><Clock className="text-slate-400 dark:text-slate-900" size={18} /><span>{Math.floor(userSong?.duration / 60)}:{String(Math.floor(userSong?.duration % 60)).padStart(2, '0')}</span></div>
                        <div className="relative group">
                            <button type="button" disabled={likedLoading} onClick={() => handleLike(userSong?.song_id)} className={"disabled:opacity-50 flex gap-1 items-center cursor-pointer hover:text-green-400 dark:hover:text-pink-700 dark:text-pink-600" + (userSong?.is_liked ? " text-green-400 dark:text-pink-400" : " text-slate-300 dark:text-pink-600")}>
                                {userSong?.is_liked ? <Heart className="fill-green-400" size={18} /> : <Heart size={18} />}
                                <span className=" hover:text-green-400 dark:hover:text-pink-500 text-xs">{userSong?.likes}</span>
                            </button>
                            <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-500 text-xs text-slate-200 dark:text-slate-800 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                {userSong?.is_liked ? 'Unlike' : 'Like'}
                            </div>
                        </div>
                        {/* CHECKOUT BUTTON FOR TOKEN */}
                        {userSong?.token &&
                            <div className="relative group">
                                <a href={userSong?.token} target="_blank" rel="noopener noreferrer" className="relative flex items-center justify-center w-5 h-5 rounded-full border border-1 border-cyan-400 bg-gradient-to-br from-teal-400 to-cyan-500/20 dark:from-cyan-500/20 dark:to-blue-500/20 hover:bg-gradient-to-bl transition-all duration-300 transform shadow-lg shadow-amber-500/20 dark:shadow-red-500/30">
                                    <div className="flex items-center justify-center animate-pulse-slow">
                                        <LazyLoadImage effect="blur" src={PUMPFUN} alt="pumpfun" className="w-4 h-4" />
                                    </div>
                                </a>
                                <div className="absolute bottom-full -left-2 -translate-x-1/2 mb-0.5 px-2 py-1 bg-slate-800 dark:bg-pink-500 text-xs text-slate-200 dark:text-slate-800 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10">
                                    Buy Token
                                </div>
                            </div>
                        }
                        {/* WALLET CONNECT BUTTON - Only show if no token exists and user is author */}
                        {(!userSong?.token) && ((author || user?.username === userSong.username) ? (
                            // Author can always see and use the button
                            <div className="group relative">
                                <button onClick={handleWalletConnect} className="relative group flex items-center justify-center w-5 h-5 mr-1 rounded-full border border-1 border-amber-400 dark:border-cyan-400 bg-gradient-to-br from-red-800/70 to-amber-500/20 dark:from-cyan-500/20 dark:to-blue-500/20 hover:bg-gradient-to-bl transition-all duration-300 transform hover:scale-105 shadow-lg shadow-amber-500/20 dark:shadow-red-500/30" >
                                    <div className="text-amber-400 dark:text-cyan-300 group-hover:text-amber-200 dark:group-hover:text-red-200 transition-colors duration-300 animate-pulse-slow">
                                        <Coins size={12} />
                                    </div>
                                    <div className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                        <div className="absolute w-full h-full bg-gradient-to-r from-indigo-500/40 to-purple-400/30 dark:from-cyan-400/40 dark:to-blue-400/30 opacity-0 group-hover:opacity-100 blur-[10px] transition-opacity duration-300" />
                                    </div>
                                </button>
                                <span className="absolute invisible group-hover:visible bottom-full -left-2 -translate-x-1/2 whitespace-nowrap bg-black/90 text-white text-xs py-1 px-2 rounded z-10">
                                    Launch token
                                </span>
                            </div>
                        ) : (
                            // Non-author conditions based on creation time
                            userSong?.date_time ? (
                                isDisabled ? (
                                    <div className="group relative">
                                        <button disabled={true} className="relative flex items-center justify-center w-5 h-5 mr-1 rounded-full border border-1 border-amber-400/50 dark:border-cyan-400/50 bg-gradient-to-br from-red-800/30 to-amber-500/10 dark:from-cyan-500/10 dark:to-blue-500/10 opacity-50 cursor-not-allowed" >
                                            <div className="text-amber-400/70 dark:text-cyan-300/70">
                                                <Coins size={12} />
                                            </div>
                                        </button>
                                        <span className="absolute invisible bottom-full -left-20 lg:-left-10 group-hover:visible -translate-x-1/2 whitespace-nowrap bg-black/90 text-white text-xs py-1 px-2 rounded z-10">
                                            Check back in {Math.ceil(diffHours / (1000 * 60 * 60))} hours to launch token for this song
                                        </span>
                                    </div>
                                ) : (
                                    // Song is older than 24 hours - show enabled button with tooltip
                                    <div className="group relative">
                                        <button onClick={handleWalletConnect} className="relative flex items-center justify-center w-5 h-5 mr-1 rounded-full border border-1 border-amber-400 dark:border-cyan-400 bg-gradient-to-br from-red-800/70 to-amber-500/20 dark:from-cyan-500/20 dark:to-blue-500/20 hover:bg-gradient-to-bl transition-all duration-300 transform hover:scale-105 shadow-lg shadow-amber-500/20 dark:shadow-red-500/30" >
                                            <div className="text-amber-400 dark:text-cyan-300 group-hover:text-amber-200 dark:group-hover:text-red-200 transition-colors duration-300 animate-pulse-slow">
                                                <Coins size={12} />
                                            </div>
                                            <div className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                <div className="absolute w-full h-full bg-gradient-to-r from-indigo-500/40 to-purple-400/30 dark:from-cyan-400/40 dark:to-blue-400/30 opacity-0 group-hover:opacity-100 blur-[10px] transition-opacity duration-300" />
                                            </div>
                                        </button>
                                        <span className="absolute invisible group-hover:visible bottom-full -left-2 -translate-x-1/2 whitespace-nowrap bg-black/90 text-white text-xs py-1 px-2 rounded z-10">
                                            Launch token
                                        </span>
                                    </div>
                                )
                            ) : (
                                // Adding this fallback for if no date_time available, show enabled button with tooltip
                                <div className="group relative">
                                    <button onClick={handleWalletConnect} className="relative flex items-center justify-center w-5 h-5 mr-1 rounded-full border border-1 border-amber-400 dark:border-cyan-400 bg-gradient-to-br from-red-800/70 to-amber-500/20 dark:from-cyan-500/20 dark:to-blue-500/20 hover:bg-gradient-to-bl transition-all duration-300 transform hover:scale-105 shadow-lg shadow-amber-500/20 dark:shadow-red-500/30" >
                                        <div className="text-amber-400 dark:text-cyan-300 group-hover:text-amber-200 dark:group-hover:text-red-200 transition-colors duration-300 animate-pulse-slow">
                                            <Coins size={12} />
                                        </div>
                                        <div className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                            <div className="absolute w-full h-full bg-gradient-to-r from-indigo-500/40 to-purple-400/30 dark:from-cyan-400/40 dark:to-blue-400/30 opacity-0 group-hover:opacity-100 blur-[10px] transition-opacity duration-300" />
                                        </div>
                                    </button>
                                    <span className="absolute invisible bottom-full -left-2 group-hover:visible -translate-x-1/2 whitespace-nowrap bg-black/90 text-white text-xs py-1 px-2 rounded z-10">
                                        Launch token
                                    </span>
                                </div>
                            )
                        ))}

                       
                        <div className="relative group lg:block hidden ">
                            <button type="button" onClick={(e) => {
                                e.stopPropagation()
                                handleShare(userSong)
                            }} className="flex items-center text-slate-300 dark:text-slate-900 hover:text-green-400 dark:hover:text-pink-700 text-xs cursor-pointer" >
                                <Share2 size={18} className="cursor-pointer flex-shrink-0" />
                            </button>
                            <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-500 text-xs text-slate-200 dark:text-slate-800 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                Share
                            </div>
                        </div>
                        <div className="relative group hidden lg:block">
                            <button type="button" disabled={downloadLoading} onClick={(e) => { e.stopPropagation(); downloadSong(userSong) }} className=" disabled:opacity-50  text-slate-300 dark:text-slate-900 hover:text-green-400 dark:hover:text-pink-500 cursor-pointer flex-shrink-0" >
                                <Download size={18} />
                            </button>
                            <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-500 text-xs text-slate-200 dark:text-slate-800 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                Download
                            </div>
                        </div>
                        {!author && <div className={"relative group hidden xl:block"}>
                            <button type="button" onClick={AddToPlaylist} className="flex items-center text-slate-300 dark:text-slate-900 hover:text-green-400 dark:hover:text-pink-500 text-xs cursor-pointer" >
                                <FolderPlus size={18} className="cursor-pointer flex-shrink-0" />
                            </button>
                            <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-500 text-xs text-slate-200 dark:text-slate-800 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                Add to Playlist
                            </div>
                        </div>}
                        {author && <div className="relative group hidden xl:block">
                            <button type="button" onClick={handleAddToLeaderboard} className="flex items-center text-slate-300 dark:text-slate-900 hover:text-green-400 dark:hover:text-pink-500 text-xs cursor-pointer" >
                                <Leaderboard size={20} className="cursor-pointer flex-shrink-0" />
                            </button>
                            <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-500 text-xs text-slate-200 dark:text-slate-800 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                Add to Leaderboard
                            </div>
                        </div>}
                        <div className="" >
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <div className="cursor-pointer text-slate-300 dark:text-slate-900" >
                                        <MoreHorizontal size={20} />
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="bg-slate-800/90 dark:bg-gradient-to-b dark:from-pink-200 dark:to-pink-300 backdrop-blur w-auto border-slate-950 dark:border-pink-500 text-slate-200 mr-4 mt-2 rounded-lg">
                                    <DropdownMenuGroup>
                                        <DropdownMenuItem onClick={() => handleShare(userSong)} className=" cursor-pointer p-1.5 focus:text-black text-amber-400 dark:text-pink-700 focus:bg-amber-400 dark:focus:bg-pink-700 flex items-center gap-2 focus:outline-none rounded">
                                            <Share2 size={16} />
                                            <span>Share</span>
                                        </DropdownMenuItem>
                                        {(author || user?.username === userSong?.username) && <DropdownMenuItem disabled={loading} onClick={handlePublish} className="cursor-pointer p-1.5 focus:text-black text-blue-400 dark:text-blue-700 focus:bg-blue-400 dark:hover:bg-blue-500/40 flex items-center gap-2 focus:outline-none rounded">
                                            {!userSong?.publish ? <Globe size={16} /> : <GlobeLock size={16} />}
                                            <span>{!userSong?.publish ? "Publish" : "Unpublish"}</span>
                                        </DropdownMenuItem>}
                                        {(userSong?.prompt?.type === "create" || userSong?.prompt?.type === "custom") && <DropdownMenuItem onClick={() => handleRecreate(userSong)} className="  cursor-pointer p-1.5 focus:text-black text-violet-400 dark:text-violet-700 focus:bg-violet-400 dark:hover:bg-violet-500/40 flex items-center gap-2 focus:outline-none rounded">
                                            <RefreshCcw size={16} />
                                            <span>Recreate</span>
                                        </DropdownMenuItem>}
                                        <DropdownMenuItem onClick={handleExtend} className="cursor-pointer p-1.5 focus:text-black text-green-400 dark:text-green-700 focus:bg-green-400 dark:hover:bg-green-500/40 flex items-center gap-2 focus:outline-none rounded">
                                            <UnfoldHorizontal size={16} />
                                            <span>Extend</span>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={handleClone} className="cursor-pointer p-1.5 focus:text-black text-cyan-400 dark:text-cyan-700 focus:bg-cyan-400 dark:hover:bg-cyan-500/40 flex items-center gap-2 focus:outline-none rounded">
                                            <MusicalNotes size={16} />
                                            <span>Clone</span>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={() => downloadSong(song)} className=" xl:hidden cursor-pointer p-1.5 focus:text-black text-cyan-400 dark:text-cyan-700 focus:bg-cyan-400 dark:hover:bg-cyan-500/40 flex items-center gap-2 focus:outline-none rounded">
                                            <Download size={16} />
                                            <span>Downloadd</span>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={AddToPlaylist} className={"cursor-pointer p-1.5 focus:text-black text-fuchsia-400 dark:text-fuchsia-700 focus:bg-fuchsia-400  dark:hover:bg-fuchsia-500/40 flex items-center gap-2 focus:outline-none rounded" + (author && user?.username === userSong?.username ? " flex " : "")}>
                                            <FolderPlus size={16} />
                                            <span>Add to Playlist</span>
                                        </DropdownMenuItem>
                                        {(author || user?.username === userSong?.username) && <DropdownMenuItem onClick={handleAddToLeaderboard} className={" cursor-pointer p-1.5 focus:text-black text-emerald-400 dark:text-emerald-700 focus:bg-emerald-400  dark:hover:bg-emerald-500/40 flex items-center gap-2 focus:outline-none rounded" + (!author && user?.username === userSong?.username ? " flex " : " xl:hidden flex")}>
                                            <Leaderboard size={16} />
                                            <span>Add to Leaderboard</span>
                                        </DropdownMenuItem>}
                                        {author && <DropdownMenuItem onClick={handleOpenDeleteDialog} className=" cursor-pointer p-1.5 focus:text-black text-red-500 dark:text-red-700 focus:bg-red-500  dark:hover:bg-red-500/40 flex items-center gap-2 focus:outline-none rounded">
                                            <Trash2 className="fill-red-600" size={16} />
                                            <span>Delete</span>
                                        </DropdownMenuItem>}
                                    </DropdownMenuGroup>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sm:hidden flex items-center text-xs justify-between gap-2 px-4 mt-2 border-b border-slate-800 dark:border-pink-300/20 pb-2" >
                {/* CHECKOUT BUTTON FOR TOKEN */}
                {(userSong?.token) &&
                    <div className="relative group">
                        <a href={userSong?.token} target="_blank" rel="noopener noreferrer" className="relative flex items-center justify-center w-7 h-7 rounded-full border border-1 border-cyan-400 bg-gradient-to-br from-teal-400 to-cyan-500/20 dark:from-cyan-500/20 dark:to-blue-500/20 hover:bg-gradient-to-bl transition-all duration-300 transform shadow-lg shadow-amber-500/20 dark:shadow-red-500/30">
                            <div className="flex items-center justify-center animate-pulse-slow">
                                <LazyLoadImage effect="blur" src={PUMPFUN} alt="pumpfun" className="w-5 h-5" />
                            </div>
                        </a>
                        <div className="absolute bottom-full left-10 -translate-x-1/2 mb-0.5 px-2 py-1 bg-slate-800 dark:bg-pink-500 text-xs text-slate-200 dark:text-slate-800 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10">
                            Buy Token
                        </div>
                    </div>
                }
                {/* WALLET CONNECT BUTTON - Only show if no token exists (Mobile) */}
                {(!userSong?.token) && ((author || user?.username === userSong.username) ? (
                    // Author can always see and use the button
                    <div className="group relative">
                        <button onClick={handleWalletConnect} className="relative group flex items-center justify-center w-6 h-6 mr-1 rounded-full border border-1 border-amber-400 dark:border-cyan-400 bg-gradient-to-br from-red-800/70 to-amber-500/20 dark:from-cyan-500/20 dark:to-blue-500/20 hover:bg-gradient-to-bl transition-all duration-300 transform hover:scale-105 shadow-lg shadow-amber-500/20 dark:shadow-red-500/30" >
                            <div className="text-amber-400 dark:text-cyan-300 group-hover:text-amber-200 dark:group-hover:text-red-200 transition-colors duration-300 animate-pulse-slow">
                                <Coins size={14} />
                            </div>
                            <div className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                <div className="absolute w-full h-full bg-gradient-to-r from-indigo-500/40 to-purple-400/30 dark:from-cyan-400/40 dark:to-blue-400/30 opacity-0 group-hover:opacity-100 blur-[10px] transition-opacity duration-300" />
                            </div>
                        </button>
                        <span className="absolute invisible group-hover:visible bottom-full left-10 -translate-x-1/2 whitespace-nowrap bg-black/90 text-white text-xs py-1 px-2 rounded z-10">
                            Launch token
                        </span>
                    </div>
                ) : (
                    // Non-author conditions based on creation time
                    userSong?.date_time ? (
                        isDisabled ? (
                            <div className="group relative">
                                <button disabled={true} className="relative flex items-center justify-center w-6 h-6 mr-1 rounded-full border border-1 border-amber-400/50 dark:border-cyan-400/50 bg-gradient-to-br from-red-800/30 to-amber-500/10 dark:from-cyan-500/10 dark:to-blue-500/10 opacity-50 cursor-not-allowed" >
                                    <div className="text-amber-400/70 dark:text-cyan-300/70">
                                        <Coins size={14} />
                                    </div>
                                </button>
                                <span className="absolute invisible bottom-full left-32 group-hover:visible -translate-x-1/2 whitespace-nowrap bg-black/90 text-white text-xs py-1 px-2 rounded z-10">
                                    Check back in {Math.ceil(diffHours / (1000 * 60 * 60))} hours to launch token for this song
                                </span>
                            </div>
                        ) : (
                            // Song is older than 24 hours - show enabled button with tooltip
                            <div className="group relative">
                                <button onClick={handleWalletConnect} className="relative flex items-center justify-center w-6 h-6 mr-1 rounded-full border border-1 border-amber-400 dark:border-cyan-400 bg-gradient-to-br from-red-800/70 to-amber-500/20 dark:from-cyan-500/20 dark:to-blue-500/20 hover:bg-gradient-to-bl transition-all duration-300 transform hover:scale-105 shadow-lg shadow-amber-500/20 dark:shadow-red-500/30" >
                                    <div className="text-amber-400 dark:text-cyan-300 group-hover:text-amber-200 dark:group-hover:text-red-200 transition-colors duration-300 animate-pulse-slow">
                                        <Coins size={14} />
                                    </div>
                                    <div className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                        <div className="absolute w-full h-full bg-gradient-to-r from-indigo-500/40 to-purple-400/30 dark:from-cyan-400/40 dark:to-blue-400/30 opacity-0 group-hover:opacity-100 blur-[10px] transition-opacity duration-300" />
                                    </div>
                                </button>
                                <span className="absolute invisible group-hover:visible bottom-full left-10 -translate-x-1/2 whitespace-nowrap bg-black/90 text-white text-xs py-1 px-2 rounded z-10">
                                    Launch token
                                </span>
                            </div>
                        )
                    ) : (
                        // Adding this fallback for if no date_time available, show enabled button with tooltip
                        <div className="group relative">
                            <button onClick={handleWalletConnect} className="relative flex items-center justify-center w-6 h-6 mr-1 rounded-full border border-1 border-amber-400 dark:border-cyan-400 bg-gradient-to-br from-red-800/70 to-amber-500/20 dark:from-cyan-500/20 dark:to-blue-500/20 hover:bg-gradient-to-bl transition-all duration-300 transform hover:scale-105 shadow-lg shadow-amber-500/20 dark:shadow-red-500/30" >
                                <div className="text-amber-400 dark:text-cyan-300 group-hover:text-amber-200 dark:group-hover:text-red-200 transition-colors duration-300 animate-pulse-slow">
                                    <Coins size={14} />
                                </div>
                                <div className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                    <div className="absolute w-full h-full bg-gradient-to-r from-indigo-500/40 to-purple-400/30 dark:from-cyan-400/40 dark:to-blue-400/30 opacity-0 group-hover:opacity-100 blur-[10px] transition-opacity duration-300" />
                                </div>
                            </button>
                            <span className="absolute invisible -left-2 group-hover:visible -translate-x-1/2 -bottom-8 whitespace-nowrap bg-black/90 text-white text-xs py-1 px-2 rounded z-10">
                                Launch token
                            </span>
                        </div>
                    )
                ))}
                <div className="flex items-center gap-1 text-slate-300 dark:text-black" >
                    <Clock className="fill-slate-400 text-black" size={20} />
                    <span>{Math.floor(userSong?.duration / 60)}:{String(Math.floor(userSong?.duration % 60)).padStart(2, '0')}</span>
                </div>
                <button type="button" disabled={likedLoading} onClick={() => handleLike(userSong?.song_id)} className={"disabled:opacity-50 flex gap-1 items-center cursor-pointer hover:text-green-400 dark:hover:text-pink-500 " + (userSong?.is_liked ? " text-green-400 dark:text-pink-500" : " text-slate-300 dark:text-pink-700")}>
                    {userSong?.is_liked ? <Heart className="fill-green-400" size={18} /> : <Heart size={18} />}
                    <span className="hover:text-green-400 dark:hover:text-pink-500 text-xs">{userSong?.likes}</span>
                </button>
                {(author || user?.username === userSong?.username) ? <button onClick={() => handlePublishSpotify(userSong)} className="flex items-center text-xs gap-1 text-slate-300 dark:text-black" ><SpotifyIcon size={18} /></button> : <button onClick={() => handleShare(userSong)} className="flex items-center text-xs gap-1 text-slate-300 dark:text-black" ><Share2 size={20} /></button>}
                <div className="flex items-center mr-1.5" >
                    <Dialog>
                        <DrawerTrigger asChild>
                            <button className="cursor-pointer" onClick={handleOpenSongDrawer}><MoreHorizontal size={22} className="text-slate-300 dark:text-black hover:text-green-400 dark:hover:text-pink-500" /></button>
                        </DrawerTrigger>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default SongCardListView

export const SongCardMyCreationSkeleton = ({ author }) => {
    return (
        <div>
            <div className="w-full flex items-center gap-2 animate-pulse py-1 px-4" >
                <div className="w-full max-w-[80px] aspect-square rounded bg-slate-800 dark:bg-pink-400/30" ></div>
                <div className="flex flex-col justify-between gap-2 h-full sm:max-w-[250px] md:max-w-[220px] lg:max-w-xs xl:max-w-sm 2xl:max-w-md 3xl:max-w-2xl flex-grow" >
                    <div className="h-4 rounded-full mt-1 max-w-sm w-4/5 bg-slate-800 dark:bg-pink-400/30" ></div>
                    <div className="flex items-center gap-2 mt-1" >
                        <div className="w-10 bg-slate-800 dark:bg-pink-400/30 rounded-full h-3" ></div>
                        <div className="w-10 bg-slate-800 dark:bg-pink-400/30 rounded-full h-3" ></div>
                        <div className="w-10 bg-slate-800 dark:bg-pink-400/30 rounded-full h-3" ></div>
                    </div>
                    <div className="flex items-center gap-1" >
                        <div className="w-5 h-5 bg-slate-800 dark:bg-pink-400/30 rounded-full" ></div>
                        <div className="w-24 h-3 bg-slate-800 dark:bg-pink-400/30 rounded-full" ></div>
                    </div>
                </div>
                <div className=" hidden sm:flex flex-grow justify-end gap-8 lg:gap-12 2xl:gap-16 3xl:gap-20 text-slate-600 dark:text-pink-400" >
                    {author && <RefreshCcw size={20} className="hidden lg:block" />}
                    <Clock size={20} className="mr-6 ml-2" />
                    <Heart size={20} className="mr-2 fill-slate-600" />
                    <div className="h-5 w-5 border border-1 border-slate-600 rounded-full flex items-center justify-center"><Coins size={14} className="opacity-30 dark:text-pink-400" /></div>
                    {!author && <Share2 size={20} className="" />}
                    {author && <SpotifyIcon size={20} className="hidden sm:block" />}
                    <Download size={20} className="hidden lg:block" />
                    {!author && <FolderPlus size={20} className="hidden xl:block opacity-40" />}
                    {author && <Leaderboard size={20} className="hidden xl:block" />}
                    <MoreHorizontal size={20} />
                </div>
            </div>
            <div className="mt-2 px-4 border-b text-slate-600 dark:text-pink-400 border-slate-800 dark:border-pink-400/30 pb-2 sm:hidden flex items-center justify-between" >
                <div className="h-5 w-5 mr-6 border border-1 border-slate-600 rounded-full flex items-center justify-center"><Coins size={14} className="opacity-30 dark:text-pink-400" /></div>
                <Clock size={20} className="mr-7 text-slate-800 dark:text-pink-400" />
                <Heart size={20} className="mr-3 text-slate-800 dark:text-pink-400" />
                {author && <SpotifyIcon size={20} className="mr-3 text-slate-800 dark:text-pink-400" />}
                {!author && <Share2 size={20} className="" />}
                <MoreHorizontal size={22} className="mr-1.5" />
            </div>
        </div>
    )
}