import { BrowserRouter, Routes, Route } from "react-router-dom"
import WenWhite from "./pages/wenWhite/WenWhite"
import { Toaster } from "react-hot-toast"
import Player from "./components/player/Player"
import Sidebar from "./components/sidebar/Sidebar"
import MyCreations from "./pages/myCreations/MyCreations"
import Discover from "./pages/discover/Discover"
import LikedSongs from "./pages/likedSongs/LikedSongs"
import axios from "axios"
import { useEffect, Suspense, lazy } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUserDetails, SET_MAINTENANCE_MODE } from "./redux/features/auth/authSlice"
import Create from "./pages/create/Create"
import Login from "./pages/login/Login"
import Search from "./pages/search/Search"
import Subscription from "./pages/subscription/Subscription"
import PaymentSuccess from "./pages/subscription/PaymentSuccess"
import PaymentFailure from "./pages/subscription/PaymentFailure"
import Profile from "./pages/profile/Profile"
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy"
import DiscoverRedirect from "./pages/discover/DiscoverRedirect"
import { analytics } from "./firebase"
import { logEvent } from "firebase/analytics"
import LiveSongPlayer from "./components/liveSongPlayer/LiveSongPlayer"
import PlaylistWrapper from "./pages/playlist/PlaylistWrapper"
import ChangePassword from "./pages/changepassword/ChangePassword"
import ForgetPassword from "./pages/forget/ForgetPassword"
import ResetPassword from "./pages/resetpassword/ResetPassword"
import RedirectToPlaylist from "./pages/playlist/RedirectToPlaylist"
import DeleteSongDialog from "./components/deleteCreatedSong.jsx/DeleteSongDialog "
import AiMusicMaker from "./pages/aimusicmaker/AiMusicMaker"
import LoginDialog from "./components/loginDialog/LoginDialog"
import ProfileDetail from "./pages/profileDetail/ProfileDetail"
import TermsCondition from "./pages/termsandconditions/TermsCondition"
import Artists from "./pages/artists/Artists"
import Genres from "./pages/genres/Genres"
import Affliliate from "./pages/affiliate/Affliliate"
import useClarity from "./customHook/useClarity"
import { getDeviceFingerprint } from "./utils/fingerprint"
import { ThemeProvider } from './components/ThemeProvider'
import GiftSuccess from "./pages/giftsubscription/GiftSuccess"
import RedeemSuccess from "./pages/giftsubscription/RedeemSuccess"
import GiftDialog from "./pages/giftsubscription/GiftDialog"
import GiftFailure from "./pages/giftsubscription/GiftFailure"
import { trackMetaCustomEvent } from "./utils/metaPixel"
import SongSearch from "./pages/discover/SongSearch"
import PlaylistSearch from "./pages/discover/PlaylistSearch"
import UserSearch from "./pages/discover/UserSearch"
import Feedback from "./pages/contact/Feedback"
import FeedbackDialog from "./pages/contact/FeedbackDialog"
import { trackLinkedInEvent } from "./utils/linkedinTag"
import WelcomeDialog from "./components/extradialogs/WelcomeDialog"
import GamifyDialog from "./components/extradialogs/GamifyDialog"
import MusicPlayer from "./components/musicPlayer/MusicPlayer"
import SongDetailSkeleton from "./pages/songdetail/SongDetailSkeleton"
import DashboardSkeleton from "./pages/advertisementsystem/DashboardSkeleton"
import DeletePlaylistDialog from "./pages/playlistview/DeletePlaylistDialog"
import AddSongDialog from "./pages/playlist/AddSongDialog"
import LeaderboardLoader from "./pages/leaderboard/LeaderBoardSkeleton"
import UserGifts from "./pages/giftsubscription/UserGifts"
import NotFound from "./pages/home/NotFound"


// Replace the direct import with lazy loading
const SongDrawerLazy = lazy(() => import('./components/drawer/SongDrawer'))
const ReferralDialogLazy = lazy(() => import('./pages/profile/ReferralDialog'))
const LaunchTokenDialogLazy = lazy(() => import('./components/songCard/LaunchTokenDialog'))
const HomeLazy = lazy(() => import('./pages/home/Home'));
const AddSongToLeaderboardLazy = lazy(() => import('./components/addSongToLeaderboard/AddSongToLeaderboard'))
const SongDetailLazy = lazy(() => import('./pages/songdetail/SongDetail'))
const AddToPlaylistDialogLazy = lazy(() => import('./components/songCard/AddToPlaylistDialog'))
const AdvertisementLazy = lazy(() => import('./pages/advertisement/Advertisement'))
const AdvertDashboardLazy = lazy(() => import('./pages/advertisementsystem/AdvertDashboard'))
const ContactLazy = lazy(() => import('./pages/contact/Contact'))
const LeaderboardLazy = lazy(() => import('./pages/leaderboard/Leaderboard'))
const MaintenanceLazy = lazy(() => import('./components/maintenanceScreen/MaintenanceScreen'))

function App() {
  // initiate clarity
  useClarity()
  axios.defaults.baseURL = "https://testapi.soundofmeme.com"
  const dispatch = useDispatch()
  const { user, maintenanceMode } = useSelector(state => state.auth)
  // const isOpenFeedbackDialog = useSelector((state) => state.extradialogs.isOpenFeedbackDialog);
  const MAINTENANCE_MODE = maintenanceMode || false;

  useEffect(() => {
    const token = window.localStorage.getItem('token')
    const guestToken = window.localStorage.getItem('guestToken')
    if (token) {
      dispatch(getUserDetails(token))
    } else if (guestToken) {
      dispatch(getUserDetails(guestToken))
    } else {
      fingerprint()
    }
    async function fingerprint() {
      try {
        const fingerprint = await getDeviceFingerprint()
        const { data } = await axios.post(`/guest`, { fingerprint })
        localStorage.setItem('guestToken', data?.access_token)
        dispatch(getUserDetails(data?.access_token))
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          dispatch(SET_MAINTENANCE_MODE(true))
        }
        console.log(error);
      }
    }
    // Log a custom event to Firebase Analytics
    logEvent(analytics, 'app_open');
    trackMetaCustomEvent('app_open');
    trackLinkedInEvent('app_open', {
      event: 'app_open',
    });
  }, [dispatch])

  // update affiliate code
  useEffect(() => {
    const affCode = localStorage.getItem('affCode')
    if (affCode && user) {
      const token = window.localStorage.getItem('token')
      try {
        const { data } = axios.post(`/affiliate/update-link-access?affiliate_code=${affCode}`, {}, { headers: { Authorization: `Bearer ${token}` } })
        console.log(data);
        localStorage.removeItem('affCode')
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.remove_affiliate_code) {
          localStorage.removeItem('affCode')
        }
      }
    }
  }, [user])

  useEffect(() => {
    // Dispatch custom event when React is mounted
    window.dispatchEvent(new Event('react-loaded'));
  }, []);

  return (

    <BrowserRouter>
      <ThemeProvider>
        {MAINTENANCE_MODE ? (
          <Suspense fallback={<div className="min-h-screen flex items-center justify-center">
            <div className="text-green-400">Loading...</div>
          </div>}>
            <MaintenanceLazy />
          </Suspense>
        ) : (
          <>
            <Player />
            <Routes>
              <Route path="/discover" element={<DiscoverRedirect />} />
              <Route path="/about" element={
                <Suspense fallback={
                  <div className="min-h-screen flex items-center justify-center">
                    <div className="text-green-400">Loading...</div>
                  </div>
                }>
                  <HomeLazy />
                </Suspense>
              } />
              <Route path="/paper" element={<WenWhite />} />

              <Route path="/creations" element={
                <Sidebar>
                  <MyCreations />
                </Sidebar>
              } />
              <Route path="/" element={
                <Sidebar>
                  <Discover />
                </Sidebar>
              } />
              <Route path="/liked" element={
                <Sidebar>
                  <LikedSongs />
                </Sidebar>
              } />
              <Route path="/create" element={
                <Sidebar>
                  <Create />
                </Sidebar>
              } />
              <Route path="/login" element={
                <Sidebar>
                  <Login />
                </Sidebar>
              } />
              <Route path="/affiliate" element={
                <Sidebar>
                  <Affliliate />
                </Sidebar>
              } />
              <Route path="/changepassword" element={
                <Sidebar>
                  <ChangePassword />
                </Sidebar>
              } />
              <Route path="/resetpassword" element={
                <Sidebar>
                  <ForgetPassword />
                </Sidebar>
              } />
              <Route path="/reset/:token" element={
                <Sidebar>
                  <ResetPassword />
                </Sidebar>
              } />
              <Route path="/search" element={
                <Sidebar>
                  <Search />
                </Sidebar>
              } />
              <Route path="/premium" element={
                <Sidebar>
                  <Subscription />
                </Sidebar>
              } />
              <Route path="/profile" element={
                <Sidebar>
                  <Profile />
                </Sidebar>
              } />
              <Route path="/leaderboard/*" element={
                <Sidebar>
                  <Suspense fallback={<LeaderboardLoader />}>
                    <LeaderboardLazy />
                  </Suspense>
                </Sidebar>
              } />
              <Route
                path="/playlist"
                element={
                  <Sidebar>
                    <PlaylistWrapper />
                  </Sidebar>
                }
              />
              <Route
                path="/playlistview/:playlistId"
                element={<RedirectToPlaylist />}
              />
              <Route path="/contact" element={
                <Sidebar>
                  <Suspense fallback={
                    <div className="min-h-screen flex items-center justify-center">
                      <div className="text-green-400">Loading...</div>
                    </div>
                  }>
                    <ContactLazy />
                  </Suspense>
                </Sidebar>
              } />
              <Route path="/feedback" element={
                <Sidebar>
                  <Feedback />
                </Sidebar>
              } />
              <Route path="/song/:slug" element={
                <Sidebar>
                  <Suspense fallback={<SongDetailSkeleton />}>
                    <SongDetailLazy />
                  </Suspense>
                </Sidebar>
              } />
              <Route path="/profile/:slug" element={
                <Sidebar>
                  <ProfileDetail />
                </Sidebar>
              } />
              <Route path="/advertisement" element={
                <Sidebar>
                  <Suspense fallback={<div className="w-full h-screen bg-slate-900"></div>}>
                    <AdvertisementLazy />
                  </Suspense>
                </Sidebar>
              } />
              <Route path="/advertisement-dashboard/*" element={
                <Sidebar>
                  <Suspense fallback={<DashboardSkeleton />}>
                    <AdvertDashboardLazy />
                  </Suspense>
                </Sidebar>
              } />
              <Route path="/artists" element={
                <Sidebar>
                  <Artists />
                </Sidebar>
              } />
              <Route path="/genres/:genreId" element={
                <Sidebar>
                  <Genres />
                </Sidebar>
              } />
              <Route path="/songs/search" element={
                <Sidebar>
                  <SongSearch />
                </Sidebar>
              } />
              <Route path="/playlists/search" element={
                <Sidebar>
                  <PlaylistSearch />
                </Sidebar>
              } />
              <Route path="/users/search" element={
                <Sidebar>
                  <UserSearch />
                </Sidebar>
              } />
              <Route path="/gift/success/:code?" element={
                <Sidebar>
                  <GiftSuccess />
                </Sidebar>
              } />
              <Route path="/gift/fail" element={
                <Sidebar>
                  <GiftFailure />
                </Sidebar>
              } />
              <Route path="/redeem/success/:code?" element={
                <Sidebar>
                  <RedeemSuccess />
                </Sidebar>
              } />
              <Route path="/user/gifts" element={
                <Sidebar>
                  <UserGifts />
                </Sidebar>
              } />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              {/* SEO ROUTES */}
              <Route path="/ai-music-maker" element={<AiMusicMaker />} />
              <Route path="/music-generator" element={<AiMusicMaker />} />
              <Route path="/ai-beat-maker" element={<AiMusicMaker />} />
              <Route path="/ai-music-generator-online" element={<AiMusicMaker />} />
              <Route path="/ai-music-generator-free-online" element={<AiMusicMaker />} />
              <Route path="/ai-song-generator" element={<AiMusicMaker />} />
              <Route path="/ai-song-generator-free" element={<AiMusicMaker />} />
              <Route path="/ai-music-generator" element={<AiMusicMaker />} />
              <Route path="/ai-music-generator-free" element={<AiMusicMaker />} />
              <Route path="/crypto-music" element={<AiMusicMaker />} />
              <Route path="/crypto-songs" element={<AiMusicMaker />} />
              <Route path="/ai-song-maker" element={<AiMusicMaker />} />
              <Route path="/music-ai-generator" element={<AiMusicMaker />} />
              <Route path="/free-ai-song-generator" element={<AiMusicMaker />} />
              <Route path="/free-ai-music-generator" element={<AiMusicMaker />} />
              <Route path="/ai-music-generator-free-no-sign-up" element={<AiMusicMaker />} />
              <Route path="/best-ai-music-generator" element={<AiMusicMaker />} />
              <Route path="/best-ai-song-generator" element={<AiMusicMaker />} />
              <Route path="/best-ai-song-generator-free" element={<AiMusicMaker />} />
              <Route path="/best-ai-music-generator-free" element={<AiMusicMaker />} />
              <Route path="/terms-and-conditions" element={<TermsCondition />} />
              <Route path="/premium/success/:token" element={
                <PaymentSuccess />
              } />
              <Route path="/premium/failure" element={
                <PaymentFailure />
              } />
              <Route path="*" element={<NotFound />} />

            </Routes>
            <Toaster
              toastOptions={{
                className: "toast"
              }}
            />
            {/* Feedback Button */}
            <FeedbackDialog />
            <div className="relative z-50">
              <GamifyDialog />
            </div>
            <MusicPlayer />
            <LiveSongPlayer />
            <Suspense fallback={<div className="w-full h-screen bg-slate-900"></div>}>
              <SongDrawerLazy />
            </Suspense>
            <Suspense fallback={<div className="w-full h-screen bg-slate-900"></div>}>
              <AddSongToLeaderboardLazy />
            </Suspense>
            <Suspense fallback={<div className="w-full h-screen bg-slate-900"></div>}>
              <AddToPlaylistDialogLazy />
            </Suspense>
            <DeleteSongDialog />
            <LoginDialog />
            <Suspense fallback={<div className="w-full h-screen bg-slate-900"></div>}>
              <ReferralDialogLazy />
            </Suspense>
            <Suspense fallback={<div className="w-full h-screen bg-slate-900"></div>}>
              <LaunchTokenDialogLazy />
            </Suspense>
            <DeletePlaylistDialog />
            <AddSongDialog />
            <GiftDialog />
            <WelcomeDialog />
            {/* <AffiliateDialog /> */}
            {/* <CreateFreeSongDialog /> */}
          </>
        )}
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
