import TopBar from '@/components/navbar/TopBar'
import BottomBar from '@/components/sidebar/BottomBar'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { SET_OTPCARD } from '@/redux/features/auth/authSlice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoginForm from './LoginForm'
import SignUpForm from './SignUpForm'
import OtpForm from './OtpForm'
import blueBird from "../../assets/blue bird.png";
import greenBird from "../../assets/greenbird.png";
import purpuleBird from "../../assets/purple-bird.png";
import { useSearchParams } from 'react-router-dom'

const Login = () => {

    const dispatch = useDispatch()
    const { song } = useSelector(state => state.music)
    const { otpCard } = useSelector(state => state.auth)
    const [searchParams] = useSearchParams()
    const refCode = searchParams.get('ref')

    useEffect(() => {
        const otpToken = sessionStorage.getItem('otpToken')
        if (otpToken) {
            dispatch(SET_OTPCARD(true))
        }
        if (refCode) {
            sessionStorage.setItem('refCode', refCode)
        }
    }, [dispatch, refCode])

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div className='relative min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 dark:bg-gradient-to-br dark:from-pink-100 dark:via-pink-200 dark:to-pink-100 overflow-hidden flex flex-col flex-grow w-full' >
            <TopBar heading={"Get Started"} />
            <div className={'md:pt-20 pt-20 flex-grow flex items-center justify-center relative z-50 ' + (song ? " md:pb-32 pb-36 " : " pb-40 sm:pb-20 ")} >
                <Tabs defaultValue="login" className="w-full p-4 max-w-lg  ">
                    <TabsList className="grid w-full grid-cols-2  text-slate-400 dark:text-slate-900 font-semibold bg-slate-900 dark:bg-pink-300 dark:border-pink-400 dark:hover:bg-pink-300/70 dark:rounded-lg">
                        <TabsTrigger className="data-[state=active]:bg-green-400 dark:data-[state=active]:bg-pink-400" value="login">Login</TabsTrigger>

                        <TabsTrigger className="data-[state=active]:bg-green-400 font-semibold dark:data-[state=active]:bg-pink-400" value="signup">SignUp</TabsTrigger>
                    </TabsList>
                    {!otpCard ? <LoginForm /> : <OtpForm tabName={"login"} />}
                    {!otpCard ? <SignUpForm /> : <OtpForm tabName={"signup"} />}
                </Tabs>
            </div>
            <BottomBar />
            <img src={blueBird} alt='bird' className='w-16 absolute top-[80%] right-[10%]' />
            <img src={greenBird} alt='bird' className='w-16 absolute left-[5%] top-[85%] lg:top-1/2' />
            <img src={purpuleBird} alt='bird' className='w-16 absolute right-[20%] top-[10%]' />
        </div>
    )
}

export default Login