import { SET_ACHIEVEMENTS, SET_CLAIMABLE_ACHIEVEMENT, SET_SHOW_CLAIM_DIALOG } from '@/redux/features/theme/extradialogs';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogOverlay, DialogTitle } from '../ui/dialog'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import LOGINSTREAK from '../../assets/login-streak-achievement.webp';
import SONGSMADE from '../../assets/song-created-achievement.webp';
import SONGSVIEWS from '../../assets/total-view-achievement.webp';
import SPOTIFYDISTRIBUTIONS from '../../assets/Spotify-distributions-achievement.webp';
import TOTALLIKES from '../../assets/song-liked-achievement.webp';
import TOKENSLAUNCHED from '../../assets/song-liked-achievement.webp';
import SINGLESSONGLIKES from '../../assets/singlesonglikes.webp';
import SINGLESSONGVIEWS from '../../assets/singlesongviews.webp';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { X } from 'lucide-react';
import Lottie from 'lottie-react';
import celebrationAnimation from '../../assets/success.json';
import SUCCESSSOUND from '../../assets/success.mp3';

const AchievementCard = ({ achievement, setShowAnimation }) => {
  const dispatch = useDispatch();
  const achievements = useSelector(state => state.extradialogs.achievements);

  const getClaimableMilestone = () => {
    const claimableMilestone = achievement.milestones.find(m => m.reached && !m.claimed);
    return claimableMilestone;
  };
  const claimableMilestone = getClaimableMilestone();

  const handleClaimReward = async (achievementType) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/achievements/claim/${achievementType}`, null, {
        headers: { 'accept': 'application/json', 'Authorization': `Bearer ${token}` }
      });
      if (response.data.success) {
        // Play sound
        const audio = new Audio(SUCCESSSOUND);
        audio.play();
        // Show animation
        setShowAnimation(true);
        setTimeout(() => {
          setShowAnimation(false);
        }, 2500);
        // Update claim status for this specific milestone
        const updatedAchievements = achievements.map(ach => {
          if (ach.achievement_type === achievement.achievement_type) {
            // Create a copy of the achievement as a new object for updating the claim
            const updatedAchievement = { ...ach };
            // Update only the milestone that was claimed
            updatedAchievement.milestones = ach.milestones.map(m => {
              if (m.milestone === claimableMilestone.milestone) {
                return { ...m, claimed: true };
              }
              return m;
            });
            // Check if there are still unclaimed milestones that have been reached
            const hasClaimableMilestones = updatedAchievement.milestones.some(m =>
              m.reached && !m.claimed
            );
            // Update can_claim flag
            updatedAchievement.can_claim = hasClaimableMilestones;
            return updatedAchievement;
          }
          return ach;
        });
        dispatch(SET_ACHIEVEMENTS(updatedAchievements));
        dispatch(SET_CLAIMABLE_ACHIEVEMENT(updatedAchievements));
        if (updatedAchievements.can_claim === true) {
          dispatch(SET_SHOW_CLAIM_DIALOG(true));
        }
        toast.success(`${claimableMilestone.description} claimed successfully`);
      }
    } catch (error) {
      console.error('Error claiming milestone:', error);
    }
  };

  const getAchievementImage = (achievementType) => {
    switch (achievementType) {
      case 'login_days':
        return LOGINSTREAK;
      case 'songs_created':
        return SONGSMADE;
      case 'song_views':
        return SONGSVIEWS;
      case 'spotify_distributions':
        return SPOTIFYDISTRIBUTIONS;
      case 'tokens_launched':
        return TOKENSLAUNCHED;
      case 'total_likes':
        return TOTALLIKES;
      case 'max_song_likes':
        return SINGLESSONGLIKES;
      case 'max_song_views':
        return SINGLESSONGVIEWS;
      default:
        return null;
    }
  }

  return (
    <div className=" w-[300px] bg-gradient-to-br from-green-950/50  to-slate-950/90 rounded-xl border border-green-900/20 shadow-md shadow-green-900/10 md:p-4 p-2 transition-all hover:shadow-lg hover:shadow-green-900/20 hover:border-green-900/30 h-full flex flex-col">
      <div className="flex items-center justify-center gap-3 mb-3">
        <h3 className="text-xl font-bold text-white capitalize">{achievement.description}</h3>
      </div>
      {/* Next Milestone */}
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-2 mt-4 mb-3">
          <img src={getAchievementImage(achievement.achievement_type)} alt={achievement.description} className="w-36" />
          {claimableMilestone ? (
            <p className="text-white font-medium">
              {claimableMilestone.description}
            </p>
          ) : (
            <p className="text-white font-medium">No claimable milestone available</p>
          )}
        </div>
        <div className="flex items-center gap-1 text-slate-300 text-sm capitalize">
          <span>Reward:</span>
          <span>{claimableMilestone ? claimableMilestone.reward_value : 0} {claimableMilestone ? claimableMilestone.reward_type : ''}</span>
        </div>
      </div>
      {achievement.can_claim && (
        <button onClick={() => handleClaimReward(achievement.achievement_type, claimableMilestone.id)} className="mt-4 w-full py-2 bg-gradient-to-r from-green-600 to-teal-500 rounded-md text-black font-medium hover:from-green-500 hover:to-teal-400 transition-all">
          Claim Reward
        </button>
      )}
    </div>
  );
};

const ClaimDialog = ({ achievements, setActiveTab }) => {
  const dispatch = useDispatch();
  const showClaimDialog = useSelector(state => state.extradialogs.showClaimDialog);
  const [showAnimation, setShowAnimation] = useState(false);

  const handleClose = () => {
    dispatch(SET_SHOW_CLAIM_DIALOG(false));
  };
  const claimableAchievements = achievements.filter(achievement => achievement.can_claim);

  useEffect(() => {
    if (claimableAchievements.length === 0) {
      dispatch(SET_SHOW_CLAIM_DIALOG(false));
      setActiveTab("running");
    }
  }, [claimableAchievements, dispatch, setActiveTab]);

  return (
    <Dialog open={showClaimDialog} onOpenChange={handleClose}>
      <DialogOverlay className="z-[9999] backdrop-blur-2xl">
        <DialogContent className="w-full z-[99999] max-w-[95%] xs:max-w-[90%] lg:max-w-[1080px] mx-auto h-[80vh] overflow-auto scrollbar-hide bg-slate-950 border border-1 border-green-900/80 !rounded-3xl shadow-md shadow-green-900/10">
          <X className="absolute top-2 right-2 text-slate-300 hover:text-slate-100 transition-all bg-slate-800/50 rounded-full p-1" onClick={handleClose} />
          <DialogHeader className="relative flex flex-col justify-center items-center w-full">
            <DialogTitle className="text-md sm:text-xl font-potta md:text-3xl lg:text-4xl font-normal tracking-wide text-white">
              Your Unclaimed Achievements
            </DialogTitle>
            <DialogDescription className="text-sm sm:text-base md:text-lg font-medium text-slate-300">
              Track your progress and earn rewards
            </DialogDescription>
          </DialogHeader>
          {/* Lottie Animation */}
          {showAnimation && (
            <div className="absolute inset-0 bg-slate-900/80 flex items-center justify-center z-50 pointer-events-none">
              <Lottie animationData={celebrationAnimation} loop={false} autoplay style={{ width: 250, height: 250 }} />
            </div>
          )}
          {/* Achievement Cards */}
          <div className={`grid grid-cols-1 ${claimableAchievements.length === 1 ? 'lg:grid-cols-1' : claimableAchievements.length === 2 ? 'lg:grid-cols-2' : 'lg:grid-cols-3'} justify-items-center items-center gap-4 mt-4 w-fit mx-auto`}>
            {claimableAchievements.map((achievement, index) => (
              <AchievementCard key={index} achievement={achievement} showAnimation={showAnimation} setShowAnimation={setShowAnimation} />
            ))}
          </div>
        </DialogContent>
      </DialogOverlay>
    </Dialog>
  );
};

export default ClaimDialog;
