import { lazy, Suspense } from 'react';
import { SongCardSkeleton } from '../createSong/CreateSongLoading';

// Lazy load the SongCard component
const SongCardLazy = lazy(() => import('./SongCard'));

// Wrapper component that provides Suspense boundary
const SongCard = (props) => {
    return (
        <Suspense fallback={<SongCardSkeleton />}>
            <SongCardLazy {...props} />
        </Suspense>
    );
};

export default SongCard; 