
const ArtistCardSkeleton = () => {
    return (
        <div className="flex flex-col items-center group pt-4 transition-all duration-900 animate-pulse">
            {/* Circular Image Container */}
            <div className="relative mb-4">
                {/* Main Circle */}
                <div className="w-full h-24 sm:h-28 2xl:h-32 aspect-square rounded-full overflow-hidden border-1 border-slate-700/50 bg-slate-700/50 dark:bg-pink-300/50 dark:border-pink-300/50" />

                {/* Stats Badge */}
                <div className="absolute -bottom-2 left-1/2 -translate-x-1/2 bg-slate-800/90 dark:bg-pink-300/90 backdrop-blur px-3 py-1 rounded-full border border-slate-700/50 dark:border-pink-300/50 flex items-center gap-3 text-xs shadow-lg">
                    <div className="flex items-center gap-1">
                        <div className="w-3 h-3 bg-slate-600/50 dark:bg-pink-300/50 rounded-full" />
                        <div className="w-8 h-3 bg-slate-600/50 dark:bg-pink-300/50 rounded" />
                    </div>
                    <div className="flex items-center gap-1">
                        <div className="w-3 h-3 bg-slate-600/50 dark:bg-pink-300/50 rounded-full" />
                        <div className="w-8 h-3 bg-slate-600/50 dark:bg-pink-300/50 rounded" />
                    </div>
                </div>
            </div>

            <div className="w-24 h-4 bg-slate-700/50 dark:bg-pink-300/50 rounded my-1 " />

            <div className="flex items-center gap-1 my-1">
                <div className="w-3 h-3 bg-slate-600/50 dark:bg-pink-300/50 rounded-full" />
                <div className="w-16 h-3 bg-slate-600/50 dark:bg-pink-300/50 rounded" />
            </div>
        </div>
    )
}

export default ArtistCardSkeleton;
