import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    song: null,
    songsList: {}, // Add this to store all songs' states
    songFrom: null,
    isPlaylist: false
}

const songDrawerSlice = createSlice({
    name: 'songDrawer',
    initialState,
    reducers: {
        SET_SONG_DRAWER_OPEN: (state, action) => {
            state.isOpen = action.payload;
        },
        RESET_SONG_DRAWER: (state) => {
            state.isOpen = false;
            state.song = null;
        },
        SET_SONG_DRAWER_SONG: (state, action) => {
            state.song = action.payload;
            // Also update in songsList
            if (action.payload) {
                state.songsList[action.payload.song_id] = action.payload;
            }
        },
        UPDATE_SONG_PRIVACY: (state, action) => {
            const { songId, isPublic } = action.payload;
            // Update in both places
            if (state.song && state.song.song_id === songId) {
                state.song.publish = isPublic;
            }
            state.songsList[songId] = {
                ...state.songsList[songId],
                publish: isPublic
            };
        },
        SET_SONG_FROM: (state, action) => {
            state.songFrom = action.payload;
        },
        SET_SONG_DRAWER_IS_PLAYLIST: (state, action) => {
            state.isPlaylist = action.payload;
        }
    }
});

export const {
    SET_SONG_DRAWER_OPEN,
    RESET_SONG_DRAWER,
    SET_SONG_DRAWER_SONG,
    UPDATE_SONG_PRIVACY,
    SET_SONG_FROM,
    SET_SONG_DRAWER_IS_PLAYLIST
} = songDrawerSlice.actions;

export default songDrawerSlice.reducer;