import { useSelector } from "react-redux"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card"
import { Button } from "../ui/button"
import { AudioLines, Coins, Heart, Play, Share2, MoreVertical, Circle } from "lucide-react"

export const SongCardSkeleton = () => {
    return (
        <div className="animate-pulse border border-slate-800 dark:border-pink-800 rounded-xl overflow-hidden" >
            <div className={"w-full overflow-hidden p-4 bg-slate-800 dark:bg-pink-400/80"} style={{ aspectRatio: "10/9" }} >
            </div>
            <div className="p-2" >
                <div className="flex items-center justify-between" >
                    <div className="flex-grow" >
                        <p className="mt-1 h-4 rounded-full mb-2 w-4/5 bg-slate-800 dark:bg-pink-400/30"></p>
                        <div className="flex gap-2 text-xs mt-2 font-semibold text-slate-400 dark:text-pink-300/50 font-comforta" >
                            <span className="h-3 w-8 bg-slate-800/80 dark:bg-pink-400/20 rounded-full"></span>
                            <span className="h-3 w-10 bg-slate-800/80 dark:bg-pink-400/20 rounded-full"></span>
                            <span className="h-3 w-6 bg-slate-800/80 dark:bg-pink-400/20 rounded-full"></span>
                        </div>
                    </div>
                    <div className="w-9 h-9 bg-slate-800 dark:bg-pink-400/30 rounded-full"></div>
                </div>
                <div className="mt-2 flex items-center justify-between gap-1" >
                    <div className="flex items-center gap-1" >
                        <div className="h-5 w-5 bg-slate-800 dark:bg-pink-400/30 rounded-full border border-slate-700 dark:border-pink-300/30 flex-shrink-0"></div>
                        <div className="h-3 my-0.5 w-[72px] bg-slate-800/80 dark:bg-pink-400/20 rounded-full flex-grow"></div>
                    </div>
                    <Circle size={8} className="text-slate-400 dark:text-pink-300/50 opacity-30" />
                    <div className="h-3 my-0.5 w-12 hidden md:block  bg-slate-800/80 dark:bg-pink-400/20 rounded-full"></div>
                    {<div className="w-9 h-9 flex items-center justify-center rounded-full border border-slate-700/50 dark:border-cyan-400/30 bg-slate-800/80 dark:bg-pink-400/20 flex-shrink-0 animate-pulse"><Coins size={16} className="text-slate-600 dark:text-cyan-300" /></div>}
                </div>
                <div className="flex mt-3 gap-3 pb-1.5 pt-2 border-t border-slate-800 dark:border-pink-400/30 justify-between" >
                    <Play size={20} className="opacity-30 dark:text-pink-400 fill-slate-400" />
                    <Heart size={20} className="opacity-30 dark:text-pink-400 fill-slate-400" />
                    <Share2 size={20} className="opacity-30 dark:text-pink-400 fill-slate-400" />
                    <MoreVertical size={20} className="opacity-30 dark:text-pink-400 fill-slate-400" />
                </div>
            </div>
        </div>
    )
}

export const SongCardSkeletonMobile = () => {
    return (
        <div className={"flex gap-2 h-12 w-full rounded animate-pulse"}>
            <div className={"flex-shrink-0 object-cover w-12 h-12 bg-slate-700 dark:bg-pink-400/30 rounded"}></div>
            <div className="flex flex-col justify-between truncate w-full" >
                <p className="h-3 w-4/5 bg-slate-700 dark:bg-pink-400/30 rounded-full mt-1"></p>
                <div className="flex items-center gap-2.5 text-xs truncate text-slate-400 dark:text-pink-300/50" >
                    <p className="h-2.5 rounded-full bg-slate-700 dark:bg-pink-400/20 w-1/5"></p>
                    -
                    <div className="flex items-center gap-1.5 truncate" >
                        <span className="w-7 h-2 bg-slate-700 dark:bg-pink-400/20 rounded-full"></span>
                        <span className="w-12 h-2 bg-slate-700 dark:bg-pink-400/20 rounded-full"></span>
                        <span className="w-5 h-2 bg-slate-700 dark:bg-pink-400/20 rounded-full"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const CreateSongIconLoading = () => {
    const { creatingSongs } = useSelector(state => state.create)

    return (
        <HoverCard>
            <HoverCardTrigger asChild>
                <Button className="animate-border2 text-green-400 dark:text-pink-400 rounded-full p-2 border-2 w-10"><AudioLines size={24} className="animate-pulse" /></Button>
            </HoverCardTrigger>
            <HoverCardContent className="mr-4 w-auto bg-slate-900 dark:bg-gradient-to-br dark:from-pink-300/95 dark:to-pink-400/95 border-slate-700 dark:border-pink-400/30 text-slate-10 dark:text-black p-2">
                <div className="flex flex-col gap-2">
                    {creatingSongs?.map((song) => (
                        <div key={song.creationId} className="flex items-center gap-2 bg-slate-950 dark:bg-pink-400/30 p-2 rounded animate-pulse" >
                            <div className="w-8 rounded-full border border-slate-700 dark:border-pink-300/50 aspect-square flex items-center justify-center" >
                                <img src={song?.ani} alt="animation" className="w-full p-1" />
                            </div>
                            <p className="text-sm">{song.text}</p>
                        </div>
                    ))}
                </div>
            </HoverCardContent>
        </HoverCard>
    )
}