import TopBar from "@/components/navbar/TopBar";
import AdvertCardSkeleton from "./AdvertCardSkeleton";
import BottomBar from "@/components/sidebar/BottomBar";
import LEADERBOARD_IMAGE from '../../assets/leaderboard.jpg';

const DashboardSkeleton = () => {
    return (
      <div className="bg-[#0A0F1C] dark:bg-gradient-to-br dark:from-pink-100 dark:to-pink-300 text-white dark:text-black sm:pb-24 pb-32">
        <TopBar heading="Advertisement Dashboard" />
        <div className="relative bg-gradient-to-r from-[#1A1F2E] to-[#2A2F3E] dark:from-pink-400/80 dark:to-pink-600/80 p-8 pt-20 px-4 md:px-8 h-64 overflow-hidden">
          {/* Background Image */}
          <div className="absolute inset-0 z-0">
            <img src={LEADERBOARD_IMAGE} alt="Crypto background" className="w-full h-full object-cover opacity-80 dark:opacity-40" />
            {/* Gradient Overlay */}
            <div className="absolute inset-0 bg-gradient-to-r from-[#1A1F2E]/90 to-[#2A2F3E]/90 dark:from-pink-400/80 dark:via-pink-500/70 dark:to-pink-600/80" />
            
            {/* Optional: Add subtle animated gradient */}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-green-500/5 dark:via-pink-300/20 to-transparent animate-pulse-slow" />
          </div>

          {/* Content */}
          <div className="relative z-10 mx-auto">
            <h1 className="text-2xl sm:text-3xl font-comfortaa font-bold mb-2">
              <span className="bg-gradient-to-br from-slate-400 via-green-400 to-green-500 dark:from-pink-200 dark:via-pink-300 dark:to-pink-400 text-transparent bg-clip-text">
                Competition Analytics & Overview
              </span>
            </h1>
            <p className="text-gray-400 dark:text-pink-100/70">Manage your advertising competitions and track engagement</p>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-6 px-4 md:px-8 pt-12 sm:-mt-6 -mt-4">
          {[...Array(6)].map((_, index) => (
            <div 
              key={index}
              className="bg-gradient-to-br from-gray-800/40 to-gray-900/40 dark:from-pink-300/30 dark:via-pink-400/20 dark:to-pink-500/30 
                       backdrop-blur-xl rounded-2xl p-6 border border-gray-700/50 dark:border-pink-300/30"
            >
              <div className="bg-gray-700/50 dark:bg-pink-400/30 rounded-xl w-12 h-12 mb-4 animate-pulse" />
              <div className="bg-gray-700/50 dark:bg-pink-400/30 rounded h-4 w-24 mb-2 animate-pulse" />
              <div className="bg-gray-700/50 dark:bg-pink-400/30 rounded h-8 w-32 animate-pulse" />
            </div>
          ))}
        </div>
  
        {/* Tab Navigation Skeleton */}
        <div className="flex gap-4 mt-12 mb-8 border-b border-gray-800 dark:border-pink-400/30 px-4 md:px-8">
          <div className="bg-gray-700/50 dark:bg-pink-400/30 rounded h-8 w-24 animate-pulse" />
          <div className="bg-gray-700/50 dark:bg-pink-400/30 rounded h-8 w-24 animate-pulse" />
        </div>
  
        {/* Advertisement Grid Skeleton */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8 md:pb-24 pb-36 px-4 md:px-8">
          {[...Array(6)].map((_, index) => (
            <AdvertCardSkeleton key={index} />
          ))}
        </div>
        <BottomBar />
      </div>
    );
  };

export default DashboardSkeleton;