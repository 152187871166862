// src/utils/redditPixel.js
export const initRedditPixel = () => {
    if (!window.rdt) {
        (function (w, d) {
            if (!w.rdt) {
                var p = w.rdt = function () {
                    p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
                };
                p.callQueue = [];
                var t = d.createElement("script");
                t.src = "https://www.redditstatic.com/ads/pixel.js";
                t.async = true;
                var s = d.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(t, s);
            }
        })(window, document);

        // Initialize Reddit Pixel
        window.rdt("init", "a2_gjl2gmivr1b0"); // Replace with your actual Pixel ID
    } else {
        console.log("ℹ️ Reddit Pixel Already Initialized");
        window.rdt("track", "PageVisit");
    }
};

export const trackRedditEvent = (event, data = {}) => {
    if (typeof window.rdt === "function") {
        window.rdt("track", event, { ...data });
    } else {
        console.warn("⚠️ Reddit Pixel is not loaded yet!");
    }
};


// Function to track custom events
export const trackRedditCustomEvent = (event, data = {}) => {
    if (typeof window.rdt === "function") {
        window.rdt("track", "Custom", { customEventName: event, ...data });
    } else {
        console.warn("⚠️ Reddit Pixel is not loaded yet!");
    }
};


