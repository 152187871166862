export const initLinkedInTag = () => {
    if (!window.lintrk) {
        window.lintrk = function (a, b) {
            window.lintrk.q.push([a, b]);
        };
        window.lintrk.q = [];
    }

    window._linkedin_partner_id = "6998884"; // Replace with your Partner ID
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(window._linkedin_partner_id);

    // Load LinkedIn Insight Script
    const s = document.getElementsByTagName("script")[0];
    const b = document.createElement("script");
    b.type = "text/javascript";
    b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
};

// Function to track LinkedIn events
export const trackLinkedInEvent = (eventName, eventData = {}) => {
    if (window.lintrk) {
        window.lintrk("track", eventData);
    } else {
        console.warn("⚠️ LinkedIn Insight Tag not loaded.");
    }
};
