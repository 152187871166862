import { useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogOverlay, DialogTitle } from '@/components/ui/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { SET_OPEN_GIFT_DIALOG } from '@/redux/features/theme/extradialogs';
import axios from 'axios';
import premium from "@/assets/premium-badge.svg"
import gold from "@/assets/gold-badge.svg"
import platinum from "@/assets/platinum.svg"
import toast from 'react-hot-toast';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const GiftDialog = () => {
  const dispatch = useDispatch();
  const { isOpenGiftDialog } = useSelector(state => state.extradialogs);
  const [selectedPlan, setSelectedPlan] = useState('premium');
  const [months, setMonths] = useState(3);
  const { user } = useSelector(state => state.auth);

  // Convert plans to array format
  const plansArray = [
    { id: 'premium', img: premium, name: 'Premium', description: 'Premium features and support', basePrice: 14 },
    { id: 'gold', img: gold, name: 'Gold', description: 'Advanced features with priority support', basePrice: 19 },
    { id: 'platinum', img: platinum, name: 'Platinum', description: 'Ultimate experience with exclusive perks', basePrice: 45 }
  ];

  // Get discount based on the number of months
  const getDiscount = (monthCount) => {
    switch (monthCount) {
      case 3: return 0.95; // 5% discount
      case 6: return 0.90; // 10% discount
      case 12: return 0.80; // 20% discount
      default: return 1.0;
    }
  };

  // Get base price for a plan
  const getBasePrice = (planId) => {
    const plan = plansArray.find(p => p.id === planId);
    return plan ? plan.basePrice : 0;
  };

  const handleClose = () => {
    dispatch(SET_OPEN_GIFT_DIALOG(false));
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token') || localStorage.getItem('guestToken');
      toast.loading('Generating gift code...')
      const planIndex = plansArray.findIndex(plan => plan.id === selectedPlan) + 1;
      const response = await axios.post('/create-gift-subscription',
        {
          plan_type: planIndex,
          duration_months: months,
          recipient_email: user.email
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      toast.success('Successfully generated!');
      toast.dismiss();
      if (response.data?.url) {
        try {
          window.location.assign(response.data.url);
        } catch (error) {
          // Fallback
          window.location.href = response.data.url;
          console.error('Navigation error:', error);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate gift subscription');
    }
  };



  return (
    <Dialog open={isOpenGiftDialog} onOpenChange={handleClose}>
      <DialogOverlay className="z-[999] backdrop-blur-lg">
        <DialogContent className="w-full max-w-xl sm:max-w-3xl h-[80vh] bg-transparent border-none backdrop-blur-3xl rounded-2xl shadow-2xl p-2 sm:p-4 z-[9999] overflow-y-auto overflow-x-hidden">
          <div className='flex flex-col gap-6 items-center justify-center'>
            <div className='flex flex-col gap-2 items-center justify-center sm:pt-0 pt-8'>
              <DialogTitle className="sm:text-4xl text-3xl font-poppins font-semibold text-center text-white">
                Gift Subscription
              </DialogTitle>
              <DialogDescription className="text-slate-300 text-center text-lg">
                Choose a perfect gift for your loved ones
              </DialogDescription>
            </div>

            <div className="flex flex-col items-center justify-center gap-4">
              <div className="flex items-center justify-center gap-1 w-max bg-transparent p-0">
                {[3, 6, 12].map(num => (
                  <div key={num}>
                    <button onClick={() => setMonths(num)} className={`sm:px-6 px-4 py-1 text-sm sm:text-base rounded-full font-normal ${months === num ? 'bg-gradient-to-r from-green-400 to-teal-400 text-slate-950 shadow-lg' : 'bg-transparent border border-green-900/80 hover:bg-green-800/40 text-green-400 hover:text-green-300'}`}                    >
                      {num} {num === 1 ? 'Month' : 'Months'}
                    </button>
                  </div>
                ))}
              </div>

              <div className="flex flex-col gap-4 items-center justify-center sm:pb-0 pb-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                  {plansArray.map((plan) => (
                    <div key={plan.id} className={`relative flex flex-col items-center p-2 rounded-2xl border border-green-900/30 bg-transparent shadow-2xl shadow-green-900/30 `}                    >
                      <div className="text-4xl mb-1 flex items-center justify-center">
                        <LazyLoadImage effect="blur" src={plan.img} alt={plan.name} className="w-20 h-20 sm:w-24 sm:h-24" />
                      </div>

                      <div className="flex-1 flex flex-col items-center justify-between">
                        <h3 className="text-xl font-semibold text-green-400 mb-4">{plan.name}</h3>
                        <p className="text-center text-slate-300 text-sm mb-1">{plan.description}</p>
                        <div className="flex items-center gap-1 mb-1">
                          <span className='line-through text-red-500 text-sm'>
                            ${getBasePrice(plan.id)}/M
                          </span>
                          <span className='text-green-500 text-sm font-medium'>
                            ${(getBasePrice(plan.id) * getDiscount(months)).toFixed(2)}/M
                          </span>
                        </div>
                        <span className='text-slate-300 text-lg font-semibold mb-4'>
                          Total: ${(getBasePrice(plan.id) * months * getDiscount(months)).toFixed(2)}
                        </span>
                        <button onClick={() => setSelectedPlan(plan.id)} className={`w-[18rem] md:w-full py-2 rounded-lg font-semibold transition-all duration-300 bg-transparent hover:bg-green-800/40 text-green-400  border border-green-900/30 ${selectedPlan === plan.id ? 'bg-gradient-to-r from-green-400 to-teal-400 text-slate-950' : ''}`}                        >
                          {selectedPlan === plan.id ? 'Selected' : 'Select'}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                <button onClick={handleSubmit} className="w-full py-2 px-6 bg-gradient-to-r from-green-400 to-teal-400 text-slate-950 rounded-lg text-xl font-semibold shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-[1.02]"                >
                  Generate Gift
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </DialogOverlay>
    </Dialog>
  );
};

export default GiftDialog;