import { useState, useEffect } from 'react'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ADD_GENRES, SET_GENRES } from '@/redux/features/music/searchSlice'
import { useDispatch, useSelector } from 'react-redux'
import TopBar from '@/components/navbar/TopBar'
import BottomBar from '@/components/sidebar/BottomBar'
import { SongCardSkeleton } from '@/components/createSong/CreateSongLoading'
import { useParams } from 'react-router-dom'
import SongCard from '@/components/songCard/LazyLoadSongCard'

function Genres() {
    const dispatch = useDispatch()
    const genres = useSelector(state => state.search.genres)
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(true)
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(null)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [totalItems, setTotalItems] = useState(0)
    const [genreInfo, setGenreInfo] = useState(null)
    const { genreId } = useParams()
    const { song } = useSelector(state => state.music)

    const fetchGenres = async () => {
        try {
            if (page === 1) {
                dispatch(SET_GENRES([]))
            }
            const response = await axios.get(`/genre?genre=${genreId}&sort=latest&page=${page}&limit=20`)
            
            const { songs, pagination, genre_search } = response.data
            setTotalItems(pagination.total_items)
            setGenreInfo(genre_search)
            
            if (songs.length === 0 || page >= pagination.total_pages) {
                setHasMore(false)
            }
            
            dispatch(ADD_GENRES(songs))
            setLoading(false)
        } catch (err) {
            console.error('Error fetching genres:', err)
            setError(err.message)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchGenres()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])


    return (
        <div className={`min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 dark:bg-gradient-to-br dark:from-pink-100 dark:to-pink-300 sm:px-4 px-0 py-8 pt-[6rem] ${song ? "pb-36 md:pb-28" : "pb-24 sm:pb-16"}`}>
            <TopBar heading={genreInfo?.input} />

            <div className="mx-auto px-4">
                {/* Header Section */}
                <div className="text-center mb-12">
                    <h1 className="text-2xl md:text-3xl font-semibold font-comfortaa text-slate-200 dark:text-black mb-4">
                        {genreInfo?.processed_genres?.map(genre => genre.charAt(0).toUpperCase() + genre.slice(1)).join(', ') || 'Genre'} Music Collection
                    </h1>
                    <p className="text-slate-400 dark:text-black text-lg max-w-2xl mx-auto">
                        {totalItems.toLocaleString()} tracks available in {genreInfo?.input || 'this'} genre
                    </p>
                </div>

                {/* Genre Grid */}
                <InfiniteScroll
                    dataLength={genres.length}
                    next={() => setPage(prev => prev + 1)}
                    hasMore={hasMore}
                    loader={<GenreSkeleton />}
                    className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-6"
                >
                    {genres.map((song, i) => (
                        <SongCard song={song} key={i} playingFrom={"genres"} />
                    ))}
                </InfiniteScroll>
            </div>
            <BottomBar />
        </div>
    )
}

const GenreSkeleton = () => {
    return (
        <>
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
        </>
    )
}

export default Genres