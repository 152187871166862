import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ADD_ARTISTS, SET_ARTISTS } from '@/redux/features/music/searchSlice'
import { useDispatch, useSelector } from 'react-redux'
import TopBar from '@/components/navbar/TopBar'
import BottomBar from '@/components/sidebar/BottomBar'
import { Crown, Clock, BookUser, Heart } from 'lucide-react'

function Artists() {
    const dispatch = useDispatch()
    const artists = useSelector(state => state.search.artists)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const { song } = useSelector(state => state.music)

    const fetchArtists = async () => {
        try {
            if (page === 1) {
                dispatch(SET_ARTISTS([]))
            }
            const response = await axios.get(`/discover/artists?page=${page}`)
            console.log('Response:', response.data.artists)

            if (response.data.artists.length === 0 || response.data.artists.length < 10) {
                setHasMore(false)
            }

            dispatch(ADD_ARTISTS(response.data.artists))
        } catch (err) {
            console.error('Error fetching artists:', err)
        }
    }

    useEffect(() => {
        fetchArtists()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <div className={`min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 dark:from-pink-100 dark:via-pink-200 dark:to-pink-300 px-4 py-8 ${song ? "pb-36 md:pb-28" : "pb-24 sm:pb-16"}`}>
            <TopBar heading={"Artists"} />
            <div className="mx-auto pt-16">
                {/* Header Section */}
                <div className="text-center mb-12">
                    <h1 className="text-2xl md:text-4xl font-semibold font-comfortaa text-green-400 dark:text-pink-600 mb-4">
                        Featured Artists
                    </h1>
                    <p className="text-slate-400 dark:text-pink-800 text-lg max-w-2xl mx-auto">
                        Discover talented creators and their unique sound collections
                    </p>
                </div>

                {/* Artists Grid */}
                {/* <div > */}
                <InfiniteScroll
                    dataLength={artists.length}
                    next={() => setPage(prev => prev + 1)}
                    hasMore={hasMore}
                    loader={<ArtistCardSkeleton />}
                    className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-6"
                >
                    {Array.isArray(artists) && artists.map((artist, i) => (
                        <div
                            key={i}
                            className="group relative bg-slate-800/50 dark:bg-pink-200/50 rounded-xl overflow-hidden hover:shadow-xl hover:shadow-green-500/10 dark:hover:shadow-pink-500/30 transition-all duration-300 backdrop-blur-sm border border-slate-700/50 dark:border-pink-400/30 hover:border-green-500/50 dark:hover:border-pink-500/50"
                        >
                            {/* Artist Image */}
                            <div className="aspect-square overflow-hidden">
                                {artist.profile_url ? (
                                    <img
                                        src={artist.profile_url}
                                        alt={artist.name}
                                        className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                                    />
                                ) : (
                                    <div className="w-full h-full flex items-center justify-center bg-gradient-to-br from-slate-800 to-slate-900 dark:from-pink-300 dark:to-pink-400">
                                        <span className="text-6xl font-bold text-green-400/80 dark:text-pink-700 font-potta group-hover:scale-110 transition-transform duration-300">
                                            {artist.name.charAt(0).toUpperCase()}
                                        </span>
                                    </div>
                                )}
                                {artist.is_featured && (
                                    <div className="absolute top-3 right-3">
                                        <Crown className="w-5 h-5 text-yellow-400 dark:text-pink-600" />
                                    </div>
                                )}
                            </div>

                            {/* Artist Info */}
                            <div className="p-2 sm:p-4">
                                <h2 className="text-xl font-semibold text-white dark:text-pink-900 mb-2 truncate">
                                    {artist.name}
                                </h2>

                                {/* Stats Grid */}
                                <div className="flex items-center justify-start gap-3 mb-4">
                                    <div className="flex items-center gap-1 sm:gap-2 justify-center">
                                        <div className="text-green-400 dark:text-pink-600 text-sm font-semibold">{artist.stats.total_songs}</div>
                                        <div className="text-xs text-slate-400 dark:text-pink-700">Songs</div>
                                    </div>
                                    <div className="flex items-center gap-1 sm:gap-2 justify-center">
                                        <div className="text-green-400 dark:text-pink-600 text-sm font-semibold">{artist.stats.total_views}</div>
                                        <div className="text-xs text-slate-400 dark:text-pink-700">Views</div>
                                    </div>
                                </div>

                                {/* Additional Stats */}
                                <div className="flex items-center justify-between text-slate-400 dark:text-pink-700 text-sm">
                                    <div className="flex items-center gap-1 sm:gap-2">
                                        <Heart className="w-4 h-4 fill-green-500 text-green-500" />
                                        <span>{artist.stats.total_likes}</span>
                                    </div>
                                    <div className="flex items-center gap-1 sm:gap-2">
                                        <Clock className="w-4 h-4" />
                                        <span>{new Date(artist.stats.last_upload).toLocaleString('en-US', { day: 'numeric', month: 'short' })}</span>
                                    </div>
                                </div>

                                {/* Action Button */}
                                <Link to={`/profile/${artist.username}`} 
                                    className="w-full mt-4 py-2 rounded-lg bg-green-500/10 dark:bg-pink-400/20 text-green-400 dark:text-pink-700 hover:bg-green-500/20 dark:hover:bg-pink-400/30 transition-colors flex items-center justify-center gap-2">
                                    <BookUser className="w-4 h-4" />
                                    <span>View Profile</span>
                                </Link>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
                {/* </div> */}
            </div>
            <BottomBar />
        </div>
    )
}

const ArtistCardSkeletons = () => {
    return (
        <div className="group relative bg-slate-800/50 dark:bg-pink-200/50 rounded-xl overflow-hidden hover:shadow-xl hover:shadow-green-500/10 dark:hover:shadow-pink-500/30 transition-all duration-900 backdrop-blur-sm border border-slate-700/50 dark:border-pink-400/30 animate-pulse">
            {/* Artist Image */}
            <div className="aspect-square overflow-hidden bg-slate-700/50 dark:bg-pink-300/50" />

            {/* Artist Info */}
            <div className="p-4">
                {/* Name Skeleton */}
                <div className="h-6 bg-slate-700/50 dark:bg-pink-300/50 rounded-lg w-3/4 mb-2" />

                {/* Stats Skeleton */}
                <div className="flex items-center justify-between text-slate-400 dark:text-pink-700 text-sm mb-4">
                    <div className="w-16 h-4 bg-slate-700/50 dark:bg-pink-300/50 rounded" />
                    <div className="w-16 h-4 bg-slate-700/50 dark:bg-pink-300/50 rounded" />
                </div>

                {/* Action Button Skeleton */}
                <div className="w-full h-10 bg-slate-700/50 dark:bg-pink-300/50 rounded-lg" />
            </div>
        </div>
    );
};

function ArtistCardSkeleton() {
    return (
        <>
            <ArtistCardSkeletons />
            <ArtistCardSkeletons />
            <ArtistCardSkeletons />
            <ArtistCardSkeletons />
            <ArtistCardSkeletons />
            <ArtistCardSkeletons />
            <ArtistCardSkeletons />
        </>
    )
}


export default Artists