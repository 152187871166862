import { useCallback, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { debounce } from "@/utils/debounce";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogOverlay, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { addSong, APPEND_SONG_TO_PLAYLIST, SET_ADD_SONG_DIALOG_OPEN } from "@/redux/features/music/playlistSlice";
import { toast } from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import AddSongDialogSkeleton from "./AddSongDialogSkeleton";
import imgGreenBird from '../../assets/greenbird.png';
import { MusicAdd } from "@/components/navbar/SocialIcons";
import { Music4 } from "lucide-react";

const AddSongDialog = () => {
  const dispatch = useDispatch();
  const { playling } = useSelector((state) => state.music);
  const [searchParams] = useSearchParams();
  const playlistId = searchParams.get('id');
  const [searchTerm, setSearchTerm] = useState("");
  const [isAdding, setIsAdding] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [songs, setSongs] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [noResultMsg, setNoResultMsg] = useState(false);
  const [searchPage, setSearchPage] = useState(1);
  const [hasMoreSearch, setHasMoreSearch] = useState(true);
  const [playlistSongIds, setPlaylistSongIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const initialFetchRef = useRef(false);
  const [dialogSong, setDialogSongs] = useState([])
  const [dialogPage, setDialogPage] = useState(1)
  const [dialogLoading, setDialogLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { addSongDialogOpen } = useSelector((state) => state.playlists);
  // Reset states when dialog opens
  useEffect(() => {
    if (addSongDialogOpen) {
      // console.log('isOpen',isOpen)
      // setCurrentPage(1);
      setSongs([]);
      setHasMore(true);
      setSearchTerm("");
      setSearchResults([]);
      setSearchPage(1);
      setHasMoreSearch(true);
      setNoResultMsg(false);
      initialFetchRef.current = false;
    }
  }, [addSongDialogOpen]);

  // Fetch playlist songs
  useEffect(() => {
    const fetchPlaylistSongs = async () => {
      if (!addSongDialogOpen) return;

      setIsLoading(true);
      try {
        const token = window.localStorage.getItem("token");
        const response = await axios.get(
          `/getplaylistsongs`,
          {
            params: { playlist_slug: playlistId },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setPlaylistSongIds(response.data || []);
        initialFetchRef.current = false;
      } catch (error) {
        console.error("Failed to fetch playlist songs:", error);
        if (error.response?.status !== 404) {
          toast.error("Failed to load playlist songs");
        }
        setPlaylistSongIds([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlaylistSongs();
  }, [addSongDialogOpen, playlistId]);



  // Search functionality with debounce
  useEffect(() => {
    const token = window.localStorage.getItem("token");
    const debouncedSearch = debounce(async () => {
      if (!searchTerm) {
        setSearchResults([]);
        setNoResultMsg(false);
        setHasMoreSearch(true);
        setSearchPage(1);
        return;
      }

      try {
        setIsSearching(true);
        const response = await axios.get(
          `/songsmenus?search=${searchTerm}&page=${searchPage}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const newSongs = response.data.songs;
        const filteredSongs = newSongs.filter(
          (song) => !playlistSongIds.includes(song.song_id)
        );

        setSearchResults(prev =>
          searchPage === 1 ? filteredSongs : [...prev, ...filteredSongs]
        );
        setHasMoreSearch(newSongs.length === 20);
        setNoResultMsg(searchPage === 1 && filteredSongs.length === 0);
      } catch (error) {
        console.error("Search failed:", error);
        toast.error("Failed to search songs");
      } finally {
        setIsSearching(false);
      }
    }, 1000);

    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [searchTerm, playlistSongIds, searchPage]);


  const fetchMoreSongs = useCallback(async () => {
    if (!hasMore || dialogLoading) return;
    setDialogLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/allsongs?page=${dialogPage}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const newSongs = response.data.songs;
      if (newSongs.length === 0 || newSongs.length < 20) {
        setHasMore(false);
      } else {
        setDialogPage(prev => prev + 1);
      }
      setDialogSongs(prevSongs => {
        const existingSongIds = prevSongs.map(song => song.song_id);
        const uniqueNewSongs = newSongs.filter(song => !existingSongIds.includes(song.song_id));
        return [...prevSongs, ...uniqueNewSongs];
      });
    } catch (error) {
      console.error('Error fetching more songs:', error);
    } finally {
      setDialogLoading(false);
    }
  }, [dialogPage, hasMore, dialogLoading]);

  const handleLoadMoreSongs = useCallback(() => {
    const nextPage = currentPage + 1;
    fetchMoreSongs(nextPage);
    setCurrentPage(nextPage);
  }, [currentPage, fetchMoreSongs]);

  const filteredSongs = dialogSong.filter((song) => !playlistSongIds.includes(song.song_id));
  // console.log('filteredSongs', filteredSongs)
  if (filteredSongs.length < 15 && hasMore) {
    fetchMoreSongs();
  }

  const handleAddSong = useCallback(
    async (song) => {
      if (isAdding[song.song_id]) return;

      setIsAdding((prev) => ({ ...prev, [song.song_id]: true }));
      try {
        const response = await dispatch(
          addSong({
            songId: song.song_id,
            playlistId,
          })
        ).unwrap();

        if (response.status === 'Song already in the playlist') {
          toast.error(response.status);
        } else {
          dispatch(APPEND_SONG_TO_PLAYLIST(song));
          toast.success(`Added "${song.song_name}" to playlist`);
          // Update playlistSongIds first
          setPlaylistSongIds(prev => [...prev, song.song_id]);
          // dispatch(SET_PLAYLISTS_UPDATED(true));
        }
        // Then update the songs list by filtering out the added song
        setSongs(prev => prev.filter(s => s.song_id !== song.song_id));
        // Update search results if in search mode
        if (searchTerm) {
          setSearchResults(prev => prev.filter(s => s.song_id !== song.song_id));
        }
      } catch (error) {
        toast.error(`Failed to add song: ${error}`);
      } finally {
        setIsAdding((prev) => ({ ...prev, [song.song_id]: false }));
      }
    },
    [dispatch, playlistId, isAdding, searchTerm]
  );

  return (
    <Dialog open={addSongDialogOpen} onOpenChange={() => dispatch(SET_ADD_SONG_DIALOG_OPEN(false))}>
      <DialogOverlay className="z-[999]">
        <DialogContent className={`z-[9999] max-w-3xl max-h-[80vh] sm:max-h-[80vh] overflow-hidden bg-gradient-to-br from-slate-950 via-slate-800 to-slate-950 dark:bg-gradient-to-br dark:from-pink-100  dark:to-pink-300 border-2 border-green-400/30 dark:border-pink-800 rounded-2xl shadow-2xl shadow-green-900/20 text-slate-300 ${playling ? 'mb-44' : ''}`}>
          <DialogHeader className="space-y-2">
            <div className="flex items-center space-x-3">
              <Music4 className="text-green-400 dark:text-pink-900" size={28} />
              <DialogTitle className="text-2xl text-start font-bold text-green-400 dark:text-pink-900">
                Add Songs to Playlist
              </DialogTitle>
            </div>
            <DialogDescription className="text-start text-slate-400 dark:text-pink-800">
              Explore and curate your perfect playlist
            </DialogDescription>
          </DialogHeader>

          <div className="relative mb-4">
            <Input type="text" placeholder="Search songs..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="w-full pl-12 pr-4 py-3 bg-slate-800/60 dark:bg-pink-100 backdrop-blur-md border-green-400/30 dark:border-pink-800 rounded-xl focus:ring-2 dark:focus-ring-1 focus:ring-green-400/50 dark:focus:ring-pink-400 text-slate-200 dark:text-slate-900 dark:placeholder:text-slate-900" />
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-slate-400 dark:text-pink-800" size={24} />
          </div>

          <div id="scrollableDiv" className="overflow-y-auto max-h-[60vh] pb-12 z-[999] custom-scrollbar">
            {isLoading && dialogLoading ? (
              <AddSongDialogSkeleton />
            ) : searchTerm === "" ? (
              <InfiniteScroll
                dataLength={dialogSong.length}
                next={handleLoadMoreSongs}
                hasMore={hasMore}
                loader={<AddSongDialogSkeleton />}
                scrollableTarget="scrollableDiv"
                scrollThreshold={0.8}
                style={{ overflow: 'hidden' }}
              >
                {filteredSongs.map((song, index) => (
                  <SongItem key={`${song.song_id}-${index}`} song={song} isAdding={isAdding} handleAddSong={handleAddSong} />
                ))}
              </InfiniteScroll>
            ) : (
              <>
                {isSearching && searchPage === 1 ? (
                  <AddSongDialogSkeleton />
                ) : (
                  <InfiniteScroll
                    dataLength={searchResults.length}
                    next={() => setSearchPage(prev => prev + 1)}
                    hasMore={hasMoreSearch}
                    loader={<AddSongDialogSkeleton />}
                    scrollableTarget="scrollableDiv"
                    scrollThreshold={0.8}
                  >
                    {searchResults.map((song, index) => (
                      <SongItem key={`${song.song_id}-${index}`} song={song} isAdding={isAdding} handleAddSong={handleAddSong} />
                    ))}
                  </InfiniteScroll>
                )}
                {noResultMsg && searchResults.length === 0 && !isSearching && (
                  <div className="flex flex-col items-center justify-center p-8 space-y-4">
                    <Music4 className="text-green-400 opacity-50" size={48} />
                    <p className="text-slate-400 text-center">
                      No songs found for your search. Try different keywords.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </DialogContent>
      </DialogOverlay>
    </Dialog>
  );
};

const SongItem = ({ song, isAdding, handleAddSong }) => (
  <div className="flex items-center justify-between py-2 px-2 sm:p-4 bg-slate-900/50 dark:bg-gradient-to-br dark:from-pink-200 dark:via-pink-300 dark:to-pink-400 hover:bg-slate-900 dark:hover:from-pink-100 dark:hover:via-pink-200 dark:hover:to-pink-300 backdrop-blur-sm rounded-lg mb-2">
    <div className="flex items-center space-x-4">
      <img src={song.image_url || imgGreenBird} alt={song.song_name} className="w-12 h-12 rounded object-cover" />
      <div>
        <h4 className="truncate max-w-[120px] sm:max-w-full font-sm text-sm sm:text-md sm:font-medium text-white dark:text-slate-900">
          {song.song_name}
        </h4>
        <p className="truncate max-w-[120px] text-xs sm:text-sm text-slate-400 dark:text-pink-800">
          {song.username || "Unknown Artist"}
        </p>
      </div>
    </div>
    <Button onClick={() => handleAddSong(song)} disabled={isAdding[song.song_id]} variant="secondary" className="flex items-center gap-1 bg-green-400 hover:bg-green-300 w-20 sm:min-w-[100px] dark:bg-pink-600 dark:hover:bg-pink-600/90 dark:text-white" >
      {isAdding[song.song_id] ? (
        <span className="flex items-center gap-2">
          <span className=""></span>
          Adding...
        </span>
      ) : (
        <>
          <MusicAdd className="w-5 h-5" />
          Add
        </>
      )}
    </Button>
  </div>
);

export default AddSongDialog;