import { Link } from "react-router-dom"
import logo from "../../assets/footer-frog.png"
import { useEffect } from "react"
import BottomBar from "@/components/sidebar/BottomBar"
import { useSelector } from "react-redux"

const TermsCondition = () => {
    const { playing } = useSelector(state => state.music)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="z-[9999] bg-gradient-to-br from-slate-950 to-slate-800 dark:from-pink-200 dark:via-pink-300 dark:to-pink-200 text-slate-300 dark:text-slate-700 min-h-screen font-sans">
            <div className="fixed top-0 left-0 w-full bg-slate-950 dark:bg-pink-200 h-16 border-b border-slate-800 dark:border-pink-700">
                <div className="mx-auto max-w-screen-lg px-4 md:px-10 flex items-center justify-between">
                    <Link to={"/"} className="flex items-center gap-4 py-4">
                        <img src={logo} className="max-w-[40px] w-full rounded-full" alt="img" />
                        <div className="font-semibold text-center lg:text-xl text-green-400 dark:text-pink-700">
                            SoundOfMeme
                        </div>
                    </Link>
                    <Link to="/" className="text-slate-300 dark:text-slate-700 font-medium hover:text-green-400 dark:hover:text-pink-700">
                        Home
                    </Link>
                </div>
            </div>

            <div className={`max-w-screen-lg mx-auto px-4 md:px-10 py-20 ${playing ? 'mb-24 sm:mb-12' : ''}`}>
                <h1 className="font-potta text-4xl text-green-400 dark:text-pink-700">Terms and Conditions</h1>
                <p className="mt-8 font-medium">Effective Date: <span className="text-green-400 dark:text-pink-700">30 September 2024</span></p>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">1. Acceptance of Terms</h2>
                <p className="mt-4">By accessing or using SoundOfMeme the Service, you agree to be bound by these Terms and Conditions. If you disagree with any part of these terms, you may not access the Service.</p>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">2. User Accounts</h2>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2">
                    <li>You must be at least 13 years old to use this Service.</li>
                    <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
                    <li>You agree to provide accurate and complete information when creating an account.</li>
                    <li>You are responsible for all activities that occur under your account.</li>
                </ul>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">3. Subscription Terms</h2>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2">
                    <li><span className="font-medium text-slate-100 dark:text-slate-800">Auto-Renewal:</span> Subscriptions automatically renew unless cancelled at least 24 hours before the end of the current period.</li>
                    <li><span className="font-medium text-slate-100 dark:text-slate-800">Billing:</span> You will be billed at the start of each subscription period.</li>
                    <li><span className="font-medium text-slate-100 dark:text-slate-800">Non-Refundable:</span> Subscription payments are non-refundable. No refunds or credits for partial subscription periods.</li>
                    <li><span className="font-medium text-slate-100 dark:text-slate-800">Cancellation:</span> You can cancel your subscription through your account settings or by contacting support.</li>
                </ul>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">4. User Content</h2>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2">
                    <li>You retain ownership of content you create using our Service.</li>
                    <li>You grant SoundOfMeme a license to use, modify, and distribute your content for Service operation.</li>
                    <li>You are responsible for ensuring your content doesn&apos;t violate any third-party rights.</li>
                    <li>We reserve the right to remove any content that violates these terms.</li>
                </ul>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">5. Data Policy</h2>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2">
                    <li><span className="font-medium text-slate-100 dark:text-slate-800">Data Collection:</span> We collect and process data as outlined in our Privacy Policy.</li>
                    <li><span className="font-medium text-slate-100 dark:text-slate-800">Data Deletion:</span> You can request deletion of your account and associated data by contacting support.</li>
                    <li><span className="font-medium text-slate-100 dark:text-slate-800">Processing Time:</span> Data deletion requests are typically processed within 30 minutes.</li>
                    <li><span className="font-medium text-slate-100 dark:text-slate-800">Retention:</span> Some data may be retained for legal or operational purposes.</li>
                </ul>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">6. Acceptable Use</h2>
                <p className="mt-4">You agree not to:</p>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2">
                    <li>Use the Service for any illegal purposes</li>
                    <li>Create or upload content that infringes on intellectual property rights</li>
                    <li>Attempt to gain unauthorized access to the Service</li>
                    <li>Use the Service to distribute malware or harmful code</li>
                    <li>Engage in activities that disrupt the Service or other users experience</li>
                </ul>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">7. Service Modifications</h2>
                <p className="mt-4">We reserve the right to:</p>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2">
                    <li>Modify or discontinue any part of the Service</li>
                    <li>Change subscription fees with reasonable notice</li>
                    <li>Update these terms with notification to users</li>
                </ul>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">8. Intellectual Property</h2>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2">
                    <li>The Service and its original content are protected by copyright and other intellectual property laws.</li>
                    <li>The SoundOfMeme name, logo, and branding are our trademarks.</li>
                    <li>You may not use our trademarks without prior written consent.</li>
                </ul>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">9. Limitation of Liability</h2>
                <p className="mt-4">SoundOfMeme shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use the Service.</p>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">10. Governing Law</h2>
                <p className="mt-4">These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">11. Additional Terms for Apple Users</h2>
                <p className="mt-4">If you download our application from the Apple App Store, the following additional terms apply:</p>

                <h3 className="mt-6 text-xl font-medium text-slate-100 dark:text-slate-800">a. License Scope</h3>
                <p className="mt-2">The app is licensed, not sold, to you by Apple. Your license is non-transferable and allows use on Apple-branded products that you own or control, subject to Apple&apos;s Usage Rules. You may not distribute, transfer, or sublicense the app.</p>

                <h3 className="mt-6 text-xl font-medium text-slate-100 dark:text-slate-800">b. Data Collection and Usage</h3>
                <p className="mt-2">We may collect technical data and related information (including technical information about your device, system, application software, and peripherals) to facilitate updates, support, and other services. This information will be used in a form that does not personally identify you.</p>

                <h3 className="mt-6 text-xl font-medium text-slate-100 dark:text-slate-800">c. Termination</h3>
                <p className="mt-2">This license is effective until terminated by you or the Licensor. Your rights will terminate automatically if you fail to comply with these terms.</p>

                <h3 className="mt-6 text-xl font-medium text-slate-100 dark:text-slate-800">d. External Services</h3>
                <p className="mt-2">The app may provide access to external services. Use of these services is at your sole risk. We are not responsible for third-party services or content.</p>

                <h3 className="mt-6 text-xl font-medium text-slate-100 dark:text-slate-800">e. Warranty Disclaimer</h3>
                <p className="mt-2">The app is provided &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; without warranty of any kind. We disclaim all warranties, whether express, implied, or statutory, including merchantability, fitness for a particular purpose, and non-infringement.</p>

                <h3 className="mt-6 text-xl font-medium text-slate-100 dark:text-slate-800">f. Limitation of Liability</h3>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2">
                    <li>We shall not be liable for personal injury or any incidental, special, indirect, or consequential damages.</li>
                    <li>Total liability for all damages shall not exceed fifty dollars ($50.00).</li>
                    <li>Some jurisdictions may not allow these limitations.</li>
                </ul>

                <h3 className="mt-6 text-xl font-medium text-slate-100 dark:text-slate-800">g. Export Restrictions</h3>
                <p className="mt-2">You may not export or re-export the app except as authorized by United States law and the jurisdiction where the app was obtained.</p>

                <h3 className="mt-6 text-xl font-medium text-slate-100 dark:text-slate-800">h. Commercial Items</h3>
                <p className="mt-2">The app and documentation are &quot;Commercial Items&quot; as defined at 48 C.F.R. §2.101, licensed to U.S. Government end users only as Commercial Items with only those rights granted to all other end users.</p>

                <h3 className="mt-6 text-xl font-medium text-slate-100 dark:text-slate-800">i. Governing Law</h3>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2">
                    <li>For U.S. users: This agreement is governed by California law, with exclusive jurisdiction in Santa Clara County, California.</li>
                    <li>For EU, Switzerland, Norway, or Iceland citizens: The governing law and forum shall be the laws and courts of your usual place of residence.</li>
                    <li>The UN Convention on the International Sale of Goods is specifically excluded.</li>
                </ul>

                <h2 className="mt-8 text-2xl font-potta text-green-400 dark:text-pink-700">Contact Us</h2>
                <p className="mt-4">If you have any questions about these Terms, please contact us at:</p>
                <p className="mt-4">
                    <span className="text-slate-100 dark:text-slate-800 font-medium">Email: </span>
                    <a href="mailto:contact@soundofmeme.com" className="text-green-400 dark:text-pink-700 hover:underline">
                        contact@soundofmeme.com
                    </a>
                </p>
            </div>
            <BottomBar />
        </div>
    )
}

export default TermsCondition