import { Link } from 'react-router-dom';
import SOME from '../../assets/hero.gif';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const NotFound = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-slate-950 to-green-950/20 flex flex-col items-center justify-center text-white p-4">
            {/* Logo with animation */}
            <div className="relative mb-8 ml-8 transform hover:rotate-12 transition-transform duration-300">
                <LazyLoadImage src={SOME} alt="Logo" className="w-40 h-52 shadow-2xl" />
                <div className="absolute -bottom-10 left-16 -translate-x-1/2 w-[120%] h-10 bg-gradient-to-t from-green-900/90 to-black/50 rounded-[50%] flex items-center justify-center"></div>
            </div>
            {/* Error Message with animation */}
            <div className="text-center space-y-4 mb-8">
                <h1 className="text-7xl font-bold bg-gradient-to-r from-green-400 to-teal-400 text-transparent bg-clip-text animate-pulse">
                    404
                </h1>
                <h2 className="text-4xl font-bold mb-2">Page not found</h2>
                <p className="text-gray-400 text-lg max-w-md mx-auto">
                    We can&apos;t seem to find the page you are looking for.
                </p>
            </div>
            {/* Buttons with improved styling */}
            <div className="flex flex-col sm:flex-row gap-4 w-full max-w-xs">
                <Link to="/" className="bg-gradient-to-r from-green-400 to-teal-400 text-black px-8 py-3 rounded-md font-bold block text-center hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-green-500/25">
                    Back to Home
                </Link>
                <Link to="/contact" className="bg-transparent border border-white/20 text-white px-8 py-3 rounded-md font-bold block text-center hover:bg-white/10 transition-all duration-300">
                    Get Help
                </Link>
            </div>
        </div>
    );
};

export default NotFound;