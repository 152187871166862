export const initTwitterPixel = () => {
    if (window.twq) {
        console.log("ℹ️ Twitter Pixel Already Initialized");
        return;
    }

    (function (e, t, n, s, u, a) {
        e.twq ||
            ((s = e.twq =
                function () {
                    s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
                }),
                (s.version = "1.1"),
                (s.queue = []),
                (u = t.createElement(n)),
                (u.async = !0),
                (u.src = "https://static.ads-twitter.com/uwt.js"),
                (a = t.getElementsByTagName(n)[0]),
                a.parentNode.insertBefore(u, a));
    })(window, document, "script");

    window.twq("config", "p774a");
    window.twq("track", "PageView");
};


export const trackTwitterEvent = (event, data = {}) => {
    if (typeof window.twq === "function") {
        window.twq("event", event, { ...data });
        console.log(`✅ Twitter Standard Event Tracked: ${event}`, data);
    } else {
        console.warn("⚠️ Twitter Pixel is not loaded yet!");
    }
};
