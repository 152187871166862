import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { SET_VIEWSONG, SET_SONG, SET_PLAYING, SET_PLAYINGFROM, SET_WALLETBTN, SET_LAUNCHTOKENPLAYLIST, SET_LAUNCHTOKENSONG } from "@/redux/features/music/musicSlice";
import { UPDATE_PLAYLIST_DATA, UPDATE_PLAYLIST_NAME, SET_PLAYLIST_SONGS, fetchPlaylistSongs, ADD_PLAYLIST_SONGS, SET_PLAYLIST_TO_DELETE, SET_DELETE_PLAYLIST_DIALOG_OPEN, SET_ADD_SONG_DIALOG_OPEN } from "@/redux/features/music/playlistSlice";
import TopBar from "@/components/navbar/TopBar";
// import AddSongDialog from "../playlist/AddSongDialog";
import BottomBar from "@/components/sidebar/BottomBar";
import PlaylistViewLoader from "./PlaylistViewLoader";
import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogOverlay, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import toast from "react-hot-toast";
import playlistImgCover from "../../assets/meme-1.png";
import Lottie from "lottie-react";
import errorAnimation from "../../assets/error.json";
import AudioVisualizer from "@/components/AudioVisualizer/AudioVisualizer";
import { SET_OTPCARD, SET_SHOW_LOGIN } from "@/redux/features/auth/authSlice";
import PlaylistSongCard, { SongCardMyCreationSkeleton } from "./PlaylistSongCard";
import { Globe, GlobeLock, Trash2, Share2, Pencil, ListVideo, Coins } from "lucide-react";
import { MusicAdd } from "@/components/navbar/SocialIcons";

const PlaylistView = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { songs: playlistSongs = [], error, loading, playlistName, playlistCoverImage, playlistData, playlistOwner } = useSelector((state) => state.playlists);
  const { song: activeSong, playing } = useSelector((state) => state.music);
  const [searchParams] = useSearchParams();
  const playlistId = searchParams.get("id");
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isSharedView, setIsSharedView] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [newPlaylistName, setNewPlaylistName] = useState("");
  const listRef = useRef(null);
  const activeItemRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentlyFetching, setCurrentlyFetching] = useState(false);
  const fetchTimeoutRef = useRef(null);
  const lastFetchedPage = useRef(0);

  // to persist otp card after refresh
  useEffect(() => {
    const token = localStorage.getItem("token");
    const otpToken = sessionStorage.getItem("otpToken");
    if (!token && !user) {
      dispatch(SET_SHOW_LOGIN(false));
    }
    if (otpToken) {
      dispatch(SET_OTPCARD(true));
      dispatch(SET_SHOW_LOGIN(true));
    }
    window.scrollTo(0, 0);
  }, [user, dispatch]);

  // to check if the user is the owner of the playlist
  useEffect(() => {
    if (playlistId) {
      const isUserOwnPlaylist = user?.email === playlistOwner;
      setIsSharedView(!isUserOwnPlaylist);
    }
  }, [playlistId, user, playlistOwner]);

  // to fetch the initial songs of the playlist
  useEffect(() => {
    if (!playlistId) return;
    const fetchInitialSongs = async () => {
      try {
        setIsLoading(true);
        setCurrentlyFetching(true);
        dispatch(SET_PLAYLIST_SONGS([]));
        setPage(1);
        setHasMore(true);
        lastFetchedPage.current = 0;
        const result = await dispatch(
          fetchPlaylistSongs({ playlistId, page: 1 })
        ).unwrap();
        lastFetchedPage.current = 1;
        if (result.playlistSongs?.length) {
          dispatch(ADD_PLAYLIST_SONGS(result.playlistSongs));
          setHasMore(result.playlistSongs.length >= 10);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error fetching initial songs:", error);
        setHasMore(false);
      } finally {
        setIsLoading(false);
        setCurrentlyFetching(false);
      }
    };
    fetchInitialSongs();
    // Cleanup function
    return () => {
      if (fetchTimeoutRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(fetchTimeoutRef.current);
      }
    };
  }, [dispatch, playlistId]);

  // to fetch more songs of the playlist
  const handleFetchMoreSongs = useCallback(async () => {
    // Prevent concurrent fetches and duplicate page fetches
    if (currentlyFetching || !hasMore || loading) return;
    try {
      setCurrentlyFetching(true);
      const nextPage = page + 1;
      const result = await dispatch(
        fetchPlaylistSongs({ playlistId, page: nextPage })).unwrap();
      setPage(nextPage);
      if (result.playlistSongs?.length) {
        dispatch(ADD_PLAYLIST_SONGS(result.playlistSongs));
        setHasMore(result.playlistSongs.length >= 10);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching more songs:", error);
      setHasMore(false);
    } finally {
      setCurrentlyFetching(false);
    }
  }, [currentlyFetching, dispatch, hasMore, loading, page, playlistId]);

  // to play all songs of the playlist
  const handlePlayAll = () => {
    dispatch(SET_PLAYING(true));
    dispatch(SET_PLAYINGFROM("playlists"));
    dispatch(SET_SONG(playlistSongs[0]));
    dispatch(SET_VIEWSONG(playlistSongs[0]));
  };

  // to open the add song dialog
    // const handleOpenDialog = () => {
    //   setIsDialogOpen(true);
    // };

  // to close the add song dialog
  // const handleCloseDialog = useCallback(() => {
  //   setIsDialogOpen(false);
  // }, []);

  // to share the playlist
  const handleShare = async () => {
    if (!playlistData || !playlistData.publish) {
      toast.error("This playlist is not public and cannot be shared.");
      return;
    }
    const url = `https://soundofmeme.com/playlist?id=${playlistId}`;
    const shareText = `Check out this playlist: ${playlistName} - ${url}`;
    const shareData = {
      text: shareText,
      url
    };
    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      navigator.clipboard.writeText(url).then(() => {
        toast.success('URL copied to clipboard!', { id: "copyToast" })
      }).catch(err => {
        console.error('Failed to copy:', err);
      });
    }
  };

  // to update the publish status of the playlist
  const handlePublic = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Authentication token is missing");
      return;
    }
    // Add null check for playlistData
    if (!playlistData) {
      toast.error("Playlist data not available");
      return;
    }
    try {
      const newPublishStatus = !playlistData.publish;
      await axios.post("/playlist/update-publish", {
        playlist_slug: playlistId,
        publish: newPublishStatus,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Update local Redux state immediately
      dispatch(UPDATE_PLAYLIST_DATA({
        ...playlistData,
        publish: newPublishStatus,
      }));
      toast.success(newPublishStatus ? "Playlist is now public" : "Playlist is now private");
    } catch (error) {
      console.error("Error during API call:", error.response?.data || error.message);
      toast.error("Failed to update playlist privacy");
    }
  };

  const handleOpenRenamePlaylist = () => {
    setNewPlaylistName(playlistName || "");
    setShowDialog(true);
  };

  // to update the name of the playlist
  const handleUpdatePlaylistName = async () => {
    if (!newPlaylistName) {
      toast.error("Playlist name cannot be empty");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      await axios.post("/playlist/update-name", {
        playlist_slug: playlistId,
        name: newPlaylistName, // Change key to `name`
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Update Redux state immediately to reflect the new name
      dispatch(UPDATE_PLAYLIST_NAME(newPlaylistName));
      toast.success("Playlist name updated successfully");
      setShowDialog(false);
    } catch (error) {
      console.error(
        "Error during API call:",
        error.response?.data || error.message
      );
      toast.error("Failed to update playlist name");
    }
  };

  // to open the delete dialog
  const handleOpenDeleteDialog = () => {
    dispatch(SET_DELETE_PLAYLIST_DIALOG_OPEN(true));
    dispatch(SET_PLAYLIST_TO_DELETE({
      playlist_slug: playlistId,
      name: playlistName,
      cover_image: playlistCoverImage
    }));
  };

  // to connect the wallet
  const handleWalletConnect = () => {
    if (!user || user?.is_guest) {
      dispatch(SET_SHOW_LOGIN(true))
      return toast.error("Login to launch token", { id: "walletConnect" })
    }
    dispatch(SET_LAUNCHTOKENPLAYLIST(playlistData))
    dispatch(SET_LAUNCHTOKENSONG(null))
    dispatch(SET_WALLETBTN(true))
  }

  // to scroll to the active song when the song changes
  useEffect(() => {
    if (activeSong && activeItemRef.current && listRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeSong?.song_id, activeSong]);

  // to set the playing from to playlists when the page is refreshed
  useEffect(() => {
    if (location.pathname === "/playlist") {
      dispatch(SET_PLAYINGFROM("playlists"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dispatch]);

  // to show the loader when the page is loading
  if (loading)
    return (
      <div>
        <PlaylistViewLoader />
      </div>
    );
  // to show the error message when the playlist is not found
  if (error == "Request failed with status code 403")
    return (
      <div className="flex items-center justify-center  min-h-screen bg-slate-950 dark:bg-gradient-to-br dark:from-pink-200 dark:via-pink-300 dark:to-pink-400">
        <TopBar />
        <div className="text-red-500 w-full">
          <div className="relative min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 dark:bg-gradient-to-br dark:from-pink-200 dark:via-pink-300 dark:to-pink-400 overflow-hidden flex flex-col items-center justify-center">
            <div className="z-50 text-center max-w-lg px-6">
              <Lottie animationData={errorAnimation} className="sm:w-56 sm:h-56 w-44 h-44 mx-auto" />
              <h2 className="text-xl font-normal text-green-400 font-potta mb-3">
                Playlist Access Restricted
              </h2>
              <p className="text-gray-400 text-sm mb-6">
                {" "}
                Please try reaching out to the playlist owner. They may have
                changed the playlist&apos;s privacy settings to private. You can
                request them to update the settings to make it public.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    );


  return (
    <div className={"max-w-7xl 2xl:max-w-full h-full  mx-auto bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 dark:bg-gradient-to-br dark:from-pink-100  dark:to-pink-300 rounded-lg overflow-hidden shadow-lg"}>
      <TopBar heading={
        <div className="overflow-hidden">
          <p className="truncate max-w-[250px] xs:max-w-[300px] sm:max-w-[350px] md:max-w-[250px] lg:max-w-[450px] xl:max-w-[500px] 2xl:max-w-[700px] pt-5">
            {playlistName}
          </p>
        </div>} />
      <div className={"flex flex-col lg:flex-row gap-4 min-h-screen overflow-hidden pb-6 pt-16 " + (activeSong ? "mb-12" : "")}>
        <div className=" text-white dark:text-slate-900 min-h-screen overflow-hidden flex w-full">
          <div className="flex-1 ">
            <div className="relative w-full h-[10rem] sm:h-[15rem] bg-gradient-to-b from-slate-900 to-slate-600">
              <div className={`sm:h-[15rem] h-[10rem] ${activeSong ? "lg:h-15" : ""}`}>
                <AudioVisualizer isPlaying={playing} coverImageUrl={playing ? activeSong?.image_url : (playlistCoverImage || playlistImgCover)} />
              </div>
              <div className={`absolute inset-0 flex flex-col items-start p-4 sm:p-6 bg-gradient-to-t from-slate-900/70 dark:from-pink-200/30 to-transparent ${playing ? "justify-start" : "justify-end"}`}>
                <div className=" flex items-center text-sm space-x-1">
                  <h1 className={"truncate  max-w-[250px] xs:max-w-[300px] sm:max-w-[350px] lg:max-w-[450px] xl:max-w-[500px] 2xl:max-w-[700px] text-xl sm:text-4xl font-normal text-transparent bg-clip-text bg-gradient-to-r from-teal-300 via-green-400 to-red-400 dark:from-pink-300 dark:via-pink-400 dark:to-pink-500 font-potta" +
                    (activeSong?.song_id && playing ? " border-none animate-border shadow-lg shadow-cyan-300 dark:shadow-pink-300 rounded-lg " : " border-none")}>
                    {playing ? activeSong?.song_name : playlistName}
                  </h1>{" "}
                  {!isSharedView && !playing && (
                    <Dialog open={showDialog} onOpenChange={setShowDialog}>
                      <DialogTrigger asChild>
                        <button onClick={handleOpenRenamePlaylist} className="text-green-400 hover:text-green-300 dark:text-pink-400 dark:hover:text-pink-300 transition-colors pl-1 bottom-2 relative">
                          <Pencil size={20} className="text-sm fill-green-400 text-black sm:text-xl" />
                        </button>
                      </DialogTrigger>
                      <DialogOverlay className="z-[99]">
                        <DialogContent className="z-[999] truncate bg-gradient-to-r from-slate-950 via-slate-900 to-slate-950 dark:bg-gradient-to-r dark:from-pink-200 dark:via-pink-300 dark:to-pink-400 text-slate-300 dark:text-pink-950 border-green-400 dark:border-pink-400">
                          <DialogHeader>
                            <div className="flex flex-col space-y-2 items-start">
                              <DialogTitle>Add New Playlist</DialogTitle>
                              <DialogDescription className="text-slate-400 dark:text-pink-900 dark:font-semibold font-normal text-xs sm:text-md">
                                Fill in the details for your new playlist.
                              </DialogDescription>
                            </div>
                          </DialogHeader>
                          <div className="space-y-4">
                            <input type="text" placeholder="Playlist Name" value={newPlaylistName || ""} onChange={(e) => setNewPlaylistName(e.target.value)} className="w-full p-2 rounded bg-slate-800 dark:bg-pink-100/60 text-sm text-slate-200 dark:text-pink-950 placeholder-slate-400 dark:placeholder-pink-950 focus:outline-none focus:ring-1 focus:ring-green-300 dark:focus:ring-pink-300 dark:border-2 dark:border-pink-300" />
                          </div>
                          <DialogFooter>
                            <div className="flex flex-row justify-end gap-2">
                              <Button onClick={handleUpdatePlaylistName} className="bg-green-400 hover:bg-green-300 text-slate-700 dark:bg-pink-600 dark:hover:bg-pink-500 dark:text-pink-950">
                                Update
                              </Button>
                              <DialogClose asChild>
                                <Button className="bg-slate-700 hover:bg-slate-800 text-slate-300 dark:bg-pink-100/60 dark:hover:bg-pink-100/70 dark:text-pink-950">
                                  Cancel
                                </Button>
                              </DialogClose>
                            </div>
                          </DialogFooter>
                        </DialogContent>
                      </DialogOverlay>
                    </Dialog>
                  )}
                </div>
                <div className={`flex items-center gap-2 mt-1 md:mt-5 ${playing ? "hidden" : ""} ${playlistSongs.length === 0 ? "opacity-50" : ""}`}>
                  <button onClick={handlePlayAll} disabled={playlistSongs.length === 0} title={playlistSongs.length === 0 ? "Add songs to play all" : ""} className="flex items-center justify-center h-10 w-10 bg-green-400 text-slate-700 dark:bg-pink-400 dark:text-pink-950 font-medium text-sm sm:text-base rounded-full shadow-lg hover:bg-green-600 dark:hover:bg-pink-600 transition-all hover:scale-105 active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100">
                    <ListVideo size={24} />
                  </button>
                  <span className="text-green-400 dark:text-pink-400 text-sm sm:text-base font-normal font-potta ">
                    Play All
                  </span>
                </div>
              </div>

            </div>

            {/* Playlist Controls */}
            <div className="p-4 flex flex-wrap items-center justify-between gap-3 sm:space-x-6 w-full">
              <div className="flex items-center sm:space-x-8 space-x-5 ml-4 sm:ml-8">
                <button onClick={() => handleShare(playlistData)} className="hover:scale-110 transition-transform">
                  <Share2 size={18} className="text-green-400 dark:text-black text-lg" />
                </button>
                {!isSharedView && (
                  <>
                    <button className="flex items-center justify-center focus:outline-none hover:scale-110 active:scale-95 transition-transform" onClick={handlePublic}>
                      {playlistData?.publish ? (
                        <Globe className="text-green-400 dark:text-pink-700 hover:text-green-300 dark:hover:text-pink-600" size={22} title="Make Private" />
                      ) : (
                        <GlobeLock className="text-red-400 dark:text-black hover:text-red-300" size={22} title="Make Public" />
                      )}
                    </button>
                    <button onClick={handleOpenDeleteDialog} className="cursor-pointer focus:text-red-500 dark:focus:text-pink-900 hover:bg-transparent text-red-400 dark:text-pink-900 hover:text-red-300 dark:hover:text-pink-800 flex items-center focus:outline-none rounded hover:scale-110 active:scale-95"                    >
                      <Trash2 size={22} />
                    </button>
                  </>
                )}
                {!playlistData?.token ? (
                  // Author can always see and use the button
                  <div className="group relative">
                    <button onClick={handleWalletConnect} className="relative group flex items-center justify-center w-5 h-5 mr-1 rounded-full border border-1 border-amber-400 dark:border-cyan-400 bg-gradient-to-br from-red-800/70 to-amber-500/20 dark:from-cyan-500/20 dark:to-blue-500/20 hover:bg-gradient-to-bl transition-all duration-300 transform hover:scale-105 shadow-lg shadow-amber-500/20 dark:shadow-red-500/30" >
                      <div className="text-amber-400 dark:text-cyan-300 group-hover:text-amber-200 dark:group-hover:text-red-200 transition-colors duration-300 animate-pulse-slow">
                        <Coins size={12} />
                      </div>
                      <div className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                        <div className="absolute w-full h-full bg-gradient-to-r from-indigo-500/40 to-purple-400/30 dark:from-cyan-400/40 dark:to-blue-400/30 opacity-0 group-hover:opacity-100 blur-[10px] transition-opacity duration-300" />
                      </div>
                    </button>
                    <span className="absolute invisible group-hover:visible -left-2 -translate-x-1/2 -bottom-8 whitespace-nowrap bg-black/90 text-white text-xs py-1 px-2 rounded z-10">
                      Launch token
                    </span>
                  </div>
                ) : (
                  // Token exists - show enabled button with tooltip
                  <div className="group relative">
                    <button onClick={handleWalletConnect} className="relative flex items-center justify-center w-5 h-5 mr-1 rounded-full border border-1 border-amber-400 dark:border-cyan-400 bg-gradient-to-br from-red-800/70 to-amber-500/20 dark:from-cyan-500/20 dark:to-blue-500/20 hover:bg-gradient-to-bl transition-all duration-300 transform hover:scale-105 shadow-lg shadow-amber-500/20 dark:shadow-red-500/30" >
                      <div className="text-amber-400 dark:text-cyan-300 group-hover:text-amber-200 dark:group-hover:text-red-200 transition-colors duration-300 animate-pulse-slow">
                        <Coins size={12} />
                      </div>
                      <div className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                        <div className="absolute w-full h-full bg-gradient-to-r from-indigo-500/40 to-purple-400/30 dark:from-cyan-400/40 dark:to-blue-400/30 opacity-0 group-hover:opacity-100 blur-[10px] transition-opacity duration-300" />
                      </div>
                    </button>
                    <span className="absolute invisible group-hover:visible -left-2 -translate-x-1/2 -bottom-8 whitespace-nowrap bg-black/90 text-white text-xs py-1 px-2 rounded z-10">
                      Launch token
                    </span>
                  </div>
                )}
              </div>
              <div className="flex items-center">
                {!isSharedView && (<button onClick={(e) => { e.preventDefault(); dispatch(SET_ADD_SONG_DIALOG_OPEN(true)); }} className="py-1 px-2 mr-2 sm:mr-4 rounded-md flex items-center text-green-400 border border-green-300 hover:text-green-300 hover:border-green-400 dark:bg-pink-700 dark:text-white dark:border-pink-700 dark:hover:text-white dark:hover:border-pink-400 transition-all hover:scale-105 active:scale-95 text-xs sm:text-sm">
                  <MusicAdd size={20} className="mr-1" />
                  Add Songs
                </button>)}
              </div>
            </div>

            {/* Song List */}
            <div className={"px-0 md:px-6" + (activeSong ? "pb-24" : "pb-0")}>
              <div className="flex flex-row justify-between text-neutral-400 dark:text-black border-b border-neutral-700 dark:border-pink-500 pb-2 mb-2 text-xs sm:text-sm">
                <div className=" flex gap-20 sm:pl-8 pl-4">
                  <span className="text-center sm:block hidden">#</span>
                  <span>Title</span>
                </div>
                <div className="flex gap-8 sm:pr-8 pr-4">
                  <span className="text-center">Actions</span>
                </div>
              </div>

              <div ref={listRef}>
                <InfiniteScroll
                  dataLength={playlistSongs.length}
                  next={handleFetchMoreSongs}
                  hasMore={hasMore && !isLoading && !currentlyFetching}
                  loader={<SongCardSkeleton />}
                  className={" flex-grow py-4 pt-3 w-full grid grid-cols-1 gap-x-4 gap-y-3 max-h-[500px] overflow-y-auto " + (activeSong ? " pb-36 md:pb-28 " : " pb-24 ")}
                  scrollThreshold={0.9}
                  endMessage={null}
                  scrollableTarget="scrollableDiv"
                  // Add throttle to prevent rapid scroll triggers
                  throttle={300}
                >
                  {!isLoading && playlistSongs.map((song) => (
                    <PlaylistSongCard
                      author={false}
                      hasMore={hasMore}
                      loading={isLoading || currentlyFetching}
                      handleFetchMoreSongs={handleFetchMoreSongs}
                      extend={true}
                      song={song}
                      key={song.song_id}
                      playingFrom={"playlists"}
                    />
                  ))}
                  {isLoading && currentlyFetching && <SongCardSkeleton />}
                  {currentlyFetching && <SongCardSkeleton />}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}

      {/* <AddSongDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        className={`pb-1 ${activeSong ? "pb-24" : "pb-0"}`}
      /> */}
      <BottomBar />
    </div>
  );
};

export default PlaylistView;

function SongCardSkeleton() {
  return (
    <div>
      <SongCardMyCreationSkeleton />
      <SongCardMyCreationSkeleton />
      <SongCardMyCreationSkeleton />
      <SongCardMyCreationSkeleton />
      <SongCardMyCreationSkeleton />
    </div>
  );
}
