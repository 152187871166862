import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import PlaylistView from "../playlistview/PlaylistView";
import Playlist from "./Playlist";

const PlaylistWrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [playlistId, setPlaylistId] = useState(null);

  const finalPlaylistId = useMemo(() => {
    // Prioritize playlist ID from search params
    const idFromSearch = new URLSearchParams(location.search).get('id');
    
    // Fallback to ID from location state if needed
    const idFromState = location.state?.from?.search 
      ? new URLSearchParams(location.state.from.search).get('id')
      : null;

    return idFromSearch || idFromState;
  }, [location]);

  useEffect(() => {
    // Handle playlist ID navigation
    if (finalPlaylistId) {
      if (playlistId !== finalPlaylistId) {
        setPlaylistId(finalPlaylistId);
        navigate(`/playlist?id=${finalPlaylistId}`, { 
          replace: true,
          state: { from: location }
        });
      }
    } else {
      // Reset to default playlist view
      if (playlistId !== null) {
        setPlaylistId(null);
        navigate('/playlist', { 
          replace: true,
          state: { from: location }
        });
      }
    }
  }, [finalPlaylistId, playlistId, location, navigate]);

  // Render PlaylistView only if a valid playlist ID exists
  return playlistId ? <PlaylistView id={playlistId} /> : <Playlist />;
};

export default PlaylistWrapper;