import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";

import { useState } from "react";
import UpdateProfile from "../../components/updateProfile/UpdateProfileData";
import EasyCropper from "../../components/updateProfile/UpdateProfileCropImage";
import { useSelector } from "react-redux";

const type = Object.freeze({
  profileContent: "profileContent",
  cropImage: "cropImage",
});

// Main Component
export default function UpdateProfileDialogBox({ isOpen, close }) {
  const { user } = useSelector((state) => state.auth);

  const [imageSrc, setImageSrc] = useState(user.profile_url);
  const [currentDialogContent, setDialogContent] = useState(
    type.profileContent
  );

  const onCroppedImage = (imgBlob) => {
    setDialogContent(type.profileContent);
    setImageSrc(imgBlob);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(val) =>
        !val &&
        (() => {
          setDialogContent(type.profileContent);
          setImageSrc(user.profile_url);
          close();
        })()
      }
    >
      <DialogTitle className="hidden">Update Profile</DialogTitle>
      <DialogDescription className="hidden">
        Here you can update your profile picture, as well as your first and last
        name. You can also view your email address here, but it cannot be
        updated
      </DialogDescription>
      <DialogContent
        className={` w-[90%] sm:w-full flex p-4 md:p-8 flex-col items-center overflow-hidden bg-gradient-to-br from-slate-950 via-slate-800 to-slate-950 dark:from-pink-100 dark:via-pink-300 dark:to-pink-100 border-2 border-green-400/30 dark:border-pink-800/60 rounded-xl shadow-2xl shadow-green-900/20 dark:shadow-pink-900/20 text-slate-300 dark:text-pink-900 z-[999]`}
      >

        {isOpen && (
          <>
            <UpdateProfile
              isVisible={currentDialogContent === type.profileContent}
              setImageSrc={setImageSrc}
              image={imageSrc}
              showCropContent={setDialogContent.bind(null, type.cropImage)}
              close={close}
            />

            {currentDialogContent === type.cropImage && (
              <EasyCropper
                image={imageSrc}
                onCroppedImage={onCroppedImage}
                close={close}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
