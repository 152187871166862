import { SongCardSkeleton } from "@/components/createSong/CreateSongLoading"

const TrendingSongSkeleton = ({ lazyLoad = false }) => {
    return (
        <div className={lazyLoad ? "px-4 mb-2 mt-6" : ""}>
            {lazyLoad && <h2 className="text-xl xl:text-2xl font-comfortaa font-bold text-slate-200 dark:text-black truncate mb-4">
                Daily Hits
            </h2>}
            <div className='flex-grow tsfdtaf py-4 w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-8 ' >
                <SongCardSkeleton />
                <SongCardSkeleton />
                {window.innerWidth >= 640 && <SongCardSkeleton />}
                {window.innerWidth >= 1280 && <SongCardSkeleton />}
                {window.innerWidth >= 1536 && <SongCardSkeleton />}
                {window.innerWidth >= 1920 && <SongCardSkeleton />}
            </div>
        </div>
    )
}

export default TrendingSongSkeleton