import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SHOW_LOGIN } from '@/redux/features/auth/authSlice';
import Lottie from 'lottie-react';
import successAni from "../../assets/success.json";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowRight, Gift, Sparkles } from 'lucide-react';
import TopBar from '@/components/navbar/TopBar';
import BottomBar from '@/components/sidebar/BottomBar';
import premium from "@/assets/premium-badge.svg";
import gold from "@/assets/gold-badge.svg";
import platinum from "@/assets/platinum.svg";
import failedAni from "../../assets/failure.json";
import GiftCodeSkeleton from './GiftCodeSkeleton';

const RedeemSuccess = () => {
    const { code } = useParams();
    const [showAnimation, setShowAnimation] = useState(false);
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const [verifiedData, setVerifiedData] = useState(null);
    const isCodeFromParams = !!code;

    useEffect(() => {
        if (showAnimation) {
            const timer = setTimeout(() => {
                navigate('/');
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [showAnimation, navigate]);

    const handleRedeem = async () => {
        if ([1, 2, 3].includes(user.plan)) {
            toast.error('You cannot redeem a gift with your current plan.');
            return;
        }
        if (user.is_guest) {
            toast.error('Login to redeem gift');
            dispatch(SET_SHOW_LOGIN(true));
            return;
        }
        await redeemGift(code);
    };

    async function redeemGift(giftCode) {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.post(`/redeem-gift`, {
                gift_code: giftCode,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data && response.data.message === "Gift subscription redeemed successfully") {
                setShowAnimation(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                toast.error('Failed to redeem gift. The code may have already been redeemed or is invalid.');
            } else {
                toast.error('An error occurred while redeeming the gift.');
            }
        }
    }

    useEffect(() => {
        async function verifyGiftCode() {
            const toastId = "gift-code-verification";
            try {
                toast.loading("Verifying Gift Code...", { id: toastId });
                const token = localStorage.getItem("token");
                setLoading(true);
                const response = await axios.get(`/verifygiftcode?gift_code=${code}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                toast.dismiss(toastId);
                toast.success("Gift Code Verified Successfully!", { id: toastId, duration: 3000 });
                setVerified(true);
                setVerifiedData(response.data);
                setLoading(false);
            } catch (error) {
                setVerified(false);
                toast.error("Gift Code Verification Failed!", { id: toastId, duration: 3000 });
                setLoading(false);
            }
        }
        if (code) {
            verifyGiftCode();
        }
        return () => {
            toast.dismiss("gift-code-verification");
        };
    }, [code]);

    if (showAnimation) {
        return (
            <div className="fixed inset-0 flex flex-col items-center justify-center bg-gradient-to-br from-slate-950/95 via-slate-900/95 to-slate-800/95 backdrop-blur-sm">
                <div className="w-72 h-72">
                    <Lottie animationData={successAni} loop={false} />
                </div>
                <div className="text-center space-y-3 max-w-md px-6">
                    <h2 className="text-3xl font-bold bg-gradient-to-r from-green-400 via-emerald-500 to-teal-500 bg-clip-text text-transparent animate-pulse">
                        Subscription Redeemed Successfully!
                    </h2>
                    <p className="text-lg text-slate-200 font-medium">
                        Welcome to your {verifiedData?.gift_details?.plan_type === 1 ? 'Premium' : verifiedData?.gift_details?.plan_type === 2 ? 'Gold' : verifiedData?.gift_details?.plan_type === 3 ? 'Platinum' : ''} experience
                    </p>
                    <p className="text-sm text-slate-400">
                        Redirecting you to homepage...
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-slate-900 via-slate-950 to-black">
            <TopBar heading="Redeem Gift" />

            <div className="container mx-auto px-4 pt-28 pb-8">
                {loading && <GiftCodeSkeleton />}
                {verified && !loading && (
                    <Card className="max-w-md mx-auto bg-gradient-to-b from-slate-900 via-slate-950 to-black rounded-2xl px-4 sm:px-10 py-8 shadow-2xl shadow-amber-500/10 border border-amber-500/10">
                        <CardContent className="relative ">
                            <div className="absolute inset-0 bg-gradient-to-r from-amber-500/10 via-transparent to-amber-500/10 rounded-2xl blur-3xl" />
                            <div className="relative z-10 flex flex-col items-center space-y-4 sm:space-y-8">
                                <div className="sm:space-y-3 space-y-1 text-center">
                                    <div className="flex items-center justify-center gap-2">
                                        <Gift className="w-6 h-6 text-green-400" />
                                        <h1 className="text-2xl sm:text-3xl font-bold text-green-400">
                                            Redeem Your Gift
                                        </h1>
                                    </div>
                                    <p className="text-slate-400 text-sm">
                                        {isCodeFromParams ? "Your gift code is ready to be redeemed" : "Enter your coupon code below to activate your subscription"}
                                    </p>
                                </div>

                                <div className="relative group">
                                    <div className="rounded-full overflow-hidden flex-shrink-0 sm:w-40 w-32 ">
                                        <img src={verifiedData?.gift_details?.plan_type === 1 ? premium : verifiedData?.gift_details?.plan_type === 2 ? gold : verifiedData?.gift_details?.plan_type === 3 ? platinum : ''} alt="rocket" className=" w-full scale-125 xl:scale-110 object-cover" />
                                    </div>
                                </div>

                                <div className="text-center flex flex-col sm:gap-2 gap-1">
                                    <div className="flex items-center justify-center sm:gap-2 gap-1">
                                        <Sparkles className="w-5 h-5 text-emerald-400" />
                                        <h3 className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-emerald-400 to-green-400 bg-clip-text text-transparent">
                                            {verifiedData?.gift_details?.plan_type === 1 ? 'Premium' : verifiedData?.gift_details?.plan_type === 2 ? 'Gold' : 'Platinum'} Plan
                                        </h3>
                                    </div>
                                    <p className="text-base text-slate-300 font-medium">
                                        {verifiedData?.gift_details?.duration_months} {verifiedData?.gift_details?.duration_months === 1 ? 'Month' : 'Months'}
                                    </p>
                                </div>

                                <div className="w-full">
                                    <Button onClick={handleRedeem} className="w-full h-12 bg-gradient-to-r from-emerald-500 to-green-500 hover:from-emerald-600 hover:to-green-600 text-black font-medium transition-all duration-300 shadow-lg shadow-emerald-500/25 hover:shadow-emerald-500/40">
                                        <span>Redeem Now</span>
                                        <ArrowRight className="w-5 h-5 ml-2" />
                                    </Button>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                )}
                {(!verified && !loading) && (
                    <div className="flex flex-col items-center justify-center h-screen w-full max-w-md mx-auto">
                        <div className="w-full max-w-[240px] mb-8">
                            <Lottie animationData={failedAni} loop={true} />
                        </div>
                        <h1 className="text-4xl text-center font-bold bg-gradient-to-r from-red-400 via-rose-500 to-red-500 bg-clip-text text-transparent">
                            Gift Code Invalid
                        </h1>
                        <p className="mt-4 text-slate-400 text-center">
                            The gift code you entered has expired or is invalid. Please check and try again.
                        </p>
                    </div>
                )}
            </div>
            <BottomBar />
        </div>
    );
};

export default RedeemSuccess;