import TopBar from '@/components/navbar/TopBar';
import BottomBar from '@/components/sidebar/BottomBar';

const PlaylistViewLoader = () => {
  return (
    <div className="max-w-7xl 2xl:max-w-full h-full mx-auto bg-dark dark:bg-darker rounded-lg overflow-hidden shadow-lg">
      <TopBar heading={
        <div className="h-6 bg-gray-700 dark:bg-pink-600 rounded w-48 animate-pulse" />
      } />

      <div className="flex flex-col lg:flex-row gap-4 min-h-screen overflow-hidden pb-6 pt-16">
        <div className="text-white min-h-screen overflow-hidden flex w-full">
          <div className="flex-1">
            {/* Playlist Header */}
            <div className="relative w-full h-[10rem] sm:h-[15rem] bg-gradient-to-b from-gray-800 to-gray-700 dark:from-pink-900 dark:to-pink-800 animate-pulse">
              <div className="absolute inset-0 flex flex-col justify-between p-4 sm:p-6 bg-gradient-to-t from-gray-900/70 dark:from-pink-600 to-transparent">
                <div className="flex items-center gap-3">
                  <div className="h-10 w-10 rounded-full bg-gray-700 dark:bg-pink-600" />
                  <div className="h-6 bg-gray-700 dark:bg-pink-600 rounded w-24" />
                </div>
                <div className="h-8 sm:h-12 bg-gray-700 dark:bg-pink-600 rounded w-3/4" />
              </div>
            </div>

            {/* Controls */}
            <div className="p-4 flex flex-wrap items-center justify-between gap-3 sm:space-x-6 w-full">
              <div className="flex items-center sm:space-x-8 space-x-5 ml-4 sm:ml-8">
                {[...Array(3)].map((_, i) => (
                  <div key={i} className="h-6 w-6 bg-gray-700 dark:bg-pink-600 rounded-full animate-pulse" />
                ))}
              </div>
              <div className="h-8 w-24 bg-gray-700 dark:bg-pink-600 rounded animate-pulse" />
            </div>

            {/* Song List */}
            <div className="px-4 md:px-6">
              <div className="flex flex-row justify-between text-gray-400 border-b border-gray-700 dark:border-pink-600 pb-2 mb-2">
                <div className="flex gap-20 sm:pl-8 pl-4">
                  <span className="sm:block hidden">#</span>
                  <span>Title</span>
                </div>
                <div className="flex gap-8 sm:pr-8 pr-4">
                  <span>Actions</span>
                </div>
              </div>

              {[...Array(8)].map((_, index) => (
                <div key={index} className="flex items-center justify-between py-2 px-2 sm:px-4 rounded mb-2 animate-pulse">
                  <div className="flex gap-8 pl-1">
                    <span className="w-8 text-center hidden sm:block">{index + 1}</span>
                    <div className="flex items-center min-w-0">
                      <div className="w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4 bg-gray-700 dark:bg-pink-600 rounded" />
                      <div className="flex-grow">
                        <div className="h-4 bg-gray-700 dark:bg-pink-600 rounded w-32 sm:w-48 mb-2" />
                        <div className="h-3 bg-gray-700 dark:bg-pink-600 rounded w-24 sm:w-32" />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-8 pr-4">
                    <div className="h-6 w-6 sm:w-8 sm:h-8 bg-gray-700 dark:bg-pink-600 rounded" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default PlaylistViewLoader;