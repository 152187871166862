import toast from 'react-hot-toast';
import { Forward } from 'lucide-react';

const ShareSongButton = ({ position, song, viewSong, showViewSong }) => {

    // copy song link
    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            console.log('URL copied to clipboard!');
            toast.success('URL copied to clipboard!', { id: "copyToast" })
        }).catch(err => {
            console.error('Failed to copy:', err);
        });
    };

    // handle share song
    const handleShare = async (song) => {
        const url = `https://soundofmeme.com/song/${song?.slug}`;
        const title = 'Check out this song!';
        // const text = song?.song_name + ":";

        if (navigator.share) {
            try {
                await navigator.share({
                    title,
                    // text,
                    url
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            copyToClipboard(url)
        }
    };

    return (
        <>
            {
                position === 'bigScreen' ?
                    <button type='button' className=' relative z-10 focus:outline-none text-white hover:text-green-400 dark:text-pink-500 dark:hover:text-pink-900'
                        onClick={(e) => {
                            e.stopPropagation()
                            handleShare(song)
                        }} ><Forward strokeWidth={3} size={24} />
                    </button>
                    : position === 'smallScreen' ?
                        <button type='button' className=' relative z-10 focus:outline-none text-white'
                            onClick={(e) => {
                                e.stopPropagation()
                                handleShare(song)
                            }} ><Forward strokeWidth={3} size={24} />
                        </button>
                        : position === 'viewSongTop' ?
                            <button type='button' className=' focus:outline-none text-white dark:text-pink-950 ' onClick={() => showViewSong ? handleShare(viewSong) : handleShare(song)} ><Forward strokeWidth={3.3} size={28} /> </button>
                            : <button type='button' className=' focus:outline-none text-white dark:text-pink-950' onClick={() => handleShare(song)} ><Forward strokeWidth={4} size={24} /> </button>

            }
        </>
    )
}

export default ShareSongButton