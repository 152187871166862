import TopBar from "@/components/navbar/TopBar"
import BottomBar from "@/components/sidebar/BottomBar"
import SongCard from "@/components/songCard/LazyLoadSongCard"
import { SET_SEARCHSONGS, SET_TERM } from "@/redux/features/music/searchSlice"
import axios from "axios"
import { debounce } from "@/utils/debounce"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SongCardSkeleton } from "@/components/createSong/CreateSongLoading"

const Search = () => {
    const dispatch = useDispatch()
    const { term, searchSongs } = useSelector(state => state.search)
    const { song } = useSelector(state => state.music)
    const [noResultMsg, setNoResultMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // Define the debounced function directly inside useEffect
    useEffect(() => {
        const debouncedSearch = debounce(async () => {
            if (!term) return;
            try {
                setIsLoading(true)
                const response = await axios.get(`/search?item=${term}`);
                dispatch(SET_SEARCHSONGS(response.data.songs))
                setIsLoading(false)
                if (response?.data?.songs?.length === 0) {
                    setNoResultMsg(true)
                } else {
                    setNoResultMsg(false)
                }
            } catch (error) {
                setIsLoading(false)
                console.error('Search failed:', error);
            }
        }, 500);
        debouncedSearch();
        return () => {
            debouncedSearch.cancel();
        };
    }, [term, dispatch]);

    const handleChange = async (e) => {
        dispatch(SET_TERM(e.target.value))
        // setIsLoading(true)
    }

    useEffect(() => {
        if (term === "") {
            setNoResultMsg(false)
            dispatch(SET_SEARCHSONGS([]))
            setIsLoading(false)
        }
    }, [dispatch, term])

    return (
        <div className='relative min-h-screen bg-slate-950 dark:bg-gradient-to-br dark:from-pink-200 dark:via-pink-300 dark:to-pink-200 overflow-hidden flex flex-col flex-grow w-full'>
            <TopBar heading={"Search"} />
            <div className="pt-16">
                <div className='mt-8 pb-2 flex justify-between gap-4 px-4'>
                    <input type='text' placeholder='Search all songs' onChange={handleChange} value={term} className='flex-grow bg-transparent dark:bg-pink-300 border border-slate-700 dark:border-pink-700/75 p-2 rounded w-full focus:outline-green-700 dark:focus:outline-pink-700 text-slate-200 dark:text-slate-800 placeholder:text-slate-500 dark:placeholder:text-slate-700' />
                </div>
                {searchSongs.length > 0 && !noResultMsg && !isLoading && (
                    <>
                        <div className={'sm:p-4 p-2 w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 sm:gap-y-8 gap-y-4 ' + (song ? "pb-36 md:pb-28" : "pb-24")}>
                            {searchSongs && searchSongs.map((song) => (
                                <SongCard key={song?.song_id} song={song} playingFrom={"search"} />
                            ))}
                        </div>
                    </>
                )}
                {!isLoading && !noResultMsg && searchSongs.length === 0 && (
                    <p className="px-4 mt-4 text-slate-300 dark:text-slate-700">
                        Ready to find your next favorite song? Start searching now!
                    </p>
                )}
                {isLoading && !noResultMsg && (
                    <>
                        <div className={'sm:p-4 p-2 w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 sm:gap-y-8 gap-y-4 ' + (song ? "pb-36 md:pb-28" : "pb-24")}>
                            <Loader />
                        </div>
                    </>
                )}
                {noResultMsg && !isLoading && (
                    <p className="px-4 mt-4 text-slate-300 dark:text-slate-700">
                        No results found. Try different search terms.
                    </p>
                )}
            </div>
            <BottomBar />
        </div>
    )
}
export default Search

function Loader() {
    return (
        <>
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
        </>
    )
}