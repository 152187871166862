import { SET_LEADSONGS, SET_CRYPTO_LEADSONGS } from "@/redux/features/music/leaderboardSlice";
import { ADD_LIKESONG, REMOVE_LIKESONG, SET_DETAIL_PAGE_SONG, SET_DISCSONGS, SET_LIKEDSONGS, SET_USERSONGS } from "@/redux/features/music/musicSlice";
import { SET_PLAYLIST_SONGS } from "@/redux/features/music/playlistSlice";
import { SET_SEARCHSONGS } from "@/redux/features/music/searchSlice";
import { SET_VALENTINE_SONGS, SET_VALENTINE_SONGS_FROM_USER } from "@/redux/features/theme/extradialogs";
import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Heart } from "lucide-react";

const LikeButtton = ({ position, liked, setLiked }) => {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const [likedLoading, setLikedLoading] = useState(false)
    const { songs } = useSelector(state => state.playlists)
    const { song, discoverSongs, userSongs, likedSongs, playingFrom, detailsPageSong } = useSelector(state => state.music);
    const { leaderBoardSongs, cryptoLeaderboardSongs } = useSelector(state => state.leaderboard)
    const { valentineSongs, valentineSongsFromUser } = useSelector(state => state.extradialogs)
    const { searchSongs } = useSelector(state => state.search)

    // get songs source
    const getSongsFromSource = (source, allSongs) => {
        const { songs, discoverSongs, userSongs, likedSongs, searchSongs, leaderBoardSongs, valentineSongs, valentineSongsFromUser, cryptoLeaderboardSongs } = allSongs;
        const sourceMap = {
            "playlists": songs,
            "discoverSongs": discoverSongs,
            "userSongs": userSongs,
            "likedSongs": likedSongs,
            "leaderBoard": leaderBoardSongs,
            "cryptoLeaderboard": cryptoLeaderboardSongs,
            "valentineSongs": valentineSongs,
            "valentineSongsFromUser": valentineSongsFromUser,
            "searchSongs": searchSongs
        };
        return sourceMap[source] || [];
    };

    // Update songs like status
    const updateSongsInStore = (updatedSongs, playingFrom, dispatch) => {
        const dispatchMap = {
            "playlists": SET_PLAYLIST_SONGS,
            "discoverSongs": SET_DISCSONGS,
            "userSongs": SET_USERSONGS,
            "likedSongs": SET_LIKEDSONGS,
            "leaderBoard": SET_LEADSONGS,
            "cryptoLeaderboard": SET_CRYPTO_LEADSONGS,
            "valentineSongs": SET_VALENTINE_SONGS,
            "valentineSongsFromUser": SET_VALENTINE_SONGS_FROM_USER,
            "searchSongs": SET_SEARCHSONGS
        };
        const action = dispatchMap[playingFrom];
        if (action) {
            dispatch(action(updatedSongs));
        }
    };

    // Simplified handleLike function
    const handleLike = async (songId) => {
        const token = window.localStorage.getItem('token');
        if (!user || !token) {
            return toast.error("Login to like the song", { id: "likeSong" });
        }
        try {
            setLikedLoading(true);
            const isLiking = !liked;
            const endpoint = isLiking ? "/like" : "/dislike";
            const loadingMessage = isLiking ? "Adding to liked songs" : "Removing from liked songs";
            toast.loading(loadingMessage, { id: "likeSong" });
            await axios.post(endpoint,
                { song_id: songId },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (isLiking) {
                dispatch(ADD_LIKESONG(song));
            } else {
                dispatch(REMOVE_LIKESONG(songId));
            }
            const currentSongs = getSongsFromSource(playingFrom, {
                songs, discoverSongs, userSongs, likedSongs, searchSongs, leaderBoardSongs, valentineSongs, valentineSongsFromUser, cryptoLeaderboardSongs
            });
            const updatedSongs = currentSongs.map(song =>
                song?.song_id === songId
                    ? {
                        ...song,
                        is_liked: isLiking,
                        likes: song?.likes + (isLiking ? 1 : -1)
                    }
                    : song
            );
            updateSongsInStore(updatedSongs, playingFrom, dispatch);
            if (song?.song_id === detailsPageSong?.song_id) {
                dispatch(SET_DETAIL_PAGE_SONG({ ...detailsPageSong, is_liked: isLiking }))
            }
            // Update UI state
            setLikedLoading(false);
            setLiked(isLiking);
            toast.success(isLiking ? "Like Added" : "Like Removed", { id: "likeSong" });
        } catch (error) {
            console.log(error);
            setLikedLoading(false);
            toast.error(error.response.data.detail || "Error Occurred", { id: "likeSong" });
        }
    };

    return (
        <>
            {position === "bigScreen" ?
                <button disabled={likedLoading} type='button' className='disabled:opacity-50 relative z-10 focus:outline-none text-white dark:text-slate-700 hover:text-green-400 dark:hover:text-pink-700' onClick={(e) => { e.stopPropagation(); handleLike(song?.song_id) }} >
                    {liked ? <Heart className='text-green-400 fill-green-400 dark:text-pink-700 dark:fill-pink-700' size={20} /> : <Heart className='text-white dark:text-pink-700' size={20} />}
                </button>
                : position === "smallScreen" ?
                    <button disabled={likedLoading} type='button' className=' disabled:opacity-50  relative z-10 focus:outline-none text-white  '
                        onClick={(e) => {
                            e.stopPropagation()
                            handleLike(song?.song_id)
                        }} >
                        {liked ? <Heart className='text-green-400 fill-green-400 dark:text-pink-700 dark:fill-pink-700' size={24} /> : <Heart className='text-white dark:text-pink-700' size={24} />}
                    </button>
                    :
                    <button disabled={likedLoading} type='button' className=' disabled:opacity-50 relative z-10 focus:outline-none text-white dark:text-pink-500 ' onClick={() => handleLike(song?.song_id)} > {liked ? <Heart className='text-green-400 fill-green-400 dark:text-pink-700' size={24} /> : <Heart className='text-white dark:text-pink-700' size={24} />} </button>
            }
        </>
    )
}

export default LikeButtton