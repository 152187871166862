import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';

// Add this utility function at the top of the file
// const removeDuplicateSongs = (songs) => {
//   const uniqueSongs = new Map();
//   songs.forEach(song => {
//     if (!uniqueSongs.has(song.song_id)) {
//       uniqueSongs.set(song.song_id, song);
//     }
//   });
//   return Array.from(uniqueSongs.values());
// };

//Change to playlist slug
export const fetchPlaylists = createAsyncThunk(
  'playlists/fetchPlaylists',
  async (page, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/playlists?page=${page}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      return response.data.playlists || [];
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);



//Changed to playlist slug
export const deletePlaylist = createAsyncThunk(
  'playlists/deletePlaylist',
  async (playlistId, { rejectWithValue }) => {
    try {
      await axios.post(
        `/playlist/delete?playlist_slug=${playlistId}`,
        {},  // empty body
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      return playlistId;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);

    }
  }
);



//Changed to playlist slug
export const addPlaylist = createAsyncThunk(
  'playlist/addPlaylist',
  async ({ playlistName, publish }, { rejectWithValue }) => {
    const token = localStorage.getItem('token');

    if (!token) {
      toast.error("Authorization token missing. Please log in again.");
      return rejectWithValue("Authorization token missing.");
    }

    try {
      const response = await axios.post(
        '/playlist/create',
        { name: playlistName, publish: publish },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      toast.success("Playlist added successfully");
      return response.data;
    } catch (error) {
      console.error('Error creating playlist:', error);
      if (error.response?.status === 403) {
        toast.error("Playlist creation limit reached. Upgrade plan or delete playlists.");
        return rejectWithValue(error.response.data.message);
      }
      if (error.response && error.response.data) {
        toast.error(`Failed to create playlist: ${error.response.data.message}`);
        return rejectWithValue(error.response.data.message);
      } else {
        toast.error("Failed to create playlist");
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchPlaylistSongs = createAsyncThunk(
  'playlists/fetchPlaylistSongs',
  async ({ playlistId, page }, { rejectWithValue }) => {
    if (!playlistId) return rejectWithValue('Invalid playlist ID');

    try {
      const response = await axios.get(`/playlist/${playlistId}?page=${page}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const {
        songs = [], // Default to empty array if undefined
        name = "My Playlist",
        publish,
        user: playlistOwner
      } = response.data || {};

      const playlistCoverImage = response.data.cover_image;

      return {
        playlistSongs: songs, // Return songs as playlistSongs
        name,
        playlistCoverImage,
        publish,
        playlistOwner,
        playlistSlug: playlistId,
        playlistId: response.data.id
      };
    } catch (error) {
      if (error.response?.status === 404) {
        return rejectWithValue('Playlist not found');
      }
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


export const fetchSongs = createAsyncThunk(
  'playlists/fetchSongs',
  async ({page}, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/allsongs?page=${page}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      return {
        dialogSongs: response.data.songs || []
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


//Changed to playlist slug
export const removeSong = createAsyncThunk(
  'playlists/removeSong',
  async ({ songId, playlistId }, { rejectWithValue }) => {
    try {
       await axios.post('/playlist/remove', {
        song_id: songId,
        playlist_slug: playlistId
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      // console.log('Remove song response:', response.data);
      return { songId };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


//Changed to playlist slug
export const addSong = createAsyncThunk(
  'playlists/addSong',
  async ({ songId, playlistId }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/playlist/add', {
        song_id: songId,
        playlist_slug: playlistId,
      },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })

      // await dispatch(fetchPlaylistSongs({ playlistId, page: 1 }));

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);

    }
  }
);

const playlistsSlice = createSlice({
  name: 'playlists',
  initialState: {
    playlists: [],
    // playlistsUpdated: false,
    playlistCoverImage: null,
    page: 1,
    status: null,
    error: null,
    songs: [],
    dialogSongs: [],
    playlistName: '',
    isLoading: false,
    hasMore: false,
    playlistData: {
      publish: false,
      name: '',
      playlistCoverImage: null,
    },
    dialogOpen: false,
    songToAdd: null,
    isDeletePlaylistDialogOpen: false,
    playlistToDelete: null,
    addSongDialogOpen: false,
  },

  reducers: {
    setPlaylistCoverImage: (state, action) => {
      state.playlistCoverImage = action.payload;
    },
    APPEND_SONG_TO_PLAYLIST: (state, action) => {
      const newSongs = Array.isArray(action.payload) ? action.payload : [action.payload];
      const existingIds = new Set(state.songs.map(song => song.song_id));
      const filteredSongs = newSongs.filter(song => !existingIds.has(song.song_id));
      state.songs = [...state.songs, ...filteredSongs];
    },
    SET_PLAYLIST_SONGS(state, action) {
      state.songs = action.payload;
    },
    ADD_PLAYLIST_SONGS(state, action) {
      state.songs = [...state.songs, ...action.payload];
    },
    SET_DIALOG_SONGS(state, action) {
      state.dialogSongs = action.payload;
    },
    ADD_DIALOG_SONGS(state, action) {
      state.dialogSongs = [...state.dialogSongs, ...action.payload];
    },
    SET_PLAYLIST: (state, action) => {
      state.playlists = action.payload;
    },
    ADD_PLAYLIST: (state, action) => {
      state.playlists = [ action.payload, ...state.playlists];
    },
    APPEND_PLAYLIST_TO_PLAYLIST: (state, action) => {
      state.playlists = [  ...state.playlists,...action.payload];
    },
    REMOVE_DELETED_SONG: (state, action) => {
      state.songs = state.songs.filter(song => song.song_id !== action.payload);
    },
    removePlaylist: (state, action) => {
      state.playlists = state.playlists.filter(
        playlist => playlist.playlist_slug !== action.payload
      );
    },
    UPDATE_PLAYLIST_DATA: (state, action) => {
      state.playlistData = { ...state.playlistData, ...action.payload }; // Update playlistData directly
    },
    UPDATE_PLAYLIST_NAME: (state, action) => {
      state.playlistName = action.payload;
    },
    SET_DIALOG_OPEN_TWO(state, action) {
      state.dialogOpen = action.payload;
    },
    SET_SONG_TO_ADD_TWO(state, action) {
      state.songToAdd = action.payload;
    },
    SET_DELETE_PLAYLIST_DIALOG_OPEN(state, action) {
      state.isDeletePlaylistDialogOpen = action.payload;
    },
    SET_PLAYLIST_TO_DELETE(state, action) {
      state.playlistToDelete = action.payload;
    },
    SET_ADD_SONG_DIALOG_OPEN(state, action) {
      state.addSongDialogOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlaylists.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchPlaylists.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.playlists = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPlaylists.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.isLoading = false;

      })
      // eslint-disable-next-line no-unused-vars
      .addCase(deletePlaylist.pending, (state, action) => {
        state.isLoading = true;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(deletePlaylist.fulfilled, (state, action) => {
        toast.success("Playlist deleted successfully");
        state.isLoading = false;
      })
      .addCase(deletePlaylist.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(addPlaylist.fulfilled, (state, action) => {
        // state.status = 'succeeded';
      })
      .addCase(addPlaylist.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchPlaylistSongs.pending, (state) => { state.isLoading = true; state.error = null; })
      .addCase(fetchPlaylistSongs.fulfilled, (state, action) => {
        const { name, playlistCoverImage } = action.payload;
        state.isLoading = false;
        
        // if (action.meta.arg.page === 1) {
        //   state.songs = playlistSongs || [];
        // } else {
        //   // Combine existing and new songs, then remove duplicates
        //   const combinedSongs = [...state.songs, ...(playlistSongs || [])];
        //   state.songs = removeDuplicateSongs(combinedSongs);
        // }
        
        state.playlistName = name || '';
        state.playlistCoverImage = playlistCoverImage;
        state.playlistData = action.payload;
        state.playlistOwner = action.payload.playlistOwner;
      })
      .addCase(fetchPlaylistSongs.rejected, (state, action) => { state.isLoading = false; state.error = action.payload; })
      // New fetchSongs 
      .addCase(fetchSongs.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchSongs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.meta.arg.page === 1) {
          state.dialogSongs = action.payload.dialogSongs || [];
        } else {
          state.dialogSongs = [...state.dialogSongs, ...(action.payload.dialogSongs || [])];
        }
      })
      .addCase(fetchSongs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // New removeSong 
      .addCase(removeSong.pending, (state) => {
        state.status = 'loading';
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(removeSong.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(removeSong.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addSong.pending, (state) => {
        state.status = 'loading';
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(addSong.fulfilled, (state, action) => {
        state.status = 'succeeded';

      })

      .addCase(addSong.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });

  }
});

export const { SET_ADD_SONG_DIALOG_OPEN, SET_DELETE_PLAYLIST_DIALOG_OPEN, SET_PLAYLIST_TO_DELETE, ADD_PLAYLIST,SET_PLAYLIST,SET_DIALOG_SONGS,ADD_DIALOG_SONGS,SET_PLAYLIST_SONGS,ADD_PLAYLIST_SONGS,SET_DIALOG_OPEN_TWO, SET_SONG_TO_ADD_TWO, UPDATE_PLAYLIST_NAME, UPDATE_PLAYLIST_DATA, removePlaylist, APPEND_PLAYLIST_TO_PLAYLIST, REMOVE_DELETED_SONG, setPlaylistCoverImage, APPEND_SONG_TO_PLAYLIST } = playlistsSlice.actions;
export default playlistsSlice.reducer;