import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "./authService";
import toast from "react-hot-toast";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase";
import { getDeviceFingerprint } from "@/utils/fingerprint";
import axios from "axios";
import { trackMetaEvent } from "@/utils/metaPixel";
import { trackRedditEvent } from "@/utils/redditPixel";

const initialState = {
  authLoading: false,
  isLoggedIn: false,
  authDialog: false,
  otpCard: false,
  user: null,
  showLogin: false,
  referralDialog: false,
  affiliateDialog: false,
  maintenanceMode: false,
};

export const loginUser = createAsyncThunk(
  "auth/login",
  async (formData, thunkAPI) => {
    try {
      const response = await authService.loginUser(formData);
      localStorage.removeItem("guestToken")
      return response;

    } catch (error) {
      console.log(error);
      const message = error?.response?.data?.detail || "Error logging in";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const signUpUser = createAsyncThunk(
  "auth/signup",
  async (formData, thunkAPI) => {
    try {
      const response = await authService.signUpUser(formData);
      const utmParams = JSON.parse(localStorage.getItem('utmParams')) || {};
      const utmSource = utmParams.utm_source || 'unknown';
 
      logEvent(analytics, "sign_up", {
        method: "email",
        email: formData.email,
      });
      if (utmSource.includes('meta')) {
        trackMetaEvent('CompleteRegistration', {
          method: 'email',
          email: formData.email
        })
      } else if (utmSource.includes('reddit')) {
        trackRedditEvent('SignUp', {
          method: 'email',
          email: formData.email
        })
      }
      sessionStorage.removeItem('refCode')
      return response;
    } catch (error) {
      console.log(error);
      const message = error?.response?.data?.detail || "Error Creating Account";
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (formData, thunkAPI) => {
    try {
      const response = await authService.changePassword(formData);
      toast.success("Password updated successfully");
      return response;
    } catch (error) {
      console.log(error);
      const message =
        error?.response?.data?.detail || "Error changing password";
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (formData, thunkAPI) => {
    try {
      return await authService.forgotPassword(formData);
    } catch (error) {
      console.log(error);
      const message =
        error?.response?.data?.detail || "Error sending the reset link";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (formData, thunkAPI) => {
    try {
      return await authService.resetPassword(formData);
    } catch (error) {
      console.log(error);
      const message =
        error?.response?.data?.detail || "Error sending the reset link";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verify",
  async (formData, thunkAPI) => {
    try {
      const response = await authService.verifyOtp(formData);
      localStorage.removeItem("guestToken")
      return response;
    } catch (error) {
      console.log(error);
      const message = error?.response?.data?.detail || "Error Verifying OTP";
      return thunkAPI.rejectWithValue(message);
    }

  }
);
export const getUserDetails = createAsyncThunk(
  "auth/getUser",
  async (token, thunkAPI) => {
    try {
      return await authService.getUserDetails({ token });
    } catch (error) {
      console.log(error);
      const message = error?.response?.data?.detail || "Error getting user details";
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        try {
          const fingerprint = await getDeviceFingerprint()
          const { data } = await axios.post(`/guest`, { fingerprint })
          localStorage.setItem('guestToken', data.access_token)
          return await authService.getUserDetails({ token: data?.access_token })
        } catch (error) {
          console.log(error);
          const message = error?.response?.data?.detail || "Error getting user details";
          return thunkAPI.rejectWithValue({ message, code: error.code });
        }
      }
      return thunkAPI.rejectWithValue({ message, code: error.code });
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    PATCH_USER(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
    SET_ISLOADING(state, action) {
      state.isLoading = action.payload;
    },
    SET_LOGIN(state, action) {
      state.isLoggedIn = action.payload;
    },
    SET_AUTH_DIALOG(state, action) {
      state.authDialog = action.payload;
    },
    SET_USER(state, action) {
      state.user = action.payload;
    },
    SET_OTPCARD(state, action) {
      state.otpCard = action.payload;
    },
    SET_CREDITS(state, action) {
      state.user.credits = action.payload;
    },
    SET_SHOW_LOGIN(state, action) {
      state.showLogin = action.payload;
    },
    SET_REFERRAL_DIALOG(state, action) {
      state.referralDialog = action.payload;
    },
    SET_AFFILIATE_DIALOG(state, action) {
      state.affiliateDialog = action.payload;
    },
    SET_MAINTENANCE_MODE(state, action) {
      state.maintenanceMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // login user cases
      .addCase(loginUser.pending, (state) => {
        state.authDialog = true;
        toast.loading("Logging In", { id: "login" });
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        if (action.payload?.access_token) {
          state.authLoading = false;
          state.isLoggedIn = true;
          state.authDialog = false;
          localStorage.setItem("token", action.payload?.access_token);
          toast.success("Logged In", { id: "login" });
        } else if (action.payload?.session_token) {
          state.authLoading = false;
          state.otpCard = true;
          sessionStorage.setItem("otpToken", action.payload?.session_token);
          toast.success("OTP Sent to mail", { id: "login" });
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.authLoading = false;
        toast.error(action.payload, { id: "login" });
      })
      // signup user cases
      .addCase(signUpUser.pending, (state) => {
        state.authDialog = true;
        toast.loading("Sending Verification OTP...", { id: "signup" });
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        const { session_token } = action.payload;
        state.authLoading = false;
        state.otpCard = true;
        sessionStorage.setItem("otpToken", session_token);
        toast.success("OTP Sent to mail", { id: "signup" });
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.authLoading = false;
        state.isLoggedIn = false;
        toast.error(action.payload, { id: "signup" });
      })
      // change password cases
      .addCase(changePassword.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.authLoading = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.authLoading = false;
      })
      // forgot password cases
      .addCase(forgotPassword.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.authLoading = false;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.authLoading = false;
      })
      // verify OTP cases
      .addCase(verifyOtp.pending, (state) => {
        state.authDialog = true;
        toast.loading("Verifying OTP...", { id: "verify" });
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        const { access_token } = action.payload;
        state.authLoading = false;
        state.isLoggedIn = true;
        state.authDialog = false;
        state.otpCard = false;
        sessionStorage.removeItem("otpToken");
        localStorage.setItem("token", access_token);
        toast.success("Account Verified", { id: "verify" });
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.authLoading = false;
        state.isLoggedIn = false;
        toast.error(action.payload, { id: "verify" });
      })
      // get user details cases
      .addCase(getUserDetails.pending, () => {
        // toast.loading("Logging In", { id: "login" })
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoggedIn = true;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
        if (action.payload.code === "ERR_NETWORK") {
          state.maintenanceMode = true;
        }
      });
  },
});

export const {
  PATCH_USER,
  SET_LOGIN,
  SET_AUTH_DIALOG,
  SET_USER,
  SET_ISLOADING,
  SET_OTPCARD,
  SET_CREDITS,
  SET_SHOW_LOGIN,
  SET_REFERRAL_DIALOG,
  SET_AFFILIATE_DIALOG,
  SET_MAINTENANCE_MODE,
} = authSlice.actions;

export default authSlice.reducer;
