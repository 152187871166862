import { Link } from 'react-router-dom';
import imgGreenBird from '../../assets/greenbird.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Optional for blur effect

const PlaylistCard = ({ playlist }) => {
    const stackedCards = [1, 2, 3]
    return (
        <Link to={`/playlist?id=${playlist?.playlist_slug}`}>
            <div className="relative w-full aspect-square flex items-center justify-start sm:justify-center hover:rotate-1 transition-transform duration-300 ease-in-out">
                {stackedCards.map((_, index) => (
                    <div key={`${playlist?.playlist_id}-${index}`}
                        className={`absolute w-5/6 h-3/4 sm:w-full sm:h-full md:w-full md:h-full rounded-lg ${index === 0 ? 'z-30 hover:-translate-x-1 hover:-rotate-2 transition-transform duration-300' : ''} ${index === 1 ? 'z-20 -translate-y-0 rotate-2 translate-x-2' : ''} ${index === 2 ? 'z-10 -translate-y-0 rotate-3 translate-x-4' : ''}`}                    >
                        <div className={`relative w-full h-full rounded-lg p-[2px] bg-gradient-to-r from-green-500 to-green-300 shadow-[0_0_10px_rgba(34,197,94,0.5)] dark:bg-gradient-to-r dark:from-pink-500 dark:to-pink-300 dark:shadow-[0_0_10px_rgba(229,30,120,0.5)]${index !== 0 ? 'opacity-50' : ''}`}>
                            <div className="relative w-full h-full bg-gray-900 rounded-lg overflow-hidden">
                                <div className="absolute top-2 left-2 flex justify-between items-center z-20 w-full">
                                    <div className="bg-black/50 text-white text-xs px-2 py-1 rounded-full flex items-center">
                                        <span className="mr-1">
                                            {playlist?.song_count === 1 ? "1 Song" : `${playlist?.song_count || 0} Songs`}
                                        </span>
                                    </div>
                                </div>
                                <LazyLoadImage src={playlist.cover_image || imgGreenBird} alt={playlist.name} className="w-full h-full object-cover" />
                                <div className="absolute flex flex-col justify-between bottom-0 w-full p-1 sm:p-2 bg-gradient-to-t from-black/80 to-transparent">
                                    <h3 className="text-white text-md sm:text-lg font-semibold truncate">
                                        {playlist?.name || 'Hello'}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Link>
    )
}

export default PlaylistCard