import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogOverlay, DialogTitle } from "@/components/ui/dialog"
import { useSelector } from "react-redux";
import { deletePlaylist, fetchPlaylists, removePlaylist, SET_DELETE_PLAYLIST_DIALOG_OPEN } from "@/redux/features/music/playlistSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import deleteImgCover from "../../assets/meme-1.png";

const DeletePlaylistDialog = () => {
    const { isDeletePlaylistDialogOpen, playlistCoverImage, playlistName } = useSelector((state) => state.playlists);
    const dispatch = useDispatch();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const playlistId = searchParams.get("id");

    const handleDeletePlaylist = async () => {
        try {
          setDeleteLoading(true);
          await dispatch(deletePlaylist(playlistId)).unwrap();
          dispatch(removePlaylist(playlistId));
          // toast.success(`Playlist "${playlistName}" deleted successfully`);
          dispatch(SET_DELETE_PLAYLIST_DIALOG_OPEN(false));
          navigate("/playlist");
          setDeleteLoading(false);
        } catch (error) {
          toast.error(
            `Failed to delete playlist: ${error.message || "Unknown error"}`
          );
          dispatch(fetchPlaylists(1));
        }
      };

    return (
        <div>
            <Dialog open={isDeletePlaylistDialogOpen} onOpenChange={() => dispatch(SET_DELETE_PLAYLIST_DIALOG_OPEN(!isDeletePlaylistDialogOpen))}>
                <DialogOverlay className="z-[99]">
                    <DialogContent className="z-[999] w-[90%] max-w-sm p-4 bg-gradient-to-br from-slate-900 via-slate-800 to-slate-900 dark:from-pink-200 dark:via-pink-300 dark:to-pink-400 border border-red-400/30 dark:border-pink-500/50 rounded-lg shadow-2xl shadow-red-900/20 dark:shadow-pink-400/30">
                        <DialogHeader>
                            <DialogTitle className="text-xl sm:text-2xl text-left text-red-600 dark:text-pink-700 font-normal font-potta">
                                Delete Playlist
                            </DialogTitle>
                            <DialogDescription className="text-left text-slate-300 dark:text-pink-900 text-sm sm:text-md mt-1">
                                Are you sure you want to delete? This action
                                cannot be undone.
                            </DialogDescription>
                            <div className="flex gap-2 items-center bg-slate-800 dark:bg-pink-200/50 rounded-lg mt-3 p-2">
                                <img
                                    className="w-6 sm:w-8 rounded-lg"
                                    src={playlistCoverImage || deleteImgCover}
                                    alt="cover"
                                />
                                <p className="truncate max-w-[250px] text-green-400 dark:text-pink-700 text-xs sm:text-sm font-normal font-potta">
                                    {playlistName}
                                </p>
                            </div>
                        </DialogHeader>
                        <DialogFooter className="flex flex-row gap-2 justify-end mt-3">
                            <Button
                                variant="outline"
                                onClick={() => dispatch(SET_DELETE_PLAYLIST_DIALOG_OPEN(false))}
                                className="bg-slate-800 dark:bg-pink-200/50 hover:bg-slate-700 dark:hover:bg-pink-300/50 text-slate-300 dark:text-pink-700 hover:text-green-400 dark:hover:text-pink-900 font-medium px-3 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm rounded-lg hover:scale-105 active:scale-95"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="destructive"
                                onClick={handleDeletePlaylist}
                                className="disabled:animate-pulse bg-slate-800 dark:bg-pink-700 hover:bg-slate-700 dark:hover:bg-pink-600 hover:text-red-500 dark:hover:text-white font-medium text-red-500 dark:text-white px-3 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm rounded-lg hover:scale-105 active:scale-95"
                            >
                                {deleteLoading ? "Deleting..." : "Delete"}
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </DialogOverlay>
            </Dialog>
        </div>
    )
}

export default DeletePlaylistDialog
