import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import { APPEND_SONGS } from "@/redux/features/music/musicSlice";
import axios from "axios";
import ani1 from "../../assets/createani1.gif"
import ani2 from "../../assets/createani2.gif"
import ani3 from "../../assets/createani3.gif"
import { SET_CREDITS } from "@/redux/features/auth/authSlice";
import { ADD_CREATING_SONG, REMOVE_CREATING_SONG, SET_CREATEHOMEDESC, STOP_LIVESONG, UPDATE_CREATING_SONG } from "@/redux/features/music/createSlice";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase";
import { Loader2 } from "lucide-react";
import { trackMetaCustomEvent } from "@/utils/metaPixel";
import { trackLinkedInEvent } from "@/utils/linkedinTag";
import { trackRedditCustomEvent } from "@/utils/redditPixel";

const CreateSongHomeComp = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    // creating ref to use in change status function
    const creatinRef = useRef({})
    const { user } = useSelector(state => state.auth)
    const { createHomeDesc, creatingSongs } = useSelector(state => state.create)
    const pollIntervalsRef = useRef({});
    const [isLoading, setIsLoading] = useState(false)

    const handleLyricsChange = (e) => {
        dispatch(SET_CREATEHOMEDESC(e.target.value))
    }
    // Create request
    const handleCreate = async () => {

        if ((user?.is_guest || user?.plan === 0) && creatingSongs?.length >= 1) {
            return toast.error("One song generation at a time, Upgrade plan to create more", { id: "create", duration: 5000 })
        }
        if (user?.plan === 1 && creatingSongs?.length >= 2) {
            return toast.error("You can only create 2 songs simultaneously, Upgrade plan to create more", { id: "create", duration: 5000 })
        }
        if (user?.plan === 2 && creatingSongs?.length >= 3) {
            return toast.error("You can only create 3 songs simultaneously, Upgrade plan to create more", { id: "create", duration: 5000 })
        }
        if (user?.plan === 3 && creatingSongs?.length >= 5) {
            return toast.error("Maximum 5 simultaneously song generations are allowed", { id: "create", duration: 5000 })
        }
        // validation
        if (!createHomeDesc || createHomeDesc.length === 0 || createHomeDesc === ",") {
            return toast.error("Please enter song description", { id: "create" })
        }
        const creationId = uuidv4();
        try {
            setIsLoading(true)
            toast.loading("Initializing", { id: "credits" })
            const token = localStorage.getItem('token') || localStorage.getItem("guestToken")
            const { data } = await axios.get("/balance", { headers: { Authorization: `Bearer ${token}` } })
            dispatch(SET_CREDITS(data?.amount))
            if (data?.amount < 100) {
                setIsLoading(false)
                return toast.error("Not enough credits", { id: "credits" })
            } else {
                toast.dismiss("credits")
            }
            toast.loading("Sending Request", { id: creationId })
            const formData = {
                prompt: createHomeDesc,
                publish: user?.is_guest ? false : true,
            }
            const response = await axios.post("https://engine.soundofmeme.com/create",
                formData,
                { headers: { Authorization: `Bearer ${token}` } })

            const songIds = response.data.songs
            const songIdsArr = songIds?.split(',')
            const songsArray = songIdsArr.map(id => ({ song_id: parseInt(id) }))
            creatinRef.current[creationId] = true
            dispatch(ADD_CREATING_SONG({ creationId: creationId, feat: "create", songs: songsArray }))
            changeStatus(creationId)
            startPollingForSongCompletion(songIds, creationId)
            dispatch(SET_CREDITS(data?.amount - 100)) // after creation credits are deducted and shown in the credits span
            setIsLoading(false)
            dispatch(SET_CREATEHOMEDESC(''))
            navigate("/creations")
        } catch (error) {
            toast.dismiss("credits")
            setIsLoading(false)
            handleError(error, creationId)
        }
    }

    // Polling method for song completion
    const startPollingForSongCompletion = (songIds, creationId) => {
        const interval = setInterval(async () => {
            try {
                const token = localStorage.getItem('token') || localStorage.getItem("guestToken")
                const { data } = await axios.get(`/songs/?song_ids=${songIds}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (data === null) {
                    toast.error("Unexpected Error, Received null response")
                    clearInterval(interval)
                    delete pollIntervalsRef.current[songIds]
                    dispatch(REMOVE_CREATING_SONG({ creationId }));
                    delete creatinRef.current[creationId];
                } else if (data.status === 'completed') {
                    clearInterval(interval);
                    delete pollIntervalsRef.current[songIds];
                    dispatch(REMOVE_CREATING_SONG({ creationId }));
                    dispatch(APPEND_SONGS(data.songs));
                    dispatch(SET_CREDITS(user?.credits - 100))
                    // dispatch(STOP_LIVESONG())
                    toast.success(`All done! Your song is ready to play.`, { id: creationId });
                    delete creatinRef.current[creationId];
                    logEvent(analytics, 'song_creation', {
                        method: "create",
                        success: true,
                        songId: songIds,
                    });
                    trackMetaCustomEvent('song_creation', {
                        method: "create",
                        success: true,
                        songId: songIds,
                    })
                    trackLinkedInEvent('song_creation', {
                        event: 'song_creation',
                        success: true,
                        songId: songIds,
                        method: "create",
                    });
                    trackRedditCustomEvent('song_creation', {
                        event: 'song_creation',
                        success: true,
                        songId: songIds,
                        method: "create",
                    });
                } else {
                    dispatch(UPDATE_CREATING_SONG({ creationId: creationId, songs: data.songs }))
                }
            } catch (error) {
                dispatch(STOP_LIVESONG())
                clearInterval(interval)
                delete pollIntervalsRef.current[songIds];
                handleError(error, creationId);
                // Only log failure if there are no next IDs
                if (!error?.response?.data?.next_ids) {
                    logEvent(analytics, 'song_creation', {
                        success: false,
                        songId: songIds,
                        method: "create",
                    });
                    trackMetaCustomEvent('song_creation', {
                        success: false,
                        songId: songIds,
                        method: "create",
                    })
                    trackLinkedInEvent('song_creation', {
                        event: 'song_creation',
                        success: false,
                        songId: songIds,
                        method: "create",
                    });
                    trackRedditCustomEvent('song_creation', {
                        event: 'song_creation',
                        success: false,
                        songId: songIds,
                        method: "create",
                    });
                }
            }
        }, 10000);
        // Store interval ID for cleanup
        pollIntervalsRef.current[songIds] = interval;
    };

    // Handle errors
    const handleError = (error, creationId) => {
        console.error(error);
        const nextSongIds = error?.response?.data?.next_ids;
        if (nextSongIds) {
            const songIdsArr = nextSongIds.split(',');
            const songsArray = songIdsArr.map(id => ({ song_id: parseInt(id) }));
            dispatch(UPDATE_CREATING_SONG({
                creationId: creationId,
                songs: songsArray
            }));
            startPollingForSongCompletion(nextSongIds, creationId);
            creatinRef.current[creationId] = true;
        } else {
            dispatch(REMOVE_CREATING_SONG({ creationId }));
            delete creatinRef.current[creationId];
            toast.error(
                error?.response?.data?.detail ||
                error.message ||
                "Oops! Something went wrong. Please try again.",
                { id: creationId }
            );
        }
    };

    // Change status function
    const changeStatus = useCallback((creationId) => {
        toast("Crafting your tune, hang tight!", { id: creationId })
        dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani1, text: "Analyzing your description..." }))
        setTimeout(() => {
            if (!creatinRef.current[creationId]) return toast.dismiss(creationId);
            dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani2, text: "Adding the rhythms..." }))
        }, 15000);
        setTimeout(() => {
            if (!creatinRef.current[creationId]) return toast.dismiss(creationId);
            dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani3, text: "Composing your melody..." }))
        }, 30000);
    }, [dispatch])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (createHomeDesc.trim() !== '') {
                event.preventDefault();
                event.returnValue = ''; // Required for modern browsers
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [createHomeDesc]);

    return (
        <div className={` w-full xl:w-1/2 flex-shrink-0 p-4 pt-0 xl:pt-4 pb-0`}>
            <Card className="bg-transparent border-none pb-2 h-full flex flex-col">
                <CardHeader className="p-0 pb-2" >
                    <CardTitle className="text-slate-200 sm:text-2xl font-bold font-poppins text-xl flex items-center justify-between gap-2" >
                        <span>Create Song</span>
                        <p className="text-slate-300 font-medium font-poppins text-sm mt-3 flex items-center gap-2" > <span>Credits: {user?.credits}</span>  |  <span>Cost: 100</span></p>
                    </CardTitle>
                </CardHeader>
                <CardContent className=" flex-grow flex flex-col justify-end gap-4 text-slate-200 dark:text-slate-800 p-0 sm:pt-2 pt-0">
                    <div className="relative flex-grow">
                        <textarea
                            id="description"
                            type="text"
                            placeholder="Start with a feeling, a story, or just a single sentence... What do you want your song to be about? Let the music begin!"
                            value={createHomeDesc}
                            onChange={handleLyricsChange}
                            className=" h-full w-full bg-slate-900/60 border  border-1 border-slate-400/30 rounded-lg min-h-28 p-2 placeholder:text-sm placeholder:text-slate-600 placeholder:font-medium focus:outline-none focus:ring-1 ring-green-400 shadow-md shadow-green-400/10" >
                        </textarea>
                    </div>
                    <div className="flex justify-end w-full">
                        <button disabled={isLoading} onClick={handleCreate} className='flex items-center justify-center  w-full sm:gap-2 disabled:opacity-50 border border-1  font-semibold font-poppins text-sm py-2 px-2 rounded-sm shadow-md shadow-green-400/10 hover:shadow-green-400/20 opacity-90 hover:opacity-100 border-green-400/40 bg-gradient-to-r from-green-400 to-teal-400 text-black transition-all duration-200' >
                            {isLoading ? (
                                <div className="flex items-center gap-2">
                                    <span>Creating...</span>
                                    <Loader2 className="w-4 h-4 animate-spin" />
                                </div>
                            ) : (
                                <span>Create Song</span>
                            )}
                        </button>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default CreateSongHomeComp;