import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@/components/ui/input-otp"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { getUserDetails, SET_OTPCARD, verifyOtp } from "@/redux/features/auth/authSlice"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import axios from "axios"
import { Button } from "@/components/ui/button"
import { TabsContent } from "@/components/ui/tabs"
import { useLocation, useNavigate } from "react-router-dom"
import { ArrowLeft } from "lucide-react"

const FormSchema = z.object({
    otp: z.string().min(4, {
        message: "Your one-time password must be 4 characters.",
    }),
})

const OtpForm = ({ tabName }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [timeLeft, setTimeLeft] = useState(60);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // otp form
    const form = useForm({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            otp: "",
        },
    })
    const { reset: resetOtp } = form;

    // otp submit
    const handleOtpSubmit = async (data) => {
        await dispatch(verifyOtp(data)).unwrap()
        const token = localStorage.getItem('token')
        await dispatch(getUserDetails(token)).unwrap()
        // navigate("/")
        const from = location.pathname === '/login' ? '/' : location.state?.from;
        // console.log('from', from);
        navigate(from);
        resetOtp()
    }

    // resend OTP
    const handleResend = async () => {
        const otpToken = sessionStorage.getItem('otpToken')
        try {
            toast.loading("Resending OTP", { id: "token" })
            await axios.post("/sendotp", {}, { headers: { Authorization: `Bearer ${otpToken}` } })
            toast.success("OTP Sent", { id: "token" })
            setTimeLeft(60)
            setIsButtonDisabled(true)
        } catch (error) {
            console.log(error);
            toast.error("Error Sending OTP", { id: "token" })
        }
    }

    const handleBack = () => {
        sessionStorage.removeItem('otpToken')
        dispatch(SET_OTPCARD(false))
    }

    useEffect(() => {
        if (timeLeft === 0) {
            setIsButtonDisabled(false);
            return;
        }
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft => timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);

    }, [timeLeft]);

    return (
        <TabsContent value={tabName}>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleOtpSubmit)} className="relative w-full bg-slate-800 dark:bg-pink-200 rounded-lg p-4 flex gap-4 items-center justify-center flex-col" >
                    <button onClick={handleBack} className="absolute rounded-full p-1 top-4 left-4 bg-green-400 dark:bg-gradient-to-br dark:from-pink-600 dark:to-pink-900 hover:bg-green-500 dark:hover:opacity-90 text-black dark:text-white" type="button" >
                        <ArrowLeft size={24} />
                    </button>
                    <FormField control={form.control} name="otp" render={({ field }) => (
                        <FormItem className="flex items-center justify-center flex-col mt-4">
                            <FormLabel className="text-green-400 dark:text-pink-700">
                                One-Time Password
                            </FormLabel>
                            <FormControl>
                                <InputOTP maxLength={4} {...field}>
                                    <InputOTPGroup className="text-white dark:text-slate-800">
                                        <InputOTPSlot
                                            className="border-slate-400 dark:border-pink-700 ring-green-400 dark:ring-pink-700 bg-slate-900 dark:bg-pink-300"
                                            index={0}
                                        />
                                        <InputOTPSlot
                                            className="border-slate-400 dark:border-pink-700 ring-green-400 dark:ring-pink-700 bg-slate-900 dark:bg-pink-300"
                                            index={1}
                                        />
                                        <InputOTPSlot
                                            className="border-slate-400 dark:border-pink-700 ring-green-400 dark:ring-pink-700 bg-slate-900 dark:bg-pink-300"
                                            index={2}
                                        />
                                        <InputOTPSlot
                                            className="border-slate-400 dark:border-pink-700 ring-green-400 dark:ring-pink-700 bg-slate-900 dark:bg-pink-300"
                                            index={3}
                                        />
                                    </InputOTPGroup>
                                </InputOTP>
                            </FormControl>
                            <FormDescription className="text-slate-300 dark:text-slate-700">
                                Please enter the one-time password sent to your mail address.
                            </FormDescription>
                            <FormMessage className="text-red-500 dark:text-red-600" />
                        </FormItem>
                    )}
                    />
                    <div className="flex items-center gap-2 w-full">
                        <Button className="w-full bg-green-400 dark:bg-gradient-to-br dark:from-pink-600 dark:to-pink-900 hover:bg-green-500 dark:hover:opacity-90 text-black dark:text-white" type="submit" >Submit</Button>
                        <Button disabled={isButtonDisabled} onClick={handleResend} className="w-full bg-green-400 dark:bg-gradient-to-br dark:from-pink-600 dark:to-pink-900 hover:bg-green-500 dark:hover:opacity-90 text-black dark:text-white disabled:opacity-50" type="button" >{isButtonDisabled ? `Resend in : ${timeLeft}` : "Resend OTP"}</Button>
                    </div>
                </form>
            </Form>
        </TabsContent>
    )
}

export default OtpForm