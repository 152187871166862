import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"


const SortFilter = ({ setSort, sortValue, options }) => {
    const [open, setOpen] = React.useState(false)

    const handleSelect = (currentValue) => {
        if (currentValue === sortValue) {
            setOpen(false)
            return
        }
        setSort(currentValue, 1)
        setOpen(false)
    }

    return (
        <Popover open={open} onOpenChange={setOpen} >
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-auto min-w-28 justify-between bg-slate-800 dark:bg-gradient-to-r dark:from-pink-400 dark:to-pink-500 hover:bg-slate-700 dark:hover:from-pink-500 dark:hover:to-pink-600 hover:text-white dark:text-black  border-0 text-xs h-8 sm:text-sm sm:h-10"
                >
                    {sortValue
                        ? options.find((option) => option.value === sortValue)?.label
                        : "Sort.."}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto min-w-28 p-0 border-0 ">
                <Command className="bg-slate-800 dark:bg-gradient-to-r dark:from-pink-200 dark:to-pink-300 " >
                    <CommandList >
                        <CommandGroup className="bg-slate-800 dark:bg-gradient-to-r dark:from-pink-300 dark:to-pink-400 text-slate-300 dark:text-black">
                            {options.map((option) => (
                                <CommandItem
                                    key={option.value}
                                    value={option.value}
                                    onSelect={handleSelect}
                                    className={`hover:cursor-pointer data-[selected='true']:bg-slate-700 dark:data-[selected='true']:bg-pink-400 data-[selected='true']:text-white dark:data-[selected='true']:text-black ${option.value === sortValue && " text-green-400 dark:text-white  data-[selected='true']:text-green-400 dark:data-[selected='true']:text-white "} `}
                                >
                                    <Check
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            sortValue === option.value ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                    {option.label}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

export default SortFilter