import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    creatingSongs: [],
    creatingValentineSongs: [],
    extendSong: null,
    cloneSong: null,
    selectedTab: "create",
    createDesc: '',
    createGenre: '',
    createHomeDesc: '',
    createHomeGenre: '',
    customTitle: '',
    customLyrics: '',
    customDesc: '',
    liveSong: {
        streamUrl: null,
        isBuffering: false,
        isPlaying: false
    },
    isDialogOpen: false,
    songToDelete: null,
    createFreeSongDialogOpen: false
}

const createSongSlice = createSlice({
    name: "create",
    initialState,
    reducers: {
        ADD_CREATING_SONG(state, action) {
            state.creatingSongs.unshift(action.payload)
        },
        REMOVE_CREATING_SONG(state, action) {
            state.creatingSongs = state.creatingSongs.filter(song => song.creationId !== action.payload.creationId);
        },
        UPDATE_CREATING_SONG(state, action) {
            const index = state.creatingSongs.findIndex(song => song.creationId === action.payload.creationId);
            if (index !== -1) {
                state.creatingSongs[index] = { ...state.creatingSongs[index], ...action.payload };
            }
        },
        ADD_CREATING_VALENTINE_SONG(state, action) {
            state.creatingValentineSongs.unshift(action.payload)
        },
        REMOVE_CREATING_VALENTINE_SONG(state, action) {
            state.creatingValentineSongs = state.creatingValentineSongs.filter(song => song.creationId !== action.payload.creationId);
        },
        UPDATE_CREATING_VALENTINE_SONG(state, action) {
            const index = state.creatingValentineSongs.findIndex(song => song.creationId === action.payload.creationId);
            if (index !== -1) {
                state.creatingValentineSongs[index] = { ...state.creatingValentineSongs[index], ...action.payload };
            }
        },
        SET_EXTENDSONG(state, action) {
            state.extendSong = action.payload
        },
        SET_CLONESONG(state, action) {
            state.cloneSong = action.payload
        },
        SET_SELECTEDTAB(state, action) {
            state.selectedTab = action.payload
        },
        SET_CREATEDESC(state, action) {
            state.createDesc = action.payload
        },
        SET_CREATEGENRE(state, action) {
            state.createGenre = action.payload
        },
        SET_CREATEHOMEGENRE(state, action) {
            state.createHomeGenre = action.payload
        },
        SET_CREATEHOMEDESC(state, action) {
            state.createHomeDesc = action.payload
        },
        SET_CUSTOMTITLE(state, action) {
            state.customTitle = action.payload
        },
        SET_CUSTOMLYRICS(state, action) {
            state.customLyrics = action.payload
        },
        SET_CUSTOMDESC(state, action) {
            state.customDesc = action.payload
        },
        PLAY_LIVESONG(state, action) {
            state.liveSong = action.payload
        },
        // eslint-disable-next-line no-unused-vars
        STOP_LIVESONG(state, action) {
            state.liveSong = null
        },
        SET_BUFFERING(state, action) {
            state.liveSong.isBuffering = action.payload
        },
        SET_DELETE_DIALOG_OPEN: (state, action) => {
            state.isDialogOpen = action.payload;
          },
          SET_SONG_TO_DELETE: (state, action) => {
            state.songToDelete = action.payload;
          },
          SET_CREATE_FREE_SONG_DIALOG_OPEN: (state, action) => {
            state.createFreeSongDialogOpen = action.payload;
          }
    }
});

export const {
    ADD_CREATING_SONG,
    REMOVE_CREATING_SONG,
    UPDATE_CREATING_SONG,
    SET_CLONESONG,
    SET_EXTENDSONG,
    SET_SELECTEDTAB,
    SET_CREATEDESC,
    SET_CREATEGENRE,
    SET_CREATEHOMEGENRE,
    SET_CREATEHOMEDESC,
    SET_CUSTOMTITLE,
    SET_CUSTOMDESC,
    SET_CUSTOMLYRICS,
    STOP_LIVESONG,
    PLAY_LIVESONG,
    SET_BUFFERING,

    SET_DELETE_DIALOG_OPEN,
    SET_SONG_TO_DELETE,
    SET_CREATE_FREE_SONG_DIALOG_OPEN,
    ADD_CREATING_VALENTINE_SONG,
    REMOVE_CREATING_VALENTINE_SONG,
    UPDATE_CREATING_VALENTINE_SONG
} = createSongSlice.actions

export default createSongSlice.reducer