import { LazyLoadImage } from 'react-lazy-load-image-component'
import leaderBoardImg from "../../assets/leaderboard.jpg"
import { Link } from 'react-router-dom'

const LeaderboardImageSection = () => {
    return (
        <div className="w-full h-[220px] xs:h-[180px] sm:h-[200px] lg:h-[220px] xl:h-[250px] overflow-hidden mt-16 relative" >
            <LazyLoadImage src={leaderBoardImg} alt="leaderboard" className=" absolute top-0 left-0 w-full h-full object-cover object-top" effect="blur" wrapperClassName="w-full h-full absolute bottom-0 left-0" />
            <div className="w-full h-full p-4 relative z-10 bg-gradient-to-r from-black via-black/70 lg:via-black/50 xl:via-black/30 to-transparent" >
                <h1 className="text-2xl sm:text-3xl lg:text-4xl xl:text-5xl xl:w-1/2 font-potta lg:w-2/3 text-white" >Rise to the Top of the Charts!</h1>
                <p className=" sm:w-2/3 lg:w-2/3 xl:w-1/2 text-sm lg:text-base mt-1 lg:mt-2 xl:mt-3 text-slate-100" >Showcase your creativity and let your music shine. Create your best song, compete with others, and claim your spot at the top!</p>
                <Link to={"/create"} className="text-black text-sm font-medium bg-green-400 hover:bg-green-300 px-4 py-2 rounded-full mt-4 inline-block" >Create Your Song</Link>
            </div>
        </div>
    )
}

export default LeaderboardImageSection
