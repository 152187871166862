import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { resetPassword } from '@/redux/features/auth/authSlice';
import TopBar from '@/components/navbar/TopBar'
import BottomBar from '@/components/sidebar/BottomBar'
import { Tabs, TabsContent, TabsList } from '@/components/ui/tabs'
import blueBird from "../../assets/blue bird.png";
import greenBird from "../../assets/greenbird.png";
import purpuleBird from "../../assets/purple-bird.png";
import { Card, CardContent, CardFooter } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { ChevronLeft, Eye, EyeOff } from "lucide-react";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { song } = useSelector(state => state.music)
  const { handleSubmit, register, formState: { errors }, watch } = useForm();
  const {token} = useParams(); // Get token from URL

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleBackToLogin = () => {
    navigate('/login');
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleResetPassword = async (data) => {
    // Validate passwords match
    if (data.newPassword !== data.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      const formData = {        
        new_password: data.newPassword,
        token: token
      };

      await dispatch(resetPassword(formData)).unwrap();
      
      toast.success("Password reset successfully");
      navigate('/login'); // Redirect to login page
    } catch (error) {
      console.error("Password reset failed", error);
    }
  };

  return (
    <div className='relative min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 dark:from-pink-200 dark:via-pink-300 dark:to-pink-200 overflow-hidden flex flex-col flex-grow w-full'>
      <TopBar heading={"Reset Password"} />
      <div className={`flex-grow flex items-center justify-center relative z-50 w-full ${song ? "md:pb-32 pb-36" : "pb-16 sm:pb-10"}`}>
        <Tabs 
          defaultValue="reset-password" 
          className="w-full pt-6 max-w-md border backdrop-blur-md border-green-900 dark:border-pink-700 rounded-lg shadow-2xl shadow-slate-800 dark:shadow-pink-300/20"
        >
          <h1 className="text-center text-xl font-normal text-green-400 dark:text-pink-700 font-potta mb-4">
            Enter your new password
          </h1>
          <TabsContent value="reset-password">
            <Card className="bg-transparent border-none pt-6 rounded-lg">
              <form onSubmit={handleSubmit(handleResetPassword)}>
                <CardContent className="px-6">
                  <div className="flex flex-col gap-6 relative">
                    <div className="relative">
                      <input
                        type={showNewPassword ? 'text' : 'password'}
                        {...register('newPassword', {
                          required: 'New password is required',
                          minLength: {
                            value: 8,
                            message: 'Password must be at least 8 characters'
                          }
                        })}
                        placeholder="New Password"
                        className='w-full bg-slate-900 dark:bg-pink-300 text-slate-200 dark:text-slate-800 p-2 rounded placeholder:text-sm placeholder:text-slate-500 dark:placeholder:text-slate-700 border-green-900/75 dark:border-pink-700/75 border outline-none focus:outline-1 focus:outline-green-400 dark:focus:outline-pink-700 pr-10'
                      />
                      <button 
                        type="button"
                        onClick={toggleNewPasswordVisibility}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 focus:outline-none"
                      >
                        {showNewPassword ? (
                          <EyeOff className="text-slate-500 dark:text-slate-700 w-5 h-5" />
                        ) : (
                          <Eye className="text-slate-500 dark:text-slate-700 w-5 h-5" />
                        )}
                      </button>
                    </div>
                    {errors.newPassword && (
                      <p className='absolute top-12 right-1 text-xs text-red-400 dark:text-red-600 font-medium'>
                        {errors.newPassword.message}
                      </p>
                    )}
                    <div className="relative">
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        {...register('confirmPassword', {
                          required: 'Confirm password is required',
                          validate: (val) => {
                            if (watch('newPassword') !== val) {
                              return "Passwords do not match";
                            }
                          }
                        })}
                        placeholder="Confirm Password"
                        className='w-full bg-slate-900 dark:bg-pink-300 text-slate-200 dark:text-slate-800 p-2 rounded placeholder:text-sm placeholder:text-slate-500 dark:placeholder:text-slate-700 border-green-900/75 dark:border-pink-700/75 border outline-none focus:outline-1 focus:outline-green-400 dark:focus:outline-pink-700 pr-10'
                      />
                      <button 
                        type="button"
                        onClick={toggleConfirmPasswordVisibility}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 focus:outline-none"
                      >
                        {showConfirmPassword ? (
                          <EyeOff className="text-slate-500 dark:text-slate-700 w-5 h-5" />
                        ) : (
                          <Eye className="text-slate-500 dark:text-slate-700 w-5 h-5" />
                        )}
                      </button>
                    </div>
                    {errors.confirmPassword && (
                      <p className='absolute -bottom-5 right-1 text-xs text-red-400 dark:text-red-600 font-medium'>
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </div>
                </CardContent>
                <CardFooter className="ml-4 mr-4 px-2">
                  <Button
                    type="submit"
                    className="w-full py-2 bg-green-500 dark:bg-gradient-to-br dark:from-pink-600 dark:to-pink-900 hover:bg-green-600 dark:hover:opacity-90 text-slate-800 dark:text-white font-semibold rounded-sm"
                  >
                    Reset Password
                  </Button>
                </CardFooter>
              </form>
            </Card>
            <TabsList className="flex flex-row bg-slate-900 dark:bg-pink-200 justify-center">
              <button
                onClick={handleBackToLogin}
                className="flex flex-row items-center text-sm text-green-400 dark:text-pink-700 pb-6"
              >
                <ChevronLeft size={20} />
                <span className="pl-2 hover:text-green-300 dark:hover:text-pink-800">
                  Back to Login
                </span>
              </button>
            </TabsList>
          </TabsContent>
        </Tabs>
      </div>
      <BottomBar />
      <img src={blueBird} alt='bird' className='w-16 absolute top-[69%] right-[10%] sm:top-[85%] dark:opacity-60' />
      <img src={greenBird} alt='bird' className='w-16 absolute left-[5%] top-[65%] sm:top-1/2 dark:opacity-60' />
      <img src={purpuleBird} alt='bird' className='w-16 absolute right-[20%] top-[10%] dark:opacity-60' />
    </div>
  );
};

export default ResetPassword;