import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Star, ThumbsUp, MessageSquare, Smile, Frown } from 'lucide-react';
import { useSelector } from 'react-redux';
import TopBar from '@/components/navbar/TopBar';
import BottomBar from '@/components/sidebar/BottomBar';
import blueBird from "../../assets/blue bird.png";
import greenBird from "../../assets/greenbird.png";
import purpuleBird from "../../assets/purple-bird.png";
import toast from 'react-hot-toast';

// Zod Schema for Validation
const feedbackSchema = z.object({
    name: z.string().min(1, { message: "Name is required" }),
    email: z.string().email({ message: "Invalid email address" }),
    rating: z.number().min(1, { message: "Rating is required" }).max(5),
    review_type: z.string().min(1, { message: "Review type is required" }),
    description: z.string().min(10, { message: "Feedback must be at least 10 characters" }),
});

function Feedback() {
    const { user } = useSelector(state => state.auth);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [rating, setRating] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: zodResolver(feedbackSchema),
        defaultValues: {
            name: user?.name || 'Anonymous',
            email: user?.email || '',
            rating: 0,
            review_type: '',
            description: ''
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onSubmit = async (data) => {
        setIsSubmitting(true);

        try {
            const feedbackData = {
                name: data.name || 'Anonymous',
                email: data.email,
                rating: rating,
                review_type: selectedCategory?.toLowerCase().replace(' ', '_') || 'general_feedback',
                description: data.description
            };

            toast.loading('Submitting your feedback...', { id: 'feedback' });

            const response = await fetch('https://testapi.soundofmeme.com/reviews', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify(feedbackData)
            });

            const responseData = await response.json();

            if (response.ok) {
                toast.success('Thank you! Your feedback has been submitted.', { id: 'feedback' });
                reset();
                setRating(0);
                setSelectedCategory(null);
            } else {
                toast.error(`Submission failed: ${responseData.message || 'Please try again.'}`, { id: 'feedback' });
            }
        } catch (error) {
            toast.error('Network error. Please check your connection.', { id: 'feedback' });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleRatingClick = (value) => {
        setRating(value);
        setValue('rating', value);
    };

    const categories = [
        { icon: <ThumbsUp className="w-5 h-5" />, label: 'General Feedback', value: 'general_feedback' },
        { icon: <MessageSquare className="w-5 h-5" />, label: 'Feature Request', value: 'feature_request' },
        { icon: <Frown className="w-5 h-5" />, label: 'Bug Report', value: 'bug_report' },
        { icon: <Smile className="w-5 h-5" />, label: 'Compliment', value: 'compliment' }
    ];

    const handleCategoryChange = (category) => {
        setSelectedCategory(category.label);
        setValue('review_type', category.value);
    };

    const renderStars = () => {
        return Array.from({ length: 5 }, (_, index) => {
            const starValue = index + 1;
            const filled = starValue <= rating;
            return (
                <Star
                    key={starValue}
                    className={`w-8 h-8 cursor-pointer ${filled ? 'text-green-400 fill-green-400' : 'text-slate-600'} hover:text-green-400 transition-colors`}
                    onClick={() => handleRatingClick(starValue)}
                />
            );
        });
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-slate-950 to-slate-900 flex items-center justify-center sm:p-4 p-0 overflow-y-auto">
            <TopBar heading="Feedback" />
            <div className="max-w-2xl w-full bg-gradient-to-b from-green-900/10 to-slate-950 backdrop-blur-sm sm:rounded-2xl rounded-md shadow-2xl sm:p-8 p-2 border border-slate-700/50 mt-20 mb-20">
                <div className="text-center sm:mb-4 mb-2">
                    <h1 className="sm:text-3xl text-2xl mt-4 sm:mt-0 font-bold text-green-400 sm:mb-4 mb-2">Share Your Feedback</h1>
                    <p className="text-slate-300 sm:text-lg text-sm">Your feedback helps us improve and create a better experience for everyone!</p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="sm:space-y-6 space-y-2">
                    {/* Rating Section */}
                    <div className="text-center mb-4 sm:mb-8">
                        <label className="block text-slate-300 mb-3">How would you rate your experience?</label>
                        <div className="flex justify-center gap-2">
                            {renderStars()}
                        </div>
                        {errors.rating && (
                            <span className="text-red-400 text-sm mt-2 block">{errors.rating.message}</span>
                        )}
                    </div>

                    {/* Category Selection */}
                    <div className="grid grid-cols-2 sm:gap-4 gap-2">
                        {categories.map((category) => (
                            <label key={category.value} onClick={() => handleCategoryChange(category)} className={`flex items-center gap-3 sm:p-4 p-2 rounded-lg border cursor-pointer transition-all ${selectedCategory === category.label ? 'border-green-400 bg-gradient-to-r from-green-400 to-teal-600 text-black' : 'border-slate-700 hover:border-green-400'}`}>
                                <div className={`flex items-center gap-2 text-sm sm:text-lg`}>
                                    {category.icon}
                                    <span>{category.label}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                    {errors.review_type && (
                        <span className="text-red-400 text-sm block">{errors.review_type.message}</span>
                    )}

                    {/* Feedback Text */}
                    <div>
                        <textarea {...register('description')} placeholder="Tell us what you think..." className="w-full p-4 bg-slate-900/50 text-white rounded-lg border border-slate-600 focus:border-green-400 focus:ring-2 focus:ring-green-400/20 transition-all h-32" />
                        {errors.description && (
                            <span className="text-red-400 text-sm mt-1 block">{errors.description.message}</span>
                        )}
                    </div>
                    {/* Visible Name Field (auto-filled, not editable) */}
                    <div className="w-full">
                        <input {...register('name')} type="hidden" disabled className="w-full p-4 bg-slate-900/50 text-slate-300 rounded-lg border border-slate-600 cursor-not-allowed" value={user?.name || 'Anonymous'} />
                    </div>
                    {/* Editable Email Field */}
                    <div>
                        <input {...register('email')} placeholder="Your email (required)" className="w-full p-4 bg-slate-900/50 text-white rounded-lg border border-slate-600 focus:border-green-400 focus:ring-2 focus:ring-green-400/20 transition-all" defaultValue={user?.email || ''} />
                        {errors.email && (
                            <span className="text-red-400 text-sm mt-1 block">{errors.email.message}</span>
                        )}
                    </div>

                    {/* Submit Button */}
                    <button type="submit" disabled={isSubmitting} className="w-full py-4 bg-gradient-to-r from-green-500 to-teal-600 text-black rounded-lg hover:from-green-600 hover:to-teal-700 focus:ring-4 focus:ring-green-500/20 transition-all disabled:opacity-50 flex items-center justify-center gap-2">
                        {isSubmitting ? (<>
                            <span>Sending</span>
                            <div className="animate-spin">🔄</div>
                        </>) : (
                            'Submit Feedback'
                        )}
                    </button>
                </form>
            </div>
            <BottomBar />
            <img src={blueBird} alt="bird" className="w-16 absolute top-[70%] sm:top-[80%] right-[80%] sm:right-[10%] animate-float opacity-80 dark:opacity-60" />
            <img src={greenBird} alt="bird" className="w-10 sm:w-16 absolute sm:left-[82%] left-[85%] top-[15%] sm:top-[16%] animate-float-slow opacity-80 dark:opacity-60" />
            <img src={purpuleBird} alt="bird" className="w-16 absolute left-[20%] top-[8%] sm:top-[18%] md:top-[15%] animate-float opacity-80 dark:opacity-60" />
        </div>
    );
}

export default Feedback;