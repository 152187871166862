import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Optional for blur effect
import { Music, Heart } from "lucide-react";

const UserCard = ({ user }) => {
    return (
        <div className="flex flex-col items-center group pt-4 transition-all duration-300 truncate">
        <div className="relative mb-4">
            <Link to={`/profile/${user.username}`} className=" block w-24 h-24 sm:w-28 sm:h-28 2xl:w-32 2xl:h-32 rounded-full overflow-hidden border-1 border-slate-700/50 dark:border-pink-300/50">
                {user.profile_url ? (
                    <LazyLoadImage src={user.profile_url} alt={user.name} className="w-full h-full object-cover hover:scale-105 transition-transform duration-300" onError={(e) => { e.target.onerror = null; e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name)}&background=1F2937&color=fff`; }} />
                ) : (
                    <div className="w-full h-full flex items-center justify-center bg-gradient-to-br from-slate-800 to-slate-900 dark:from-pink-200 dark:to-pink-300">
                        <span className="text-6xl font-bold text-green-400/80 dark:text-pink-400 font-potta hover:scale-110 transition-transform duration-300">
                            {user.name.charAt(0).toUpperCase()}
                        </span>
                    </div>
                )}
            </Link>
            <div className="absolute -bottom-2 left-1/2 -translate-x-1/2 bg-slate-800/90 dark:bg-pink-300/90 backdrop-blur px-3 py-1 rounded-full border border-slate-700/50 dark:border-pink-300/80 flex items-center gap-3 text-xs shadow-lg">
                <div className="flex items-center gap-1">
                    <Music className="w-3 h-3 text-green-400 dark:text-black" />
                    <span className="text-slate-300 dark:text-slate-700">{user.total_songs >= 1000 ? (user.total_songs / 1000).toFixed(1) + 'k' : user.total_songs}</span>
                </div>
                <div className="flex items-center gap-1">
                    <Heart className="w-3 h-3 text-green-400 fill-green-400 dark:text-pink-700 dark:fill-pink-700" />
                    <span className="text-slate-300 dark:text-pink-600">{user.total_likes >= 1000 ? (user.total_likes / 1000).toFixed(1) + 'k' : user.total_likes}</span>
                </div>
            </div>
        </div>
        <h3 className="text-center font-medium truncate w-full">
            <Link to={`/profile/${user.username}`} className="text-white dark:text-black hover:text-green-400 dark:hover:text-pink-700 transition-colors duration-500 text-xs 2xl:text-sm truncate w-full"
            >{user.name}
            </Link>
            </h3>
        </div>
    );
};

export default UserCard;
