import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter } from "@/components/ui/card"
import { TabsContent } from "@/components/ui/tabs"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { getUserDetails, loginUser } from "@/redux/features/auth/authSlice"
import GoogleLoginButton from "@/components/googleLoginButton/GoogleLoginButton"
import { useLocation, useNavigate } from "react-router-dom"
import { useState } from 'react';
import { Eye, EyeOff } from "lucide-react";

const LoginForm = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { handleSubmit, register, reset, formState: { errors } } = useForm()
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // login
    const handleLogin = async (data) => {
        const { email, password } = data
        const formData = {
            email,
            password
        }
        await dispatch(loginUser(formData)).unwrap()
        const token = localStorage.getItem("token")
        await dispatch(getUserDetails(token)).unwrap()
        // navigate("/")


        const from = location.pathname === '/login' ? '/' : location.state?.from;
        console.log('from', from);
        navigate(from);
        reset()
    }

    return (
        <TabsContent value="login" className="mt-4">
            <Card className="bg-transparent p-0 border-0">
                <form onSubmit={handleSubmit(handleLogin)}>
                    <CardContent className="space-y-5 p-0 w-full max-w-lg">
                        <div className="flex flex-col gap-3 relative">
                            <input
                                type='text'
                                {...register('email', {
                                    required: 'Email or Username is required',
                                    validate: (value) => {
                                        const trimmedValue = value.trim();
                                        if (trimmedValue === '') {
                                            return 'Email or Username cannot be empty';
                                        }
                                        return true;
                                    },
                                })}
                                placeholder="Email or Username"
                                className="bg-slate-900 dark:bg-pink-300 text-white dark:text-slate-800 p-2 rounded placeholder:text-sm placeholder:text-slate-500 dark:placeholder:text-slate-700 border-slate-700/75 dark:border-pink-700/75 border focus:outline-none focus:ring-1 focus:ring-green-400 dark:focus:ring-pink-700"
                            />
                            {errors.email && <p className="absolute -bottom-4 right-1 text-xs text-green-400 dark:text-pink-700 font-medium">{errors.email.message}</p>}
                        </div>
                        <div className="flex flex-col gap-3 relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="off"
                                placeholder="Password"
                                {...register('password', {
                                    required: 'Password is required',
                                    minLength: {
                                        value: 8,
                                        message: 'Password must have at least 8 characters'
                                    }
                                })}
                                className="bg-slate-900 dark:bg-pink-300 border border-slate-700/75 dark:border-pink-700/75 text-white dark:text-slate-800 p-2 placeholder:text-sm placeholder:text-slate-500 dark:placeholder:text-slate-700 rounded focus:outline-none focus:ring-1 focus:ring-green-400 dark:focus:ring-pink-700"
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-slate-500 dark:text-slate-700 focus:outline-none"
                            >
                                {showPassword ? <EyeOff /> : <Eye />}
                            </button>
                            {errors.password && <p className="absolute -bottom-4 right-1 text-xs text-green-400 dark:text-pink-700 font-medium">{errors.password.message}</p>}
                        </div>
                    </CardContent>
                    <CardFooter className="p-0 mt-4">
                        <Button
                            type="submit"
                            className="w-full bg-green-400 dark:bg-gradient-to-br dark:from-pink-600 dark:to-pink-900 hover:bg-green-500 dark:hover:opacity-90 text-black dark:text-white"
                        >
                            Login
                        </Button>
                    </CardFooter>
                </form>
                <div className="my-4 flex flex-row justify-between">
                    {/* <p className='text-center text-slate-400 text-sm' ><span className='text-green-400 cursor-pointer' onClick={() => navigate('/changepassword')} >Change Password</span></p> */}
                    <p className="text-center text-slate-400 dark:text-slate-700 text-sm">
                        Didn&apos;t remember your password?
                        <span
                            className="text-green-400 dark:text-pink-700 cursor-pointer pl-2 hover:underline"
                            onClick={() => navigate('/resetpassword')}
                        >
                            Forget Password
                        </span>
                    </p>
                </div>
                <div className="h-px my-4 bg-slate-600 dark:bg-pink-700/50"></div>
                <div className="flex items-center justify-center">
                    <GoogleLoginButton defaultPath={location.pathname === '/login' ? '/' : location.state?.from} />
                </div>
            </Card>
        </TabsContent>
    )
}

export default LoginForm