import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    songs: [],
    cryptoSongs: [],
    categories: [],
    subCategories: [],
    dialogOpen: false,
    songToAdd: null,
}

const leaderboardSlice = createSlice({
    name: "leaderboard",
    initialState,
    reducers: {
        SET_LEADSONGS(state, action) {
            state.songs = action.payload
        },
        SET_CRYPTO_LEADSONGS(state, action) {
            state.cryptoSongs = action.payload
        },
        ADD_MORE_LEADSONGS(state, action) {
            const newSongs = action.payload;
            const existingIds = new Set(state.songs.map(song => song.song_id));
            const filteredSongs = newSongs.filter(song => !existingIds.has(song.song_id));
            state.songs = [...state.songs, ...filteredSongs];
        },
        SET_DIALOG_OPEN(state, action) {
            state.dialogOpen = action.payload;
        },
        SET_SONG_TO_ADD(state, action) {
            state.songToAdd = action.payload;
        }
    }
});

export const { SET_CRYPTO_LEADSONGS, SET_LEADSONGS, ADD_MORE_LEADSONGS, SET_DIALOG_OPEN, SET_SONG_TO_ADD } = leaderboardSlice.actions

export default leaderboardSlice.reducer