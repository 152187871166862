import { useDispatch, useSelector } from 'react-redux';
import { SET_OPEN_WELCOME_DIALOG } from '../../redux/features/theme/extradialogs';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogOverlay } from '@/components/ui/dialog';
import appPreview from "../../assets/mobile1.jpg";
import appPreview2 from "../../assets/mobile2.jpg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useEffect } from 'react';
import googlePlay from "../../assets/google-play.png";
import FaApple from "../../assets/apple-logo.png";

const WelcomeDialog = () => {
    const dispatch = useDispatch();
    const { isOpenWelcomeDialog, isOpenGamifyDialog } = useSelector((state) => state.extradialogs);

    const handleClose = () => {
        dispatch(SET_OPEN_WELCOME_DIALOG(false));
    };

    useEffect(() => {
        const today = new Date().toDateString();
        const lastWelcomeOpen = localStorage.getItem('lastWelcomeOpen');
        const isMobile = window.innerWidth <= 768; // Common mobile breakpoint

        if (!lastWelcomeOpen) {
            dispatch(SET_OPEN_WELCOME_DIALOG(true));
            if (!isOpenGamifyDialog) {
                dispatch(SET_OPEN_WELCOME_DIALOG(true));
            }
            localStorage.setItem('lastWelcomeOpen', today);

            return;
        }

        const lastOpenDate = new Date(lastWelcomeOpen);
        const daysSinceLastOpen = Math.floor((new Date() - lastOpenDate) / (1000 * 60 * 60 * 24));

        if ((isMobile && daysSinceLastOpen >= 1) || (!isMobile && daysSinceLastOpen >= 3)) {
            if (!isOpenGamifyDialog) {
                dispatch(SET_OPEN_WELCOME_DIALOG(true));
            }
            localStorage.setItem('lastWelcomeOpen', today);
        }
    }, [dispatch, isOpenGamifyDialog]);

    return (
        <Dialog open={isOpenWelcomeDialog && !isOpenGamifyDialog} onOpenChange={handleClose}>
            <DialogOverlay className="z-[999]">
                <DialogContent className=" md:w-[70%] w-[95%] max-w-7xl h-[60vh] overflow-hidden border-1 border shadow-2xl shadow-green-900 border-green-900/10 bg-gradient-to-br z-[9999] from-slate-950 via-slate-900 to-slate-950 lg:overflow-y-hidden ">
                    <div className="flex flex-col lg:flex-row items-center justify-center gap-2">
                        <DialogHeader className="relative flex flex-col justify-center items-center w-auto md:w-full">
                            <div className="text-center md:space-y-4 space-y-2">
                                <div className="flex items-center justify-center gap-2 sm:gap-3 mt-8 sm:mt-0">
                                    <DialogTitle className="text-2xl sm:text-xl md:text-3xl lg:text-4xl font-poppins font-bold tracking-wide text-white">
                                        Download Our App
                                    </DialogTitle>
                                </div>
                                <DialogDescription asChild className="text-sm sm:text-base md:text-lg font-medium text-slate-300">
                                    <div className="space-y-4 sm:space-y-6 p-2">
                                        <div className="flex flex-col gap-6 items-center justify-center">
                                            <div className="flex flex-col gap-4 items-center justify-center">
                                                <div className="text-sm sm:text-base  text-center  md:text-lg text-slate-300/90 max-w-full break-words whitespace-normal px-2">
                                                    Get SoundofMeme on your mobile device and unlock a world of AI-generated music.
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-center justify-center gap-4 lg:gap-3 xl:gap-6">
                                                <a href="https://apps.apple.com/us/app/sound-of-meme/id6737062886" target="_blank" rel="noopener noreferrer" className="w-full sm:w-auto">
                                                    <div className='flex flex-row items-center justify-center lg:gap-2 gap-1 bg-white rounded-lg py-1 text-black w-full mx-2 lg:mx-4'>
                                                        <div className=''>
                                                            <LazyLoadImage src={FaApple} alt="Apple" className='w-9 h-9' />
                                                        </div>
                                                        <div className='flex flex-col items-center justify-center'>
                                                            <p className='text-xs sm:text-lg md:text-sm lg:text-md font-normal'>Download on the</p>
                                                            <p className='text-xs sm:text-2xl md:text-lg lg:text-xl font-bold font-mono'>App Store</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="https://play.google.com/store/apps/details?id=com.app.soundofmeme" target="_blank" rel="noopener noreferrer" className="w-full sm:w-auto">
                                                    <div className='flex flex-row items-center justify-center lg:gap-2 gap-1 bg-white rounded-lg py-1 text-black w-full mx-2 lg:mx-4'>
                                                        <div className=''>
                                                            <LazyLoadImage src={googlePlay} alt="Google Play" className='w-9 h-9' />
                                                        </div>
                                                        <div className='flex flex-col items-center justify-center'>
                                                            <p className='text-xs sm:text-lg md:text-sm lg:text-md font-normal'>Download on the</p>
                                                            <p className='text-xs sm:text-2xl md:text-lg lg:text-xl font-bold font-mono'>Play Store</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </DialogDescription>
                            </div>
                        </DialogHeader>
                        {/* App preview image */}
                        <div className="relative w-full h-[350px] mt-4 mb-8 flex items-center justify-center">
                            <div className="relative h-full w-[300px]">
                                {/* Background phone (slightly rotated) */}
                                <LazyLoadImage src={appPreview} alt="SoundOfMeme App Preview" className="absolute w-[150px] sm:w-[250px] md:w-[200px] lg:w-[220px] 2xl:w-[280px] left-[60%] lg:left-[65%] -translate-x-1/2 top-2 lg:top-0 object-contain rounded-2xl shadow-xl z-0" />
                                {/* Foreground phone */}
                                <LazyLoadImage src={appPreview2} alt="SoundOfMeme App Preview" className="absolute w-[150px] sm:w-[250px] md:w-[200px] lg:w-[220px] 2xl:w-[280px] left-[35%] lg:left-[45%] -translate-x-1/2 top-10 lg:top-20 object-contain rounded-3xl shadow-xl z-10" />
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </DialogOverlay>
        </Dialog>
    );
};

export default WelcomeDialog;