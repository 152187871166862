import { Gift } from 'lucide-react';

const GiftCodeSkeleton = () => {
  return (
      <div className="flex flex-col items-center justify-center h-screen w-full max-w-md mx-auto gap-4">
          <div className="animate-bounce">
              <div className="relative">
                  <Gift className="text-6xl text-slate-400" />
                  <div className="absolute inset-0 w-full h-full animate-pulse bg-gradient-to-r from-transparent via-slate-400/20 to-transparent"></div>
              </div>
          </div>
          <div className="space-y-3">
              <div className="h-4 w-48 bg-slate-700 rounded animate-pulse"></div>
              <div className="h-3 w-36 bg-slate-700 rounded animate-pulse mx-auto"></div>
          </div>
      </div>
  );
};

export default GiftCodeSkeleton;