export const debounce = (fn, Time) => {
    let timer;
    
    function debounced(...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, Time);
    }

    // Add cancel method
    debounced.cancel = function() {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    };

    return debounced;
}