import SongCardSkeleton from '../leaderboard/SongTableRowSkeleton';

const SongDetailSkeleton = () => {
    const shimmerClass = "animate-pulse";

    return (
        <div className={`relative w-full h-full bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 dark:from-pink-100 dark:via-pink-200 dark:to-pink-300 overflow-hidden pb-12`}>
            <div className="relative pt-20 lg:pt-24 mb-10">
                <div className="mx-auto px-4 lg:px-8 relative z-10">
                    <div className="flex flex-col lg:flex-row items-start gap-8 lg:gap-16">
                        {/* Left Column */}
                        <div className="w-full lg:w-[250px] xl:w-[400px] space-y-8">
                            {/* Image Skeleton */}
                            <div className="flex justify-center lg:justify-start">
                                <div className={`aspect-square w-full sm:w-[400px] lg:w-[250px] xl:w-[400px] rounded-2xl bg-white/5 ${shimmerClass}`} />
                            </div>

                            {/* Info Section */}
                            <div className="space-y-5">
                                <div className="flex flex-col justify-between items-center gap-3">
                                    <div className={`h-8 w-full bg-white/5 rounded-lg ${shimmerClass}`} />
                                    <div className={`h-8 w-full bg-white/5 rounded-lg ${shimmerClass}`} />
                                    {/* <div className="flex items-center gap-2">
                                        {[1, 2, 3].map(i => (
                                            <div key={i} className={`h-8 w-8 bg-white/5 rounded-full ${shimmerClass}`} />
                                        ))}
                                    </div> */}
                                </div>
                                <div className="flex justify-between items-start gap-3">
                                    <div className={`h-8 w-full bg-white/5 rounded-lg ${shimmerClass}`}></div>

                                    <div className="flex gap-2">
                                        {[1, 2, 3].map(i => (
                                            <div key={i} className={`h-10 w-10 bg-white/5 rounded-full ${shimmerClass}`} />
                                        ))}
                                    </div>
                                </div>

                                <div className={`h-6 w-1/3 bg-white/5 rounded-lg ${shimmerClass}`} />
                                <div className="flex gap-2">
                                    {[1, 2, 3, 4, 5].map(i => (
                                        <div key={i} className={`h-7 w-16 bg-white/5 rounded-full ${shimmerClass}`} />
                                    ))}
                                </div>
                                <div className="flex flex-wrap gap-3">
                                    <div className={`flex-1 h-8 bg-white/5 rounded-full ${shimmerClass}`} />
                                    <div className={`w-32 h-8 bg-white/5 rounded-full ${shimmerClass}`} />
                                </div>
                            </div>
                        </div>

                        {/* Right Column - Lyrics */}
                        <div className="flex-1 w-full">
                            <div className="bg-white/5 rounded-2xl p-6 lg:p-8 space-y-4 w-full">
                                <div className={`h-8 w-24 mx-auto bg-white/10 rounded-lg ${shimmerClass} mb-8`} />
                                <div className="space-y-4 flex flex-col items-center">
                                    {[...Array(16)].map((_, i) => (
                                        <div
                                            key={i}
                                            className={`h-3 bg-white/10 rounded-lg ${shimmerClass}`}
                                            style={{ width: `${Math.random() * 30 + 70}%`, marginLeft: `${Math.random() * 10}%` }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Related Songs Skeleton Section */}
            <div className="mt-12 mx-auto px-3 sm:px-4 lg:px-8 py-8">
                <div className={`h-8 w-48 bg-white/5 rounded-lg ${shimmerClass} mb-6`} />
                <div className="flex-grow py-4 pt-3 w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-8">
                    {[...Array(12)].map((_, index) => (
                        <div key={index} className="w-full">
                            <SongCardSkeleton />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SongDetailSkeleton