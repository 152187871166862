import TopBar from '@/components/navbar/TopBar'
import BottomBar from '@/components/sidebar/BottomBar'
import Lottie from 'lottie-react'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ani from "../../assets/createani3.json"
import musicAni from "../../assets/music.json"
import { logEvent } from 'firebase/analytics'
import { analytics } from '@/firebase'
import CreatingSongsLoading from '@/components/songCard/CreatingSongsLoading'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ADD_SONGS, SET_USERSONGS } from '@/redux/features/music/musicSlice'
import axios from 'axios'
import SongCardCreatePage, { SongCardCreatePageSkeleton } from '@/components/songCard/SongCardCreatePage'
import guitarAni from "../../assets/guitar.json"
import { SET_OTPCARD, SET_SHOW_LOGIN } from '@/redux/features/auth/authSlice'
import { trackMetaEvent } from '@/utils/metaPixel'
import SubscribeCard from './SubscribeCard'
import boxPattern from "@/assets/box-pattern.svg"
import { trackLinkedInEvent } from '@/utils/linkedinTag'
import { trackRedditEvent } from '@/utils/redditPixel'
import { CircleArrowRight } from 'lucide-react'

const CreateSongLazy = lazy(() => import('@/components/createSong/CreateSong'))

const Create = () => {

    const dispatch = useDispatch()
    const { song, userSongs } = useSelector(state => state.music)
    const { creatingSongs } = useSelector(state => state.create)
    const { user } = useSelector(state => state.auth)
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchSongs = async () => {
            const token = localStorage.getItem('token') || localStorage.getItem('guestToken');
            try {
                setIsLoading(true)
                if (page === 1) {
                    dispatch(SET_USERSONGS([]))
                }
                const response = await axios.get(`/usersongs?page=${page}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                dispatch(ADD_SONGS(response.data.songs))
                if (response.data.songs.length === 0 || response.data.songs.length < 20) {
                    setHasMore(false); // No more songs to load
                } else {
                    setHasMore(true); // More songs to load
                }
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                console.log('Error fetching songs:', error);
            }
        };
        if (user) {
            fetchSongs();
        }
    }, [page, user, dispatch]);

    useEffect(() => {
        if (user) {
            dispatch(SET_SHOW_LOGIN(false))
        }
    }, [dispatch, user])

    useEffect(() => {
        const otpToken = sessionStorage.getItem('otpToken')
        if (otpToken) {
            dispatch(SET_SHOW_LOGIN(true));
            dispatch(SET_OTPCARD(true));
        }
    }, [dispatch])

    const handleLogin = () => {
        dispatch(SET_SHOW_LOGIN(true))
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        logEvent(analytics, 'page_view', { page_title: 'Create Song Page' });
        trackMetaEvent('PageView', { page_title: 'Create Song Page' });
        trackLinkedInEvent('page_view', {
            event: 'page_view',
            page_title: 'Create Song Page',
        })
        trackRedditEvent('PageVisit', {
            page_title: 'Create Song Page',
        })
    }, [])

    return (
        <div className='relative min-h-screen bg-slate-950 flex flex-col flex-grow w-full' >
            <TopBar heading={"Create Song"} />
            {((user?.plan === 0 || user?.is_guest) && creatingSongs.length >= 1
                || user?.plan === 1 && creatingSongs.length >= 3
                || user?.plan === 2 && creatingSongs.length >= 5
                || user?.plan === 3 && creatingSongs.length >= 10) && <>
                    <div className='absolute top-0 left-0 w-full h-[650px]'>
                        <img src={boxPattern} alt="box pattern" className='w-full h-full object-cover' />
                    </div>
                    <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-transparent via-transparent to-green-700/20 dark:to-pink-400/30 mix-blend-screen z-20 pointer-events-none'></div>
                    <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-tl from-transparent via-transparent to-green-500/20 dark:to-pink-400/20 mix-blend-screen z-20 pointer-events-none'></div>
                </>}
            <div className={' pt-20 flex-grow flex justify-center pl-4 pr-4 sm:pr-0 '} >

                {!user ? <div className='px-4 flex items-center justify-center flex-col' >
                    <div className='max-w-[250px] w-full ' >
                        <Lottie animationData={musicAni} />
                    </div>
                    <h2 className='text-center text-xl font-medium text-slate-100 dark:text-slate-900 mt-4' >Login to start creating songs...</h2>
                    <button onClick={handleLogin} className='mt-2 text-green-400 dark:text-slate-900 font-medium flex items-center justify-center gap-1 underline' >Login <CircleArrowRight className='mt-1' /></button>
                </div> :
                    <>
                        {((user?.plan === 0 || user?.is_guest) && creatingSongs.length >= 1
                            || user?.plan === 1 && creatingSongs.length >= 3
                            || user?.plan === 2 && creatingSongs.length >= 5
                            || user?.plan === 3 && creatingSongs.length >= 10)
                            ?
                            <div className=' flex-grow flex-col flex items-center justify-center pb-32 px-4' >
                                <div className='max-w-[150px] w-full' >
                                    <Lottie animationData={ani} loop />
                                </div>
                                <h3 className='text-3xl font-semibold text-green-400 dark:text-slate-900 text-center ' >
                                    {(user?.plan === 0 || user?.is_guest) && "More Songs Await!"}
                                    {user?.plan === 1 || user?.plan === 2 && "Need More Bandwidth?"}
                                    {user?.plan === 3 && "Creating Your Songs"}
                                </h3>
                                {(user?.plan === 0 || user?.is_guest) ?
                                    <p className='text-center mt-2' >You can only create 1 song at a time. Unlock more creations by upgrading your plan!</p>
                                    :
                                    user?.plan <= 2 ?
                                        <p className='text-center mt-2' >You can currently create up to
                                            {user?.plan === 1 ? " 2 " : user?.plan === 2 ? " 3 " : " "}
                                            songs simultaneously with your current plan. Unlock more creations by upgrading your plan!
                                        </p>
                                        :
                                        user?.plan === 3 ?
                                            <p className='text-center mt-2' >Maximum 5 simultaneously song generations are allowed with your current plan.</p>
                                            : null
                                }
                                {(user?.plan < 3 || user?.is_guest) && <SubscribeCard />}
                            </div>
                            :
                            <div className={'w-full flex gap-4 h-full '} >
                                <div className={' w-full sm:w-1/2 xl:w-1/3 h-[calc(100vh-5rem)] overflow-y-auto no-scrollbar ' + (song ? " md:pb-28 pb-36 " : " pb-20 md:pb-8")} >
                                    <Suspense fallback={<div className="w-full h-[80%] bg-slate-900 animate-pulse"></div>}>
                                        <CreateSongLazy />
                                    </Suspense>
                                </div>
                                <div className='rounded w-1/2 xl:w-2/3 hidden sm:flex flex-col bg-slate-950 dark:bg-gradient-to-br dark:from-pink-100 dark:to-pink-200' >
                                    <div id="scrollableDiv" className='h-[calc(100vh-5rem)] overflow-y-auto w-full overflow-x-hidden'>
                                        {creatingSongs?.length > 0 && <div className={`w-full grid grid-cols-1 gap-x-4 gap-y-3 pt-4`} >{creatingSongs.map((creation) => (
                                            <React.Fragment key={creation.creationId}>
                                                {creation?.songs.map((song) => (
                                                    <CreatingSongsLoading createPage={true} key={song?.song_id} ani={creation?.ani} song={song} />
                                                ))}
                                            </React.Fragment>
                                        ))}</div>}
                                        <InfiniteScroll
                                            dataLength={userSongs?.length}
                                            next={() => setPage(prevPage => prevPage + 1)}
                                            hasMore={hasMore}
                                            loader={<Loader />}
                                            endMessage={null}
                                            className='flex-grow w-full grid grid-cols-1 gap-x-4 gap-y-3 mt-3'
                                            scrollableTarget="scrollableDiv"
                                        >
                                            {userSongs?.map((song, i) => (
                                                <SongCardCreatePage author={true} song={song} key={i} playingFrom={"userSongs"} />
                                            ))}
                                        </InfiniteScroll>
                                        {!isLoading && userSongs?.length === 0 && creatingSongs?.length === 0 && <div className='flex flex-col items-center justify-center' >
                                            <Lottie animationData={guitarAni} />
                                            <p className='-mt-16 font-medium ' >No songs found. Start creating your first one!</p>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
            <BottomBar />
        </div>
    )
}
export default Create

function Loader() {
    return (
        <>
            <SongCardCreatePageSkeleton />
            <SongCardCreatePageSkeleton />
            <SongCardCreatePageSkeleton />
            <SongCardCreatePageSkeleton />
            <SongCardCreatePageSkeleton />
            <SongCardCreatePageSkeleton />
            <SongCardCreatePageSkeleton />
            <SongCardCreatePageSkeleton />
            <SongCardCreatePageSkeleton />
            <SongCardCreatePageSkeleton />
        </>

    )
}