// LeaderboardLoader.jsx
import { ArrowLeft, ArrowRight, Bitcoin, Coins, MapPin, Music2, Music4, ReplaceAll, Trophy, User } from "lucide-react";
import SongTableRowSkeleton from "./SongTableRowSkeleton";
import LeaderboardImageSection from "./LeaderboardImageSection";
import TopBar from "@/components/navbar/TopBar";

const LeaderboardLoader = ({ tab, selectedCategory, selectedSubCategory }) => {
    const isCrypto = tab === "crypto";

    // Podium Loading Skeleton
    const PodiumSkeleton = () => (
        <div className="flex items-center justify-center mt-16 px-4 w-full">
            {/* Second Place */}
            <div className="flex flex-col items-center translate-y-12">
                <div className="w-12 h-12 sm:w-24 sm:h-24 rounded-lg overflow-hidden mb-4 z-10 bg-slate-700 shadow-xl shadow-slate-300/60 dark:bg-pink-300/50 animate-pulse" />
                <div className="relative max-w-48 min-w-[100px] sm:min-w-[150px] lg:min-w-[220px] w-full h-[160px] overflow-hidden">
                    <div className="absolute top-4 sm:top-3 w-1/2 h-2 sm:h-4 bg-slate-600 dark:bg-pink-400 transform -skew-x-[60deg] sm:-skew-x-[78deg]" />
                    <div className="absolute top-4 sm:top-3 right-0 w-1/2 h-2 sm:h-4 bg-slate-600 dark:bg-pink-400 transform skew-x-[65deg] sm:skew-x-[70deg]" />
                    <div className="absolute bottom-0 w-full h-[140px] bg-gradient-to-b from-slate-900 via-slate-950 to-slate-950 dark:from-pink-400 dark:via-pink-300 dark:to-pink-200/20" />
                    <div className="absolute top-8 sm:top-10 left-1/2 -translate-x-1/2 text-center z-20 w-full px-4">
                        <div className="flex flex-col items-center mt-1">
                            <div className="h-6 w-6 sm:h-8 sm:w-8 bg-slate-700 dark:bg-pink-300/50 rounded animate-pulse" />
                            <div className="h-2 sm:h-3 w-3/4 bg-slate-700 dark:bg-pink-300/50 rounded-full animate-pulse mt-2 sm:mt-3" />
                            <div className="h-1.5 sm:h-2.5 w-1/2 bg-slate-700 dark:bg-pink-300/50 rounded-full animate-pulse mt-1 sm:mt-2" />
                            <div className="h-2 sm:h-3 w-3/4 bg-slate-700 dark:bg-pink-300/50 rounded-full animate-pulse mt-2 sm:mt-3" />
                        </div>
                    </div>
                </div>
            </div>

            {/* First Place */}
            <div className="flex flex-col items-center">
                <div className="w-16 h-16 sm:w-28 sm:h-28 rounded-lg overflow-hidden mb-4 z-10 bg-slate-700 shadow-xl shadow-amber-300/60 dark:bg-pink-300/50 animate-pulse" />
                <div className="relative max-w-[250px] min-w-[130px] sm:min-w-[250px] w-full h-[200px] overflow-hidden">
                    <div className="absolute top-3.5 sm:top-2.5 left-1 w-1/3 h-2 sm:h-4 bg-slate-600 dark:bg-pink-400 transform -skew-x-[60deg] sm:-skew-x-[80deg]" />
                    <div className="absolute top-3.5 sm:top-2.5 right-0 w-1/3 h-2 sm:h-4 bg-slate-600 dark:bg-pink-400 transform skew-x-[65deg] sm:skew-x-[80deg]" />
                    <div className="absolute top-3.5 sm:top-[11px] left-1/2 -translate-x-1/2 w-2/3 h-2 sm:h-4 bg-slate-600 dark:bg-pink-400" />
                    <div className="absolute bottom-0 w-full h-[180px] bg-gradient-to-b from-slate-900 via-slate-950 to-slate-950 dark:from-pink-400 dark:via-pink-300 dark:to-pink-200/20" />
                    <div className="absolute top-8 sm:top-10 left-1/2 -translate-x-1/2 text-center z-20 w-full px-4">
                        <div className="flex flex-col items-center mt-1">
                            <div className="h-6 w-6 sm:h-8 sm:w-8 bg-slate-700 dark:bg-pink-300/50 rounded animate-pulse" />
                            <div className="h-2 sm:h-3.5 w-3/4 sm:w-2/3 bg-slate-700 dark:bg-pink-300/50 rounded-full animate-pulse mt-2 sm:mt-3" />
                            <div className="h-1.5 sm:h-2.5 w-1/2 bg-slate-700 dark:bg-pink-300/50 rounded-full animate-pulse mt-1 sm:mt-2" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Third Place */}
            <div className="flex flex-col items-center translate-y-12">
                <div className="w-12 h-12 sm:w-24 sm:h-24 rounded-lg overflow-hidden mb-4 z-10 bg-slate-700 shadow-xl shadow-amber-800/90 dark:bg-pink-300/50 animate-pulse" />
                <div className="relative max-w-48 min-w-[100px] sm:min-w-[150px] lg:min-w-[220px] w-full h-[160px] overflow-hidden">
                    <div className="absolute top-4 sm:top-3 w-1/2 h-2 sm:h-4 bg-slate-600 dark:bg-pink-400 transform -skew-x-[60deg] sm:-skew-x-[70deg]" />
                    <div className="absolute top-4 sm:top-3 right-0 w-1/2 h-2 sm:h-4 bg-slate-600 dark:bg-pink-400 transform skew-x-[65deg] sm:skew-x-[78deg]" />
                    <div className="absolute bottom-0 w-full h-[140px] bg-gradient-to-b from-slate-900 via-slate-950 to-slate-950 dark:from-pink-400 dark:via-pink-300 dark:to-pink-200/20" />
                    <div className="absolute top-8 sm:top-10 left-1/2 -translate-x-1/2 text-center z-20 w-full px-4">
                        <div className="flex flex-col items-center mt-1">
                            <div className="h-6 w-6 sm:h-8 sm:w-8 bg-slate-700 dark:bg-pink-300/50 rounded animate-pulse" />
                            <div className="h-2 sm:h-3 w-3/4 bg-slate-700 dark:bg-pink-300/50 rounded-full animate-pulse mt-2 sm:mt-3" />
                            <div className="h-1.5 sm:h-2.5 w-1/2 bg-slate-700 dark:bg-pink-300/50 rounded-full animate-pulse mt-1 sm:mt-2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    // Time Range Loading Skeleton for non-crypto leaderboard
    const TimeRangeSkeleton = () => (
        <div className="flex items-center justify-center mt-8">
            <div className="bg-slate-900 dark:bg-pink-300/50 rounded-full flex border-2 border-slate-700 dark:border-pink-700/50 overflow-hidden text-xs xs:text-sm">
                <button className="font-medium px-6 xs:px-8 py-2 truncate rounded-full text-slate-200 dark:text-slate-700">Daily</button>
                <button className="font-medium px-6 xs:px-8 py-2 truncate rounded-full text-slate-200 dark:text-slate-700">Weekly</button>
                <button className="font-medium px-6 xs:px-8 py-2 truncate rounded-full text-slate-200 dark:text-slate-700">Monthly</button>
                <button className="font-medium px-6 xs:px-8 py-2 truncate rounded-full text-slate-200 dark:text-slate-700">All Time</button>
            </div>
        </div>
    );

    // Genre Crypto Loading Skeleton
    const SwiperGenreCryptoSkeleton = () => {
        return (
            <div className="w-full mx-auto relative mt-8">
                {/* Fade gradients on sides */}
                <>
                    <div className="absolute left-8 top-0 bottom-0 w-16 sm:w-24 bg-gradient-to-r from-slate-950 via-slate-950/50 to-transparent dark:from-pink-200/20 dark:via-pink-100/10 dark:to-transparent z-[1] pointer-events-none" />
                    <div className="absolute right-8 top-0 bottom-0 w-16 sm:w-24 bg-gradient-to-l from-slate-950 via-slate-950/50 to-transparent dark:from-pink-200/20 dark:via-pink-100/10 dark:to-transparent z-[1] pointer-events-none" />
                </>
                {/* Navigation buttons placeholders */}
                <button className={"swiper-button-prev z-[2] rounded-md p-0.5 after:!hidden flex items-center justify-center " + "!text-slate-100 dark:!text-white " + "!bg-slate-700 dark:!bg-pink-400 " + "hover:!bg-green-400 dark:hover:!bg-pink-400 " + "hover:!text-black dark:hover:!text-white dark:!text-white " + " !top-[26px] !w-6 !h-6 !left-0 !-mt-[14px]"}>
                    <ArrowLeft className="w-4 h-4" />
                </button>
                <button className={"swiper-button-next z-[2] rounded-md p-0.5 after:!hidden flex items-center justify-center " + "!text-slate-100 dark:!text-white " + "!bg-slate-700 dark:!bg-pink-400 " + "hover:!bg-green-400 dark:hover:!bg-pink-400 " + "hover:!text-black dark:hover:!text-white dark:!text-white " + " !top-[26px] !w-6 !h-6 !right-0 !-mt-[14px]"}>
                    <ArrowRight className="w-4 h-4" />
                </button>
                {/* Skeleton items */}
                <div className="flex overflow-x-hidden mx-8 relative z-0 gap-2">
                    {Array(20).fill(0).map((_, i) => (
                        <div key={i} className="!w-auto min-w-[100px] animate-pulse">
                            <div className="flex items-center justify-center py-1 rounded-md w-full mt-2 bg-slate-800/70 dark:bg-pink-300/50 h-8" />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    // Song Cards Loading Skeleton
    const SongCardsSkeleton = () => (
        <div className="grid items-center justify-items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 sm:gap-x-2 sm:gap-y-4 lg:gap-x-1 lg:gap-y-2 xl:gap-x-4 xl:gap-y-4 gap-x-4 gap-y-2 mt-8 px-2 sm:px-4 mx-auto w-full no-scrollbar">
            {Array(6).fill(0).map((_, index) => (
                <SongTableRowSkeleton key={index} isCrypto={isCrypto} />
            ))}
        </div>
    );

    // Leaderboard Tab switch Loading Skeleton
    const LeaderboardTabSwitchSkeleton = () => (
        <div className="relative border-b border-slate-600 dark:border-pink-700/50 bg-slate-900/30 dark:bg-pink-100/30 md:p-1.5 p-1 rounded-sm overflow-hidden shadow-lg w-full">
            {/* Animated background highlight */}
            <div className={`absolute top-1.5 bottom-1.5 ${tab === 'crypto' ? 'left-1.5 right-[calc(50%+1.5px)]' : 'left-[calc(50%+1.5px)] right-1.5'} bg-gradient-to-r from-green-500/90 to-green-400/80 dark:from-pink-500/90 dark:to-pink-400/80 rounded-sm transition-all duration-300 ease-in-out shadow-md`} />
            {/* Tab buttons */}
            <div className="grid grid-cols-2 relative z-10">
                <button className={`flex items-center justify-center gap-2 md:py-2.5 md:px-4 py-2 px-1 rounded-sm font-medium transition-all duration-300 ${tab === 'crypto' ? 'text-black dark:text-white lg:text-lg text-sm' : 'lg:text-lg text-sm text-slate-300 dark:text-slate-600 hover:text-slate-100 dark:hover:text-slate-800'}`}>
                    <Bitcoin size={18} className={`${tab === 'crypto' ? "animate-spin-slow" : ""}`} />
                    <span>Crypto Leaderboard</span>
                </button>
                <button className={`flex items-center justify-center gap-2 md:py-2.5 md:px-4 py-2 px-1 rounded-sm font-medium transition-all duration-300 ${tab === 'some' ? 'text-black dark:text-white lg:text-lg text-sm' : 'lg:text-lg text-sm text-slate-300 dark:text-slate-600 hover:text-slate-100 dark:hover:text-slate-800'}`}>
                    <Trophy size={18} className={tab === 'some' ? "animate-bounce-subtle" : ""} />
                    <span>SOME Leaderboard</span>
                </button>
            </div>
        </div>
    );

    // Leaderboard Category switch Loading Skeleton
    const LeaderboardCategorySwitchSkeleton = () => {
        const categoryItems = [
            { category_id: 0, name: "All Categories" },
            { name: "Celebrities" },
            { name: "Tokens" },
            { name: "Genre" },
            { name: "Countries" },
            { name: "Advertising" }
        ];

        const icons = [
            <ReplaceAll size={18} key="all" />,
            <User size={18} key="user" />,
            <Coins size={18} key="coins" />,
            <Music2 size={18} key="music" />,
            <MapPin size={18} key="map" />,
            <Music4 size={18} key="megaphone" />
        ];

        return (
            <div className="flex w-full items-center justify-start overflow-x-auto text-sm sm:text-base font-semibold sm:font-medium 
                [&::-webkit-scrollbar]:!h-0.5
                [&::-webkit-scrollbar-track]:!rounded-full 
                [&::-webkit-scrollbar-track]:!bg-slate-900 
                dark:[&::-webkit-scrollbar-track]:!bg-pink-300
                [&::-webkit-scrollbar-thumb]:!rounded-full 
                [&::-webkit-scrollbar-thumb]:!bg-slate-400
                dark:[&::-webkit-scrollbar-thumb]:!bg-pink-700">
                <div className="flex flex-grow w-full dark:border dark:border-b dark:border-pink-700">
                    {categoryItems.map((item, index) => {
                        // Check if this category matches the selected category
                        const isSelected = selectedCategory?.name === item.name ||
                            (selectedCategory?.category_id === 0 && item.category_id === 0);

                        return (
                            <div
                                key={item.name}
                                className={`border-t-2 min-w-28 px-4 py-4 sm:py-2 flex-1 transition-all border-b-2 whitespace-nowrap flex items-center justify-center gap-1 
                                    ${isSelected
                                        ? "text-green-400 dark:text-pink-700 border-b-green-600 dark:border-b-pink-900 border-t-green-600 dark:border-t-pink-400 sm:border-t-slate-700 dark:sm:border-t-pink-300 bg-green-500/10 dark:bg-pink-400/10"
                                        : "text-slate-300 dark:text-slate-600 border-slate-700 dark:border-pink-300 border-b-transparent"}`}
                            >
                                {icons[index]}
                                <span>{item.name === "All Categories" ? "All" : item.name}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    // Add this new component after LeaderboardCategorySwitchSkeleton
    const SubCategorySwiperSkeleton = () => {
        return (
            <div className={"w-full mx-auto relative my-4 px-4"}>
                {/* Fade gradients */}
                <div className="absolute left-8 top-0 bottom-0 w-16 sm:w-24 bg-gradient-to-r from-slate-950 via-slate-950/50 to-transparent dark:from-pink-200/20 dark:via-pink-100/10 dark:to-transparent z-[1] pointer-events-none" />
                <div className="absolute right-8 top-0 bottom-0 w-16 sm:w-24 bg-gradient-to-l from-slate-950 via-slate-950/50 to-transparent dark:from-pink-200/20 dark:via-pink-100/10 dark:to-transparent z-[1] pointer-events-none" />
                {/* Navigation buttons */}
                <button className={"swiper-button-prev z-[2] rounded-md p-0.5 after:!hidden flex items-center justify-center " + "!text-slate-100 dark:!text-white " + "!bg-slate-700 dark:!bg-pink-400 " + "!w-7 !h-7 !top-[29px] !left-1"}>
                    <ArrowLeft className="w-4 h-4" />
                </button>
                <button className={"swiper-button-next z-[2] rounded-md p-0.5 after:!hidden flex items-center justify-center " + "!text-slate-100 dark:!text-white " + "!bg-slate-700 dark:!bg-pink-400 " + "!w-7 !h-7 !top-[29px] !right-1"}>
                    <ArrowRight className="w-4 h-4" />
                </button>
                {/* Show actual subcategories if available, otherwise show loading skeleton */}
                <div className="flex gap-2 mx-8 overflow-hidden">
                    {selectedCategory?.subcategories ? (
                        // Render actual subcategories
                        selectedCategory.subcategories.map((subcat, index) => (
                            <div key={index} className="!w-auto min-w-[100px]">
                                <div className={`flex items-center gap-2 pl-1 pr-2.5 py-1 rounded-md w-full 
                                    ${(selectedSubCategory?.subcategory_id === subcat.subcategory_id ||
                                        selectedSubCategory?.name === subcat.name) ?
                                        'bg-green-500/20 dark:bg-pink-400/20 text-green-400 dark:text-pink-700' :
                                        'bg-slate-800/70 dark:bg-pink-300/50 text-slate-300 dark:text-slate-600'}`}>
                                    <div className="w-8 h-8 rounded overflow-hidden">
                                        {subcat.image_url && <img src={subcat.image_url} alt={subcat.name} className="w-full h-full object-cover" />}
                                    </div>
                                    <span className="truncate">{subcat.name}</span>
                                </div>
                            </div>
                        ))
                    ) : (
                        // Loading skeleton
                        Array(8).fill(0).map((_, index) => (
                            <div key={index} className="!w-auto min-w-[100px] animate-pulse">
                                <div className="flex items-center gap-2 pl-1 pr-2.5 py-1 rounded-md w-full 
                                    bg-slate-800/70 dark:bg-pink-300/50">
                                    <div className="w-8 h-8 rounded bg-slate-700 dark:bg-pink-400/50" />
                                    <div className="h-4 w-16 bg-slate-700 dark:bg-pink-400/50 rounded" />
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <TopBar header="Leaderboard" />
            <div className="bg-slate-950 dark:bg-pink-300/50">
                <LeaderboardImageSection />
                <LeaderboardTabSwitchSkeleton />
                {!isCrypto && (
                    <>
                        <LeaderboardCategorySwitchSkeleton selectedCategory={selectedCategory} />
                        {/* Only show SubCategorySwiperSkeleton if we're not in "All Categories" */}
                        {selectedCategory?.category_id !== 0 && <SubCategorySwiperSkeleton />}
                    </>
                )}
                <PodiumSkeleton />
                {!isCrypto && <TimeRangeSkeleton />}
                {isCrypto && (
                    <>
                        <SwiperGenreCryptoSkeleton />
                        <h2 className="md:text-2xl text-lg font-bold text-start px-2 sm:px-4 mt-6">
                            Crypto Songs Leaderboard
                        </h2>
                    </>
                )}
                <SongCardsSkeleton />
            </div>
        </>
    );
};

export default LeaderboardLoader;