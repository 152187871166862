import { Pill, Forward, Play, Heart, Clock } from 'lucide-react';

const SongTableRowSkeleton = ({ isCrypto = false }) => {
  return (
    <div className="w-full max-w-2xl">
      <div className="bg-gradient-to-br from-slate-800/80 via-amber-900/10 to-slate-950/80 dark:bg-pink-50/90 rounded-md overflow-hidden shadow-lg animate-pulse border border-slate-700/30 dark:border-pink-200/30">
        <div className="flex flex-row">
          {/* Left Side - Image with Play Controls Skeleton */}
          <div className="relative w-36 h-36 flex-shrink-0 bg-slate-800 dark:bg-pink-400/80">
            {/* Rank Badge Skeleton */}
            <div className="absolute top-0 left-0 bg-slate-700 dark:bg-pink-400/90 text-white dark:text-white rounded-br-md min-w-10 h-5"></div>
            {/* Play button area skeleton */}
            <div className="absolute inset-0 flex items-center justify-center bg-black/30 dark:bg-pink-950/30">
              <div className="w-11 h-11 rounded-full bg-slate-700 dark:bg-pink-400/50"></div>
            </div>
          </div>

          {/* Right Side - Content Skeleton */}
          <div className="p-1 px-2 flex flex-col justify-between flex-grow min-w-0">
            {/* Top Section - Title, Category Skeleton */}
            <div className="flex justify-between items-start space-x-3">
              <div className="flex-grow min-w-0 flex flex-col gap-2">
                {/* Title Skeleton */}
                <div className="h-5 w-4/5 bg-slate-700 dark:bg-pink-400/30 rounded-md"></div>
                
                <div className="flex flex-row items-start gap-2">
                  {/* Username Skeleton */}
                  <div className="h-3 w-20 bg-slate-700/80 dark:bg-pink-400/20 rounded-md"></div>
                  {/* Category Skeleton */}
                  <div className="h-3 w-16 bg-slate-700/80 dark:bg-pink-400/20 rounded-md"></div>
                </div>

                {/* Stats row skeleton */}
                <div className="flex flex-row items-center gap-2 mt-0.5">
                  <div className="flex items-center gap-2 flex-shrink-0">
                    {/* Views skeleton */}
                    <div className="flex flex-row items-center gap-1">
                      <Play size={16} className="text-slate-600" />
                      <div className="h-3 w-10 bg-slate-700/80 dark:bg-pink-400/20 rounded-md"></div>
                    </div>
                    <span className="text-xs text-slate-400 dark:text-slate-600">•</span>
                    {/* Duration skeleton */}
                    <div className="flex flex-row items-center gap-1">
                      <Clock size={16} className="text-slate-600" />
                      <div className="h-3 w-10 bg-slate-700/80 dark:bg-pink-400/20 rounded-md"></div>
                    </div>
                    <span className="text-xs text-slate-400 dark:text-slate-600">•</span>
                    {/* Token age skeleton */}
                    <div className="h-3 w-14 bg-slate-700/80 dark:bg-pink-400/20 rounded-md"></div>
                  </div>
                </div>
                
                {/* Market cap skeleton - only shown for crypto */}
                {isCrypto && (
                  <div className="flex flex-row items-center gap-2 mt-0.5">
                    <div className="h-3 w-32 bg-slate-700/80 dark:bg-pink-400/20 rounded-md"></div>
                  </div>
                )}
              </div>

              {/* Like/Share buttons skeleton */}
              <div className="flex items-center gap-2">
                <div className="flex gap-1 items-center">
                  <Heart size={18} className="text-slate-600 dark:text-pink-400/30" />
                  <div className="h-3 w-6 bg-slate-700/80 dark:bg-pink-400/20 rounded-md"></div>
                </div>
                <div className="p-1.5 rounded-md bg-slate-700/80 dark:bg-pink-400/20">
                  <Forward size={18} className="text-slate-600 dark:text-pink-400/30" />
                </div>
              </div>
            </div>

            {/* Bottom Section - Actions Skeleton */}
            <div className="pt-0 border-t border-slate-700/30 dark:border-pink-200/30 mt-1">
              {!isCrypto && (
                <div className="flex items-center justify-between py-1">
                  {/* Voting Controls Skeleton */}
                  <div className="flex items-center space-x-1 w-full">
                    {/* Upvote Button Skeleton */}
                    <div className="w-full h-7 bg-slate-700/80 dark:bg-pink-400/20 border border-slate-600/50 dark:border-pink-300/30 rounded-sm"></div>
                    
                    {/* Score Skeleton */}
                    <div className="h-5 w-10 bg-slate-700/80 dark:bg-pink-400/20 rounded-md flex-shrink-0 text-center"></div>
                    
                    {/* Downvote Button Skeleton */}
                    <div className="w-full h-7 bg-slate-700/80 dark:bg-pink-400/20 border border-slate-600/50 dark:border-pink-300/30 rounded-sm"></div>
                  </div>
                </div>
              )}
              
              {isCrypto && (
                <div className="flex items-center justify-center pt-0.5">
                  <div className="flex items-center w-full justify-center gap-2 border border-slate-600/50 dark:border-pink-300/30 rounded-sm py-1 bg-slate-700/80 dark:bg-pink-400/20">
                    <Pill size={16} className="text-slate-600 dark:text-pink-300" />
                    <div className="h-3 w-20 bg-slate-700/80 dark:bg-pink-400/20 rounded-md"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SongTableRowSkeleton;