import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff, Lock, ShieldCheck } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import TopBar from '@/components/navbar/TopBar';
import BottomBar from '@/components/sidebar/BottomBar';
import { changePassword } from '@/redux/features/auth/authSlice';
import { Link } from 'react-router-dom';

const ChangePassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleSubmit, register, reset, formState: { errors } } = useForm();
    
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    
    const handleChangePassword = async (data) => {
        const result = await dispatch(changePassword({
            old_password: data.oldPassword,
            new_password: data.newPassword
        })).unwrap(); 
        
        if (result) {
            navigate('/profile'); 
            reset(); 
        }
    }

    return (
        <div className='relative min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 dark:from-pink-100 dark:via-pink-200 dark:to-pink-300 overflow-hidden flex flex-col'>
            <TopBar heading="Change Password" />
            
            <div className='flex-grow flex items-center justify-center relative z-50 p-4'>
                <div className='w-full max-w-md'>
                    <Card className='bg-slate-800/60 dark:bg-pink-200/60 backdrop-blur-lg border-2 border-green-900/30 dark:border-pink-400/30 rounded-2xl shadow-2xl shadow-green-900/20 dark:shadow-pink-400/30 overflow-hidden'>
                        <div className='absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-green-400 via-slate-500 to-slate-600 dark:from-pink-400 dark:via-pink-500 dark:to-pink-600'></div>
                        
                        <CardContent className='p-8 space-y-6'>
                            <div className='flex items-center justify-center mb-6'>
                                <ShieldCheck className='w-16 h-16 text-green-400 dark:text-pink-500 opacity-80' strokeWidth={1.5} />
                            </div>
                            
                            <form onSubmit={handleSubmit(handleChangePassword)} className='space-y-6'>
                                <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                                        <Lock className='w-5 h-5 text-slate-500 dark:text-pink-600' />
                                    </div>
                                    <input 
                                        type={showOldPassword ? 'text' : 'password'}
                                        {...register('oldPassword', {
                                            required: 'Old password is required',
                                        })}
                                        placeholder="Old Password"
                                        className='w-full pl-10 pr-12 py-3 bg-slate-900/50 dark:bg-pink-100/50 text-slate-200 dark:text-pink-900 rounded-xl border border-green-900/30 dark:border-pink-400/30 focus:border-green-400 dark:focus:border-pink-500 focus:ring-2 focus:ring-green-400/50 dark:focus:ring-pink-500/50 transition-all duration-300'
                                    />
                                    <button 
                                        type="button"
                                        onClick={() => setShowOldPassword(!showOldPassword)}
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-slate-500 dark:text-pink-600 hover:text-green-400 dark:hover:text-pink-700 transition-colors"
                                    >
                                        {showOldPassword ? <EyeOff /> : <Eye />}
                                    </button>
                                    {errors.oldPassword && (
                                        <p className='absolute text-xs text-red-400 dark:text-red-500 mt-1'>
                                            {errors.oldPassword.message}
                                        </p>
                                    )}
                                </div>
                                
                                <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                                        <Lock className='w-5 h-5 text-slate-500 dark:text-pink-600' />
                                    </div>
                                    <input
                                        type={showNewPassword ? 'text' : 'password'}
                                        {...register('newPassword', {
                                            required: 'New password is required',
                                            minLength: {
                                                value: 8,
                                                message: 'Password must have at least 8 characters'
                                            }
                                        })}
                                        placeholder="New Password"
                                        className='w-full pl-10 pr-12 py-3 bg-slate-900/50 dark:bg-pink-100/50 text-slate-200 dark:text-pink-900 rounded-xl border border-green-900/30 dark:border-pink-400/30 focus:border-green-400 dark:focus:border-pink-500 focus:ring-2 focus:ring-green-400/50 dark:focus:ring-pink-500/50 transition-all duration-300'
                                    />
                                    <button 
                                        type="button"
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-slate-500 dark:text-pink-600 hover:text-green-400 dark:hover:text-pink-700 transition-colors"
                                    >
                                        {showNewPassword ? <EyeOff /> : <Eye />}
                                    </button>
                                    {errors.newPassword && (
                                        <p className='absolute text-xs text-red-400 dark:text-red-500 mt-1'>
                                            {errors.newPassword.message}
                                        </p>
                                    )}
                                </div>
                                
                                <Button 
                                    type="submit" 
                                    className="w-full mt-4 bg-gradient-to-r from-green-400 to-teal-400 dark:from-pink-400 dark:to-pink-600 hover:from-green-500 hover:to-yellow-600 dark:hover:from-pink-500 dark:hover:to-pink-700 text-slate-900 dark:text-white py-3 rounded-xl transition-all duration-300 transform hover:scale-105 hover:shadow-lg dark:hover:shadow-pink-400/30"
                                >
                                    Change Password
                                </Button>
                            </form>
                            <h1 className='text-center text-slate-400 dark:text-pink-800 text-sm'>
                               Forgot Password? <Link to="/resetpassword" className='text-green-400 dark:text-pink-600 hover:text-green-500 dark:hover:text-pink-700'>Click here</Link>
                            </h1>
                        </CardContent>
                    </Card>
                    
                    <div className='text-center mt-4 text-slate-400 dark:text-pink-800 text-sm'>
                        Secure your account with a strong, unique password
                    </div>
                </div>
            </div>
            
            <BottomBar />
        </div>
    )
}

export default ChangePassword;