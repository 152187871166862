import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const RedirectToPlaylist = () => {
    const { playlistId } = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (playlistId) {
        // Redirect to playlist route with query parameter
        navigate(`/playlist?id=${playlistId}`, { 
          replace: true,
          state: { fromRedirect: true }
        });
      } else {
        // Redirect to default playlist route
        navigate('/playlist', { 
          replace: true,
          state: { fromRedirect: true }
        });
      }
    }, [playlistId, navigate]);
  
    return null;
};

export default RedirectToPlaylist;