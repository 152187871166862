import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './index.css';
import { Provider } from 'react-redux';
import store from './redux/store.js';
import { Wallet } from './components/walletConnect/WalletConnect.jsx';
import ErrorBoundary from './components/errorBoundry/ErrorBoundry.jsx';
import { initMetaPixel } from './utils/metaPixel';
import { initLinkedInTag } from './utils/linkedinTag';
import { initRedditPixel } from './utils/redditPixel';
import { initTwitterPixel } from './utils/twitterPixel.js';

initMetaPixel()
initLinkedInTag()
initRedditPixel()
initTwitterPixel()

// Function to extract UTM parameters from URL
function getUTMParameters() {
  const params = new URLSearchParams(window.location.search);
  let utmData = {};

  // Store UTM values in an object
  params.forEach((value, key) => {
    if (key.startsWith("utm_")) {
      utmData[key] = value;
    }
  });

  return utmData;
}

// Function to save UTM data in localStorage
function saveUTMToLocalStorage() {
  const utmData = getUTMParameters();
  if (Object.keys(utmData).length > 0) {
    localStorage.setItem("utmParams", JSON.stringify(utmData));
  }
}

saveUTMToLocalStorage();

ReactDOM.createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <Provider store={store}>
      <Wallet>
        <App />
      </Wallet>
    </Provider>
  </ErrorBoundary>
)
