import { Crown, Trophy, Target, CheckCircle, Sparkles, Star, Medal, Award, Lock } from "lucide-react";

const AchievementSkeleton = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 pb-20">
      {[1, 2, 3, 4, 5, 6].map((index) => (
        <div key={index} className="relative w-full overflow-hidden rounded-lg md:p-5 p-2 pt-3 bg-slate-900/60 border border-slate-800/40 animate-pulse"        >
          {/* Achievement Title and Info */}
          <div className="flex flex-col gap-4 justify-between w-full items-start mb-3">
            <div className="flex items-center gap-2 w-full">
              <Trophy className="w-4 h-4 text-gray-600" />
              <div className="h-6 bg-gray-600 rounded w-3/4" />
              <span className="text-xs">
                {index % 2 === 0 ? (
                  <Crown className="w-6 h-6 text-gray-600" />
                ) : (
                  <Medal className="w-6 h-6 text-gray-600" />
                )}
              </span>
            </div>
            <span className="text-xs">
              <div className="text-sm font-normal border border-gray-600 rounded-md px-2 py-1 inline-flex items-center gap-1">
                <Star className="w-3.5 h-3.5 text-gray-600" />
                <div className="h-3.5 bg-gray-600 rounded w-20" />
              </div>
            </span>
          </div>
          {/* Progress */}
          <div className="mb-4">
            <div className="flex justify-between items-center mb-1">
              <div className="h-5 bg-gray-600 rounded w-28" />
              <div className="flex items-center gap-1">
                <div className="h-5 bg-gray-600 rounded w-8" />
                <span className="text-gray-600">/</span>
                <div className="h-5 bg-gray-600 rounded w-8" />
              </div>
            </div>
            <div className="md:h-2 h-1 w-full bg-gray-700 rounded-full overflow-hidden">
              <div className="h-full bg-gray-600 rounded-full" style={{ width: "60%" }} />
            </div>
          </div>
          {/* Milestones */}
          <div className="h-[220px] overflow-hidden">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <Target className="w-4 h-4 text-gray-600" />
                <div className="h-5 bg-gray-600 rounded w-20" />
              </div>
              <div className="py-1 px-6 bg-gray-600 rounded-md flex items-center gap-1">
                <Sparkles className="w-3.5 h-3.5 text-gray-600" />
                <div className="h-4 bg-gray-600 rounded w-12" />
              </div>
            </div>
            <div className="h-[160px] overflow-y-hidden pr-2 space-y-3">
              {[1, 2, 3].map((milestone) => (
                <div key={milestone} className="p-3 rounded-lg bg-slate-900/60 border border-slate-800/40">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 max-w-[65%]">
                      {milestone === 1 ? (
                        <Award className="w-4 h-4 text-gray-600" />
                      ) : milestone === 2 ? (
                        <CheckCircle className="w-4 h-4 text-gray-600" />
                      ) : (
                        <Lock className="w-4 h-4 text-gray-600" />
                      )}
                      <div className="h-4 bg-gray-600 rounded w-3/4" />
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="h-4 bg-gray-600 rounded w-8" />
                      <div className="h-4 bg-gray-600 rounded w-16" />
                    </div>
                  </div>
                  <div className="mt-3 flex items-center gap-2">
                    <div className="flex-1 h-1.5 bg-gray-800 rounded-full overflow-hidden">
                      <div className="h-full bg-gray-600 rounded-full" style={{ width: milestone === 1 ? "100%" : milestone === 2 ? "60%" : "30%" }} />
                    </div>
                    <div className="text-xs min-w-[40px] text-right">
                      <div className="h-3 bg-gray-600 rounded w-8 ml-auto" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AchievementSkeleton;