import TopBar from "@/components/navbar/TopBar"
import BottomBar from "@/components/sidebar/BottomBar"
import { analytics } from "@/firebase"
import { SET_SHOW_LOGIN, SET_USER } from "@/redux/features/auth/authSlice"
import { trackLinkedInEvent } from "@/utils/linkedinTag"
import { trackMetaEvent } from "@/utils/metaPixel"
import { trackRedditEvent } from "@/utils/redditPixel"
import axios from "axios"
import { logEvent } from "firebase/analytics"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { Users, Plus, Copy, DollarSign, Loader2, Share2 } from "lucide-react";

const Affliliate = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state.auth)
    const [stats, setStats] = useState({})
    const [loadingStats, setLoadingStats] = useState(false)

    const generateAffiliateLink = async () => {
        if (user?.is_guest) {
            dispatch(SET_SHOW_LOGIN(true))
            return toast.error("Please login to generate affiliate link", { id: "guest" })
        }
        const token = localStorage.getItem('token')
        try {
            setLoading(true)
            toast.loading("Creating Affiliate Link...", { id: "affiliate-link" })
            const { data } = await axios.post("/affiliate/generate", {}, { headers: { Authorization: `Bearer ${token}` } })
            const updatedUser = { ...user, affiliate: { code: data?.affiliate_code, link: data?.affiliate_link, is_active: true } }
            dispatch(SET_USER(updatedUser))
            toast.success("Affiliate Link Created", { id: "affiliate-link" })
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.detail || error.message || "Something went wrong", { id: "referral-link" })
        } finally {
            setLoading(false)
        }
    }

    const copyAffiliateLink = () => {
        navigator.clipboard.writeText(user?.affiliate?.link)
        toast.success("Affiliate Link Copied", { id: "affiliate-link" })
    }

    const shareAffiliateLink = async () => {
        const shareText = `Get premium access to AI song generation at SOME! Use my affiliate link to subscribe:

${user?.affiliate?.link}`;
        try {
            if (navigator.share) {
                await navigator.share({
                    text: shareText
                });
            } else {
                copyAffiliateLink()
            }
        } catch (error) {
            console.error("Error sharing:", error);
            toast.error("Failed to share", { id: "affiliate-link" });
        }
    }

    useEffect(() => {
        const fetchStats = async () => {
            try {
                setLoadingStats(true)
                const token = localStorage.getItem('token')
                const { data } = await axios.get("/affiliate/stats", { headers: { Authorization: `Bearer ${token}` } })
                setStats(data)
            } catch (error) {
                console.log(error)
                toast.error(error.response?.data?.detail || error.message || "Something went wrong fetching stats", { id: "affiliate-stats" })
            } finally {
                setLoadingStats(false)
            }

        }
        if (!user?.is_guest) {
            fetchStats()
        }
    }, [user])

    useEffect(() => {
        window.scrollTo(0, 0)
        logEvent(analytics, 'page_view', { page_title: 'Affiliate Program Page' });
        trackMetaEvent('PageView', { page_title: 'Affiliate Program Page' });
        trackLinkedInEvent('page_view', {
            event: 'page_view',
            page_title: 'Affiliate Program Page',
        })
        trackRedditEvent('PageVisit', {
            page_title: 'Affiliate Program Page',
        })
    }, [])

    return (
        <div className="relative min-h-screen bg-slate-950 dark:bg-gradient-to-br dark:from-pink-100 dark:via-pink-200 dark:to-pink-100 overflow-hidden flex flex-col flex-grow w-full font-poppins">
            <TopBar heading={"Affiliate Program"} />
            <div className="md:pt-20 pt-20 flex flex-col flex-grow overflow-hidden px-4">
                <h3 className="text-2xl font-bold text-green-400 dark:text-pink-700">
                    Become an Affiliate
                </h3>
                <p className="text-slate-300 dark:text-slate-700">
                    Share your affiliate link and earn commission! Get 10% commission on first month subscription and 5% on renewals when users subscribe through your link.
                </p>
                {((user?.affiliate?.code && !user?.affiliate?.is_active) || user?.plan === 0) && (
                    <div className="mt-4">
                        {(user?.affiliate?.is_active && user?.affiliate?.code) && (
                            <p className="text-red-500 dark:text-red-600 font-bold">Affiliate is Inactive</p>
                        )}
                        <p className="text-amber-400 dark:text-amber-600 mb-2">
                            You need an active subscription or must have subscribed in the past 6 months to participate in our affiliate program.
                        </p>
                    </div>
                )}
                <div className="w-full max-w-md">
                    {user?.affiliate?.code ? (
                        <div className="flex gap-2 items-center mt-1">
                            <p className={
                                "text-sm flex-grow font-poppins font-medium break-all rounded-md py-2 truncate " +
                                (user?.affiliate?.is_active
                                    ? "text-green-400/90 dark:text-pink-700"
                                    : "text-slate-500 dark:text-slate-600 cursor-not-allowed")
                            }>
                                {user?.affiliate?.link}
                            </p>
                            <button
                                disabled={!user?.affiliate?.is_active}
                                onClick={copyAffiliateLink}
                                type="button"
                                className="disabled:opacity-50 disabled:cursor-not-allowed flex-shrink-0 w-6 h-6 bg-green-500 dark:bg-pink-600 hover:bg-green-400 dark:hover:bg-pink-500 text-black dark:text-white flex items-center justify-center rounded font-medium"
                            >
                                <Copy className="ml-0.5" size={16} />
                            </button>
                            {user?.affiliate?.code && (
                                <button
                                    disabled={!user?.affiliate?.is_active}
                                    onClick={shareAffiliateLink}
                                    type="button"
                                    className="disabled:opacity-50 disabled:cursor-not-allowed flex-shrink-0 w-6 h-6 bg-green-500 dark:bg-gradient-to-br dark:from-pink-600 dark:to-pink-900 hover:bg-green-400 dark:hover:opacity-90 text-black dark:text-white flex items-center justify-center rounded font-medium"
                                >
                                    <Share2 className="ml-0.5" size={16} />
                                </button>
                            )}
                        </div>
                    ) : (
                        <button
                            disabled={loading || user?.plan === 0 || user?.affiliate?.is_active}
                            className={`mt-4 text-sm px-4 py-2 rounded w-full font-medium flex items-center justify-center ${loading || user?.plan === 0 || user?.affiliate?.is_active
                                    ? 'opacity-50 cursor-not-allowed bg-green-500 dark:bg-pink-500'
                                    : 'bg-green-500 hover:bg-green-400 dark:bg-gradient-to-br dark:from-pink-600 dark:to-pink-900 dark:hover:opacity-90'
                                } text-black dark:text-white`}
                            onClick={generateAffiliateLink}
                        >
                            {loading && <Loader2 className="mr-2 animate-spin" size={16} />}
                            {loading ? "Creating..." : "Create Affiliate Link"}
                        </button>
                    )}
                </div>
                {user?.affiliate?.code && (
                    <div className="mt-8">
                        <h5 className="text-xl font-semibold text-green-400 dark:text-pink-700 mb-4 border-b border-green-400/20 dark:border-pink-700/20 pb-2">
                            Statistics
                        </h5>
                        <div className="mt-4 flex items-center gap-4">
                            <div className="flex flex-col gap-2 border border-green-400/20 dark:border-pink-700/20 rounded-xl p-4 w-56 bg-gradient-to-br from-slate-950 to-slate-900 dark:from-pink-100 dark:to-pink-200">
                                <div className="relative">
                                    <Users size={50} className="text-black bg-gradient-to-r from-green-400 to-teal-400 dark:from-pink-600 dark:to-pink-900 p-2 rounded-lg" />
                                    <Plus size={22} className="absolute top-[30px] right-[9.6rem] -mt-2 -mr-2 bg-green-400 dark:bg-pink-600 text-black rounded-full p-1" />
                                </div>
                                <p className="text text-slate-200 dark:text-slate-700 mt-2 font-medium">
                                    Total Referrals
                                </p>
                                {loadingStats ? (
                                    <Loader2 className="animate-spin" size={20} />
                                ) : (
                                    <p className="text-xl font-semibold text-green-400 dark:text-pink-700 ml-1">
                                        {stats?.total_referred}
                                    </p>
                                )}
                            </div>
                            <div className="flex flex-col gap-2 border border-green-400/20 dark:border-pink-700/20 rounded-xl p-4 w-56 bg-gradient-to-br from-slate-950 to-slate-900 dark:from-pink-100 dark:to-pink-200">
                                <DollarSign
                                    size={50}
                                    className="text-black bg-gradient-to-r from-green-400 to-teal-400 dark:from-pink-600 dark:to-pink-900 p-2 rounded-lg"
                                />
                                <p className="text text-slate-200 dark:text-slate-700 mt-2 font-medium">
                                    Total Earnings
                                </p>
                                {loadingStats ? (
                                    <Loader2 className="animate-spin" size={20} />
                                ) : (
                                    <p className="text-xl font-semibold text-green-400 dark:text-pink-700 ml-1">
                                        {stats?.total_earnings}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <BottomBar />
        </div>
    )
}

export default Affliliate