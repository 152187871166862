import { useState } from 'react';
import { Dialog, DialogContent, DialogClose, DialogTitle, DialogDescription, DialogOverlay } from '../../components/ui/dialog';
import { X, Star, ThumbsUp, MessageSquare, Frown, Smile } from 'lucide-react';
import { useLocation, useParams, matchPath } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { SET_FEEDBACK } from '@/redux/features/theme/extradialogs';

function FeedbackDialog() {
  const location = useLocation();
  const { slug } = useParams();
  const { user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);

  // Route-specific content configuration
  const routeMessages = {
    '/create': {
      route: '/create',
      title: 'Love Creating AI Music? We\'d love to hear from you!',
      description: 'How can we improve your music creation experience?',
      categoryPrefix: 'creation'
    },
    '/': {
      route: '/home',
      title: 'Welcome to the World of AI Music Creation!',
      description: 'Share your thoughts on how we can enhance your journey in crafting unique AI-generated melodies. Your feedback is the key to unlocking new creative possibilities!',
      categoryPrefix: 'creation'
    },
    '/premium': {
      route: '/premium',
      title: 'Have Thoughts About Our Plans? We\'d love to hear from you!',
      description: 'Help us improve our subscription offerings!',
      categoryPrefix: 'subscription'
    },
    '/playlist': {
      route: '/playlist',
      title: 'How Can We Improve Your Library? We\'d love to hear from you!',
      description: 'Your feedback helps us organize better!',
      categoryPrefix: 'library'
    },
    '/leaderboard': {
      route: '/leaderboard',
      title: 'How Can We Improve Leaderboard System? We\'d love to hear from you!',
      description: 'Your feedback helps us organize better!',
      categoryPrefix: 'leaderboard'
    },
    '/advertisement-dashboard': {
      route: '/advertisement-dashboard',
      title: 'How Can We Improve Your Advertisement Dashboard? We\'d love to hear from you!',
      description: 'Your feedback helps us organize better!',
      categoryPrefix: 'advertisement'
    },
    '/affiliate': {
      route: '/affiliate',
      title: 'How Can We Improve Your Affiliate Program? We\'d love to hear from you!',
      description: 'Your feedback helps us organize better!',
      categoryPrefix: 'affiliate'
    },
    '/song/:slug': {
      route: `/song/${slug || ':slug'}`,
      title: 'How Can We Improve Songs? We\'d love to hear from you!',
      description: 'Your feedback helps us improve music quality!',
      categoryPrefix: 'song'
    },
    '/profile/:slug': {
      route: '/profile/:slug',
      title: 'How Can We Improve Profiles? We\'d love to hear from you!',
      description: 'Your feedback helps us organize better!',
      categoryPrefix: 'profile'
    },
    '/gift/success/:code?': {
      route: '/gift/success/:code?',
      title: 'How Can We Improve Gifting Experience? We\'d love to hear from you!',
      description: 'Your feedback helps us organize better!',
      categoryPrefix: 'gift'
    },
    // Add more routes as needed
    default: {
      title: 'Love Listening to AI Music? We\'d love to hear from you!',
      description: 'Help shape the future of AI meme music! Your feedback powers our creativity.',
      categoryPrefix: 'general'
    }
  };

  // Find matching route using React Router's matchPath
  const matchedRoute = Object.entries(routeMessages).find(([path]) =>
    matchPath({ path, end: true }, location.pathname)
  );

  const currentRouteConfig = matchedRoute
    ? matchedRoute[1]
    : routeMessages.default;

  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const dispatch = useDispatch();
  const { isOpenFeedbackDialog } = useSelector((state) => state.extradialogs);
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState({
    rating: 0,
    description: '',
    review_type: '',
    name: user?.name || 'Anonymous',
    email: user?.email || ''
  });

  const submitFeedback = async () => {
    try {
      // Validate required fields
      if (rating === 0) {
        toast.error('Please select a rating before submitting', { id: 'feedback' });
        return;
      }
      if (!feedback.trim()) {
        toast.error('Please write your feedback before submitting', { id: 'feedback' });
        return;
      }
      if (!selectedCategory) {
        toast.error('Please select a feedback category', { id: 'feedback' });
        return;
      }

      setLoading(true);
      toast.loading('Submitting your feedback...', { id: 'feedback' });

      const response = await fetch('https://testapi.soundofmeme.com/reviews', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({
          rating,
          review_type: selectedCategory.toLowerCase().replace(' ', '_'),
          description: `${feedback} - Feedback for ${location.pathname}`,
          name: user?.name || 'Anonymous',
          email: user?.email || ''
        })
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success('Thank you! Your feedback has been submitted.', { id: 'feedback' });
        // Only close on success
        dispatch(SET_FEEDBACK(false));
        // Reset form
        setRating(0);
        setFeedback('');
        setSelectedCategory(null);
      } else {
        toast.error(`Submission failed: ${responseData.message || 'Please try again.'}`, { id: 'feedback' });
      }
    } catch (error) {
      toast.error('Network error. Please check your connection.', { id: 'feedback' });
    } finally {
      setLoading(false);
    }
  };

  const categories = [
    { icon: <ThumbsUp className="sm:w-5 sm:h-5 w-4 h-4" />, label: 'General Feedback', value: 'general_feedback' },
    { icon: <MessageSquare className="sm:w-5 sm:h-5 w-4 h-4" />, label: 'Feature Request', value: 'feature_request' },
    { icon: <Frown className="sm:w-5 sm:h-5 w-4 h-4" />, label: 'Bug Report', value: 'bug_report' },
    { icon: <Smile className="sm:w-5 sm:h-5 w-4 h-4" />, label: 'Compliment', value: 'compliment' }
  ];

  const handleCategoryChange = (label) => {
    if (selectedCategory === label) {
      setSelectedCategory(null);
      setValue('review_type', 'general_feedback');
    } else {
      setSelectedCategory(label);
      setValue('review_type', label.toLowerCase().replace(' ', '_'));
    }
  }

  return (
    <Dialog open={isOpenFeedbackDialog} onOpenChange={() => dispatch(SET_FEEDBACK(false))}>
      <DialogOverlay className="bg-black/70 backdrop-blur-md z-[999]">
        <DialogContent className="z-[9999] w-[95%] sm:max-w-md bg-gradient-to-br from-slate-900 to-slate-950 border border-green-500/20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-xl shadow-xl text-slate-200">
          <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-green-400 via-teal-500 to-green-400"></div>
          <DialogClose asChild className="z-[9999] absolute top-3 right-3 hover:bg-slate-800/50 p-1 rounded-full transition-colors">
            <button onClick={() => dispatch(SET_FEEDBACK(false))}>
              <X size={18} className="text-slate-400 hover:text-green-400" />
            </button>
          </DialogClose>

          <div className="px-0 pt-8 pb-6">
            <DialogTitle className="mb-2">
              <h1 className="text-xl font-medium text-center bg-gradient-to-r from-green-400 to-teal-400 bg-clip-text text-transparent">
                {currentRouteConfig.title}
              </h1>
            </DialogTitle>

            <DialogDescription className="text-center text-slate-400 text-sm mb-6">
              {currentRouteConfig.description}
            </DialogDescription>

            <div className="flex flex-col items-center gap-5">
              {/* Star Rating */}
              {rating === 0 && (
                <div className="flex flex-col items-center gap-3">
                  <span className="text-sm text-slate-300">How would you rate your experience?</span>
                  <div className="flex gap-1.5">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <button 
                        key={star} 
                        onClick={() => setRating(star)} 
                        className="p-1.5 transition-all duration-200 hover:scale-110"
                      >
                        <Star 
                          size={24} 
                          className={`${star <= rating ? 'fill-yellow-400 text-yellow-400' : 'text-slate-600 hover:text-slate-400'} transition-colors`} 
                        />
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {/* After Rating UI */}
              {rating > 0 && (
                <div className="w-full space-y-5">
                  {/* Rating Display */}
                  <div className="flex justify-center mb-1">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star 
                        key={star} 
                        size={24} 
                        onClick={() => setRating(star)}
                        className={`${star <= rating ? 'fill-green-400 text-green-400' : 'text-slate-600'} cursor-pointer mx-0.5`} 
                      />
                    ))}
                  </div>
                  
                  {/* Category Pills */}
                  <div className="grid grid-cols-2 sm:gap-2 gap-1">
                    {categories.map(({ icon, label }) => {
                      const value = label.toLowerCase().replace(' ', '_');
                      return (
                        <button
                          key={value}
                          onClick={() => handleCategoryChange(value)}
                          className={`flex items-center sm:gap-1.5 gap-1 sm:px-3 px-2 py-1.5 rounded-sm text-sm transition-all ${
                            selectedCategory === value 
                              ? 'bg-green-400/80 border border-green-500/40 text-black' 
                              : 'bg-slate-800/50 border border-slate-700/50 hover:border-green-500/30 text-slate-300'
                          }`}
                        >
                          <span className="text-xs">{icon}</span>
                          {label}
                        </button>
                      );
                    })}
                  </div>

                  {/* Feedback Text Area */}
                  <div className="relative mt-2">
                    <textarea
                      placeholder={`Tell us more about your ${selectedCategory || currentRouteConfig.categoryPrefix} feedback...`}
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                      className="w-full min-h-[80px] px-3 py-2 rounded-lg bg-slate-800/30 border border-slate-700/50 focus:border-green-400/50 focus:ring-1 focus:ring-green-400/20 transition-all resize-none text-sm"
                    />
                    
                    <div className="flex justify-between items-center mt-1 text-xs">
                      {feedback.length > 0 && feedback.length < 10 ? (
                        <div className="text-amber-400 flex items-center gap-1">
                          <span>⚠️</span>
                          Please add more details ({feedback.length}/10)
                        </div>
                      ) : (
                        <div className="text-slate-500">Min 10 characters</div>
                      )}
                      <div className="text-slate-500">
                        {feedback.length} chars
                      </div>
                    </div>
                  </div>

                  {/* Submit Button */}
                  <button 
                    onClick={submitFeedback} 
                    disabled={loading || feedback.length < 10} 
                    className={`w-full py-2.5 rounded-lg font-medium text-sm transition-all ${
                      loading || feedback.length < 10
                        ? 'bg-slate-700/50 text-slate-400 cursor-not-allowed'
                        : 'bg-gradient-to-r from-green-500 to-teal-500 hover:from-green-400 hover:to-teal-400 text-black shadow-lg shadow-green-500/20'
                    }`}
                  >
                    {loading ? (
                      <span className="flex items-center justify-center gap-2">
                        <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Submitting...
                      </span>
                    ) : 'Submit Feedback'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </DialogOverlay>
    </Dialog>
  );
}

export default FeedbackDialog;