import ArtistCardSkeleton from "../artists/ArtistCardSkeleton"

const FeaturedArtistSkeleton = ({ lazyLoad = false }) => {
    return (
        <div className={lazyLoad ? "pt-4 px-4 py-6" : ""}>
            {lazyLoad && <h2 className='text-xl xl:text-2xl font-poppins font-bold text-slate-200 dark:text-black mb-4'>
                Featured Artists
            </h2>}
            <div className='flex items-center gap-8 overflow-x-auto w-full whitespace-nowrap scrollbar-hide'>
                {Array.from({ length: 20 }).map((_, i) => (
                    <div key={i} className="!w-24 sm:!w-28 2xl:!w-32">
                        <ArtistCardSkeleton />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default FeaturedArtistSkeleton