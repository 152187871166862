import axios from "axios"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { PLAY_LIVESONG, SET_CREATEDESC, SET_CUSTOMDESC, SET_CUSTOMLYRICS, SET_CUSTOMTITLE, SET_SELECTEDTAB, STOP_LIVESONG } from "@/redux/features/music/createSlice"
import { SET_PLAYING, SET_SONG } from "@/redux/features/music/musicSlice"
import { useNavigate } from "react-router-dom"
import { SET_DIALOG_OPEN_TWO, SET_SONG_TO_ADD_TWO } from "@/redux/features/music/playlistSlice"
import { SET_SHOW_LOGIN } from "@/redux/features/auth/authSlice"
import { LoaderCircle, Play, Square, GlobeLock, Globe, Earth, RefreshCcw, Heart, FolderPlus } from "lucide-react"

const CreatingSongsLoading = ({ ani, song: creatingSong, createPage = false }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [song, setSong] = useState(creatingSong)
    const [loading, setLoading] = useState(false)
    const [isValidUrl, setIsValidUrl] = useState(false);
    const { liveSong } = useSelector(state => state.create)
    const [liked, setLiked] = useState(song?.is_liked)
    const [likedLoading, setLikedLoading] = useState(false)
    const { user } = useSelector(state => state.auth)

    const handlePublish = async (e) => {
        if (!user || user?.is_guest) {
            dispatch(SET_SHOW_LOGIN(true))
            return toast.error("Login to publish", { id: "publish" })
        }
        e.stopPropagation()
        const token = window.localStorage.getItem('token')


        try {
            if (song?.publish) {
                setLoading(true)
                toast.loading("Unpublishing", { id: "publish" })
                await axios.post("/private",
                    { song_id: song?.song_id },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                setSong(prevSong => ({
                    ...prevSong,
                    publish: !prevSong.publish
                }));
                toast.success("Unpublished", { id: "publish" })
                setLoading(false)
            } else {
                setLoading(true)
                toast.loading("Publishing", { id: "publish" })
                await axios.post("/public",
                    { song_id: song?.song_id },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                setSong(prevSong => ({
                    ...prevSong,
                    publish: !prevSong.publish
                }));
                toast.success("Published", { id: "publish" })
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
            toast.error(error?.response?.data?.detail || "Error Occurred", { id: "publish" })
        }
    }

    useEffect(() => {
        // Function to extract song_uuid from the stream URL
        const checkSongUuid = () => {
            if (song?.stream === undefined) {
                return false
            }
            const url = new URL(song?.stream);
            const songUuid = url.searchParams.get("song_uuid");
            return songUuid && songUuid.trim() !== "" && songUuid !== "null" && songUuid !== "None";
        };

        // Check if song_uuid is valid
        setIsValidUrl(checkSongUuid());
    }, [song?.stream]);

    const handleRecreate = (song) => {

        if (song?.prompt === null || song.prompt === '' || song.prompt === undefined) {
            toast.error('Feature not available on this song', { id: "recreate" })
            return
        }
        if (song?.prompt?.type === "create" || song?.prompt?.type === "custom") {
            if (song.prompt.type === "create") {
                dispatch(SET_SELECTEDTAB('create'))
                dispatch(SET_CREATEDESC(song.prompt.desc))
                navigate("/create")
            } else if (song.prompt.type === "custom") {
                dispatch(SET_SELECTEDTAB('custom'))
                dispatch(SET_CUSTOMDESC(song.prompt.genre))
                dispatch(SET_CUSTOMTITLE(song.prompt.title2))
                dispatch(SET_CUSTOMLYRICS(song.prompt.lyrics2))
                navigate("/create")
            }
        } else {
            toast.error('Feature not available on this song', { id: "recreate" })
            return
        }
    }

    const togglePlayPauseLiveSong = () => {
        if (!isValidUrl) {
            return toast.error('Loading live song...', { id: "playPause" })
        }
        if (liveSong?.isPlaying && liveSong?.streamUrl === song?.stream) {
            dispatch(STOP_LIVESONG());
        } else {
            dispatch(SET_PLAYING(false));
            dispatch(SET_SONG(null))
            // Small delay to ensure previous audio operations are completed
            setTimeout(() => {
                dispatch(PLAY_LIVESONG({
                    streamUrl: song?.stream,
                    isPlaying: true,
                    isBuffering: true  // Start with buffering true
                }));
            }, 100);
        }
    };

    // like song
    const handleLike = async (songId) => {
        if (!user || user?.is_guest) {
            dispatch(SET_SHOW_LOGIN(true))
            return toast.error("Login to like the song", { id: "guest" })
        }
        const token = window.localStorage.getItem('token');
        if (!user || !token) {
            return toast.error("Login to like the song", { id: "likeSong" });

        }
        try {
            setLikedLoading(true);
            const isLiking = !liked;
            const endpoint = isLiking ? "/like" : "/dislike";
            const loadingMessage = isLiking ? "Adding to liked songs" : "Removing from liked songs";
            toast.loading(loadingMessage, { id: "likeSong" });
            await axios.post(endpoint,
                { song_id: songId },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setLiked(isLiking)
            toast.success(isLiking ? "Like Added" : "Like Removed", { id: "likeSong" });
            setLikedLoading(false);
        } catch (error) {
            console.log(error);
            setLikedLoading(false);
            toast.error(error.response.data.detail || "Error Occurred", { id: "likeSong" });
        }
    };

    const addToPlaylist = (e) => {
        if (!user || user?.is_guest) {
            dispatch(SET_SHOW_LOGIN(true))
            return toast.error('Login to add to playlist', { id: "guest" })
        }
        e.stopPropagation()
        dispatch(SET_SONG_TO_ADD_TWO(song))
        dispatch(SET_DIALOG_OPEN_TWO(true))
    }


    // update song
    useEffect(() => {
        setSong(creatingSong)
    }, [creatingSong])


    return (
        <div className="py-1 border-y border-green-600/50 dark:border-pink-600/50">
            <div className={"w-full flex items-center gap-2  rounded px-4 " + (createPage ? " xl:h-full " : " sm:h-full ")} >
                <div className="animate-pulse w-full max-w-[80px] h-[80px] object-cover aspect-square rounded " >
                    <img src={song?.image_url ? song?.image_url : ani} alt="animations" className="w-full rounded-lg" />
                </div>
                <div className="  flex items-center gap-4 flex-grow h-full truncate" >
                    <div className="  flex flex-col justify-between gap-2 h-full flex-grow truncate" >
                        {(!song?.song_name || song?.song_name === "") ? <div className=" animate-pulse h-4 rounded-full mt-1 max-w-sm w-full bg-slate-800 dark:bg-pink-300/50" ></div> : <p className={"animate-pulse font-lato font-semibold truncate lg:text-xl capitalize cursor-pointer text-slate-200 dark:text-pink-900 "} >{song?.song_name}</p>}
                        {(song?.tags?.length === 0 || !song?.tags) ? <div className="flex items-center gap-2 animate-pulse" >
                            <div className="w-7 bg-slate-800 dark:bg-pink-300/50 rounded-full h-3"></div>
                            <div className="w-7 bg-slate-800 dark:bg-pink-300/50 rounded-full h-3"></div>
                            <div className="w-7 bg-slate-800 dark:bg-pink-300/50 rounded-full h-3"></div>
                        </div> : <div className={" animate-pulse select-none flex gap-2 text-xs font-semibold text-slate-400 dark:text-pink-700 font-comforta "} >
                            {song?.tags?.slice(0, 3)?.map((tag, index) => (
                                <span key={index} className="capitalize cursor-default truncate" >{tag}</span>
                            ))}
                        </div>}
                        <div className="flex items-center gap-1 truncate animate-pulse" >
                            {user?.profile_url ? <img src={user?.profile_url} alt="profile" className="w-5 h-5 rounded-full border border-green-400 dark:border-pink-400 flex-shrink-0" /> : <div className="w-5 h-5 rounded-full bg-green-400 dark:bg-pink-400 flex items-center justify-center border border-green-400 dark:border-pink-400 flex-shrink-0" >
                                <span className="text-black dark:text-pink-900 capitalize text-xs font-potta" >{user?.username?.charAt(0)}</span>
                            </div>}
                            <p className="text-xs text-slate-300 dark:text-pink-800 hover:text-green-400 dark:hover:text-pink-600 font-comforta capitalize truncate max-w-[100px]" >{user?.username}</p>
                        </div>
                    </div>
                    <div className={" items-center h-full justify-end gap-10 lg:gap-12 xl:gap-12 2xl:gap-16 3xl:gap-20 flex-grow " + (createPage ? " hidden xl:flex " : " sm:flex hidden ")} >
                        <div className="relative group">
                            <button disabled={!isValidUrl} onClick={togglePlayPauseLiveSong} className={`rounded-full focus:outline-none focus:shadow-outline  aspect-square overflow-hidden flex items-center justify-center ${isValidUrl ? "" : "opacity-50 animate-pulse"}`} >
                                {(liveSong?.streamUrl === song?.stream && liveSong?.isBuffering) ? (
                                    <LoaderCircle size={18} className="text-gray-500 dark:text-pink-700 animate-spin" />
                                ) : (liveSong?.isPlaying && liveSong?.streamUrl === song?.stream) ? (
                                    <Square size={18} className="text-red-500 dark:text-red-600 fill-red-500 dark:fill-red-600 animate-text" />
                                ) : (
                                    <Play size={18} className={"ml-[2px] cursor-pointer" + (isValidUrl ? "text-green-400 fill-green-400 dark:text-pink-600 dark:fill-pink-600" : "text-slate-300 fill-slate-300 dark:text-pink-400 dark:fill-pink-400")} />
                                )}
                            </button>
                            <div className="absolute z-50 bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-200 text-xs text-slate-200 dark:text-pink-900 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                {(liveSong?.streamUrl === song?.stream && liveSong?.isBuffering) ? "Loading..." :
                                    (liveSong?.isPlaying && liveSong?.streamUrl === song?.stream) ? "Stop" : "Live Play"}
                            </div>
                        </div>
                        <div className="relative group mr-1">
                            <button disabled={!song?.username} type="button" onClick={() => handleRecreate(song)} className={"flex items-center text-xs gap-1 text-slate-300 dark:text-pink-700 " + (song?.prompt?.type === "create" || song?.prompt?.type === "custom" ? " hover:text-green-400 dark:hover:text-pink-500" : "opacity-30 animate-pulse")} ><RefreshCcw size={18} /></button>
                            <div className="absolute z-50 bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-200 text-xs text-slate-200 dark:text-pink-900 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                Recreate
                            </div>
                        </div>
                        <div className="relative group">
                            <button disabled={likedLoading || !song?.username} type="button" onClick={() => handleLike(song?.song_id)} className={" disabled:opacity-50 flex items-center text-xs gap-1 " + ((song?.username) ? " " : " animate-pulse opacity-30 ") + (liked ? " text-green-400 dark:text-pink-500" : " text-slate-300 dark:text-pink-700")} >{liked ? <Heart className="fill-green-400" size={18} /> : <Heart size={18} />}</button>
                            <div className="absolute z-50 bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-200 text-xs text-slate-200 dark:text-pink-900 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                Like
                            </div>
                        </div>
                        <div className="relative group ">
                            <button disabled={loading || !song?.username} type="button" onClick={handlePublish} className={" disabled:opacity-50 flex items-center text-xs gap-1 text-slate-300 dark:text-pink-700 " + (song?.username ? " hover:text-green-400 dark:hover:text-pink-500" : "animate-pulse opacity-30")} >
                                {!song?.publish ? <GlobeLock size={18} /> : <Earth size={18} />}
                            </button>
                            {song?.publish && <div className="absolute z-10 bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-200 text-xs text-slate-200 dark:text-pink-900 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                {song?.publish ? "Unpublish" : "Publish"}
                            </div>}
                        </div>
                        <div className="relative group mr-1.5">
                            <button disabled={!song?.username} type="button" onClick={addToPlaylist} className={"flex items-center text-xs gap-1 text-slate-300 dark:text-pink-700 " + (song?.username ? " hover:text-green-400 dark:hover:text-pink-500" : "animate-pulse opacity-30")} ><FolderPlus size={18} /></button>
                            <div className="absolute z-10 bottom-full right-0 mb-2 px-2 py-1 bg-slate-800 dark:bg-pink-200 text-xs text-slate-200 dark:text-pink-900 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                Add to playlist
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"  items-center justify-between px-4 pt-2.5 pb-1 " + (!createPage ? " sm:hidden flex " : " xl:hidden flex")} >
                <button disabled={!isValidUrl} onClick={togglePlayPauseLiveSong} className={`rounded-full focus:outline-none focus:shadow-outline  aspect-square overflow-hidden flex items-center justify-center ${isValidUrl ? "" : "opacity-30 animate-pulse"}`} >
                    {(liveSong?.streamUrl === song?.stream && liveSong?.isBuffering) ? (
                        <LoaderCircle size={16} className="text-gray-500 animate-spin" /> // Loading spinner
                    ) : (liveSong?.isPlaying && liveSong?.streamUrl === song?.stream) ? (
                        <Square size={16} className="text-red-500 fill-red-500 animate-text" />
                    ) : (
                        <Play size={16} className={"ml-[2px] " + (isValidUrl ? " text-green-400 fill-green-400" : "text-slate-300 fill-slate-300")} />
                    )}
                </button>
                <button disabled={!song?.username} type="button" onClick={() => handleRecreate(song)} className={"flex items-center text-xs gap-1 text-slate-300  " + (song?.prompt?.type === "create" || song?.prompt?.type === "custom" ? " hover:text-green-400 " : "opacity-30 animate-pulse")} ><RefreshCcw size={18} /></button>
                <button disabled={likedLoading || !song?.username} type="button" onClick={() => handleLike(song?.song_id)} className={" disabled:opacity-50 flex items-center text-xs gap-1 " + ((song?.username) ? " " : " animate-pulse opacity-30 ") + (liked ? " text-green-400 " : " text-slate-300 ")} >{liked ? <Heart className="fill-pink-400" size={18} /> : <Heart size={18} />}</button>
                <button disabled={loading || !song?.username} type="button" onClick={handlePublish} className={" disabled:opacity-50 flex items-center text-xs gap-1 text-slate-300 " + (song?.username ? " hover:text-green-400  " : "animate-pulse opacity-30")} >{song?.publish ? <GlobeLock size={18} /> : <Globe size={18} />}</button>
                <button disabled={!song?.username} type="button" onClick={addToPlaylist} className={"flex items-center text-xs gap-1 text-slate-300 " + (song?.username ? " hover:text-green-400  " : "animate-pulse opacity-30")} ><FolderPlus size={18} /></button>
            </div>
        </div>
    )
}

export default CreatingSongsLoading
