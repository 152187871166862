import { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import imageCompression from "browser-image-compression";
import { Button } from "@/components/ui/button";
import { LoaderCircle } from "lucide-react";

const EasyCropper = ({ image, onCroppedImage, close }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set the canvas size to be square
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // Draw white background (for transparent images)
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw the image
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // Create circular clipping path
    ctx.globalCompositeOperation = "destination-in";
    ctx.beginPath();
    ctx.arc(
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2,
      0,
      Math.PI * 2
    );
    ctx.closePath();
    ctx.fill();

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png"); // Changed to PNG to better handle transparency
    });
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const croppedImage = await getCroppedImg(image, croppedArea);

      const compressedImage = await imageCompression(croppedImage, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      });

      onCroppedImage(compressedImage);
    } catch (e) {
      console.error("Error:", e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative">
      <div className="flex flex-col md:items-start items-center gap-1 md:pl-16 pb-6">
        <h1 className="text-2xl md:text-xl">Crop your Photo</h1>
        <h4 className="text-slate-400 dark:text-black font-normal text-base md:text-xs sm:text-md">
          Upload square image for best results
        </h4>
      </div>
      <div className="relative h-[18rem] md:w-[36rem] w-[26rem]">
        {image && (
          <>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              showGrid={true}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </>
        )}
      </div>
      <div className="flex justify-center md:justify-end md:pr-16 pt-6 gap-16 md:gap-4">
        <Button
          type="submit"
          onClick={handleSave}
          disabled={isLoading}
          className="bg-green-400 px-8 hover:bg-green-300 text-slate-800 dark:bg-pink-700 dark:hover:bg-pink-600 dark:text-black"
        >
          {isLoading ? (
            <LoaderCircle size={18} className="animate-spin" />
          ) : (
            "Save"
          )}
        </Button>
        <Button
          type="button"
          className="bg-slate-700 px-6 hover:bg-slate-800 text-slate-300 dark:bg-pink-100 dark:hover:bg-pink-200 dark:text-black dark:border-1 dark:border dark:border-pink-400"
          onClick={close}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EasyCropper;
