import { Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogOverlay, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { analytics } from "@/firebase";
import { getUserDetails } from "@/redux/features/auth/authSlice";
import { trackLinkedInEvent } from "@/utils/linkedinTag";
import { trackMetaCustomEvent } from "@/utils/metaPixel";
import { trackRedditCustomEvent } from "@/utils/redditPixel";
import axios from "axios";
import { logEvent } from "firebase/analytics";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { AlertTriangle } from "lucide-react";

const CancelSubscription = () => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const cancelBtn = useRef(null)

    const cancelSubs = async () => {
        const token = localStorage.getItem('token')
        try {
            setLoading(true)
            toast.loading("Cancelling Subscription...", { id: "subs" })
            const { data } = await axios.post("/cancelsub", {}, { headers: { Authorization: `Bearer ${token}` } })
            await dispatch(getUserDetails(token)).unwrap()
            toast.success(data?.status, { id: "subs" })
            setLoading(false)
            if (cancelBtn.current) {
                cancelBtn.current.click()
            }
            logEvent(analytics, 'CancelSubscription', {
                success: true,
            });
            trackMetaCustomEvent('CancelSubscription', {
                success: true,
            });
            trackLinkedInEvent('CancelSubscription', {
                event: 'CancelSubscription',
                success: true,
            });
            trackRedditCustomEvent('CancelSubscription', {
                event: 'CancelSubscription',
            });
        } catch (error) {
            setLoading(false)
            console.log(error);
            toast.error(error?.response?.data?.detail || error.message || "Oops! Something went wrong. Please try again.", { id: "subs" });
        }
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                <button type="button" className='text-black bg-red-500 hover:bg-red-400 text-xs font-medium  px-3 py-1 rounded' >Cancel Subscription</button>
            </DialogTrigger>
            <DialogOverlay className="z-[9999]" >
                <DialogContent className="max-w-sm z-[9999] w-[90%] sm:w-full p-4 bg-slate-900 border-red-700 dark:bg-pink-200 dark:border-pink-900 dark:border-2 rounded-xl">
                    <DialogHeader>
                        <DialogTitle className="text-red-500 dark:text-pink-900 text-xl font-potta flex items-center gap-1" ><AlertTriangle size={28} /> Cancel Subscription</DialogTitle>
                        <DialogDescription className="text-left text-slate-300 dark:text-pink-600 font-medium" >
                            By canceling your subscription, you&apos;ll keep your remaining credits until the end of your subscription month. However, all other perks will be revoked immediately.                    </DialogDescription>
                    </DialogHeader>
                    <div className="flex items-center gap-4" >
                        <button disabled={loading} onClick={cancelSubs} type="button" className=" disabled:animate-pulse w-full bg-red-500 hover:bg-red-600 text-black dark:bg-pink-800 dark:hover:bg-pink-700 dark:text-black px-4 py-2 rounded font-medium text-sm" >{loading ? "Cancelling..." : "Cancel Subscription"}</button>
                        <DialogClose asChild>
                            <button ref={cancelBtn} type="button" className="w-full bg-slate-700 hover:bg-slate-600 text-slate-100 px-4 py-2 rounded font-medium text-sm" >No</button>
                        </DialogClose>
                    </div>
                </DialogContent>
            </DialogOverlay>
        </Dialog>
    )
}

export default CancelSubscription